/* ========================*/
/* ==[ Loader ]== */
/*========================*/
.loader{
	width:100vw;
	height:100vh;
	position:fixed;
	z-index: 9999;
	pointer-events:none;
	top:0;
	left:0;

	.bg-yellow{
		background-color:$c-main;
		position:fixed;
		width:100vw;
		height:100vh;
		z-index: 12;
		transform:scaleX(1);
		transform-origin:left center;
	}

	.bg-gray{
		background-color:$c-gray-text;
		position:fixed;
		width:100vw;
		height:100vh;
		z-index: 11;
		transform:scaleX(1);
		transform-origin:left center;	
	}

	.loader-container{
		position: absolute;
		transform:translate(-50%,-50%);
		top:calc(50% - 4vh);
		left:50%;
		z-index: 20;
		display: block;
		opacity:1;
		width:calc(1000px / 2);
		height:calc(700px / 2);
		background: url('../img/sprites.png') left top;
		background-size:calc(1000px / 2) auto;	

		&.active{
			animation: play1 2.8s steps(46) infinite;	
		}

		@media #{$mobile}{
			width:calc(615px / 2);
			height:calc(346px / 2);
			background: url('../img/sprites-1.png') left top;
			background-size:calc(615px / 2) auto;
			&.active{
				animation: play 2.8s steps(52) infinite;	
			}
		}
		
		
		
		@keyframes play1 {
	    	100% { background-position-y: -32425px; }
		}
		@keyframes play {
	    	100% { background-position-y: -17992px; }
		}
	}
}