/* ========================*/
/* ==[ Bullet Navigation ]== */
/*========================*/

.bullet-navigation{
	position: fixed;
	right:0;
	width:60px;
	z-index: 12;
	top:25vh;
	pointer-events:auto;

	@media #{$mobile}{
		display: none;
	}

	ul li{
		width:6px;
		height:6px;
		margin:30px auto;
		background-color: $c-gray-light-text;
		border-radius:50%;
		position:relative;
		transition:transform .3s ease;
		cursor:pointer;
		
		.desktop &:not(.active):hover{
			transform:scale(2);
		}

		&.active{
			background-color:$c-main;
			transform:scale(1);

			&.active-current{
				&:before{
					opacity:1;
				}	
			}
		}
		
		&:before{
			content:'';
			position:absolute;
			transform:translate(-50%,-50%);
			top:50%;
			left:50%;
			width:250%;
			height:250%;
			border-radius:50%;
			border:2px solid $c-main;
			opacity:0;
			transition:opacity .3s ease;
		}
	}
}