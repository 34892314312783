/* ========================*/
/* ==[ About ]== */
/*========================*/
/* [HER] ==== Hero
/* [MAP] ==== Map Block
/* [INF] ==== Information Section
/* [INB] ==== Information Block
/* [LEA] ==== Leaders Block
/* [FUL] ==== Full image slider
/* ========================*/

/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-about{
	.hero-gray .v-line{
		height:120px;
	}

	.stats-block{
		pointer-events: none;
		.container-inner{
			padding-top:0;
			@media #{$smallDesktop}{
				.top-block{
					height:120px;
				}	
			}
			@media #{$tablet}{
				padding-top:20px;
				.top-block{
					height:80px;
				}	
			}
		}
	}
}

/* -----------------------*/
/* [MAP] ==== Map Block
/*------------------------*/
.map-block{
	height:640px;
	background-color:$c-gray-dark;

	@media #{$mobile}{
		height:450px;
	}

	h3{
		@include sectionTitle;	
		color:#fff;
		text-align:center;
		padding-top:100px;
		position: absolute;
		transform:translateX(-50%);
		left:50%;
		z-index: 2;
		will-change:transform;

		@media #{$mobile}{
			padding-top:0px;
			margin-top:50px;
		}
	}
	
	#gmap{
		height:100%;
		z-index: 1;
		position:relative;
	}
}

/* -----------------------*/
/* [INF] ==== Information Section
/*------------------------*/
.page-about .information-section{
	background-color:$c-gray-bg;
}

/* -----------------------*/
/* [LEA] ==== Leaders Block
/*------------------------*/
.page-about .leaders-block{
	&.container-main{
		padding-top: 100px;
		padding-bottom: 40px;	

		@media #{$mobile}{
			padding-top: 50px;
			padding-bottom: 10px;	
		}
	}
	

	.leaders-block__header{
		text-align: center;
		margin-bottom: 100px;

		@media #{$mobile}{
			margin-bottom: 50px;
		}

		h2{
			@include sectionTitle;	
			position:relative;
			margin-bottom:20px;
		}

		p{
			@include mainText;
		}
	}

	.leaders-block__list{
		lost-utility:clearfix;

		@media #{$mobile}{
			padding:0 20px;
		}
	}
}

/* -----------------------*/
/* [FUL] ==== Full image slider
/*------------------------*/
.page-about .fullimage-slider{
	// height:800px;
	background-size:cover;
	display: flex;
	align-items:center;
	justify-content:center;
	position: relative;
	width:100%;
	padding-bottom:55%;

	// @media #{$mobile}{
	// 	height:250px;
	// }

	.fullimage-slider__header{
		width:100%;
		text-align:center;
		z-index: 2;
		position: absolute;
		transform:translate(-50%,-50%);
		top:50%;
		left:50%;


		a{
			font-size:62px;
			color:#fff;
			position:relative;
			transition : color .3s ease;
			text-transform: uppercase;
			margin:0 20px;
			@include MainExtraBold;
			letter-spacing: 1px;

			@media #{$mobile}{
				font-size:15px;
				margin:0 5px;
			}

			&:before{
				content:'';
				width:100%;
				height:5px;
				position:absolute;
				bottom:-5px;
				left:0;
				display: block;
				background-color:#fff;
				transform:scaleX(0);
				transform-origin: center left;
				transition : background-color .3s ease,transform .3s ease;

				@media #{$mobile}{
					height:2px;
				}
			}

			&.active,.desktop &:hover{
				color:$c-main;
				
				&:before{
					background-color:$c-main;
					transform:scaleX(1);
				}
			}
		}
	}

	.background{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index: 1;
		opacity:0;
		transition:opacity .8s ease;
		background-size:cover;
		background-position: center center;
		
		&.active{
			opacity:1;
		}
	}
}


