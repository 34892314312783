/* ========================*/
/* ==[ Typography ]== */
/*========================*/
/* [MTI] ==== Main title
/* [STI] ==== Sub title
/* ========================*/

/* -----------------------*/
/* [MTI] ==== Main title
/*------------------------*/
@mixin mainTitle{
	font-size:$fs-title-big;
	@include MainExtraBold;	
	text-transform:uppercase;
	color:$c-gray-text;
	line-height:1.1em;
	
	@media #{$smallDesktop}{
		font-size:$fs-resize-title-big;
	}
	@media #{$mobile}{
		font-size:40px;
	}

	&.-white{
		color:#fff;
	}
}

@mixin sectionTitle{
	font-size:$fs-text-bigger;
	@include MainBold;
	text-transform: uppercase;
	color:$c-gray-text;
	@media #{$mobile}{
		font-size:26px;
	}
	&.-white{
		color:#fff;
	}
}

@mixin mainTextBig{
	font-size:$fs-text-big;
	@include MainExtraBold;
	color:$c-gray-text;
	@media #{$mobile}{
		font-size:20px;
	}
}

@mixin mainText{
	font-size:$fs-text;
	@include MainBook;
	line-height:2em;
	color:$c-gray-light;
}

/* -----------------------*/
/* [STI] ==== Sub title
/*------------------------*/
@mixin subTitle{
	@include MainBold;
	color : $c-gray-light-text;
	font-size:$fs-subtitle;
	text-transform:uppercase;
	letter-spacing:1px;
	position:relative;

	&.-line{
		&:before{
			content:'';
			position:absolute;
			transform:translateY(-50%);
			top:calc(50% - 1px);
			background-color:$c-gray-light-text;
			height:2px;
			width:55vw;
			transform-origin:right center;
			left:-56vw;

			@media #{$mobile}{
				height:50px;
				width:1px;
				transform:translateX(-50%);
				left:50%;
				top:-70px;

			}
		}
	}
}

