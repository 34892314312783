@charset "UTF-8";
/* ========================*/
/* ==[ Variables ]== */
/*========================*/
/* [COL] ==== Color
/* [FSI] ==== Font size
/* [CON] ==== Config
/* [MED] ==== Media Queries
/* ========================*/
/* -----------------------*/
/* [COL] ==== Color
/*------------------------*/
/* -----------------------*/
/* [FSI] ==== Font size
/*------------------------*/
/* -----------------------*/
/* [CON] ==== Config
/*------------------------*/
@lost rounder 100;
/* -----------------------*/
/* [MED] ==== Media Queries
/*------------------------*/
/*
    HTML5 Reset :: style.css
    ----------------------------------------------------------
    We have learned much from/been inspired by/taken code where offered from:

    Eric Meyer                  :: http://ericmeyer.com
    HTML5 Doctor                :: http://html5doctor.com
    and the HTML5 Boilerplate   :: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, hgroup, nav, section {
  display: block; }

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%; }

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
  don't forget to restore the bullets within content. */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom;
  *vertical-align: baseline; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit; }

/* Accessible focus treatment
    people.opera.com/patrickl/experiments/keyboard/test
a:hover,
a:focus,
a:active {
  outline: none;
}*/
small {
  font-size: 85%; }

strong, th {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't screw with your line-heights
    gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
input[type=button],
input[type=submit],
button {
  cursor: pointer; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* Style clickable inputs in iOS */ }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
/* button {width: auto; overflow: visible;} */
button::-moz-focus-inner,
input::-moz-focus-inner {
  /* Inner padding and border oddities in FF3/4 */
  padding: 0;
  border: 0; }

/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

/* new Linux- and Windows-friendly sans-serif font stack: http://mky.be/fontstack */
body {
  font: 16px Helmet, Freesans, sans-serif; }

body, html {
  width: 100%;
  height: 100%; }

a, button {
  cursor: pointer;
  font-size: 100%; }

button {
  border: 0;
  padding: 0;
  margin: 0; }

* {
  box-sizing: border-box; }

/*::-moz-selection{background: #fcd700; color: #fff; text-shadow: none;}
::selection {background: #fcd700; color: #fff; text-shadow: none;}*/
/*a:link {-webkit-tap-highlight-color: #fcd700;}*/
input, select {
  -webkit-appearance: none;
  border-radius: 0px; }

h1, h2, h3, h4, h5, h6, a, p, span, li, q, th, td, input, div {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); }

input, button {
  outline: none; }

/* ========================*/
/* ==[ Fonts ]== */
/*========================*/
/* [FON] ==== Font declaration 
/* [MIX] ==== Mixin
/* ========================*/
/* -----------------------*/
/* [FON] ==== Font declaration 
/*------------------------*/
@font-face {
  font-family: 'ReneExtraBold';
  src: url("./fonts/reneextrabold.woff2") format("woff2"), url("./fonts/reneextrabold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ReneBold';
  src: url("./fonts/renebold.woff2") format("woff2"), url("./fonts/renebold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ReneBook';
  src: url("./fonts/renebook.woff2") format("woff2"), url("./fonts/renebook.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* -----------------------*/
/* [MIX] ==== Mixin
/*------------------------*/
/* ========================*/
/* ==[ Typography ]== */
/*========================*/
/* [MTI] ==== Main title
/* [STI] ==== Sub title
/* ========================*/
/* -----------------------*/
/* [MTI] ==== Main title
/*------------------------*/
/* -----------------------*/
/* [STI] ==== Sub title
/*------------------------*/
.page-debug.barba-container {
  background-color: #404040;
  width: 100%;
  min-height: 100vh;
  padding: 60px 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .page-debug.barba-container h1 {
    font-size: 40px;
    margin-bottom: 20px; }
  .page-debug.barba-container .button-test {
    margin-top: 40px;
    padding: 50px;
    border: 2px solid gray;
    width: 100%;
    text-align: center; }
    .page-debug.barba-container .button-test .btn:first-of-type {
      margin-bottom: 20px; }
  .page-debug.barba-container .yellow-text-block {
    width: 555px;
    max-width: 100%;
    text-align: center;
    margin: 40px 0;
    font-size: 25px;
    line-height: 1.5em; }
  .page-debug.barba-container .listing {
    width: 1200px;
    padding: 100px;
    margin: auto;
    background-color: white; }
    .page-debug.barba-container .listing .col-1 {
      lost-column: 1/2 2 40px;
      margin-bottom: 100px; }

/* @group Base */
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  user-select: none; }
  .chosen-container * {
    box-sizing: border-box; }
  .chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    z-index: 1010;
    width: 100%;
    border: 1px solid #aaa;
    border-top: 0;
    background: #fff;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    pointer-events: none; }
  .chosen-container.chosen-with-drop .chosen-drop {
    opacity: 1;
    pointer-events: auto; }
  .chosen-container a {
    cursor: pointer; }
  .chosen-container .search-choice .group-name, .chosen-container .chosen-single .group-name {
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #999999; }
    .chosen-container .search-choice .group-name:after, .chosen-container .chosen-single .group-name:after {
      content: ":";
      padding-left: 2px;
      vertical-align: top; }

/* @end */
/* @group Single Chosen */
.chosen-container-single .chosen-single {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 0 0 8px;
  height: 25px;
  border: 1px solid #aaa;
  border-radius: 0px;
  background-color: #fff;
  background: linear-gradient(#fff 20%, #f6f6f6 50%, #eee 52%, #f4f4f4 100%);
  background-clip: padding-box;
  box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #444;
  text-decoration: none;
  white-space: nowrap;
  line-height: 24px; }

.chosen-container-single .chosen-default {
  color: #999; }

.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px; }

.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("../img/icon-dropdown.png") -42px 1px no-repeat;
  font-size: 1px; }
  .chosen-container-single .chosen-single abbr:hover {
    background-position: -42px -10px; }

.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px; }

.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%; }
  .chosen-container-single .chosen-single div b {
    display: block;
    width: 100%;
    height: 100%; }

.chosen-container-single .chosen-search {
  display: none;
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap; }
  .chosen-container-single .chosen-search input[type="text"] {
    margin: 1px 0;
    padding: 4px 20px 4px 5px;
    width: 100%;
    height: auto;
    outline: 0;
    border: 1px solid #aaa;
    background: url("../img/icon-dropdown.png") no-repeat 100% -20px;
    font-size: 1em;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0; }

.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0 0 0px 0px;
  background-clip: padding-box; }

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

/* @end */
/* @group Results */
.chosen-container .chosen-results {
  color: #444;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 0 0 0 0px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch; }
  .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none; }
    .chosen-container .chosen-results li.active-result {
      display: list-item;
      cursor: pointer;
      border-radius: 0px; }
    .chosen-container .chosen-results li.disabled-result {
      display: list-item;
      color: #ccc;
      cursor: default; }
    .chosen-container .chosen-results li.highlighted {
      background-color: #ffb900;
      background-image: linear-gradient(#ffb900 20%, #ffb900 90%);
      color: #fff; }
    .chosen-container .chosen-results li.no-results {
      color: #777;
      display: list-item;
      background: #f4f4f4; }
    .chosen-container .chosen-results li.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default; }
    .chosen-container .chosen-results li.group-option {
      padding-left: 15px; }
    .chosen-container .chosen-results li em {
      font-style: normal;
      text-decoration: underline; }

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  height: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: linear-gradient(#eee 1%, #fff 15%);
  cursor: text; }

.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none; }
  .chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap; }
    .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
      margin: 1px 0;
      padding: 0;
      height: 25px;
      outline: 0;
      border: 0 !important;
      background: transparent !important;
      box-shadow: none;
      color: #999;
      font-size: 100%;
      font-family: sans-serif;
      line-height: normal;
      border-radius: 0; }
  .chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 3px 5px 3px 0;
    padding: 3px 20px 3px 5px;
    border: 1px solid #aaa;
    max-width: 100%;
    border-radius: 0px;
    background-color: #eeeeee;
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-size: 100% 19px;
    background-repeat: repeat-x;
    background-clip: padding-box;
    box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
    color: #333;
    line-height: 13px;
    cursor: default; }
    .chosen-container-multi .chosen-choices li.search-choice span {
      word-wrap: break-word; }
    .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
      position: absolute;
      top: 4px;
      right: 3px;
      display: block;
      width: 12px;
      height: 12px;
      background: url("../img/icon-dropdown.png") -42px 1px no-repeat;
      font-size: 1px; }
      .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
        background-position: -42px -10px; }
  .chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #ccc;
    background-color: #e4e4e4;
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    color: #666; }
  .chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #d4d4d4; }
    .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
      background-position: -42px -10px; }

.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0; }

.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default; }

/* @end */
/* @group Active  */
.chosen-container-active .chosen-single {
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }

.chosen-container-active.chosen-with-drop .chosen-single {
  border: 1px solid #aaa;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  background-image: linear-gradient(#eee 20%, #fff 80%);
  box-shadow: 0 1px 0 #fff inset; }

.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: none;
  background: transparent; }

.chosen-container-active .chosen-choices {
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
  .chosen-container-active .chosen-choices li.search-field input[type="text"] {
    color: #222 !important; }

/* @end */
/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default; }
  .chosen-disabled .chosen-single {
    cursor: default; }
  .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default; }

/* @end */
/* @group Right to Left */
.chosen-rtl {
  text-align: right; }
  .chosen-rtl .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0; }
  .chosen-rtl .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl; }
  .chosen-rtl .chosen-single-with-deselect span {
    margin-left: 38px; }
  .chosen-rtl .chosen-single div {
    right: auto;
    left: 3px; }
  .chosen-rtl .chosen-single abbr {
    right: auto;
    left: 26px; }
  .chosen-rtl .chosen-choices li {
    float: right; }
    .chosen-rtl .chosen-choices li.search-field input[type="text"] {
      direction: rtl; }
    .chosen-rtl .chosen-choices li.search-choice {
      margin: 3px 5px 3px 0;
      padding: 3px 5px 3px 19px; }
      .chosen-rtl .chosen-choices li.search-choice .search-choice-close {
        right: auto;
        left: 4px; }
  .chosen-rtl.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0; }
  .chosen-rtl .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0; }
  .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none; }
  .chosen-rtl .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    background: url("../img/icon-dropdown.png") no-repeat -30px -20px;
    direction: rtl; }
  .chosen-rtl.chosen-container-single .chosen-single div b {
    background-position: 6px 2px; }
  .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
    background-position: -12px 2px; }

/* @end */
/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("../img/icon-dropdown.png") !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important; } }

/* @end */
[data-scrollbar], [scrollbar], scrollbar {
  display: block;
  position: relative; }

[data-scrollbar] .scroll-content, [scrollbar] .scroll-content, scrollbar .scroll-content {
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

[data-scrollbar].sticky .scrollbar-track, [scrollbar].sticky .scrollbar-track, scrollbar.sticky .scrollbar-track {
  background: rgba(222, 222, 222, 0.75); }

[data-scrollbar] .scrollbar-track, [scrollbar] .scrollbar-track, scrollbar .scrollbar-track {
  position: absolute;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity .5s ease-out,background .5s ease-out;
  transition: opacity .5s ease-out,background .5s ease-out;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[data-scrollbar] .scrollbar-track.show, [data-scrollbar] .scrollbar-track:hover, [scrollbar] .scrollbar-track.show, [scrollbar] .scrollbar-track:hover, scrollbar .scrollbar-track.show, scrollbar .scrollbar-track:hover {
  opacity: 1; }

[data-scrollbar] .scrollbar-track:hover, [scrollbar] .scrollbar-track:hover, scrollbar .scrollbar-track:hover {
  background: rgba(222, 222, 222, 0.75); }

[data-scrollbar] .scrollbar-track-x, [scrollbar] .scrollbar-track-x, scrollbar .scrollbar-track-x {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px; }

[data-scrollbar] .scrollbar-track-y, [scrollbar] .scrollbar-track-y, scrollbar .scrollbar-track-y {
  top: 0;
  right: 0;
  width: 8px;
  height: 100%; }

[data-scrollbar] .scrollbar-thumb, [scrollbar] .scrollbar-thumb, scrollbar .scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px; }

[data-scrollbar] .overscroll-glow, [scrollbar] .overscroll-glow, scrollbar .overscroll-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform,height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform,height; }

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px; }

.swiper-container-cube, .swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* ========================*/
/* ==[ Buttons ]== */
/*========================*/
/* [MBT] ==== Main Btn
/* ========================*/
/* -----------------------*/
/* [MBT] ==== Main Btn
/*------------------------*/
.btn, .btn-flat, .btn-special {
  position: relative;
  height: 60px;
  display: block; }
  .btn svg, .btn .label, .btn .arrow, .btn-flat svg, .btn-flat .label, .btn-flat .arrow, .btn-special svg, .btn-special .label, .btn-special .arrow {
    pointer-events: none; }
  .btn .loadings, .btn-flat .loadings, .btn-special .loadings {
    position: absolute;
    right: -40px;
    transform: translate(-30px, -50%);
    opacity: 0;
    top: 50%;
    transition: opacity .3s ease,transform .3s ease; }
  .btn.loading .loadings, .btn-flat.loading .loadings, .btn-special.loading .loadings {
    opacity: 1;
    transform: translate(0, -50%); }
  .btn .label, .btn-flat .label, .btn-special .label {
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 70px;
    padding-right: 10px;
    color: #ffb900;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: "ReneBold", sans-serif;
    will-change: transform; }
  .btn .arrow, .btn-flat .arrow, .btn-special .arrow {
    position: absolute;
    left: 15px;
    transform: translateY(-50%);
    top: 50%; }
  .btn.white-arrow .arrow svg *, .btn-flat.white-arrow .arrow svg *, .btn-special.white-arrow .arrow svg * {
    fill: #fff; }

.btn-flat .label {
  padding-left: 00px; }

/* ========================*/
/* ==[ Shared Styles ]== */
/*========================*/
/* [YTB] ==== Yellow text
/* [IBL] ==== Image para bg
/* [REV] ==== Reveals
/* [INF] ==== Info Icon block
/* [ICO] ==== Icon cross
/* [SEL] ==== Select
/* [INF] ==== Infobox
/* ========================*/
/* -----------------------*/
/* [YTB] ==== Yellow text
/*------------------------*/
.yellow-text-block p {
  font-family: "ReneExtraBold", sans-serif;
  color: #fff;
  line-height: 1.5em; }
  @media only screen and (max-width: 767px) {
    .yellow-text-block p {
      line-height: 1.6em; } }
  .yellow-text-block p .last + span {
    padding-left: 2px; }
  .yellow-text-block p .special {
    padding: 0 9px;
    color: #ffb900; }
    .yellow-text-block p .special.active {
      padding: 3px 0 0px;
      border-top: 2px solid #ffb900;
      border-bottom: 2px solid #ffb900; }
      @media only screen and (max-width: 1024px) {
        .yellow-text-block p .special.active {
          padding: 5px 0 0px; } }
    .yellow-text-block p .special:first-of-type {
      border-left: 2px solid #ffb900;
      padding-left: 5px; }
    .yellow-text-block p .special:last-of-type {
      border-right: 2px solid #ffb900;
      padding-right: 5px;
      margin-right: 5px; }
    .yellow-text-block p .special.first {
      border-left: 2px solid #ffb900;
      padding-left: 5px; }
    .yellow-text-block p .special.last {
      border-right: 2px solid #ffb900;
      padding-right: 5px; }

.yellow-underline {
  text-decoration: underline;
  color: #ffb900;
  font-weight: 700; }
  .yellow-underline a {
    text-decoration: underline;
    color: #ffb900;
    font-weight: 700; }

/* -----------------------*/
/* [IBL] ==== Image para bg
/*------------------------*/
.image-para-bg {
  overflow: hidden;
  position: relative; }
  .image-para-bg .image-wrapper {
    height: calc(100% + 100px);
    position: relative;
    top: -50px;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(0px);
    width: 100%; }

/* -----------------------*/
/* [REV] ==== Reveals
/*------------------------*/
.reveal-x, .reveal-x-footer {
  opacity: 0;
  transform: translateX(-100px);
  will-change: transform; }

.reveal-y, .reveal-y-footer {
  opacity: 0;
  transform: translateY(100px);
  will-change: transform; }

/* -----------------------*/
/* [INF] ==== Info Icon block
/*------------------------*/
.info-icon-block img, .info-icon-block .animated-icon {
  width: 135px;
  max-width: 135px;
  min-width: 135px;
  height: 135px;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .info-icon-block img, .info-icon-block .animated-icon {
      margin-left: auto;
      margin-right: auto; } }
  .info-icon-block img svg, .info-icon-block .animated-icon svg {
    display: block;
    width: 100%;
    height: 100%; }

.info-icon-block h4 {
  font-family: "ReneBold", sans-serif;
  color: #9a9a9a;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 25px; }
  .info-icon-block h4.-line:before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% - 1px);
    background-color: #9a9a9a;
    height: 2px;
    width: 55vw;
    transform-origin: right center;
    left: -56vw; }
    @media only screen and (max-width: 767px) {
      .info-icon-block h4.-line:before {
        height: 50px;
        width: 1px;
        transform: translateX(-50%);
        left: 50%;
        top: -70px; } }

.info-icon-block p {
  font-size: 16px;
  font-family: "ReneBook", sans-serif;
  line-height: 2em;
  color: #666666; }

/* -----------------------*/
/* [BPB] ==== Big para block
/*------------------------*/
.big-para-block {
  font-size: 25px;
  margin: auto;
  text-align: center;
  padding-bottom: 140px; }
  @media only screen and (max-width: 767px) {
    .big-para-block {
      padding-bottom: 50px; } }
  .big-para-block p {
    line-height: 1.7em; }
  .big-para-block span {
    color: #333333;
    letter-spacing: .5px; }
    .big-para-block span.special {
      color: #ffb900; }

/* -----------------------*/
/* [ICO] ==== Icon cross
/*------------------------*/
.icon-cross {
  width: 20px;
  height: 20px;
  position: relative;
  display: block;
  transition: transform .4s ease; }
  .icon-cross span {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 2px;
    width: 20px;
    background-color: #000;
    transition: background-color .4s ease,opacity .4s ease; }
  .icon-cross .line-2 {
    transform: translate(-50%, -50%) rotate(90deg); }

/* -----------------------*/
/* [SEL] ==== Select
/*------------------------*/
.ginput_container_select {
  position: relative; }
  @media only screen and (max-width: 1024px) {
    .ginput_container_select:after {
      pointer-events: none;
      content: '';
      border-radius: 50%;
      background-color: #ffb900;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 52px !important;
      height: 52px !important;
      position: absolute !important;
      transition: all .3s ease-in-out;
      background: url(../img/icon-dropdown.png) no-repeat 0px 0px;
      opacity: 0.5;
      background-position: center center; } }

.gfield_error .chosen-container .chosen-single {
  background: #f7e1e0 !important; }

@media only screen and (max-width: 1024px) {
  select {
    display: block !important; } }

.chosen-container {
  width: 100% !important;
  border: none !important;
  background-color: #eeeeee;
  box-sizing: border-box;
  font-family: "ReneBold", sans-serif;
  color: #333333;
  height: 56px;
  letter-spacing: 1px;
  padding: 17px 15px !important;
  transition: all .3s ease-in-out;
  position: relative; }
  @media only screen and (max-width: 1024px) {
    .chosen-container {
      display: none !important; } }
  .chosen-container b {
    display: none !important; }
  .chosen-container.chosen-with-drop {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px; }
    .chosen-container.chosen-with-drop .chosen-single > div {
      transform: rotate(90deg); }
  .chosen-container .chosen-single {
    font-family: "ReneBold", sans-serif;
    font-size: 13px !important;
    background: #eeeeee !important;
    border: none !important;
    line-height: 1em !important;
    height: auto !important;
    box-shadow: none !important;
    overflow: visible !important;
    position: relative;
    top: 4px; }
    .chosen-container .chosen-single > div {
      width: 56px !important;
      height: 56px !important;
      position: absolute !important;
      top: 0 !important;
      transition: transform .3s ease-in-out; }
      .chosen-container .chosen-single > div span {
        background-size: 100% 100%;
        backgorund-position: center center; }
  .chosen-container .chosen-drop {
    left: 0;
    border: none !important;
    background: #eeeeee !important;
    box-shadow: none !important;
    margin-top: -1px  !important; }
  .chosen-container .chosen-results li {
    padding: 17px 15px !important; }
  .chosen-container.chosen-with-drop:after {
    transform: rotate(180deg); }
  .chosen-container:after {
    pointer-events: none;
    content: '';
    border-radius: 50%;
    background-color: #ffb900;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 52px !important;
    height: 52px !important;
    position: absolute !important;
    transition: all .3s ease-in-out;
    background: url(../img/icon-dropdown.png) no-repeat 0px 0px;
    opacity: 0.5;
    background-position: center center; }
  .desktop .chosen-container:hover:after {
    opacity: 1; }

/* -----------------------*/
/* [INF] ==== Infobox
/*------------------------*/
.infoBox {
  width: 300px;
  text-align: center;
  font-size: 0; }
  @media only screen and (max-width: 767px) {
    .infoBox {
      width: 200px; } }
  .infoBox:before {
    content: '';
    position: absolute;
    bottom: 0;
    background-color: #ffb900;
    width: 20px;
    height: 20px;
    transform: translate(-50%, 10px) rotate(45deg);
    left: 50%; }
  .infoBox img:first-child {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 2; }
  .infoBox img:first-child.thumb {
    position: relative !important;
    width: 100%;
    z-index: 1; }
  .infoBox .category {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    font-size: 11px;
    margin-top: 10px;
    display: block; }
    .infoBox .category.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .infoBox .category.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .infoBox h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: #333333; }
    .infoBox h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .infoBox h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .infoBox .wrapper {
    padding: 20px; }

.swiper-button-disabled .swiper-button-prev,
.swiper-button-disabled .swiper-button-next {
  cursor: default; }

.swiper-button-disabled div {
  opacity: .4; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner .6s linear infinite; }

.apply-now {
  width: 100%;
  height: 50px;
  background-color: #ffb900;
  position: fixed;
  bottom: 0;
  z-index: 9998;
  display: block;
  display: none; }
  @media only screen and (max-width: 767px) {
    .apply-now.active {
      display: block; } }
  .apply-now span {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: #000;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    font-size: 11px; }

.cta-contact {
  background-color: white;
  user-select: none;
  display: block;
  position: relative; }
  .cta-contact:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffb900;
    transform-origin: top center;
    transform: scaleY(0);
    transition: transform .4s ease; }
  .desktop .cta-contact:hover:before {
    transform: scaleY(1); }
  .desktop .cta-contact:hover .container-inner p span.special {
    color: #333333;
    border-color: #333333; }
  .cta-contact .select-navigation__closed {
    width: 100%;
    height: 200px;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .cta-contact .select-navigation__closed {
        height: 120px; } }
    .cta-contact .select-navigation__closed .container-inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .cta-contact .select-navigation__closed .yellow-text-block {
      margin: auto; }
      @media only screen and (max-width: 1024px) {
        .cta-contact .select-navigation__closed .yellow-text-block {
          text-align: center;
          padding: 0 20px; } }
      @media only screen and (max-width: 767px) {
        .cta-contact .select-navigation__closed .yellow-text-block p {
          padding: 0px;
          font-size: 16px;
          line-height: 1.8em; } }
    .cta-contact .select-navigation__closed p {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .cta-contact .select-navigation__closed p {
          font-size: 20px; } }
      .cta-contact .select-navigation__closed p span.special {
        transition: color .4s ease, border-color .4s ease; }
    .desktop .cta-contact .select-navigation__closed:hover p span.special {
      color: #333333;
      border-color: #333333; }

/* ========================*/
/* ==[ Preview Block ]== */
/*========================*/
.preview-block {
  width: 100%;
  position: relative;
  z-index: 30; }
  body:not(.no-perspective) .preview-block {
    perspective: 600px; }
  .preview-block .text-wrapper {
    padding-left: 35px;
    padding-top: 25px;
    position: relative;
    pointer-events: none;
    left: 2vw;
    top: -20px;
    z-index: 30; }
    @media only screen and (max-width: 767px) {
      .preview-block .text-wrapper {
        width: 80%; } }
    body:not(.no-perspective) .preview-block .text-wrapper {
      transform: translateZ(80px); }
    body.no-perspective .preview-block .text-wrapper {
      left: 0;
      top: 0; }
    .preview-block .text-wrapper:before {
      content: '';
      position: absolute;
      left: 20px;
      top: -30px;
      height: calc(100% + 30px);
      width: 2px;
      background-color: #333333; }
  .preview-block .video-wrapper {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%; }
    .preview-block .video-wrapper video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: opacity .5s ease;
      opacity: 0; }
  .preview-block .preview-block__image {
    display: block;
    pointer-events: none;
    transform: translateZ(0px);
    width: 100%; }
  .preview-block .preview-block__caracter {
    position: absolute;
    right: 10px;
    bottom: 5vh;
    width: 42%;
    pointer-events: none;
    z-index: 50; }
    body:not(.no-perspective) .preview-block .preview-block__caracter {
      transform: translateZ(150px); }
    body.no-perspective .preview-block .preview-block__caracter {
      bottom: 0;
      right: 0; }
      @media only screen and (max-width: 767px) {
        body.no-perspective .preview-block .preview-block__caracter {
          right: -30px; } }
  .preview-block .preview-block__logo {
    position: absolute;
    transform: translate3d(-50%, -50%, 120px);
    top: 50%;
    left: 50%;
    width: 30%; }
  .preview-block .preview-block__title {
    position: absolute;
    transform: translate3d(-50%, -50%, 120px);
    top: 50%;
    left: 50%;
    font-size: 37px;
    color: #fff;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .preview-block .preview-block__title {
        font-size: 25px; } }
  .preview-block .title-wrapper {
    height: 310px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 20; }
    body:not(.no-perspective) .preview-block .title-wrapper {
      transform: translateZ(50px); }
    @media only screen and (max-width: 767px) {
      .preview-block .title-wrapper {
        height: 155px; } }
  .preview-block .img-wrapper {
    position: relative;
    height: 310px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .preview-block .img-wrapper {
        height: 155px; } }
  .preview-block .preview-wrapper {
    transform-style: preserve-3d;
    pointer-events: none; }
  .preview-block h3 {
    font-family: "ReneExtraBold", sans-serif;
    font-size: 18px;
    color: #333333;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1em;
    width: 70%; }
    @media only screen and (max-width: 767px) {
      .preview-block h3 {
        width: 80%;
        font-size: 15px; } }
  .preview-block h4 {
    font-family: "ReneBold", sans-serif;
    font-size: 10px;
    color: #9a9a9a;
    letter-spacing: 1px;
    text-transform: uppercase; }

/* ========================*/
/* ==[ Full Cta Block ]== */
/*========================*/
.full-cta-block {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .full-cta-block {
      height: 450px; } }
  .full-cta-block h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: #fff;
    font-size: 13px;
    margin-bottom: 20px; }
    .full-cta-block h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .full-cta-block h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
    @media only screen and (max-width: 767px) {
      .full-cta-block h4 {
        font-size: 11px;
        padding: 0 20px; } }
  .full-cta-block h2 {
    font-size: 25px;
    font-family: "ReneExtraBold", sans-serif;
    color: #333333;
    font-size: 80px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 20px;
    line-height: 1.1em; }
    @media only screen and (max-width: 767px) {
      .full-cta-block h2 {
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .full-cta-block h2 {
        font-size: 40px; }
        .full-cta-block h2 p {
          padding: 0 20px; } }
  .full-cta-block .wrapper {
    text-align: center; }
  .full-cta-block .btn {
    margin: auto; }
  .full-cta-block .bg {
    position: absolute;
    top: -10%;
    left: 0;
    width: 120%;
    height: 120%;
    background-size: cover; }

/* ========================*/
/* ==[ Newsletter block ]== */
/*========================*/
.newsletter-block {
  padding: 100px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .newsletter-block {
      padding: 30px 0 70px; } }
  .newsletter-block.success .success-msg {
    transform: translate(-50%, -50%);
    opacity: 1; }
  .newsletter-block.success .wrapper {
    opacity: 0 !important; }
  .newsletter-block.email-error .email-error-msg {
    transform: translate(-50%, calc(-50% + 60px));
    opacity: 1; }
    @media only screen and (max-width: 1024px) {
      .newsletter-block.email-error .email-error-msg {
        transform: translate(-50%, calc(-50% + 80px)); } }
    @media only screen and (max-width: 767px) {
      .newsletter-block.email-error .email-error-msg {
        transform: translate(-50%, calc(-50% + 60px)); } }
  .newsletter-block.server-error .server-error-msg {
    transform: translate(-50%, calc(-50% + 60px));
    opacity: 1; }
  .newsletter-block.loading .loading-msg {
    transform: translate(-50%, calc(-50% + 60px));
    opacity: 1; }
  .newsletter-block .loading-msg, .newsletter-block .server-error-msg, .newsletter-block .email-error-msg {
    position: absolute;
    transform: translate(-50%, calc(-50% + 120px));
    transition: opacity .3s ease,transform .3s ease;
    top: 50%;
    left: 50%;
    color: #c63630;
    opacity: 0;
    font-family: "ReneBook", sans-serif; }
    @media only screen and (max-width: 767px) {
      .newsletter-block .loading-msg, .newsletter-block .server-error-msg, .newsletter-block .email-error-msg {
        width: 100%;
        text-align: center; } }
  .newsletter-block .success-msg {
    position: absolute;
    transform: translate(-50%, calc(-50% + 50px));
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: opacity .3s ease,transform .3s ease;
    width: 100%;
    text-align: center; }
  .newsletter-block h3 {
    font-family: "ReneExtraBold", sans-serif;
    letter-spacing: 1px;
    font-size: 25px;
    text-transform: uppercase;
    display: inline-block;
    color: #333333;
    top: 3px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .newsletter-block h3 {
        font-size: 14px; } }
  .newsletter-block input[type="text"] {
    background-color: #eeeeee;
    border: none;
    font-size: 13px;
    color: #9a9a9a;
    padding: 20px 20px;
    font-family: "ReneBold", sans-serif;
    letter-spacing: 1px;
    width: 370px;
    margin-left: 30px;
    padding-right: 50px; }
    @media only screen and (max-width: 767px) {
      .newsletter-block input[type="text"] {
        width: 280px;
        margin: auto;
        padding: 15px 20px; } }
  .newsletter-block .placeholder {
    color: #9a9a9a;
    font-family: "ReneBold", sans-serif;
    letter-spacing: 1px;
    font-size: 11px;
    position: absolute;
    left: 50px;
    transform: translateY(-50%);
    top: 50%;
    text-transform: uppercase;
    pointer-events: none; }
    @media only screen and (max-width: 767px) {
      .newsletter-block .placeholder {
        left: 20px; } }
  .newsletter-block .submit {
    position: absolute;
    right: 20px;
    transform: translateY(calc(-50% - 1px));
    top: 50%;
    background-color: transparent; }
  .newsletter-block .input-wrapper {
    position: relative;
    display: inline-block; }
    @media only screen and (max-width: 1024px) {
      .newsletter-block .input-wrapper {
        margin-top: 20px; } }
  .newsletter-block .wrapper {
    transition: opacity .3s ease;
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .newsletter-block .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center; } }

/* ========================*/
/* ==[ Hero gray ]== */
/*========================*/
.hero-gray {
  padding-top: 170px;
  background-color: #eeeeee;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .hero-gray {
      padding-top: 110px;
      padding: 110px 20px 0 20px; } }
  .hero-gray .bg-svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 800px;
    z-index: 1;
    pointer-events: none;
    transform: translateX(-50%);
    left: 50%;
    min-width: 1700px; }
    .hero-gray .bg-svg svg {
      width: 100%;
      height: 800px; }
      .hero-gray .bg-svg svg * {
        fill: #fff; }
  .hero-gray h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 30px;
    z-index: 2; }
    .hero-gray h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .hero-gray h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .hero-gray .yellow-text-block {
    z-index: 2;
    position: relative; }
  .hero-gray p {
    color: #333333;
    font-family: "ReneExtraBold", sans-serif;
    font-size: 33px; }
    @media only screen and (max-width: 767px) {
      .hero-gray p {
        font-size: 20px; } }
  .hero-gray .hero-text {
    max-width: 850px;
    margin: auto;
    margin-bottom: 45px; }
  .hero-gray .v-line {
    width: 2px;
    height: 50px;
    display: block;
    margin: auto;
    background-color: #000;
    opacity: .2;
    z-index: 5;
    position: relative;
    transform-origin: bottom center; }
  .hero-gray .hero-gray__socials {
    padding-bottom: 50px;
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    position: relative;
    max-width: 750px; }
    @media only screen and (max-width: 1024px) {
      .hero-gray .hero-gray__socials {
        max-width: 500px; } }
    @media only screen and (max-width: 767px) {
      .hero-gray .hero-gray__socials {
        max-width: 230px; } }
    .hero-gray .hero-gray__socials svg * {
      transition: fill .3s ease; }
    .hero-gray .hero-gray__socials a {
      width: 20px; }
      .desktop .hero-gray .hero-gray__socials a:hover svg * {
        fill: #ffb900; }
      .hero-gray .hero-gray__socials a svg * {
        fill: #9a9a9a; }

/* ========================*/
/* ==[ Filter Block ]== */
/*========================*/
.filter-block {
  z-index: 2;
  position: relative; }
  .filter-block .filter-block__header {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #ffb900;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .filter-block .filter-block__header {
        padding-top: 20px;
        padding-bottom: 20px; } }
    .desktop .filter-block .filter-block__header:hover .label {
      color: #fff; }
    .desktop .filter-block .filter-block__header:hover .icon-cross {
      transform: rotate(90deg); }
    .desktop .filter-block .filter-block__header:hover .icon-cross span {
      background-color: #fff; }
    .filter-block .filter-block__header .container-inner {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .filter-block .filter-block__header .label {
      font-family: "ReneBold", sans-serif;
      text-align: Center;
      text-transform: uppercase;
      font-size: 13px;
      color: #333333;
      letter-spacing: 1px;
      transition: color .3s ease; }
  .filter-block.active .icon-cross {
    transform: rotate(90deg); }
  .filter-block.active .icon-cross span:first-of-type {
    opacity: 0; }
  .filter-block .container-list {
    background-color: #ffb900;
    lost-utility: clearfix;
    text-align: left;
    width: 100%;
    transform-origin: top center;
    transition: transform .4s ease,height .4s ease;
    overflow: hidden; }
    .filter-block .container-list .container-inner {
      display: block; }
    .filter-block .container-list li {
      lost-utility: clearfix; }
      @media only screen and (max-width: 767px) {
        .filter-block .container-list li {
          text-align: center; } }
    .filter-block .container-list a {
      lost-column: 1/4 4 20px;
      text-align: left;
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      color: #333333;
      display: inline-block;
      position: relative;
      opacity: 0;
      transform: translateY(40px);
      margin-bottom: 20px;
      top: 7px; }
      .filter-block .container-list a.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .filter-block .container-list a.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      @media only screen and (max-width: 767px) {
        .filter-block .container-list a {
          lost-column: 1/1 1 0;
          margin-bottom: 10px;
          text-align: center;
          float: none !important; } }
      .filter-block .container-list a span {
        display: inline-block;
        position: relative;
        line-height: 1em;
        transition: color .3s ease; }
      .filter-block .container-list a.active span:before {
        content: '';
        position: absolute;
        border: 2px solid #333333;
        width: calc(100% + 10px);
        height: calc(100% + 11px);
        left: -7px;
        top: -8px;
        transition: .3s ease border-color; }
  .filter-block .col {
    lost-column: 1/4 4 20px; }
    @media only screen and (max-width: 767px) {
      .filter-block .col {
        lost-column: 1/1 1 0px;
        margin-bottom: 40px;
        text-align: center; } }
    .filter-block .col .title {
      font-size: 20px;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      color: #333333;
      margin-bottom: 20px; }
    .filter-block .col li {
      margin-bottom: 10px;
      position: relative;
      display: block;
      width: 100%; }
      .filter-block .col li a {
        position: relative;
        width: auto;
        line-height: 1em;
        transition: color .3s ease; }
      .filter-block .col li.active a:before {
        content: '';
        position: absolute;
        border: 2px solid #333333;
        width: calc(100% + 10px);
        height: calc(100% + 11px);
        left: -7px;
        top: -8px; }
      .desktop .filter-block .col li:hover a {
        color: #fff; }

/* ========================*/
/* ==[ Stats block ]== */
/*========================*/
.stats-block {
  position: relative;
  margin-top: -50px;
  lost-utility: clearfix;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  z-index: 3; }
  .stats-block .container-inner {
    padding: 40px 0px 40px;
    width: 100%;
    background-color: white; }
    @media only screen and (max-width: 767px) {
      .stats-block .container-inner {
        padding: 40px 0px; } }
  .stats-block .number {
    font-size: 65px;
    font-family: "ReneExtraBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    line-height: 1.1em;
    font-size: 80px;
    display: block;
    letter-spacing: 2px;
    text-transform: none; }
    @media only screen and (max-width: 1300px) {
      .stats-block .number {
        font-size: 55px; } }
    @media only screen and (max-width: 767px) {
      .stats-block .number {
        font-size: 40px; } }
    .stats-block .number.-white {
      color: #fff; }
    @media only screen and (max-width: 1024px) {
      .stats-block .number {
        font-size: 45px; } }
  .stats-block .label {
    font-family: "ReneBold", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #333333;
    letter-spacing: 0.5px;
    min-height: 2.5em;
    display: block; }
  .stats-block .single-stat {
    lost-column: 1/4 4 20px; }
    @media only screen and (max-width: 767px) {
      .stats-block .single-stat {
        lost-column: 1/2 2 0;
        margin: 20px 0;
        padding: 0 10px; } }
  .stats-block .header h3 {
    margin-bottom: 20px;
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333; }
    @media only screen and (max-width: 767px) {
      .stats-block .header h3 {
        font-size: 26px; } }
    .stats-block .header h3.-white {
      color: #fff; }
  .stats-block .header p {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666;
    max-width: 700px;
    margin: auto;
    margin-bottom: 40px; }
  .stats-block .wrapper {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .stats-block .wrapper {
        display: block; } }
    .stats-block .wrapper .image {
      max-width: 200px;
      max-height: 150px;
      margin: auto; }
  .stats-block .top-block {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-end; }
    @media only screen and (max-width: 1024px) {
      .stats-block .top-block {
        height: 80px; } }

.page-career {
  margin-top: -100px; }
  .page-career .stats-block .container-inner {
    padding-top: 0;
    background-color: #ffb900; }

/* ========================*/
/* ==[ Logo Block ]== */
/*========================*/
.logo-block {
  background-color: #fff; }
  .logo-block.container-main {
    padding-top: 100px;
    padding-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .logo-block.container-main {
        padding-top: 50px;
        padding-bottom: 40px; } }
  .logo-block h2 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .logo-block h2 {
        font-size: 26px; } }
    .logo-block h2.-white {
      color: #fff; }
  .logo-block p {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666; }
  .logo-block .logo-block__header {
    text-align: center; }
  .logo-block .logo-block__list {
    lost-utility: clearfix;
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .logo-block .logo-block__list {
        margin-top: 40px; } }
    .logo-block .logo-block__list .single-logo {
      display: inline-block;
      width: 25%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px; }
      @media only screen and (max-width: 767px) {
        .logo-block .logo-block__list .single-logo {
          width: 50%;
          margin-bottom: 30px; } }
    .logo-block .logo-block__list img {
      max-width: 130px;
      max-height: 130px;
      width: 100%; }
      @media only screen and (max-width: 767px) {
        .logo-block .logo-block__list img {
          width: 70%; } }

/* ========================*/
/* ==[ Single Employe ]== */
/*========================*/
.single-employe {
  lost-column: 1/4 4 20px;
  position: relative;
  margin-bottom: 90px; }
  body:not(.no-perspective) .single-employe {
    perspective: 600px; }
  @media only screen and (max-width: 767px) {
    .single-employe {
      lost-column: 1/1 1 0px;
      perspective: 0px;
      margin-bottom: 40px; } }
  .single-employe * {
    pointer-events: none; }
  .single-employe .hover-wrapper {
    transform-style: preserve-3d; }
  .desktop .single-employe:hover img.hover {
    opacity: 1; }
  .single-employe img {
    width: 100%;
    margin-bottom: 25px;
    transform: translateZ(0px); }
    .single-employe img.hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .3s ease; }
  .single-employe h4, .single-employe p {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative; }
    .single-employe h4.-line:before, .single-employe p.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .single-employe h4.-line:before, .single-employe p.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .single-employe h4 {
    color: #333333;
    font-size: 13px;
    margin-bottom: 5px; }
  .single-employe p {
    color: #9a9a9a;
    font-size: 11px; }
  .single-employe .hover-wrapper .wrapper {
    transform: translateZ(0px); }
  .single-employe .initials {
    position: absolute;
    top: 30px;
    left: -15px; }
    body:not(.no-perspective) .single-employe .initials {
      transform: translateZ(60px); }
    .single-employe .initials span {
      color: #ffb900;
      font-family: "ReneExtraBold", sans-serif;
      text-transform: uppercase;
      font-size: 70px; }
      @media only screen and (max-width: 1024px) {
        .single-employe .initials span {
          font-size: 60px; } }
      @media only screen and (max-width: 767px) {
        .single-employe .initials span {
          font-size: 80px; } }
      .single-employe .initials span:nth-of-type(2) {
        position: relative;
        top: 20px;
        left: -20px; }
  .single-employe .wrapper-extra {
    height: 150px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .single-employe .wrapper-extra {
        height: 120px; } }
  .single-employe .contact-info {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 20px;
    line-height: 1.5em;
    font-family: "ReneBook", sans-serif;
    font-weight: 600;
    color: #333333;
    font-size: 16px; }

/* ========================*/
/* ==[ Slider Center Pagination ]== */
/*========================*/
.slider-center-pagination {
  height: 670px;
  width: 100%;
  background-color: #ffb900;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .slider-center-pagination {
      padding-bottom: 120px;
      height: auto; } }
  .slider-center-pagination h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    padding-top: 70px;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    z-index: 23; }
    @media only screen and (max-width: 767px) {
      .slider-center-pagination h3 {
        font-size: 26px; } }
    .slider-center-pagination h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .slider-center-pagination h3 {
        padding-top: 50px;
        margin-bottom: 30px; } }
  .slider-center-pagination .swiper-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0; }
    @media only screen and (max-width: 767px) {
      .slider-center-pagination .swiper-container {
        height: auto;
        width: 100%;
        position: relative; } }
    .slider-center-pagination .swiper-container .swiper-slide {
      position: relative;
      width: 90%;
      text-align: center;
      padding: 0 3%;
      max-width: 1600px; }
      @media only screen and (max-width: 767px) {
        .slider-center-pagination .swiper-container .swiper-slide {
          width: 100%; } }
    .slider-center-pagination .swiper-container img {
      width: 100%;
      max-width: 600px; }
    .slider-center-pagination .swiper-container .left, .slider-center-pagination .swiper-container .right {
      lost-column: 1/2 2 60px;
      height: 100%; }
      @media only screen and (max-width: 767px) {
        .slider-center-pagination .swiper-container .left, .slider-center-pagination .swiper-container .right {
          lost-column: 1/1 1 0px;
          height: auto; } }
    .slider-center-pagination .swiper-container .left {
      display: flex;
      align-items: center;
      height: 100%; }
      @media only screen and (max-width: 767px) {
        .slider-center-pagination .swiper-container .left {
          height: 350px;
          margin-bottom: 20px; } }
      .slider-center-pagination .swiper-container .left img {
        display: block; }
        @media only screen and (max-width: 767px) {
          .slider-center-pagination .swiper-container .left img {
            display: none; } }
        .slider-center-pagination .swiper-container .left img.mobile-only {
          display: none; }
          @media only screen and (max-width: 767px) {
            .slider-center-pagination .swiper-container .left img.mobile-only {
              display: block; } }
      .slider-center-pagination .swiper-container .left img {
        height: 100%;
        max-width: none;
        min-width: 0;
        width: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 2; }
        @media only screen and (max-width: 767px) {
          .slider-center-pagination .swiper-container .left img {
            height: 100%;
            width: auto; } }
    .slider-center-pagination .swiper-container .right {
      text-align: left;
      display: flex;
      align-items: center;
      max-width: 500px;
      float: left !important; }
      .slider-center-pagination .swiper-container .right h4 {
        font-family: "ReneBold", sans-serif;
        color: #333333;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 20px; }
      .slider-center-pagination .swiper-container .right p {
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666;
        margin-bottom: 20px;
        color: #333333; }
      .slider-center-pagination .swiper-container .right a {
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        color: #333333;
        transition: color .3s ease; }
        .slider-center-pagination .swiper-container .right a.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .slider-center-pagination .swiper-container .right a.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
        .desktop .slider-center-pagination .swiper-container .right a:hover {
          color: #fff; }
          .desktop .slider-center-pagination .swiper-container .right a:hover svg * {
            fill: #fff; }
        .slider-center-pagination .swiper-container .right a svg {
          margin-left: 10px; }
        .slider-center-pagination .swiper-container .right a svg * {
          fill: #333333;
          transition: fill .3s ease; }
  .slider-center-pagination .swiper-pagination {
    position: absolute;
    bottom: 40px;
    margin-top: 40px;
    display: inline-block;
    left: calc(50% - 26px);
    transition: none;
    text-align: left; }
    .slider-center-pagination .swiper-pagination .swiper-pagination-bullet {
      padding: 25px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      position: relative;
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      color: #333333;
      border: 1px solid #333333;
      opacity: .7;
      background-color: #ffb900; }
      .slider-center-pagination .swiper-pagination .swiper-pagination-bullet.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .slider-center-pagination .swiper-pagination .swiper-pagination-bullet.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      .slider-center-pagination .swiper-pagination .swiper-pagination-bullet span {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        line-height: 17px; }
      .slider-center-pagination .swiper-pagination .swiper-pagination-bullet:not(:last-of-type):before {
        content: '';
        width: 32px;
        background-color: #333333;
        height: 2px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -32px;
        opacity: .7; }
      .slider-center-pagination .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #333333;
        color: #ffb900;
        opacity: 1; }
        .slider-center-pagination .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
          opacity: .5; }

.page-career .slider-center-pagination .swiper-container .left img {
  max-width: 574px;
  width: auto;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 2; }
  @media only screen and (max-width: 1024px) {
    .page-career .slider-center-pagination .swiper-container .left img {
      max-width: 400px; } }
  @media only screen and (max-width: 767px) {
    .page-career .slider-center-pagination .swiper-container .left img {
      max-width: 400px; } }

/* ===========================*/
/* ==[ Slider Techno Product ]== */
/*===========================*/
.slider-techno-product {
  height: 680px;
  width: 100%;
  background-color: #ffb900;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .slider-techno-product {
      height: 750px; } }
  .slider-techno-product .bg-gradient {
    width: 780px;
    height: 780px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+66&0.26+0,0+67 */
    background: -moz-radial-gradient(center, ellipse cover, rgba(217, 58, 46, 0.26) 0%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 67%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(217, 58, 46, 0.26) 0%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 67%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(217, 58, 46, 0.26) 0%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 67%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42ffffff', endColorstr='#00ffffff',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .slider-techno-product .bg-gradient {
        width: 480px;
        height: 480px; } }
  .slider-techno-product h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    padding-top: 70px;
    text-align: center;
    margin-bottom: 80px;
    color: #fff;
    position: relative;
    z-index: 20; }
    @media only screen and (max-width: 767px) {
      .slider-techno-product h3 {
        font-size: 26px; } }
    .slider-techno-product h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .slider-techno-product h3 {
        padding-top: 40px; } }
  .slider-techno-product .swiper-container {
    height: 680px;
    position: absolute;
    top: 0;
    width: 100%; }
    .slider-techno-product .swiper-container .swiper-slide {
      position: relative;
      width: 90%;
      text-align: center;
      padding: 0 3%;
      max-width: 1600px; }
      @media only screen and (max-width: 767px) {
        .slider-techno-product .swiper-container .swiper-slide {
          height: auto;
          width: 100%;
          padding-top: 30px; } }
    .slider-techno-product .swiper-container img {
      width: 100%;
      max-width: 600px; }
    .slider-techno-product .swiper-container .left, .slider-techno-product .swiper-container .right {
      lost-column: 1/2 2 60px;
      height: 100%; }
      @media only screen and (max-width: 767px) {
        .slider-techno-product .swiper-container .left, .slider-techno-product .swiper-container .right {
          lost-column: 1/1 1 0px;
          height: 400px;
          display: block !important; } }
    .slider-techno-product .swiper-container .left {
      display: flex;
      align-items: center;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .slider-techno-product .swiper-container .left {
          height: 290px; } }
      .slider-techno-product .swiper-container .left img {
        height: 100%;
        max-width: none;
        min-width: 0;
        width: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 2; }
        @media only screen and (max-width: 767px) {
          .slider-techno-product .swiper-container .left img {
            height: 100%;
            width: auto; } }
    .slider-techno-product .swiper-container .right {
      text-align: left;
      display: flex;
      align-items: center;
      max-width: 500px;
      float: left !important; }
      @media only screen and (max-width: 767px) {
        .slider-techno-product .swiper-container .right {
          margin-top: 40px; } }
      .slider-techno-product .swiper-container .right h4 {
        font-family: "ReneBold", sans-serif;
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 20px; }
      .slider-techno-product .swiper-container .right p {
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666;
        margin-bottom: 20px;
        color: #fff; }
      .slider-techno-product .swiper-container .right a {
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        color: #fff;
        transition: color .3s ease; }
        .slider-techno-product .swiper-container .right a.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .slider-techno-product .swiper-container .right a.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
        .desktop .slider-techno-product .swiper-container .right a:hover {
          color: #000; }
          .desktop .slider-techno-product .swiper-container .right a:hover svg * {
            fill: #000; }
        .slider-techno-product .swiper-container .right a svg {
          margin-left: 10px; }
        .slider-techno-product .swiper-container .right a svg * {
          transition: fill .3s ease;
          fill: #fff; }
  .slider-techno-product .swiper-pagination {
    position: absolute;
    margin-top: 40px;
    display: inline-block;
    left: calc(50% - 26px);
    transition: none;
    bottom: 40px; }
    .slider-techno-product .swiper-pagination .swiper-pagination-bullet {
      padding: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      position: relative;
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      color: #333333;
      border: 2px solid rgba(0, 0, 0, 0.5);
      opacity: .7;
      background-color: transparent;
      width: 52px;
      height: 52px; }
      .slider-techno-product .swiper-pagination .swiper-pagination-bullet.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .slider-techno-product .swiper-pagination .swiper-pagination-bullet.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      .slider-techno-product .swiper-pagination .swiper-pagination-bullet span {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 28px !important;
        height: 28px !important;
        display: block; }
      .slider-techno-product .swiper-pagination .swiper-pagination-bullet img {
        width: 28px;
        height: 28px; }
      .slider-techno-product .swiper-pagination .swiper-pagination-bullet:not(:last-of-type):before {
        content: '';
        width: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        height: 2px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -32px;
        opacity: .7; }
      .slider-techno-product .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #fff;
        color: #ffb900;
        opacity: 1;
        border-color: transparent; }
        .slider-techno-product .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
          opacity: .5; }

/* ========================*/
/* ==[ Slider specs ]== */
/*========================*/
.slider-specs {
  height: 670px;
  width: 100%;
  background-color: #ffb900;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .slider-specs {
      height: auto;
      padding-bottom: 50px; } }
  .slider-specs h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    padding-top: 70px;
    text-align: center;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .slider-specs h3 {
        font-size: 26px; } }
    .slider-specs h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .slider-specs h3 {
        padding-top: 50px;
        margin-bottom: 40px; } }
  .slider-specs .swiper-container {
    height: 400px;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .slider-specs .swiper-container {
        height: auto;
        width: 90%; } }
    .slider-specs .swiper-container .swiper-slide {
      position: relative;
      width: 90%;
      text-align: center;
      padding: 0 3%;
      max-width: 1600px; }
      @media only screen and (max-width: 767px) {
        .slider-specs .swiper-container .swiper-slide {
          width: 100%; } }
    .slider-specs .swiper-container img {
      height: 100%;
      margin: auto; }
      @media only screen and (max-width: 767px) {
        .slider-specs .swiper-container img {
          width: 100%;
          min-width: 0;
          max-width: none; } }
    .slider-specs .swiper-container .left, .slider-specs .swiper-container .right {
      lost-column: 1/2 2 0px;
      height: 100%; }
      @media only screen and (max-width: 767px) {
        .slider-specs .swiper-container .left, .slider-specs .swiper-container .right {
          lost-column: 1/1 1 0px;
          height: auto; } }
    .slider-specs .swiper-container .left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      display: block; }
      @media only screen and (max-width: 767px) {
        .slider-specs .swiper-container .left {
          margin-bottom: 40px; } }
    .slider-specs .swiper-container .right {
      text-align: left;
      display: flex;
      align-items: center;
      max-width: 500px;
      float: left !important;
      padding-left: 80px; }
      @media only screen and (max-width: 767px) {
        .slider-specs .swiper-container .right {
          padding-left: 0px;
          max-width: none; } }
      .slider-specs .swiper-container .right .wrapper {
        position: relative; }
        .slider-specs .swiper-container .right .wrapper ul {
          margin-bottom: 20px; }
        .slider-specs .swiper-container .right .wrapper li {
          margin-bottom: 5px;
          font-size: 16px;
          font-family: "ReneBook", sans-serif;
          line-height: 2em;
          color: #666666;
          color: #333333; }
          .slider-specs .swiper-container .right .wrapper li strong {
            font-family: "ReneBold", sans-serif; }
      .slider-specs .swiper-container .right h4 {
        font-family: "ReneBold", sans-serif;
        color: #333333;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 20px; }
      .slider-specs .swiper-container .right p {
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666;
        margin-bottom: 20px;
        color: #333333; }
        @media only screen and (max-width: 1024px) {
          .slider-specs .swiper-container .right p {
            line-height: 1.5em; } }
      .slider-specs .swiper-container .right a {
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        color: #333333;
        transition: color .3s ease; }
        .slider-specs .swiper-container .right a.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .slider-specs .swiper-container .right a.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
        .slider-specs .swiper-container .right a svg {
          margin-left: 10px; }
        .slider-specs .swiper-container .right a svg * {
          fill: #333333;
          transition: fill .3s ease; }
        .desktop .slider-specs .swiper-container .right a:hover {
          color: #fff; }
          .desktop .slider-specs .swiper-container .right a:hover svg * {
            fill: #fff; }
  .slider-specs .swiper-pagination {
    position: relative;
    margin-top: 40px;
    display: inline-block;
    left: calc(50% - 26px);
    transition: none; }
    .slider-specs .swiper-pagination .swiper-pagination-bullet {
      padding: 25px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      position: relative;
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      color: #333333;
      border: 1px solid #333333;
      opacity: .7;
      background-color: #ffb900; }
      .slider-specs .swiper-pagination .swiper-pagination-bullet.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .slider-specs .swiper-pagination .swiper-pagination-bullet.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      .slider-specs .swiper-pagination .swiper-pagination-bullet span {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%; }
      .slider-specs .swiper-pagination .swiper-pagination-bullet:not(:last-of-type):before {
        content: '';
        width: 32px;
        background-color: #333333;
        height: 2px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: -32px;
        opacity: .7;
        z-index: 20;
        will-change: transform; }
      .slider-specs .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #333333;
        color: #ffb900;
        opacity: 1; }
        .slider-specs .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
          opacity: .5; }

/* ========================*/
/* ==[ Hero Full ]== */
/*========================*/
.hero-full {
  height: 800px;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0 13vw;
  position: relative; }
  .hero-full .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #000; }
    .hero-full .bg .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      will-change: transform; }
  .hero-full .filter-bg {
    width: 100%;
    background-color: #333333;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: .4;
    transform: translateZ(0); }
    @media only screen and (max-width: 1024px) {
      .hero-full .filter-bg {
        background-color: #000000; } }
  .hero-full .video {
    height: 100%;
    width: 100%;
    object-fit: cover; }
    @media only screen and (max-width: 1024px) {
      .hero-full .video {
        display: none; } }
  @media only screen and (max-width: 767px) {
    .hero-full {
      height: 480px;
      padding: 0 20px; }
      .page-project-single .hero-full, .page-product-single .hero-full {
        height: 600px;
        display: block;
        padding-top: 100px; } }
  .hero-full .wrapper {
    width: 100%;
    max-width: 1600px;
    position: relative;
    z-index: 3; }
  .hero-full h1 {
    font-size: 65px;
    font-family: "ReneExtraBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    line-height: 1.1em;
    color: #fff;
    margin-bottom: 30px;
    max-width: 700px;
    transform: translateZ(0); }
    @media only screen and (max-width: 1300px) {
      .hero-full h1 {
        font-size: 55px; } }
    @media only screen and (max-width: 767px) {
      .hero-full h1 {
        font-size: 40px; } }
    .hero-full h1.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .hero-full h1 {
        font-size: 30px; }
        .page-project-single .hero-full h1, .page-product-single .hero-full h1 {
          text-align: center;
          margin-bottom: 20px !important; } }
  .hero-full .btn .arrow {
    position: absolute;
    left: 25px;
    transform: translateY(-50%);
    top: calc(50% + 2px); }
  @media only screen and (max-width: 767px) {
    .page-project-single .hero-full .btn, .page-product-single .hero-full .btn {
      margin: auto; } }
  .hero-full h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: #fff;
    font-size: 13px;
    margin-bottom: 40px;
    transform: translateZ(0); }
    .hero-full h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .hero-full h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
    .hero-full h4 img {
      max-width: 200px;
      max-height: 150px; }
      @media only screen and (max-width: 767px) {
        .hero-full h4 img {
          max-width: 200px; } }
    .hero-full h4.-line:before {
      background-color: #fff;
      opacity: .3; }
    @media only screen and (max-width: 767px) {
      .page-project-single .hero-full h4, .page-product-single .hero-full h4 {
        text-align: center;
        margin-bottom: 20px; }
        .page-project-single .hero-full h4 img, .page-product-single .hero-full h4 img {
          max-width: 130px; } }
  .hero-full .hero-full__caracter {
    position: absolute;
    width: 60vw;
    bottom: -20%;
    right: 0vw;
    z-index: 2;
    min-width: 900px;
    max-width: 950px;
    transform: translateZ(0);
    display: block;
    will-change: transform; }
    @media only screen and (max-width: 1300px) {
      .hero-full .hero-full__caracter {
        right: -15vw; } }
    @media only screen and (min-width: 2000px) {
      .hero-full .hero-full__caracter {
        right: 15vw; } }
    @media only screen and (max-width: 1024px) {
      .hero-full .hero-full__caracter {
        min-width: 700px;
        bottom: -12%; } }
    @media only screen and (max-width: 767px) {
      .hero-full .hero-full__caracter {
        min-width: 0px;
        z-index: 23;
        top: auto;
        bottom: -60px;
        transform: translateX(-50%) !important;
        left: 50%;
        width: 360px;
        z-index: 1; } }

.hero-career .video {
  display: block; }

/* ========================*/
/* ==[ Dual Block ]== */
/*========================*/
.dual-block {
  transform: translateZ(0); }
  .dual-block .wrapper {
    lost-utility: clearfix;
    padding-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .dual-block .wrapper {
        padding-bottom: 50px; } }
  .dual-block .col {
    lost-column: 1/2 2 0;
    padding: 60px 4vw; }
    @media only screen and (max-width: 767px) {
      .dual-block .col {
        lost-column: 1/1 1 0;
        padding: 30px 20px; } }
    .dual-block .col.-left {
      background-color: #fff;
      position: relative;
      top: -90px;
      padding-top: 40px; }
      @media only screen and (max-width: 767px) {
        .dual-block .col.-left {
          padding-top: 20px;
          margin-top: -50px;
          top: auto; } }
      .dual-block .col.-left h4 {
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        margin-bottom: 50px; }
        .dual-block .col.-left h4.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .dual-block .col.-left h4.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
        @media only screen and (max-width: 767px) {
          .dual-block .col.-left h4 {
            margin-bottom: 30px; } }
      .dual-block .col.-left p {
        font-size: 25px;
        font-family: "ReneExtraBold", sans-serif;
        color: #333333;
        line-height: 1.6em;
        letter-spacing: .5px; }
        @media only screen and (max-width: 767px) {
          .dual-block .col.-left p {
            font-size: 20px; } }
        @media only screen and (max-width: 767px) {
          .dual-block .col.-left p {
            line-height: 1.5em;
            font-size: 20px; } }
    .dual-block .col.-right {
      background-color: #1f1f1f; }
      .dual-block .col.-right p {
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666;
        color: #fff; }
      .dual-block .col.-right span.special {
        font-weight: 600; }

/* ========================*/
/* ==[ Image Block Grid ]== */
/*========================*/
.overlay-movie-container.no-image-header,
.imagegrid-block.no-image-header {
  margin-top: 140px; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container.no-image-header,
    .imagegrid-block.no-image-header {
      margin-top: 0px; } }

.overlay-movie-container .full-image,
.imagegrid-block .full-image {
  height: 800px;
  background-size: cover;
  width: 120%;
  left: -10%;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .full-image,
    .imagegrid-block .full-image {
      height: 200px; } }
  .overlay-movie-container .full-image .btn,
  .imagegrid-block .full-image .btn {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%; }
    .overlay-movie-container .full-image .btn .arrow,
    .imagegrid-block .full-image .btn .arrow {
      position: absolute;
      left: 25px;
      transform: translateY(-50%);
      top: calc(50% + 2px); }
  .overlay-movie-container .full-image .bg-image,
  .imagegrid-block .full-image .bg-image {
    position: relative;
    left: -10%;
    top: -10%;
    height: 120%;
    width: 120%;
    background-size: cover;
    background-position: center center; }

.overlay-movie-container .video-header iframe,
.imagegrid-block .video-header iframe {
  width: 100%;
  height: 100%;
  pointer-events: none; }

.overlay-movie-container .side-image-wrapper,
.imagegrid-block .side-image-wrapper {
  padding-bottom: 120px;
  lost-utility: clearfix; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .side-image-wrapper,
    .imagegrid-block .side-image-wrapper {
      padding-bottom: 60px; } }
  .overlay-movie-container .side-image-wrapper .img-wrapper,
  .imagegrid-block .side-image-wrapper .img-wrapper {
    overflow: hidden;
    text-align: center; }
    .overlay-movie-container .side-image-wrapper .img-wrapper img,
    .imagegrid-block .side-image-wrapper .img-wrapper img {
      width: 120%;
      max-width: none;
      left: 50%;
      top: 50%;
      position: absolute; }
      @media only screen and (max-width: 767px) {
        .overlay-movie-container .side-image-wrapper .img-wrapper img,
        .imagegrid-block .side-image-wrapper .img-wrapper img {
          left: 50% !important;
          top: 50% !important;
          transform: translate(-50%, -50%) !important; } }
  .overlay-movie-container .side-image-wrapper .left,
  .imagegrid-block .side-image-wrapper .left {
    lost-column: 1/2 2 0;
    padding-bottom: calc(78% /2);
    position: relative;
    top: -100px; }
    .overlay-movie-container .side-image-wrapper .left img,
    .imagegrid-block .side-image-wrapper .left img {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    @media only screen and (max-width: 767px) {
      .overlay-movie-container .side-image-wrapper .left,
      .imagegrid-block .side-image-wrapper .left {
        lost-column: 1/1 1 0;
        top: 0px;
        margin-top: 50px;
        height: 250px; } }
  .overlay-movie-container .side-image-wrapper .right,
  .imagegrid-block .side-image-wrapper .right {
    lost-column: 7/16 2 0;
    position: relative;
    top: 100px;
    padding-bottom: calc(76% /2); }
    .overlay-movie-container .side-image-wrapper .right img,
    .imagegrid-block .side-image-wrapper .right img {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    @media only screen and (max-width: 767px) {
      .overlay-movie-container .side-image-wrapper .right,
      .imagegrid-block .side-image-wrapper .right {
        lost-column: 1/1 1 0;
        top: 0px;
        margin-top: -40px;
        height: 180px; }
        .overlay-movie-container .side-image-wrapper .right img,
        .imagegrid-block .side-image-wrapper .right img {
          width: calc(100% - 40px);
          left: -20px; } }

.overlay-movie-container .slider-image-wrapper,
.imagegrid-block .slider-image-wrapper {
  text-align: center;
  padding-bottom: 140px; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .slider-image-wrapper,
    .imagegrid-block .slider-image-wrapper {
      padding-bottom: 50px; } }

.overlay-movie-container div[class^='swiper-container-imagegrid'],
.imagegrid-block div[class^='swiper-container-imagegrid'] {
  overflow: hidden; }

.overlay-movie-container .slider-center-imagegrid, .overlay-movie-container .swiper-container-imagegrid-movie,
.imagegrid-block .slider-center-imagegrid,
.imagegrid-block .swiper-container-imagegrid-movie {
  margin: auto;
  width: 880px;
  height: 520px;
  position: relative;
  margin-bottom: 60px; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .slider-center-imagegrid, .overlay-movie-container .swiper-container-imagegrid-movie,
    .imagegrid-block .slider-center-imagegrid,
    .imagegrid-block .swiper-container-imagegrid-movie {
      width: 100%;
      height: 200px; } }
  .overlay-movie-container .slider-center-imagegrid .swiper-slide, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide,
  .imagegrid-block .slider-center-imagegrid .swiper-slide,
  .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide {
    position: relative;
    overflow: hidden; }
    .overlay-movie-container .slider-center-imagegrid .swiper-slide .placeholder, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .placeholder,
    .imagegrid-block .slider-center-imagegrid .swiper-slide .placeholder,
    .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .3s ease;
      transform: translateZ(0);
      pointer-events: none; }
      .overlay-movie-container .slider-center-imagegrid .swiper-slide .placeholder img, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .placeholder img,
      .imagegrid-block .slider-center-imagegrid .swiper-slide .placeholder img,
      .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .placeholder img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateZ(0); }
    .overlay-movie-container .slider-center-imagegrid .swiper-slide .videos-wrapper,
    .overlay-movie-container .slider-center-imagegrid .swiper-slide .video-wrapper, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper,
    .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .video-wrapper,
    .imagegrid-block .slider-center-imagegrid .swiper-slide .videos-wrapper,
    .imagegrid-block .slider-center-imagegrid .swiper-slide .video-wrapper,
    .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper,
    .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .video-wrapper {
      width: 100%;
      height: 100%; }
      .overlay-movie-container .slider-center-imagegrid .swiper-slide .videos-wrapper iframe,
      .overlay-movie-container .slider-center-imagegrid .swiper-slide .video-wrapper iframe, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper iframe,
      .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .video-wrapper iframe,
      .imagegrid-block .slider-center-imagegrid .swiper-slide .videos-wrapper iframe,
      .imagegrid-block .slider-center-imagegrid .swiper-slide .video-wrapper iframe,
      .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper iframe,
      .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .video-wrapper iframe {
        width: 100%;
        height: 100%;
        pointer-events: none; }
      .overlay-movie-container .slider-center-imagegrid .swiper-slide .videos-wrapper a,
      .overlay-movie-container .slider-center-imagegrid .swiper-slide .video-wrapper a, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper a,
      .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .video-wrapper a,
      .imagegrid-block .slider-center-imagegrid .swiper-slide .videos-wrapper a,
      .imagegrid-block .slider-center-imagegrid .swiper-slide .video-wrapper a,
      .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper a,
      .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .video-wrapper a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 20;
        transition: opacity .3s ease; }
        .overlay-movie-container .slider-center-imagegrid .swiper-slide .videos-wrapper a .arrow,
        .overlay-movie-container .slider-center-imagegrid .swiper-slide .video-wrapper a .arrow, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper a .arrow,
        .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide .video-wrapper a .arrow,
        .imagegrid-block .slider-center-imagegrid .swiper-slide .videos-wrapper a .arrow,
        .imagegrid-block .slider-center-imagegrid .swiper-slide .video-wrapper a .arrow,
        .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .videos-wrapper a .arrow,
        .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide .video-wrapper a .arrow {
          position: absolute;
          left: 25px;
          transform: translateY(-50%);
          top: calc(50% + 2px); }
    .overlay-movie-container .slider-center-imagegrid .swiper-slide video, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide video,
    .imagegrid-block .slider-center-imagegrid .swiper-slide video,
    .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide video {
      object-fit: cover;
      height: 520px;
      width: 880px; }
      @media only screen and (max-width: 767px) {
        .overlay-movie-container .slider-center-imagegrid .swiper-slide video, .overlay-movie-container .swiper-container-imagegrid-movie .swiper-slide video,
        .imagegrid-block .slider-center-imagegrid .swiper-slide video,
        .imagegrid-block .swiper-container-imagegrid-movie .swiper-slide video {
          width: 100%;
          height: 200px; } }
  .overlay-movie-container .slider-center-imagegrid .image, .overlay-movie-container .swiper-container-imagegrid-movie .image,
  .imagegrid-block .slider-center-imagegrid .image,
  .imagegrid-block .swiper-container-imagegrid-movie .image {
    width: 880px;
    height: 520px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 767px) {
      .overlay-movie-container .slider-center-imagegrid .image, .overlay-movie-container .swiper-container-imagegrid-movie .image,
      .imagegrid-block .slider-center-imagegrid .image,
      .imagegrid-block .swiper-container-imagegrid-movie .image {
        width: 100%;
        height: 200px; } }

.overlay-movie-container div[class^='swiper-pagination-imagegrid'],
.imagegrid-block div[class^='swiper-pagination-imagegrid'] {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 30px;
  z-index: 2; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container div[class^='swiper-pagination-imagegrid'],
    .imagegrid-block div[class^='swiper-pagination-imagegrid'] {
      display: none; } }
  .overlay-movie-container div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet,
  .imagegrid-block div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet {
    margin: 0 20px;
    opacity: 1;
    background-color: #9a9a9a; }
    @media only screen and (max-width: 767px) {
      .overlay-movie-container div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet,
      .imagegrid-block div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet {
        margin: 0 10px; } }
    .overlay-movie-container div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet.swiper-pagination-bullet-active,
    .imagegrid-block div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #ffb900;
      position: relative; }
      .overlay-movie-container div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet.swiper-pagination-bullet-active:before,
      .imagegrid-block div[class^='swiper-pagination-imagegrid'] .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
        border: 2px solid #ffb900;
        border-radius: 50%;
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%; }

.overlay-movie-container div[class^='arrow-wrapper-left'] span,
.imagegrid-block div[class^='arrow-wrapper-left'] span {
  transform: rotate(180deg); }

.desktop .overlay-movie-container div[class^='arrow-wrapper-left']:not(.swiper-button-disabled) .swiper-button-prev:hover, .desktop
.imagegrid-block div[class^='arrow-wrapper-left']:not(.swiper-button-disabled) .swiper-button-prev:hover {
  transform: translateX(-5px);
  background-color: #fff; }
  .desktop .overlay-movie-container div[class^='arrow-wrapper-left']:not(.swiper-button-disabled) .swiper-button-prev:hover svg *, .desktop
  .imagegrid-block div[class^='arrow-wrapper-left']:not(.swiper-button-disabled) .swiper-button-prev:hover svg * {
    fill: #ffb900; }

.desktop .overlay-movie-container div[class^='arrow-wrapper-right']:not(.swiper-button-disabled) .swiper-button-next:hover, .desktop
.imagegrid-block div[class^='arrow-wrapper-right']:not(.swiper-button-disabled) .swiper-button-next:hover {
  transform: translateX(5px);
  background-color: #fff; }
  .desktop .overlay-movie-container div[class^='arrow-wrapper-right']:not(.swiper-button-disabled) .swiper-button-next:hover svg *, .desktop
  .imagegrid-block div[class^='arrow-wrapper-right']:not(.swiper-button-disabled) .swiper-button-next:hover svg * {
    fill: #ffb900; }

.overlay-movie-container .swiper-button-prev,
.overlay-movie-container .swiper-button-next,
.imagegrid-block .swiper-button-prev,
.imagegrid-block .swiper-button-next {
  background-image: none;
  background-color: #ffb900;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transform: translateZ(0);
  transition: background-color .3s ease,transform .3s ease; }
  .overlay-movie-container .swiper-button-prev svg *,
  .overlay-movie-container .swiper-button-next svg *,
  .imagegrid-block .swiper-button-prev svg *,
  .imagegrid-block .swiper-button-next svg * {
    transition: fill .3s ease; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .swiper-button-prev,
    .overlay-movie-container .swiper-button-next,
    .imagegrid-block .swiper-button-prev,
    .imagegrid-block .swiper-button-next {
      width: 50px;
      height: 50px; } }

.overlay-movie-container .swiper-button-prev,
.imagegrid-block .swiper-button-prev {
  left: -35px; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .swiper-button-prev,
    .imagegrid-block .swiper-button-prev {
      left: 0px; } }

.overlay-movie-container .swiper-button-next,
.imagegrid-block .swiper-button-next {
  right: -35px; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-container .swiper-button-next,
    .imagegrid-block .swiper-button-next {
      right: 0px; } }

.overlay-movie-container .bg-preload {
  display: block !important; }

/* ========================*/
/* ==[ Citation Block ]== */
/*========================*/
.citation-block {
  position: relative; }
  .citation-block .quote-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #333333;
    position: absolute;
    left: 0; }
    @media only screen and (max-width: 767px) {
      .citation-block .quote-icon {
        height: 30px;
        width: 30px; } }
    .citation-block .quote-icon span {
      font-size: 30px;
      font-family: "ReneBold", sans-serif;
      top: 7px;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .citation-block .quote-icon span {
          font-size: 25px; } }
  .citation-block p {
    font-size: 25px;
    font-family: "ReneExtraBold", sans-serif;
    color: #333333;
    font-size: 21px;
    line-height: 2em;
    margin-bottom: 35px; }
    @media only screen and (max-width: 767px) {
      .citation-block p {
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .citation-block p {
        font-size: 15px;
        margin-bottom: 20px; } }
  .citation-block .author {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    font-size: 11px;
    position: relative; }
    .citation-block .author.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .citation-block .author.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
    .citation-block .author:before {
      position: absolute;
      content: '';
      width: 40px;
      height: 1px;
      background-color: #9a9a9a;
      left: -45px;
      top: 5px; }
      @media only screen and (max-width: 767px) {
        .citation-block .author:before {
          width: 30px; } }

/* ===========================*/
/* ==[ Video Text Block ]== */
/*===========================*/
.video-text-block {
  lost-utility: clearfix; }
  .video-text-block .container-inner {
    display: flex;
    align-items: center;
    margin-bottom: 50px; }
    @media only screen and (max-width: 767px) {
      .video-text-block .container-inner {
        display: block;
        lost-utility: clearfix; } }
  .video-text-block .extra-citation {
    lost-utility: clearfix; }
  .video-text-block .col-single {
    margin-bottom: 50px; }
    @media only screen and (max-width: 767px) {
      .video-text-block .col-single {
        margin-bottom: 0px; } }
    .video-text-block .col-single:nth-of-type(even) {
      lost-column: 7/16 2 20px;
      float: left !important; }
      @media only screen and (max-width: 767px) {
        .video-text-block .col-single:nth-of-type(even) {
          lost-column: 1/1 1 0px;
          margin-left: 0 !important; } }
    .video-text-block .col-single:nth-of-type(odd) {
      lost-column: 6/16 2 20px;
      margin-right: 8% !important;
      lost-offset: 1/16; }
      @media only screen and (max-width: 1024px) {
        .video-text-block .col-single:nth-of-type(odd) {
          lost-column: 1/2 2 20px;
          lost-offset: clear; } }
      @media only screen and (max-width: 767px) {
        .video-text-block .col-single:nth-of-type(odd) {
          lost-column: 1/1 1 0px;
          lost-offset: 1/1;
          margin-left: 0 !important;
          margin-right: 0 !important; } }
    @media only screen and (max-width: 767px) {
      .video-text-block .col-single .citation-block .author {
        padding-left: 0; } }
  .video-text-block.container-main {
    padding-top: 150px;
    padding-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .video-text-block.container-main {
        padding-top: 50px;
        padding-bottom: 50px; } }
  .video-text-block .left {
    lost-column: 6/16 2 20px;
    lost-offset: 1/16;
    margin-right: 8% !important; }
    @media only screen and (max-width: 1024px) {
      .video-text-block .left {
        lost-column: 1/2 2 20px;
        lost-offset: 0; } }
    @media only screen and (max-width: 767px) {
      .video-text-block .left {
        lost-column: 1/1 1 0px;
        lost-offset: 1/1;
        margin-left: 0 !important;
        margin-right: 0 !important; } }
  .video-text-block .right {
    lost-column: 1/2 2 20px; }
    @media only screen and (max-width: 767px) {
      .video-text-block .right {
        lost-column: 1/1 1 0px; } }
  .video-text-block .left .wrapper h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333; }
    @media only screen and (max-width: 767px) {
      .video-text-block .left .wrapper h3 {
        font-size: 26px; } }
    .video-text-block .left .wrapper h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .video-text-block .left .wrapper h3 {
        margin-bottom: 20px; } }
  .video-text-block .left .wrapper p {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666;
    margin-bottom: 40px; }
  .video-text-block .right {
    position: relative; }
    @media only screen and (max-width: 767px) {
      .video-text-block .right {
        margin-top: 40px; } }
    .video-text-block .right img {
      width: 100%; }
  .video-text-block .btn {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%; }
    .video-text-block .btn .arrow {
      position: absolute;
      left: 25px;
      transform: translateY(-50%);
      top: calc(50% + 2px); }
  .video-text-block .citation-block p {
    padding-left: 14%; }
  .video-text-block .citation-block .author {
    padding-left: 14%;
    display: block; }
  @media only screen and (max-width: 767px) {
    .video-text-block .citation-block {
      padding: 40px;
      padding-top: 0; }
      .video-text-block .citation-block p {
        padding-left: 0%; }
      .video-text-block .citation-block .author {
        position: relative; } }
  .video-text-block .citation-block .quote-icon {
    left: 0px; }
  .video-text-block .citation-block .author:before {
    left: 0px; }
    @media only screen and (max-width: 767px) {
      .video-text-block .citation-block .author:before {
        left: -40px; } }

/* ========================*/
/* ==[ Related Block ]== */
/*========================*/
.related-block {
  background-color: #fff;
  overflow: hidden;
  position: relative; }
  .related-block:before {
    content: '';
    position: absolute;
    top: 400px;
    left: 0;
    background-color: #eeeeee;
    width: 100%;
    height: 600px; }
  .related-block.container-main {
    padding-top: 100px;
    padding-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .related-block.container-main {
        padding-top: 50px;
        padding-bottom: 50px; } }
  .related-block .title {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    text-align: center;
    margin-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .related-block .title {
        font-size: 26px; } }
    .related-block .title.-white {
      color: #fff; }
  .related-block .wrapper {
    lost-utility: clearfix;
    margin-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .related-block .wrapper {
        margin-bottom: 50px; } }
  .related-block .wrapper .preview-block {
    lost-column: 1/2 2 80px; }
    @media only screen and (max-width: 767px) {
      .related-block .wrapper .preview-block {
        lost-column: 1/1 1 0px; }
        .related-block .wrapper .preview-block:first-of-type {
          margin-bottom: 50px; } }
  .related-block .view-more {
    text-align: center;
    font-family: "ReneBold", sans-serif;
    font-size: 11px;
    color: #333333;
    margin: auto;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative; }
    .related-block .view-more svg {
      margin-left: 5px; }
    .related-block .view-more svg * {
      fill: #333333; }
    .related-block .view-more span {
      position: relative; }
      .related-block .view-more span:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #333333;
        left: 0;
        bottom: 0;
        opacity: 0;
        transform: translateY(1px) scaleX(0);
        transition: transform .3s ease-out,opacity .3s ease-out;
        transform-origin: left center; }
      .desktop .related-block .view-more span:hover:before, .related-block .view-more span.active:before {
        opacity: 1;
        transform: translateY(1px) scale(1); }

/* ========================*/
/* ==[ Movie slide ]== */
/*========================*/
.movie-slide {
  padding-top: 100px;
  padding-bottom: 0px; }
  @media only screen and (max-width: 767px) {
    .movie-slide {
      padding-top: 50px;
      padding-bottom: 40px; } }
  .movie-slide h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    margin: auto;
    display: block;
    text-align: center;
    padding-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .movie-slide h3 {
        font-size: 26px; } }
    .movie-slide h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .movie-slide h3 {
        padding-bottom: 30px; } }
  body:not(.no-perspective) .movie-slide .perspective-wrapper {
    perspective: 1000px;
    transform-style: preserve-3d; }
  body:not(.no-perspective) .movie-slide .slider-wrapper {
    transform-style: preserve-3d; }
  .movie-slide .arrows {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    font-size: 0; }
    .movie-slide .arrows .left, .movie-slide .arrows .right {
      height: 40px;
      width: 40px;
      position: relative;
      border: 2px solid #ffb900;
      display: inline-block;
      transition: background-color .3s ease;
      cursor: pointer; }
      .desktop .movie-slide .arrows .left:hover, .desktop .movie-slide .arrows .right:hover {
        background-color: #ffb900; }
        .desktop .movie-slide .arrows .left:hover svg *, .desktop .movie-slide .arrows .right:hover svg * {
          fill: #000; }
      .movie-slide .arrows .left svg, .movie-slide .arrows .right svg {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 20px; }
        .movie-slide .arrows .left svg *, .movie-slide .arrows .right svg * {
          fill: #ffb900;
          transition: fill .3s ease; }
    .movie-slide .arrows .left {
      border-right: none; }
      .movie-slide .arrows .left svg {
        transform-origin: center center;
        transform: translate(-50%, -50%) rotate(180deg); }
  .movie-slide .swiper-slide {
    height: auto;
    width: 280px;
    margin-left: 30px;
    position: relative; }
    body:not(.no-perspective) .movie-slide .swiper-slide {
      transform-style: preserve-3d; }
    @media only screen and (max-width: 767px) {
      .movie-slide .swiper-slide {
        height: auto;
        width: 90%;
        margin-left: 0px;
        opacity: .3;
        transition: opacity .3s ease; }
        .movie-slide .swiper-slide.swiper-slide-active {
          opacity: 1; }
        .movie-slide .swiper-slide img {
          width: 100%; } }
    .movie-slide .swiper-slide img {
      height: 100%; }
      @media only screen and (max-width: 767px) {
        .movie-slide .swiper-slide img {
          height: auto; } }
    .movie-slide .swiper-slide h4 {
      color: #333333;
      font-size: 20px;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      margin-top: 20px;
      position: relative;
      left: 40px; }
      body:not(.no-perspective) .movie-slide .swiper-slide h4 {
        transform: translateZ(50px); }
      .movie-slide .swiper-slide h4:before {
        content: '';
        bottom: 5px;
        position: absolute;
        left: -20px;
        width: 2px;
        height: 80px;
        background-color: #333333; }

/* ========================*/
/* ==[ Single info General ]== */
/*========================*/
.single-info-general {
  background-color: #eeeeee;
  margin-bottom: 40px; }
  .single-info-general .wrapper {
    lost-utility: clearfix; }
    .single-info-general .wrapper h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      padding-bottom: 50px; }
      .single-info-general .wrapper h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .single-info-general .wrapper h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      @media only screen and (max-width: 767px) {
        .single-info-general .wrapper h4 {
          padding-bottom: 30px; } }
  .single-info-general .left, .single-info-general .right {
    lost-column: 1/2 2 0; }
    @media only screen and (max-width: 767px) {
      .single-info-general .left, .single-info-general .right {
        lost-column: 1/1 1 0; } }
  .single-info-general .mobile {
    display: none; }
    @media only screen and (max-width: 767px) {
      .single-info-general .mobile {
        display: block; } }
  .single-info-general .left {
    position: relative;
    margin-top: -100px; }
    @media only screen and (max-width: 767px) {
      .single-info-general .left {
        margin-top: -60px; } }
    .single-info-general .left .content {
      padding: 40px 10% 80px 10%;
      background-color: #fff; }
      @media only screen and (max-width: 767px) {
        .single-info-general .left .content {
          padding: 25px 20px 30px 20px; } }
    .single-info-general .left .header-description {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      line-height: 1.5em; }
      @media only screen and (max-width: 767px) {
        .single-info-general .left .header-description {
          font-size: 20px; } }
    .single-info-general .left .citation-block {
      padding: 60px 10% 0; }
      @media only screen and (max-width: 1024px) {
        .single-info-general .left .citation-block {
          padding: 60px 10% 0 14%; } }
      @media only screen and (max-width: 767px) {
        .single-info-general .left .citation-block {
          padding: 40px 14% 0; } }
      .single-info-general .left .citation-block .quote-icon {
        top: 60px; }
        @media only screen and (max-width: 767px) {
          .single-info-general .left .citation-block .quote-icon {
            top: 40px; } }
    @media only screen and (max-width: 767px) {
      .single-info-general .left:not(.not-left) .citation-block {
        display: none; } }
    .single-info-general .left.not-left {
      margin-top: 0; }
  .single-info-general .right {
    background-color: #1f1f1f; }
    .single-info-general .right .yellow-text-block {
      padding: 60px 10%; }
      @media only screen and (max-width: 767px) {
        .single-info-general .right .yellow-text-block {
          padding: 30px 20px; } }
      .single-info-general .right .yellow-text-block p {
        font-size: 16px;
        font-family: "ReneBold", sans-serif;
        line-height: 2em;
        color: #c0c0c0; }

/* ========================*/
/* ==[ Information Block ]== */
/*========================*/
.information-block {
  padding-top: 90px !important;
  background-color: #eeeeee; }
  @media only screen and (max-width: 767px) {
    .information-block {
      margin-top: 30px; } }
  .information-block .big-para-block {
    padding-bottom: 80px; }
  .information-block.container-main {
    padding-bottom: 90px; }
    @media only screen and (max-width: 767px) {
      .information-block.container-main {
        padding-bottom: 00px; } }
  .information-block .yellow-text-block {
    max-width: 750px; }
    .information-block .yellow-text-block p {
      color: #333333; }
      @media only screen and (max-width: 767px) {
        .information-block .yellow-text-block p {
          font-size: 18px; } }
  .information-block .point-container {
    margin-bottom: 120px; }
  .information-block .point-wrapper {
    width: 550px;
    display: block;
    margin: auto;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .information-block .point-wrapper {
        width: 100%; } }
    .information-block .point-wrapper .left {
      height: 50px;
      width: 50px;
      display: inline-block;
      vertical-align: top; }
    .information-block .point-wrapper .right {
      display: inline-block;
      padding-left: 10px;
      width: calc(100%	- 55px); }
      .information-block .point-wrapper .right h4 {
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        color: #333333;
        margin-bottom: 10px; }
        .information-block .point-wrapper .right h4.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .information-block .point-wrapper .right h4.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
      .information-block .point-wrapper .right p {
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666;
        line-height: 1.5em; }
  .information-block .graph {
    margin: auto;
    display: block;
    padding-bottom: 80px;
    width: 450px; }
    @media only screen and (max-width: 767px) {
      .information-block .graph {
        padding-bottom: 50px; } }
  .information-block .information-block__process {
    lost-utility: clearfix; }
    .information-block .information-block__process .col {
      lost-column: 1/3 3 40px;
      margin-bottom: 50px; }
      @media only screen and (max-width: 767px) {
        .information-block .information-block__process .col {
          lost-column: 1/1 1 0;
          text-align: center; } }

/* ========================*/
/* ==[ Forms ]== */
/*========================*/
.gform_wrapper form > .validation_error {
  font-family: "ReneBold", sans-serif;
  color: #9a9a9a;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  color: #c63630;
  margin-bottom: 20px;
  font-size: 13px; }
  .gform_wrapper form > .validation_error.-line:before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% - 1px);
    background-color: #9a9a9a;
    height: 2px;
    width: 55vw;
    transform-origin: right center;
    left: -56vw; }
    @media only screen and (max-width: 767px) {
      .gform_wrapper form > .validation_error.-line:before {
        height: 50px;
        width: 1px;
        transform: translateX(-50%);
        left: 50%;
        top: -70px; } }

.gform_wrapper .gform_heading {
  display: none; }

.gform_wrapper .half {
  width: 48%;
  display: inline-block; }
  @media only screen and (max-width: 767px) {
    .gform_wrapper .half {
      width: 100%; } }
  .gform_wrapper .half input, .gform_wrapper .half select {
    width: 100%; }

.gform_wrapper .first {
  margin-right: 4%; }
  @media only screen and (max-width: 767px) {
    .gform_wrapper .first {
      margin-right: 0; } }

.gform_wrapper .gfield_label {
  font-family: "ReneBold", sans-serif;
  color: #9a9a9a;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  color: #333333;
  margin-bottom: 10px;
  display: block; }
  .gform_wrapper .gfield_label.-line:before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% - 1px);
    background-color: #9a9a9a;
    height: 2px;
    width: 55vw;
    transform-origin: right center;
    left: -56vw; }
    @media only screen and (max-width: 767px) {
      .gform_wrapper .gfield_label.-line:before {
        height: 50px;
        width: 1px;
        transform: translateX(-50%);
        left: 50%;
        top: -70px; } }
  @media only screen and (max-width: 767px) {
    .gform_wrapper .gfield_label {
      font-size: 9px; } }

.gform_wrapper .gfield {
  margin-bottom: 30px; }

.gform_wrapper input[type="text"], .gform_wrapper select {
  background-color: #eeeeee;
  border: none;
  font-size: 13px;
  color: #333333;
  padding: 20px 20px;
  font-family: "ReneBold", sans-serif;
  letter-spacing: 1px; }

.gform_wrapper .full textarea, .gform_wrapper .full input, .gform_wrapper .full select {
  width: 100%; }

.gform_wrapper textarea {
  background-color: #eeeeee;
  border: none;
  font-size: 16px;
  font-family: "ReneBook", sans-serif;
  line-height: 2em;
  color: #666666;
  line-height: 1.2em;
  padding: 20px; }

.gform_wrapper .ginput_recaptcha > div {
  margin-left: auto; }

.gform_wrapper .ginput_counter {
  font-family: "ReneBold", sans-serif;
  color: #9a9a9a;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  font-size: 10px;
  color: #333333; }
  .gform_wrapper .ginput_counter.-line:before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% - 1px);
    background-color: #9a9a9a;
    height: 2px;
    width: 55vw;
    transform-origin: right center;
    left: -56vw; }
    @media only screen and (max-width: 767px) {
      .gform_wrapper .ginput_counter.-line:before {
        height: 50px;
        width: 1px;
        transform: translateX(-50%);
        left: 50%;
        top: -70px; } }

.gform_wrapper .recaptcha-wrapper .gfield_label {
  display: none; }

.gform_wrapper .validation_message {
  display: none; }

.gform_wrapper .gfield_error label {
  color: #c63630; }

.gform_wrapper .gfield_error input, .gform_wrapper .gfield_error select, .gform_wrapper .gfield_error textarea, .gform_wrapper .gfield_error .chosen-container, .gform_wrapper .gfield_error .chosen-single span {
  background-color: #f7e1e0; }

#gform_wrapper_8 {
  margin-top: 40px; }

#gform_wrapper_7 .gform_footer,
#gform_wrapper_8 .gform_footer,
#gform_wrapper_5 .gform_footer,
#gform_wrapper_9 .gform_footer {
  text-align: right; }
  #gform_wrapper_7 .gform_footer input[type="submit"],
  #gform_wrapper_8 .gform_footer input[type="submit"],
  #gform_wrapper_5 .gform_footer input[type="submit"],
  #gform_wrapper_9 .gform_footer input[type="submit"] {
    background-color: #ffb900;
    border: none;
    padding: 20px 30px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    transition: background-color .3s ease, color .3s ease;
    line-height: 1em; }
    .desktop #gform_wrapper_7 .gform_footer input[type="submit"]:hover, .desktop
    #gform_wrapper_8 .gform_footer input[type="submit"]:hover, .desktop
    #gform_wrapper_5 .gform_footer input[type="submit"]:hover, .desktop
    #gform_wrapper_9 .gform_footer input[type="submit"]:hover {
      background-color: #333333;
      color: #ffb900; }

#gform_wrapper_5.gform_wrapper .gform_heading {
  display: block;
  text-align: center; }
  #gform_wrapper_5.gform_wrapper .gform_heading h3 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 30px; }
    #gform_wrapper_5.gform_wrapper .gform_heading h3.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        #gform_wrapper_5.gform_wrapper .gform_heading h3.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  #gform_wrapper_5.gform_wrapper .gform_heading .gform_description {
    margin-bottom: 120px;
    display: block;
    font-size: 40px;
    font-family: "ReneBold", sans-serif;
    color: #333333;
    position: relative; }
    @media only screen and (max-width: 767px) {
      #gform_wrapper_5.gform_wrapper .gform_heading .gform_description {
        font-size: 28px; } }
    #gform_wrapper_5.gform_wrapper .gform_heading .gform_description:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      left: 50%;
      width: 2px;
      height: 50px;
      background-color: #ccc;
      bottom: -100px; }
  #gform_wrapper_5.gform_wrapper .gform_heading .v-line {
    width: 2px;
    height: 50px;
    display: block;
    margin: auto;
    background-color: #000;
    opacity: .2; }

.fr .gform_footer {
  position: relative; }
  .fr .gform_footer:before {
    content: '*Champ(s) requis';
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    position: absolute;
    font-weight: bold;
    width: 150px;
    left: 0;
    text-align: left;
    color: #333333;
    font-size: 12px;
    text-transform: initial; }
    .fr .gform_footer:before.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .fr .gform_footer:before.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }

.en .gform_footer {
  position: relative; }
  .en .gform_footer:before {
    content: '*Field(s) required';
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    position: absolute;
    font-weight: bold;
    width: 150px;
    left: 0;
    text-align: left;
    color: #333333;
    font-size: 12px;
    text-transform: initial; }
    .en .gform_footer:before.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .en .gform_footer:before.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }

.gfield_captcha_container {
  display: flex;
  overflow: hidden; }
  .gfield_captcha_container .gfield_captcha {
    position: absolute;
    right: 0; }
  .gfield_captcha_container input {
    height: 42px;
    order: 1; }
  .gfield_captcha_container img {
    order: 2; }

/* ========================*/
/* ==[ Overlay video ]== */
/*========================*/
.overlay-video {
  width: calc(100vw - 60px);
  height: calc(100vh - 60px);
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 55;
  background-color: #fff;
  pointer-events: none;
  transform: translateY(150px);
  opacity: 0;
  transition: transform .6s ease,opacity .6s ease; }
  @media only screen and (max-width: 767px) {
    .overlay-video {
      width: calc(100vw - 60px);
      height: calc(90vh - 60px); } }
  .overlay-video iframe {
    width: calc(100vw - 60px);
    height: calc(100vh - 60px); }
    @media only screen and (max-width: 767px) {
      .overlay-video iframe {
        height: calc(90vh - 60px); } }
  .overlay-video.active {
    pointer-events: auto;
    transform: translateY(0px);
    opacity: 1; }

.overlay-movie-copy {
  display: none; }
  @media only screen and (max-width: 767px) {
    .overlay-movie-copy {
      overflow: scroll; } }

.overlay-movie-container {
  z-index: 10;
  position: fixed;
  top: 0; }

.overlay-movie {
  z-index: 5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 0;
  overflow-y: scroll; }
  @media only screen and (max-width: 1024px) {
    .overlay-movie {
      z-index: 500; } }
  .overlay-movie .select-navigation__closed {
    z-index: 5;
    position: relative; }
    .overlay-movie .select-navigation__closed .yellow-text-block {
      margin: auto; }
    .overlay-movie .select-navigation__closed.container-main {
      padding: 0 5vw; }
  @media only screen and (max-width: 767px) {
    .overlay-movie {
      padding: 0;
      overflow: hidden; } }
  .overlay-movie .overlay-movie-copy {
    display: block;
    width: 880px;
    margin: auto;
    position: relative;
    background-color: #fff;
    margin-bottom: 40px; }
    .overlay-movie .overlay-movie-copy .bg-preload {
      width: 100%;
      height: 100%;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover; }
    @media only screen and (max-width: 900px) {
      .overlay-movie .overlay-movie-copy {
        width: calc(100% - 80px); } }
    @media only screen and (max-width: 767px) {
      .overlay-movie .overlay-movie-copy {
        width: 100%;
        height: 100vh; } }
  .overlay-movie .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 520px; }
    @media only screen and (max-width: 767px) {
      .overlay-movie .swiper-slide {
        height: auto; } }
    .overlay-movie .swiper-slide img {
      width: 100%; }
  @media only screen and (max-width: 767px) {
    .overlay-movie .swiper-wrapper {
      height: 200px; } }
  .overlay-movie .swiper-container-imagegrid-movie {
    margin: auto;
    width: 880px;
    height: 520px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .overlay-movie .swiper-container-imagegrid-movie {
        height: 250px;
        width: 100%; } }
  .overlay-movie .overlay-content {
    padding: 60px;
    background-color: #eeeeee; }
    @media only screen and (max-width: 767px) {
      .overlay-movie .overlay-content {
        padding: 30px; } }
    .overlay-movie .overlay-content p {
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666; }
    .overlay-movie .overlay-content p + p {
      padding-top: 10px; }
    .overlay-movie .overlay-content h2 {
      font-size: 33px;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      color: #333333;
      margin-bottom: 30px; }
      @media only screen and (max-width: 767px) {
        .overlay-movie .overlay-content h2 {
          font-size: 26px; } }
      .overlay-movie .overlay-content h2.-white {
        color: #fff; }

.overlay-newsletter {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed !important; }
  @media only screen and (max-width: 1024px) {
    .overlay-newsletter {
      z-index: 500 !important; } }
  @media only screen and (max-width: 767px) {
    .overlay-newsletter {
      position: absolute !important;
      bottom: auto;
      height: 100vh !important;
      z-index: 50000 !important; } }
  .overlay-newsletter .gform_confirmation_wrapper {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666; }
    .overlay-newsletter .gform_confirmation_wrapper h3 {
      font-size: 33px;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      color: #333333;
      text-transform: none;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .overlay-newsletter .gform_confirmation_wrapper h3 {
          font-size: 26px; } }
      .overlay-newsletter .gform_confirmation_wrapper h3.-white {
        color: #fff; }
  .overlay-newsletter img.gform_ajax_spinner {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    background: none !important;
    animation: spinner .6s linear infinite; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.overlay-download-pdf, .overlay-newsletter {
  position: absolute;
  left: -999em;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 30px 0;
  z-index: 40;
  overflow: scroll; }
  .overlay-download-pdf h3, .overlay-newsletter h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    text-transform: none;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .overlay-download-pdf h3, .overlay-newsletter h3 {
        font-size: 26px; } }
    .overlay-download-pdf h3.-white, .overlay-newsletter h3.-white {
      color: #fff; }
  @media only screen and (max-width: 767px) {
    .overlay-download-pdf, .overlay-newsletter {
      padding: 0px;
      padding-bottom: 40px; } }
  .overlay-download-pdf .wrapper, .overlay-newsletter .wrapper {
    width: 880px;
    background-color: #fff;
    padding: 40px 60px;
    position: relative;
    margin: auto; }
    @media only screen and (max-width: 1024px) {
      .overlay-download-pdf .wrapper, .overlay-newsletter .wrapper {
        width: 90%;
        padding: 40px; } }
    @media only screen and (max-width: 767px) {
      .overlay-download-pdf .wrapper, .overlay-newsletter .wrapper {
        width: 100%; } }

.overlay-newsletter {
  overflow-x: hidden; }

.overlay-download-pdf {
  padding-top: 60px;
  z-index: 200; }
  @media only screen and (max-width: 1024px) {
    .overlay-download-pdf .wrapper {
      padding: 40px 60px 40px 20px; } }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }
  .overlay-download-pdf .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite; }
  .overlay-download-pdf h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    text-transform: none; }
    @media only screen and (max-width: 767px) {
      .overlay-download-pdf h3 {
        font-size: 26px; } }
    .overlay-download-pdf h3.-white {
      color: #fff; }
  .overlay-download-pdf .wrapper-input {
    position: relative;
    margin: 30px 0; }
  .overlay-download-pdf input {
    width: 300px; }
  .overlay-download-pdf .placeholder {
    color: #9a9a9a;
    font-family: "ReneBold", sans-serif;
    letter-spacing: 1px;
    font-size: 11px;
    position: absolute;
    left: 20px;
    transform: translateY(-50%);
    top: 50%;
    text-transform: uppercase;
    pointer-events: none; }
    @media only screen and (max-width: 767px) {
      .overlay-download-pdf .placeholder {
        left: 20px; } }
  .overlay-download-pdf .loading-msg, .overlay-download-pdf .server-error-msg, .overlay-download-pdf .email-error-msg, .overlay-download-pdf .success-msg {
    transition: opacity .3s ease,transform .3s ease;
    margin-top: 20px;
    color: #c63630;
    display: none;
    font-family: "ReneBook", sans-serif; }
  .overlay-download-pdf button {
    background-color: #ffb900;
    border: none;
    padding: 20px 30px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    transition: background-color .3s ease, color .3s ease; }
    .desktop .overlay-download-pdf button:hover {
      background-color: #333333;
      color: #ffb900; }
  .overlay-download-pdf.success .success-msg {
    opacity: 1;
    display: block;
    margin-top: 0; }
  .overlay-download-pdf.success .form-contain {
    display: none; }
  .overlay-download-pdf.email-error .email-error-msg {
    opacity: 1;
    display: block; }
  .overlay-download-pdf.server-error .server-error-msg {
    opacity: 1;
    display: block; }
  .overlay-download-pdf.loading .loading-msg {
    opacity: 1;
    display: block; }
  .overlay-download-pdf.active .wrapper {
    pointer-events: auto; }
  .overlay-download-pdf .wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    pointer-events: none; }
    @media only screen and (max-width: 767px) {
      .overlay-download-pdf .wrapper {
        transform: none;
        top: auto;
        left: auto; } }

.overlay-download-pdf .btn-close, .overlay-newsletter .btn-close, .overlay-movie .btn-close, .overlay-video .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100px;
  background-color: rgba(31, 31, 31, 0.4);
  z-index: 5;
  transition: background-color .3s ease; }
  @media only screen and (max-width: 767px) {
    .overlay-download-pdf .btn-close, .overlay-newsletter .btn-close, .overlay-movie .btn-close, .overlay-video .btn-close {
      height: 60px; } }
  .desktop .overlay-download-pdf .btn-close:hover, .desktop .overlay-newsletter .btn-close:hover, .desktop .overlay-movie .btn-close:hover, .desktop .overlay-video .btn-close:hover {
    background-color: rgba(31, 31, 31, 0.9); }
    .desktop .overlay-download-pdf .btn-close:hover .line:nth-of-type(1), .desktop .overlay-newsletter .btn-close:hover .line:nth-of-type(1), .desktop .overlay-movie .btn-close:hover .line:nth-of-type(1), .desktop .overlay-video .btn-close:hover .line:nth-of-type(1) {
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: #fff; }
    .desktop .overlay-download-pdf .btn-close:hover .line:nth-of-type(2), .desktop .overlay-newsletter .btn-close:hover .line:nth-of-type(2), .desktop .overlay-movie .btn-close:hover .line:nth-of-type(2), .desktop .overlay-video .btn-close:hover .line:nth-of-type(2) {
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #fff; }
  .overlay-download-pdf .btn-close .line, .overlay-newsletter .btn-close .line, .overlay-movie .btn-close .line, .overlay-video .btn-close .line {
    width: 20px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    transition: transform .5s ease, background-color .3s ease; }
    .overlay-download-pdf .btn-close .line:nth-of-type(1), .overlay-newsletter .btn-close .line:nth-of-type(1), .overlay-movie .btn-close .line:nth-of-type(1), .overlay-video .btn-close .line:nth-of-type(1) {
      transform-origin: center center;
      transform: translate(-50%, -50%) rotate(135deg); }
    .overlay-download-pdf .btn-close .line:nth-of-type(2), .overlay-newsletter .btn-close .line:nth-of-type(2), .overlay-movie .btn-close .line:nth-of-type(2), .overlay-video .btn-close .line:nth-of-type(2) {
      transform-origin: center center;
      transform: translate(-50%, -50%) rotate(-135deg);
      transition-delay: .1s; }

/* ========================*/
/* ==[ Bullet Navigation ]== */
/*========================*/
.bullet-navigation {
  position: fixed;
  right: 0;
  width: 60px;
  z-index: 12;
  top: 25vh;
  pointer-events: auto; }
  @media only screen and (max-width: 767px) {
    .bullet-navigation {
      display: none; } }
  .bullet-navigation ul li {
    width: 6px;
    height: 6px;
    margin: 30px auto;
    background-color: #9a9a9a;
    border-radius: 50%;
    position: relative;
    transition: transform .3s ease;
    cursor: pointer; }
    .desktop .bullet-navigation ul li:not(.active):hover {
      transform: scale(2); }
    .bullet-navigation ul li.active {
      background-color: #ffb900;
      transform: scale(1); }
      .bullet-navigation ul li.active.active-current:before {
        opacity: 1; }
    .bullet-navigation ul li:before {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 250%;
      height: 250%;
      border-radius: 50%;
      border: 2px solid #ffb900;
      opacity: 0;
      transition: opacity .3s ease; }

/* ========================*/
/* ==[ Main ]== */
/*========================*/
/* [CON] ==== Containers
/* ========================*/
/* -----------------------*/
/* [CON] ==== Containers
/*------------------------*/
.container-main {
  width: 100%;
  padding: 0 13vw; }
  @media only screen and (max-width: 1600px) {
    .container-main {
      padding: 0 12vw; } }
  @media only screen and (max-width: 1300px) {
    .container-main {
      padding: 0 7vw; } }
  @media only screen and (max-width: 1024px) {
    .container-main {
      padding: 0 20px; } }

.container-inner {
  max-width: 1600px;
  margin: auto; }

.container-medium {
  width: 100%;
  max-width: 1250px;
  margin: auto; }

.container-small {
  width: 100%;
  max-width: 890px;
  margin: auto; }

.barba-container {
  width: 100%; }

html, body {
  width: 100%;
  overflow-x: hidden;
  height: auto; }

body {
  position: fixed; }

.scroll-wrapper {
  width: 100vw; }

#page {
  width: 100vw;
  pointer-events: auto; }

@keyframes repaint {
  from {
    width: 99.999%; }
  to {
    width: 100%; } }
  #page .repaint {
    animation: repaint 5000ms; }

.will-change {
  will-change: opacity, transform; }

/* ========================*/
/* ==[ Loader ]== */
/*========================*/
.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  top: 0;
  left: 0; }
  .loader .bg-yellow {
    background-color: #ffb900;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 12;
    transform: scaleX(1);
    transform-origin: left center; }
  .loader .bg-gray {
    background-color: #333333;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    transform: scaleX(1);
    transform-origin: left center; }
  .loader .loader-container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: calc(50% - 4vh);
    left: 50%;
    z-index: 20;
    display: block;
    opacity: 1;
    width: calc(1000px / 2);
    height: calc(700px / 2);
    background: url("../img/sprites.png") left top;
    background-size: calc(1000px / 2) auto; }
    .loader .loader-container.active {
      animation: play1 2.8s steps(46) infinite; }
    @media only screen and (max-width: 767px) {
      .loader .loader-container {
        width: calc(615px / 2);
        height: calc(346px / 2);
        background: url("../img/sprites-1.png") left top;
        background-size: calc(615px / 2) auto; }
        .loader .loader-container.active {
          animation: play 2.8s steps(52) infinite; } }

@keyframes play1 {
  100% {
    background-position-y: -32425px; } }

@keyframes play {
  100% {
    background-position-y: -17992px; } }

/* ========================*/
/* ==[ Header ]== */
/*========================*/
/* [LOG] ==== Logo
/* [MEN] ==== Menu
/* ========================*/
/* -----------------------*/
/* [LOG] ==== Logo + Menu Btn
/*------------------------*/
.bg-menu {
  background-color: #1f1f1f;
  width: 100%;
  position: absolute;
  top: 0;
  left: 200px;
  z-index: 2;
  display: block;
  transform: scaleX(0);
  transform-origin: left top;
  will-change: transform;
  pointer-events: none;
  height: 150px; }
  @media only screen and (max-width: 767px) {
    .bg-menu {
      left: 0;
      height: 60px; } }

.main-header {
  position: fixed;
  height: 150px;
  top: 0;
  z-index: 51;
  pointer-events: none; }
  .main-header .back-btn {
    width: 260px;
    background-color: #ffb900;
    padding: 10px;
    transform: translateY(150px);
    display: none;
    position: absolute;
    transition: background-color .3s ease,opacity .3s ease;
    pointer-events: auto;
    opacity: 1;
    transition-delay: 0.8s; }
    .menu-open .main-header .back-btn {
      opacity: 0;
      transition-delay: 0s; }
    @media only screen and (max-width: 767px) {
      .main-header .back-btn {
        display: none !important; } }
    .main-header .back-btn:hover .label {
      color: #fff; }
      .main-header .back-btn:hover .label svg * {
        fill: #fff; }
    .main-header .back-btn .label {
      width: 100%;
      text-align: center;
      display: inline-block;
      position: relative;
      font-family: "ReneBold", sans-serif;
      font-size: 11px;
      color: #333333;
      letter-spacing: 1px;
      transition: color .3s ease;
      text-transform: uppercase; }
      .main-header .back-btn .label svg {
        width: 20px;
        position: absolute;
        left: 10px;
        top: 0px;
        transform: rotate(180deg); }
        .main-header .back-btn .label svg * {
          transition: fill .3s ease; }
  @media only screen and (max-width: 767px) {
    .main-header {
      width: 100%; }
      .main-header .logo-bottom {
        width: 100px;
        position: absolute;
        left: 15px;
        transform: translateY(-50%);
        top: 58%;
        display: block; } }
  .main-header .logo, .main-header .menu-btn {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    float: left; }
  .main-header .logo {
    width: 200px;
    background-color: #000;
    z-index: 3;
    position: absolute;
    pointer-events: auto; }
    @media only screen and (max-width: 767px) {
      .main-header .logo {
        width: calc(100% - 60px); } }
  .main-header .menu-btn {
    width: 60px;
    background-color: #1f1f1f;
    z-index: 3;
    position: absolute;
    left: 200px;
    pointer-events: auto;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .main-header .menu-btn {
        height: 60px;
        left: auto;
        right: 0; } }
    .desktop .main-header .menu-btn:hover .line:nth-of-type(1) {
      transform: translateY(-4px); }
    .desktop .main-header .menu-btn:hover .line:nth-of-type(2) {
      transition-delay: .2s;
      transform: translateY(-50%) rotate(180deg); }
    .desktop .main-header .menu-btn:hover .line:nth-of-type(3) {
      transform: translateY(4px); }
    .menu-open .main-header .menu-btn .line:nth-of-type(1) {
      transition-delay: .3s;
      transform: translate(4px, 2px) rotate(45deg) !important; }
    .menu-open .main-header .menu-btn .line:nth-of-type(2) {
      transition-delay: .2s;
      transform: translateY(calc(-8px)) rotate(45deg) !important;
      opacity: 0; }
    .menu-open .main-header .menu-btn .line:nth-of-type(3) {
      transition-delay: .3s;
      transform: translate(-3px, -16px) rotate(-45deg) !important; }
    .desktop.menu-open .main-header .menu-btn:hover .icon-menu {
      opacity: .5;
      transform: rotate(180deg) scale(1.2); }
  .main-header .icon-menu {
    width: 20px;
    height: 20px;
    position: relative;
    transition: opacity .3s ease,transform .3s ease; }
    .main-header .icon-menu .line {
      height: 2px;
      background-color: #fff;
      width: 100%;
      display: block;
      position: absolute;
      left: 0; }
      .main-header .icon-menu .line:nth-of-type(1) {
        transform-origin: top left;
        background-color: #fff;
        transition: transform .3s ease;
        top: 0; }
      .main-header .icon-menu .line:nth-of-type(2) {
        transform-origin: center center;
        background-color: #fff;
        transform: translateY(-50%);
        top: 50%;
        opacity: 1;
        transition: transform .3s ease,opacity .3s ease;
        transition-delay: .0s; }
      .main-header .icon-menu .line:nth-of-type(3) {
        transform-origin: top right;
        background-color: #fff;
        bottom: 0;
        transition: transform .3s ease; }

/* -----------------------*/
/* [MEN] ==== Menu
/*------------------------*/
.overlay-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 50;
  pointer-events: none;
  top: 0; }
  .overlay-menu:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #1f1f1f;
    transition: opacity .2s ease; }
  .overlay-menu.bg-active:before {
    opacity: 1; }

.main-header {
  width: 100%; }

.menu {
  pointer-events: none;
  min-height: 680px;
  position: relative;
  z-index: 2; }
  @media only screen and (max-width: 767px) {
    .menu {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      height: 100vh;
      min-height: 0; } }
  .menu .left, .menu .right {
    position: absolute;
    top: 0; }
  .menu .left {
    width: 200px;
    height: 100vh;
    background-color: #000;
    transform-origin: top center;
    transform: scaleY(0);
    padding-top: 22vh;
    padding-bottom: 10vh; }
    @media screen and (max-height: 730px) {
      .desktop .menu .left {
        padding-top: 26vh; } }
    @media only screen and (max-width: 767px) {
      .menu .left {
        position: relative;
        order: 2;
        width: 100%;
        height: auto;
        padding-top: 40px;
        padding-bottom: 60px; } }
    .menu .left ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .menu .left ul {
          display: block;
          height: auto; } }
      .menu .left ul li {
        opacity: 0;
        transform: translateX(-60px); }
        @media only screen and (max-width: 767px) {
          .menu .left ul li {
            lost-column: 1/2 2 10px;
            margin-bottom: 40px; } }
        .desktop .menu .left ul li:hover img, .menu .left ul li.active img {
          opacity: 0; }
        .desktop .menu .left ul li:hover img.hover, .menu .left ul li.active img.hover {
          opacity: 1; }
        .desktop .menu .left ul li:hover .label:before, .menu .left ul li.active .label:before {
          opacity: 1; }
      .menu .left ul .circle-icon {
        width: 100px;
        height: 100px;
        display: block;
        border: 2px solid #ffb900;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 10px; }
      .menu .left ul img {
        width: 100px;
        height: 100px;
        display: block;
        margin: auto;
        opacity: 1;
        transition: opacity .3s ease; }
        .menu .left ul img.hover {
          position: absolute;
          opacity: 0;
          top: 0;
          left: 50%;
          transform: translateX(-50%); }
        @media screen and (max-height: 730px) {
          .desktop .menu .left ul img {
            width: 70px;
            height: 70px; } }
      .menu .left ul .label {
        text-transform: uppercase;
        color: #fff;
        font-family: "ReneBold", sans-serif;
        font-size: 11px;
        transition: color .3s ease;
        display: block;
        margin-top: 10px; }
        @media only screen and (max-width: 767px) {
          .menu .left ul .label {
            font-size: 10px; } }
  .menu .right {
    left: 200px;
    width: calc(100vw - 200px);
    height: 100vh;
    transform-origin: top left;
    position: relative;
    padding-top: 20vh;
    padding-bottom: 10vh;
    padding-left: 8vw; }
    @media screen and (max-height: 730px) {
      .desktop .menu .right {
        padding-top: 15vh; }
        .desktop .menu .right .main-links a {
          font-size: 50px; } }
    @media only screen and (max-width: 1024px) {
      .menu .right {
        padding-top: 15vh; } }
    @media only screen and (max-width: 767px) {
      .menu .right {
        left: 0;
        width: 100%;
        padding-left: 20px;
        padding-top: 17vh;
        transform-origin: top right;
        order: 1;
        padding-bottom: 100px;
        max-height: 600px;
        height: auto; } }
    .menu .right .main-links a {
      font-family: "ReneExtraBold", sans-serif;
      font-size: 65px;
      color: #fff;
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 20px;
      opacity: 0;
      transform: translateX(-60px);
      position: relative;
      transition: color .5s ease;
      position: relative; }
      .menu .right .main-links a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #ffb900;
        left: 0;
        bottom: 0;
        opacity: 0;
        transform: translateY(-10px) scaleX(0);
        transition: transform .3s ease-out,opacity .3s ease-out;
        transform-origin: left center; }
      .desktop .menu .right .main-links a:hover:before, .menu .right .main-links a.active:before {
        opacity: 1;
        transform: translateY(-10px) scale(1); }
      .desktop .menu .right .main-links a:hover, .menu .right .main-links a.active {
        color: #ffb900; }
      @media only screen and (max-width: 767px) {
        .menu .right .main-links a {
          font-size: 32px;
          position: relative; }
          .menu .right .main-links a:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #ffb900;
            left: 0;
            bottom: 0;
            opacity: 0;
            transform: translateY(-4px) scaleX(0);
            transition: transform .3s ease-out,opacity .3s ease-out;
            transform-origin: left center; }
          .desktop .menu .right .main-links a:hover:before, .menu .right .main-links a.active:before {
            opacity: 1;
            transform: translateY(-4px) scale(1); } }
    .menu .right .other-links {
      position: absolute;
      bottom: 10vh;
      transform: translateY(100px);
      opacity: 0;
      padding-right: inherit;
      line-height: inherit; }
      @media only screen and (max-width: 767px) {
        .menu .right .other-links {
          bottom: 60px;
          padding-right: inherit;
          line-height: inherit; } }
      @media only screen and (max-width: 480px) {
        .menu .right .other-links {
          bottom: 30px;
          padding-right: 100px;
          line-height: 2em; } }
      .menu .right .other-links a {
        font-family: "ReneBold", sans-serif;
        font-size: 12px;
        color: #fff;
        margin-right: 50px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        position: relative; }
        .menu .right .other-links a:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #ffb900;
          left: 0;
          bottom: 0;
          opacity: 0;
          transform: translateY(1px) scaleX(0);
          transition: transform .3s ease-out,opacity .3s ease-out;
          transform-origin: left center; }
        .desktop .menu .right .other-links a:hover:before, .menu .right .other-links a.active:before {
          opacity: 1;
          transform: translateY(1px) scale(1); }
        .desktop .menu .right .other-links a:hover, .menu .right .other-links a.active {
          color: #ffb900; }
        @media only screen and (max-width: 767px) {
          .menu .right .other-links a {
            margin-right: 20px;
            font-size: 11px; } }
    .menu .right .language-socials {
      position: absolute;
      right: 8vw;
      top: 20vh;
      transform: translateX(100px);
      opacity: 0; }
      @media only screen and (max-width: 1024px) {
        .menu .right .language-socials {
          top: calc(15vh - 16px); } }
      @media only screen and (max-width: 767px) {
        .menu .right .language-socials {
          top: calc(17vh - 16px);
          right: 20px; } }
      .menu .right .language-socials a, .menu .right .language-socials span {
        display: block;
        margin: 30px 0;
        text-align: center;
        color: #fff;
        font-family: "ReneExtraBold", sans-serif;
        font-size: 12px;
        letter-spacing: 1px;
        transition: color .3s ease; }
        @media only screen and (max-width: 767px) {
          .menu .right .language-socials a, .menu .right .language-socials span {
            margin: 20px 0; } }
        .menu .right .language-socials a svg *, .menu .right .language-socials span svg * {
          transition: fill .3s ease; }
        .desktop .menu .right .language-socials a:hover, .desktop .menu .right .language-socials span:hover {
          color: #ffb900; }
          .desktop .menu .right .language-socials a:hover svg *, .desktop .menu .right .language-socials span:hover svg * {
            fill: #ffb900; }
      .menu .right .language-socials .external-link {
        position: relative; }
        .menu .right .language-socials .external-link svg {
          fill: #fff;
          opacity: 0;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 0;
          right: -25px;
          transition: opacity .3s ease; }
          @media only screen and (max-width: 767px) {
            .menu .right .language-socials .external-link svg {
              position: relative;
              opacity: 1;
              top: 1px;
              right: auto;
              margin-left: 5px; } }
        .desktop .menu .right .language-socials .external-link:hover svg {
          opacity: 1; }
      .menu .right .language-socials .v-line {
        height: 120px;
        width: 2px;
        background-color: #fff;
        opacity: .2;
        margin: auto; }
        @media only screen and (max-width: 767px) {
          .menu .right .language-socials .v-line {
            height: 96px; } }

.sticky-btn-container {
  position: fixed;
  right: 0;
  bottom: 10vh;
  z-index: 11;
  text-align: right;
  display: none;
  pointer-events: none; }
  .sticky-btn-container .btn-download, .sticky-btn-container .btn-shares {
    background-color: #ffb900;
    pointer-events: auto; }
  .sticky-btn-container .btn-download {
    width: 200px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 5px;
    transform: translate(140px);
    transition: transform .3s ease; }
    .fr .sticky-btn-container .btn-download {
      width: 250px;
      transform: translate(195px); }
    .sticky-btn-container .btn-download:hover {
      transform: translate(0px); }
    .sticky-btn-container .btn-download span {
      text-transform: uppercase;
      font-family: "ReneBold", sans-serif;
      font-size: 13px;
      color: #333333;
      transition: color .3s ease;
      pointer-events: auto; }
      .desktop .sticky-btn-container .btn-download span:hover {
        color: #fff; }
    .sticky-btn-container .btn-download .svg-wrapper {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .sticky-btn-container .btn-download svg {
      width: 24px;
      height: 24px;
      position: relative;
      top: 2px; }
  .sticky-btn-container .btn-shares {
    width: 205px;
    height: 60px;
    text-align: left;
    transform: translateX(145px);
    transition: transform .3s ease; }
    .fr .sticky-btn-container .btn-shares {
      width: 200px;
      transform: translate(195px); }
    .sticky-btn-container .btn-shares:hover {
      transform: translateX(50px); }
    .sticky-btn-container .btn-shares a, .sticky-btn-container .btn-shares span {
      padding: 20px 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      pointer-events: auto; }
    .sticky-btn-container .btn-shares span {
      padding: 20px; }
    .sticky-btn-container .btn-shares a svg * {
      transition: fill .3s ease; }
    .desktop .sticky-btn-container .btn-shares a svg:hover * {
      fill: #fff; }
    .sticky-btn-container .btn-shares svg {
      width: 20px;
      height: 20px;
      position: relative; }
      .sticky-btn-container .btn-shares svg * {
        fill: #000; }

/* ========================*/
/* ==[ Footer ]== */
/*========================*/
/* [GEN] ==== General
/* ========================*/
.main-footer {
  padding-top: 100px;
  background-color: #000;
  text-align: center;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .main-footer {
      padding-top: 40px; } }
  .main-footer .bg-svg {
    position: absolute;
    width: 100%;
    height: 500px;
    bottom: 0;
    pointer-events: none; }
    .main-footer .bg-svg svg {
      width: 100%;
      position: absolute;
      bottom: 50px;
      height: 500px;
      right: 0; }
      @media only screen and (max-width: 767px) {
        .main-footer .bg-svg svg {
          height: 200px;
          bottom: 130px; } }
      .main-footer .bg-svg svg * {
        fill: #1f1f1f; }
  .main-footer .container-main {
    padding-bottom: 100px;
    position: relative;
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .main-footer .container-main {
        padding-bottom: 0px; } }
  .main-footer .text-wrapper {
    margin-bottom: 90px; }
    @media only screen and (max-width: 767px) {
      .main-footer .text-wrapper {
        margin-bottom: 40px; } }
    .main-footer .text-wrapper h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 20px; }
      .main-footer .text-wrapper h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .main-footer .text-wrapper h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .main-footer .text-wrapper p {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      color: #fff;
      max-width: 610px;
      margin: auto; }
      @media only screen and (max-width: 767px) {
        .main-footer .text-wrapper p {
          font-size: 20px; } }
      @media only screen and (max-width: 767px) {
        .main-footer .text-wrapper p {
          font-size: 17px; } }
  .main-footer .col-wrapper {
    lost-utility: clearfix;
    position: relative; }
    .main-footer .col-wrapper:before {
      content: '';
      height: 2px;
      background-color: #eeeeee;
      opacity: .5;
      position: absolute;
      bottom: 25px;
      width: 14vw;
      transform: translateX(-50%);
      left: 50%;
      z-index: 0; }
      @media only screen and (max-width: 1024px) {
        .main-footer .col-wrapper:before {
          width: 8vw; } }
      @media only screen and (max-width: 767px) {
        .main-footer .col-wrapper:before {
          display: none; } }
    .main-footer .col-wrapper .col {
      lost-column: 1/2 2 0;
      text-align: center;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .main-footer .col-wrapper .col {
          lost-column: 1/1 1 0; }
          .main-footer .col-wrapper .col.-left {
            margin-bottom: 50px; }
            .main-footer .col-wrapper .col.-left:before {
              content: '';
              position: absolute;
              bottom: -15px;
              width: 2px;
              background-color: #333333;
              transform: translateX(-50%);
              left: 50%;
              height: 30px;
              display: block; } }
    .main-footer .col-wrapper a, .main-footer .col-wrapper p {
      color: #fff;
      font-family: "ReneExtraBold", sans-serif;
      font-size: 33px;
      text-transform: uppercase;
      display: inline-block;
      width: auto;
      padding: 0 40px; }
      @media only screen and (max-width: 767px) {
        .main-footer .col-wrapper a, .main-footer .col-wrapper p {
          font-size: 21px;
          margin-bottom: 40px; } }
    .main-footer .col-wrapper a > span {
      opacity: 1;
      transition: color .4s ease; }
      .desktop .main-footer .col-wrapper a > span:hover {
        color: #ffb900; }
    .main-footer .col-wrapper h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 20px; }
      .main-footer .col-wrapper h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .main-footer .col-wrapper h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
  .main-footer .lower-footer {
    width: 100%;
    padding: 0 9vw;
    background-color: #1f1f1f;
    z-index: 5;
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .main-footer .lower-footer {
        padding-bottom: 30px; } }
    .main-footer .lower-footer .wrapper-lower {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media only screen and (max-width: 767px) {
        .main-footer .lower-footer .wrapper-lower {
          display: block;
          height: 150px;
          align-items: initial; } }
    @media only screen and (max-width: 767px) {
      .main-footer .lower-footer {
        padding: 0 50px;
        padding-bottom: 20px; } }
    .main-footer .lower-footer .label {
      color: #9a9a9a;
      font-family: "ReneBold", sans-serif;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative; }
      .main-footer .lower-footer .label:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #fff;
        left: 0;
        bottom: 0;
        opacity: 0;
        transform: translateY(1px) scaleX(0);
        transition: transform .3s ease-out,opacity .3s ease-out;
        transform-origin: left center; }
      .desktop .main-footer .lower-footer .label:hover:before, .main-footer .lower-footer .label.active:before {
        opacity: 1;
        transform: translateY(1px) scale(1); }
      @media only screen and (max-width: 767px) {
        .main-footer .lower-footer .label {
          top: 70px;
          float: left; } }
    .main-footer .lower-footer span {
      color: #9a9a9a;
      font-size: 13px;
      font-family: "ReneBook", sans-serif; }
      @media only screen and (max-width: 767px) {
        .main-footer .lower-footer span {
          top: 68px;
          position: relative;
          float: right; } }
  .main-footer .icon-wrapper {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .main-footer .icon-wrapper {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        width: calc(100% - 100px);
        top: 25px; } }
    .main-footer .icon-wrapper a svg * {
      opacity: 1;
      transition: fill .3s ease; }
    .desktop .main-footer .icon-wrapper a:hover svg * {
      fill: #ffb900; }

/* ========================*/
/* ==[ Home ]== */
/*========================*/
/* [HER] ==== Hero
/* [SEL] ==== Select Navigation
/* [HBL] ==== Hiring Block
/* [SLI] ==== Slider Fullheight
/* ========================*/
/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.home-hero {
  height: 100vh;
  min-height: 600px;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  .home-hero.draw-line:before {
    animation: drawLine 2s infinite; }

@keyframes drawLine {
  0% {
    transform: translateX(-50%) scale(0); }
  50% {
    transform: translateX(-50%) scale(1); }
  100% {
    transform: translateX(-50%) scale(0); } }
  .home-hero:before {
    content: '';
    transform-origin: bottom center;
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 60px;
    background-color: #fff;
    opacity: 1;
    transform: translateX(-50%) scale(0);
    left: 50%;
    display: block;
    z-index: 600;
    transition: transform .6s ease; }
  .home-hero .btn {
    position: relative;
    left: auto;
    display: block; }
    @media only screen and (max-width: 1024px) {
      .home-hero .btn {
        position: absolute;
        left: -999em; } }
  .home-hero .overlay-video-btn {
    position: absolute;
    left: -999em; }
    @media only screen and (max-width: 1024px) {
      .home-hero .overlay-video-btn {
        position: relative;
        left: auto;
        display: block; } }
  .home-hero .overlay-video-btn .arrow {
    position: absolute;
    left: 25px;
    transform: translateY(-50%);
    top: calc(50% + 2px); }
  .home-hero .filter-bg {
    width: 100%;
    background-color: #333333;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .4;
    transform: translateZ(0); }
  @media only screen and (max-width: 767px) {
    .home-hero {
      min-height: 540px; } }
  .home-hero .video {
    height: 100%;
    width: 100%;
    object-fit: cover; }
    @media only screen and (max-width: 1024px) {
      .home-hero .video {
        display: none; } }
  .home-hero .home-hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center; }
  .home-hero .content-wrapper {
    position: relative;
    text-align: center;
    z-index: 10;
    transform: translateZ(0); }
    .home-hero .content-wrapper .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
      display: block;
      width: 660px;
      height: 660px;
      pointer-events: none; }
      @media only screen and (max-width: 1024px) {
        .home-hero .content-wrapper .circle {
          transform: translate3d(-50%, -50%, 0) rotate(90deg) scale(0.9); } }
      @media only screen and (max-width: 767px) {
        .home-hero .content-wrapper .circle {
          transform: translate3d(-50%, -50%, 0) rotate(90deg) scale(0.8); } }
      .home-hero .content-wrapper .circle .start-circle {
        width: 20px;
        height: 20px;
        background-color: #ffb900;
        position: absolute;
        transform: translateY(-50%);
        display: block;
        border-radius: 50%;
        top: 50%;
        left: calc(100% - 20px); }
        @media only screen and (max-width: 767px) {
          .home-hero .content-wrapper .circle .start-circle {
            display: none; } }
        .home-hero .content-wrapper .circle .start-circle:before {
          content: '';
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          background-color: #000;
          width: 50%;
          height: 50%;
          display: block;
          border-radius: 50%; }
    .home-hero .content-wrapper .btn {
      margin: auto;
      margin-top: 7vh;
      transform: translateZ(0); }
    .home-hero .content-wrapper .text-wrapper {
      margin-bottom: 7vh; }
    .home-hero .content-wrapper .subtitle {
      font-size: 13px;
      color: #fff;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
      max-width: 40%;
      margin: 0 auto;
      line-height: 2em; }
      .home-hero .content-wrapper .subtitle:first-of-type {
        margin-bottom: 1vh; }
      @media only screen and (max-width: 767px) {
        .home-hero .content-wrapper .subtitle {
          max-width: 300px;
          letter-spacing: 0px;
          font-size: 9px;
          line-height: 2em; } }
    .home-hero .content-wrapper h1 {
      position: relative;
      z-index: 2;
      font-size: 116px;
      color: #fff;
      text-transform: uppercase;
      font-family: "ReneExtraBold", sans-serif; }
      @media only screen and (max-width: 1600px) {
        .home-hero .content-wrapper h1 {
          font-size: 100px; } }
      @media only screen and (max-width: 1300px) {
        .home-hero .content-wrapper h1 {
          font-size: 80px; } }
      @media only screen and (max-width: 767px) {
        .home-hero .content-wrapper h1 {
          font-size: 40px; } }
      .home-hero .content-wrapper h1 .-yellow {
        color: #ffb900; }
      .home-hero .content-wrapper h1 .-small {
        font-size: 63px;
        vertical-align: middle;
        margin: 0 20px; }
        @media only screen and (max-width: 767px) {
          .home-hero .content-wrapper h1 .-small {
            font-size: 27px;
            display: block; } }
  .home-hero .dualvideo-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    opacity: 0;
    pointer-events: none;
    z-index: 555; }
    @media only screen and (max-width: 767px) {
      .home-hero .dualvideo-overlay {
        display: none; } }
    .home-hero .dualvideo-overlay .btn-sound.off {
      opacity: 1; }
    .home-hero .dualvideo-overlay .btn-sound.on {
      opacity: 0; }
    .home-hero .dualvideo-overlay.sound-on .btn-sound.off {
      opacity: 0; }
    .home-hero .dualvideo-overlay.sound-on .btn-sound.on {
      opacity: 1; }
    .home-hero .dualvideo-overlay .btn-sound {
      position: absolute;
      bottom: 10px;
      right: 30px;
      z-index: 20; }
      .home-hero .dualvideo-overlay .btn-sound svg {
        width: 30px;
        height: 30px; }
        .home-hero .dualvideo-overlay .btn-sound svg * {
          transition: fill .3s ease; }
      .desktop .home-hero .dualvideo-overlay .btn-sound:hover svg * {
        fill: #1c1c1c; }
    .home-hero .dualvideo-overlay .wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .home-hero .dualvideo-overlay .overlay-canvas {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #000; }
      .home-hero .dualvideo-overlay .overlay-canvas canvas {
        z-index: 2;
        position: relative; }
    .home-hero .dualvideo-overlay .video-hero {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1; }
      .home-hero .dualvideo-overlay .video-hero video {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
        transition: opacity .3s ease; }

/* -----------------------*/
/* [SEL] ==== Select Navigation
/*------------------------*/
.select-navigation {
  position: relative;
  z-index: 5; }
  .select-navigation:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffb900;
    transform-origin: top center;
    transform: scaleY(0);
    transition: transform .4s ease; }
  .desktop .select-navigation:hover:before {
    transform: scaleY(1); }
  .desktop .select-navigation:hover .container-inner p span.special {
    color: #333333;
    border-color: #333333; }
  .select-navigation .container-main {
    max-width: none; }
  .select-navigation .select-navigation__closed {
    width: 100%;
    height: 200px;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .select-navigation .select-navigation__closed {
        height: 120px; } }
    .select-navigation .select-navigation__closed .container-inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    @media only screen and (max-width: 1024px) {
      .select-navigation .select-navigation__closed .yellow-text-block {
        text-align: center;
        padding: 0 20px; } }
    @media only screen and (max-width: 767px) {
      .select-navigation .select-navigation__closed .yellow-text-block p {
        padding: 0px;
        font-size: 16px;
        line-height: 1.8em; } }
    .select-navigation .select-navigation__closed p {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .select-navigation .select-navigation__closed p {
          font-size: 20px; } }
      .select-navigation .select-navigation__closed p span.special {
        transition: color .4s ease, border-color .4s ease; }
    .desktop .select-navigation .select-navigation__closed:hover p span.special {
      color: #333333;
      border-color: #333333; }
  .select-navigation .select-navigation__opened {
    height: 100vh;
    width: 100%;
    transform: scaleY(0);
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top center;
    z-index: 3; }
    .select-navigation .select-navigation__opened:before {
      content: '';
      width: 100%;
      height: 140%;
      position: absolute;
      background-color: #ffb900;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%; }
  .select-navigation .select-navigation__content {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    z-index: 4000;
    padding: 90px 0;
    text-align: center;
    background-color: #ffb900; }
    .select-navigation .select-navigation__content a, .select-navigation .select-navigation__content h3 {
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      font-size: 33px;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .select-navigation .select-navigation__content a, .select-navigation .select-navigation__content h3 {
          font-size: 20px; } }
    .select-navigation .select-navigation__content h3 {
      font-size: 25px; }
    .select-navigation .select-navigation__content ul {
      width: 100%;
      margin-top: 4vh; }
      @media only screen and (max-width: 767px) {
        .select-navigation .select-navigation__content ul {
          margin-top: 10px; } }
      .select-navigation .select-navigation__content ul a {
        width: 100%;
        display: block;
        padding: 4vh 0;
        transition: background-color .9s ease;
        position: relative; }
        .select-navigation .select-navigation__content ul a span.label {
          padding: 0 30px;
          background-color: #ffb900;
          z-index: 2;
          position: relative;
          transition: background-color .9s ease;
          display: inline-block; }
          @media only screen and (max-width: 767px) {
            .select-navigation .select-navigation__content ul a span.label {
              padding: 0 10px;
              text-align: center; } }
        .select-navigation .select-navigation__content ul a .line {
          content: '';
          z-index: 1;
          height: 2px;
          background-color: #ffb900;
          position: absolute;
          transform: translateY(-50%) scaleX(0);
          top: 50%;
          left: 90px;
          right: 60px;
          transition: transform 1s ease;
          transition-delay: .3s;
          transform-origin: left center; }
        .select-navigation .select-navigation__content ul a .msg {
          font-family: "ReneExtraBold", sans-serif;
          font-size: 12px;
          color: #333333;
          position: absolute;
          transform: translateY(-95%);
          top: 50%;
          left: 30px;
          letter-spacing: 1px;
          opacity: 0;
          transition: transform .4s ease,opacity .4s ease;
          transition-delay: .2s; }
        .desktop .select-navigation .select-navigation__content ul a:hover {
          background-color: #fff; }
          .desktop .select-navigation .select-navigation__content ul a:hover .label {
            background-color: #fff; }
          .desktop .select-navigation .select-navigation__content ul a:hover .msg {
            transform: translateY(-50%);
            opacity: 1; }
          .desktop .select-navigation .select-navigation__content ul a:hover .line {
            transform: translateY(-50%) scaleX(1); }
    .select-navigation .select-navigation__content .h-separator {
      height: 7vh;
      width: 2px;
      background-color: #000;
      opacity: .1;
      display: inline-block;
      margin-top: 30px; }
      @media only screen and (max-width: 767px) {
        .select-navigation .select-navigation__content .h-separator {
          height: 30px;
          margin-top: 20px; } }
    .select-navigation .select-navigation__content .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 100px;
      background-color: rgba(31, 31, 31, 0.1);
      transition: background-color .3s ease; }
      @media only screen and (max-width: 767px) {
        .select-navigation .select-navigation__content .btn-close {
          height: 60px; } }
      .desktop .select-navigation .select-navigation__content .btn-close:hover {
        background-color: rgba(31, 31, 31, 0.9); }
        .desktop .select-navigation .select-navigation__content .btn-close:hover .line:nth-of-type(1) {
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: #fff; }
        .desktop .select-navigation .select-navigation__content .btn-close:hover .line:nth-of-type(2) {
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: #fff; }
      .select-navigation .select-navigation__content .btn-close .line {
        width: 20px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #333333;
        transition: transform .5s ease, background-color .3s ease; }
        .select-navigation .select-navigation__content .btn-close .line:nth-of-type(1) {
          transform-origin: center center;
          transform: translate(-50%, -50%) rotate(135deg); }
        .select-navigation .select-navigation__content .btn-close .line:nth-of-type(2) {
          transform-origin: center center;
          transform: translate(-50%, -50%) rotate(-135deg);
          transition-delay: .1s; }

/* -----------------------*/
/* [SLI] ==== Slider Fullheight
/*------------------------*/
.slider-fullheight {
  padding-top: 130px;
  padding-bottom: 150px;
  background-color: #eeeeee;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .slider-fullheight {
      padding-top: 90px; } }
  .slider-fullheight.container-main {
    max-width: none;
    padding-left: 12vw;
    padding-right: 12vw; }
    @media only screen and (max-width: 1600px) {
      .slider-fullheight.container-main {
        padding-left: 10vw;
        padding-right: 10vw; } }
    @media only screen and (max-width: 1300px) {
      .slider-fullheight.container-main {
        padding-left: 7vw;
        padding-right: 7vw; } }
    @media only screen and (max-width: 1024px) {
      .slider-fullheight.container-main {
        padding-left: 40px;
        padding-right: 40px; } }
    @media only screen and (max-width: 767px) {
      .slider-fullheight.container-main {
        padding-left: 20px;
        padding-right: 20px; } }
  .slider-fullheight h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 30px;
    left: 70px; }
    .slider-fullheight h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .slider-fullheight h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
    @media only screen and (max-width: 767px) {
      .slider-fullheight h4 {
        left: 0;
        text-align: center; } }
  .slider-fullheight .swiper-container {
    overflow: visible;
    pointer-events: auto; }
  .slider-fullheight .swiper-wrapper {
    pointer-events: none; }
  @media only screen and (max-width: 767px) {
    .slider-fullheight .big-wrapper {
      height: 100%; } }
  .slider-fullheight .swiper-pagination-full {
    cursor: default;
    width: 100%;
    display: block;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -70px;
    z-index: 2;
    text-align: center;
    pointer-events: auto; }
    .slider-fullheight .swiper-pagination-full .swiper-pagination-bullet {
      margin: 0 20px;
      opacity: 1;
      background-color: #9a9a9a; }
      .slider-fullheight .swiper-pagination-full .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #ffb900;
        position: relative; }
        .slider-fullheight .swiper-pagination-full .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
          border: 2px solid #ffb900;
          border-radius: 50%;
          content: '';
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          width: 200%;
          height: 200%; }
  .slider-fullheight .slider-wrapper {
    height: 100%;
    pointer-events: auto; }
  .slider-fullheight .swiper-slide {
    width: 85%;
    height: auto;
    position: relative;
    cursor: inherit;
    pointer-events: auto; }
    .slider-fullheight .swiper-slide.swiper-slide-active .vert-title {
      opacity: 0;
      transform: translateX(-70px); }
    .slider-fullheight .swiper-slide .vert-title {
      pointer-events: none;
      position: absolute;
      transform-origin: center center;
      transform: translateY(-50%);
      left: 0px;
      top: 50%;
      text-transform: uppercase;
      color: #fff;
      opacity: 1;
      transition: opacity .7s ease-in-out,transform .7s ease-in-out;
      font-family: "ReneBold", sans-serif;
      width: 100%;
      text-align: center;
      margin-left: -45%; }
      .slider-fullheight .swiper-slide .vert-title span {
        transform: rotate(90deg);
        position: relative;
        display: block; }
    @media only screen and (max-width: 767px) {
      .slider-fullheight .swiper-slide {
        width: 100%;
        height: 450px; } }
    .slider-fullheight .swiper-slide .video-wrapper {
      width: 90%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      overflow: hidden;
      pointer-events: none; }
      .slider-fullheight .swiper-slide .video-wrapper video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .3s ease; }
        @media only screen and (max-width: 1024px) {
          body:not(.desktop) .slider-fullheight .swiper-slide .video-wrapper video {
            display: none; } }
    @media only screen and (max-width: 767px) {
      .slider-fullheight .swiper-slide .btn {
        margin: auto;
        transform: scale(0.9); } }
    .slider-fullheight .swiper-slide .bg {
      transition: opacity .3s ease;
      width: 90%;
      height: auto;
      background-size: cover;
      background-position: center center;
      position: relative; }
      .slider-fullheight .swiper-slide .bg img {
        width: 100%; }
      @media only screen and (max-width: 1300px) {
        .slider-fullheight .swiper-slide .bg {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .slider-fullheight .swiper-slide .bg {
          height: 100%; }
          .slider-fullheight .swiper-slide .bg img {
            height: 100%;
            object-fit: cover;
            max-width: none; } }
      .slider-fullheight .swiper-slide .bg:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .slider-fullheight .text-wrapper {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 70px;
    pointer-events: none; }
    @media only screen and (max-width: 1600px) {
      .slider-fullheight .text-wrapper {
        left: 30px; } }
    @media only screen and (max-width: 767px) {
      .slider-fullheight .text-wrapper {
        left: 50%;
        transform: translateX(-50%) !important;
        top: 50px;
        width: 90%;
        z-index: 2; } }
    .slider-fullheight .text-wrapper h2 {
      font-size: 65px;
      font-family: "ReneExtraBold", sans-serif;
      text-transform: uppercase;
      color: #333333;
      line-height: 1.1em;
      margin-bottom: 20px;
      width: 70%;
      pointer-events: none; }
      @media only screen and (max-width: 1300px) {
        .slider-fullheight .text-wrapper h2 {
          font-size: 55px; } }
      @media only screen and (max-width: 767px) {
        .slider-fullheight .text-wrapper h2 {
          font-size: 40px; } }
      .slider-fullheight .text-wrapper h2.-white {
        color: #fff; }
      @media only screen and (max-width: 767px) {
        .slider-fullheight .text-wrapper h2 {
          text-align: center;
          width: 90%;
          margin: auto;
          font-size: 26px;
          margin-bottom: 20px; } }
  .slider-fullheight .img-wrapper {
    position: absolute;
    right: -30%;
    width: 70%;
    height: 130%;
    transform: translateY(-50%);
    top: 53%;
    pointer-events: none;
    z-index: 10;
    will-change: transform; }
    @media only screen and (min-width: 2000px) {
      .slider-fullheight .img-wrapper {
        right: -15vw; } }
    @media only screen and (max-width: 767px) {
      .slider-fullheight .img-wrapper {
        left: 50%;
        right: auto;
        width: 280px;
        z-index: 1; } }
    .slider-fullheight .img-wrapper img {
      width: auto;
      height: 100%;
      max-width: none; }
      @media only screen and (max-width: 767px) {
        .slider-fullheight .img-wrapper img {
          width: 280px;
          height: auto;
          margin: auto;
          top: 10px;
          position: relative; } }
  body:not(.no-perspective) .slider-fullheight .perspective-wrapper {
    perspective: 5800px;
    transform-style: preserve-3d; }
  body:not(.no-perspective) .slider-fullheight .preserve-3d {
    transform-style: preserve-3d; }
  .slider-fullheight .mid-z {
    transform: translate3d(0, -50%, 120px); }
    @media only screen and (max-width: 1024px) {
      .slider-fullheight .mid-z {
        transform: translateY(-50%); } }
    @media only screen and (max-width: 767px) {
      .slider-fullheight .mid-z {
        transform: translateX(-50%); } }
  body:not(.no-perspective) .slider-fullheight .back-z {
    transform: translateZ(-30px); }
  @media only screen and (max-width: 767px) {
    .slider-fullheight .back-z {
      transform: none; } }
  .slider-fullheight .front-z {
    transform: translate3d(5%, -50%, 130px); }
    @media only screen and (max-width: 767px) {
      .slider-fullheight .front-z {
        transform: none; } }
  .slider-fullheight .swiper-slide-shadow-left,
  .slider-fullheight .swiper-slide-shadow-right {
    display: none; }

/* -----------------------*/
/* [WOR] ==== World Block
/*------------------------*/
.world-block {
  padding: 80px 0;
  height: 800px;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .world-block {
      height: 630px; } }
  .world-block .world-para {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666;
    position: relative;
    max-width: 600px;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    color: #fff;
    z-index: 10; }
    @media only screen and (max-width: 767px) {
      .world-block .world-para {
        margin-bottom: 30px; } }
  .world-block .bg-world {
    position: absolute;
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    z-index: 3;
    bottom: 0;
    pointer-events: none; }
    @media only screen and (max-width: 767px) {
      .world-block .bg-world {
        background-size: 180% auto; } }
  .world-block .bg-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    pointer-events: none; }
  .world-block h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4; }
    .world-block h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .world-block h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
    @media only screen and (max-width: 767px) {
      .world-block h4 {
        margin-bottom: 20px; } }
  .world-block h2 {
    font-size: 65px;
    font-family: "ReneExtraBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    line-height: 1.1em;
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    z-index: 4; }
    @media only screen and (max-width: 1300px) {
      .world-block h2 {
        font-size: 55px; } }
    @media only screen and (max-width: 767px) {
      .world-block h2 {
        font-size: 40px; } }
    .world-block h2.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .world-block h2 {
        margin-bottom: 20px;
        font-size: 30px; } }
  .world-block .btn {
    margin: auto;
    position: relative;
    z-index: 4; }
  .world-block .circles {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    bottom: 0; }
    .world-block .circles img {
      position: absolute;
      width: 1000px;
      height: 1000px;
      display: block;
      transform: translate(-50%, 0%);
      transform-origin: center center;
      left: 50%;
      top: 50%; }
      @media only screen and (max-width: 767px) {
        .world-block .circles img {
          max-width: none;
          height: 600px;
          width: 600px;
          transform: translate(-50%, -50%);
          top: 120%; } }

/* -----------------------*/
/* [HBL] ==== Hiring-block
/*------------------------*/
.hiring-block {
  width: 100%;
  background-color: #eeeeee; }
  .hiring-block .career-paragraph {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666;
    width: 90%;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .hiring-block .career-paragraph {
        width: 100%; } }
  .hiring-block.container-main {
    padding-top: 130px;
    padding-bottom: 130px; }
    @media only screen and (max-width: 767px) {
      .hiring-block.container-main {
        padding-bottom: 0px;
        padding-top: 100px; } }
  .hiring-block .container-inner {
    lost-utility: clearfix; }
  .hiring-block .hiring-block__right,
  .hiring-block .hiring-block__left {
    lost-column: 1/2 2 0;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .hiring-block .hiring-block__right,
      .hiring-block .hiring-block__left {
        lost-column: 1/1 1 0;
        text-align: center; } }
  .hiring-block .hiring-block__left {
    z-index: 2;
    position: relative; }
    .hiring-block .hiring-block__left .text-wrapper {
      padding-left: 10%; }
      @media only screen and (max-width: 767px) {
        .hiring-block .hiring-block__left .text-wrapper {
          padding-left: 0; } }
    .hiring-block .hiring-block__left .image-para-bg {
      width: 100%;
      height: 280px; }
      .hiring-block .hiring-block__left .image-para-bg .image-wrapper {
        background-position: center center; }
    .hiring-block .hiring-block__left .btn {
      margin-bottom: 50px; }
      @media only screen and (max-width: 767px) {
        .hiring-block .hiring-block__left .btn {
          margin: auto;
          margin-bottom: 50px; } }
  .hiring-block .hiring-block__right {
    z-index: 1;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .hiring-block .hiring-block__right {
        margin-top: -30px; } }
    .hiring-block .hiring-block__right .image-para-bg {
      width: 100%;
      height: 470px; }
      .hiring-block .hiring-block__right .image-para-bg .image-wrapper {
        background-position: center center; }
      @media only screen and (max-width: 767px) {
        .hiring-block .hiring-block__right .image-para-bg {
          height: 230px;
          width: calc(100% + 40px);
          position: relative;
          left: -20px; } }
  .hiring-block h3 {
    font-size: 65px;
    font-family: "ReneExtraBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    line-height: 1.1em;
    margin-bottom: 20px;
    width: 90%; }
    @media only screen and (max-width: 1300px) {
      .hiring-block h3 {
        font-size: 55px; } }
    @media only screen and (max-width: 767px) {
      .hiring-block h3 {
        font-size: 40px; } }
    .hiring-block h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .hiring-block h3 {
        width: 100%; } }
  .hiring-block h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 30px; }
    .hiring-block h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .hiring-block h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }

/* ========================*/
/* ==[ Contact ]== */
/*========================*/
/* [HER] ==== Hero
/* [TAB] ==== Tabs
/* [OFF] ==== Office box
/* ========================*/
/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-contact .hero-gray {
  padding-bottom: 60px; }
  .page-contact .hero-gray .v-line {
    display: none; }
  .page-contact .hero-gray .overlay-video-btn {
    position: absolute;
    left: -999em; }
    @media only screen and (max-width: 1024px) {
      .page-contact .hero-gray .overlay-video-btn {
        position: relative;
        left: auto;
        display: block; } }
  .page-contact .hero-gray .overlay-video-btn .arrow {
    position: absolute;
    left: 25px;
    transform: translateY(-50%);
    top: calc(50% + 2px); }

.page-contact .single-employe a {
  color: #333333;
  transition: opacity .3s ease; }
  .desktop .page-contact .single-employe a:hover {
    opacity: .7; }

.page-contact .single-employe * {
  pointer-events: auto; }

@media only screen and (max-width: 767px) {
  .page-contact .single-employe {
    opacity: 1;
    transform: none; } }

.page-contact .preview-block {
  perspective: none !important; }
  .page-contact .preview-block .text-wrapper {
    left: 0; }
  .page-contact .preview-block .preview-wrapper {
    transform-style: flat; }

/* -----------------------*/
/* [TAB] ==== Tabs
/*------------------------*/
.page-contact .form-block {
  position: relative;
  background-color: transparent;
  position: relative;
  z-index: 5; }
  @media only screen and (max-width: 767px) {
    .page-contact .form-block {
      padding: 0; } }
  .page-contact .form-block .icon-plus {
    display: none; }
    @media only screen and (max-width: 767px) {
      .page-contact .form-block .icon-plus {
        display: block;
        position: absolute;
        right: 20px;
        transform: translateY(-50%);
        top: 50%; }
        .page-contact .form-block .icon-plus .wrapper {
          height: 20px;
          width: 20px;
          position: relative; }
          .page-contact .form-block .icon-plus .wrapper .line {
            transform-origin: center center;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            background-color: #333333;
            width: 100%;
            height: 2px;
            transition: opacity .3s ease; }
            .page-contact .form-block .icon-plus .wrapper .line:nth-of-type(2) {
              transform: translate(-50%, -50%) rotate(90deg); } }
  .page-contact .form-block .form-block__tabs {
    width: 100%; }
    .page-contact .form-block .form-block__tabs ul {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      @media only screen and (max-width: 767px) {
        .page-contact .form-block .form-block__tabs ul {
          flex-direction: column; } }
    .page-contact .form-block .form-block__tabs li {
      width: 100%;
      text-align: center;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        .page-contact .form-block .form-block__tabs li {
          text-align: left; } }
      .page-contact .form-block .form-block__tabs li .wrapper-header {
        padding: 25px 0;
        background-color: #ffb900;
        position: relative;
        transition: color .3s ease,background-color .3s ease; }
        @media only screen and (max-width: 767px) {
          .page-contact .form-block .form-block__tabs li .wrapper-header {
            text-align: left;
            padding-left: 20px; } }
      .page-contact .form-block .form-block__tabs li .form-block__forms {
        padding: 0 20px; }
      .page-contact .form-block .form-block__tabs li.active .wrapper-header, .desktop .page-contact .form-block .form-block__tabs li:hover .wrapper-header {
        background-color: #fff;
        color: #ffb900; }
      .page-contact .form-block .form-block__tabs li.active .icon-plus .wrapper .line:nth-of-type(2), .desktop .page-contact .form-block .form-block__tabs li:hover .icon-plus .wrapper .line:nth-of-type(2) {
        opacity: 0; }
      .page-contact .form-block .form-block__tabs li:nth-of-type(2) {
        border-left: 2px solid #fff;
        border-right: 2px solid #fff; }
        @media only screen and (max-width: 767px) {
          .page-contact .form-block .form-block__tabs li:nth-of-type(2) {
            border-left: none;
            border-right: none; } }

.form-block__container {
  background-color: #eeeeee; }
  @media only screen and (max-width: 767px) {
    .form-block__container {
      padding-bottom: 0; } }

.form-block__wrapper, .form-block__tabs {
  lost-utility: clearfix;
  background-color: #fff; }
  .form-block__wrapper .gform_ajax_spinner, .form-block__tabs .gform_ajax_spinner {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    background: none !important;
    animation: spinner .6s linear infinite;
    z-index: 100; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }
  .form-block__wrapper .gform_confirmation_message, .form-block__tabs .gform_confirmation_message {
    font-family: "ReneBook", sans-serif; }
  .form-block__wrapper .employe-wrapper, .form-block__tabs .employe-wrapper {
    lost-utility: clearfix;
    width: calc(100% + 26vw);
    padding: 100px 13vw 0;
    left: -13vw;
    position: relative;
    background-color: #eeeeee; }
    @media only screen and (max-width: 1600px) {
      .form-block__wrapper .employe-wrapper, .form-block__tabs .employe-wrapper {
        padding: 100px 9vw 0;
        width: calc(100% + 24vw);
        left: -12vw; } }
    @media only screen and (max-width: 1300px) {
      .form-block__wrapper .employe-wrapper, .form-block__tabs .employe-wrapper {
        padding: 100px 7vw 0;
        width: calc(100% + 14vw);
        left: -7vw; } }
    @media only screen and (max-width: 1024px) {
      .form-block__wrapper .employe-wrapper, .form-block__tabs .employe-wrapper {
        padding: 100px 40px 0;
        width: calc(100% + 80px);
        left: -40px; } }
    @media only screen and (max-width: 767px) {
      .form-block__wrapper .employe-wrapper, .form-block__tabs .employe-wrapper {
        padding: 100px 40px 0;
        width: calc(100% + 40px);
        left: -20px; } }
    .form-block__wrapper .employe-wrapper .single-employe, .form-block__tabs .employe-wrapper .single-employe {
      margin-bottom: 60px; }
      .form-block__wrapper .employe-wrapper .single-employe p, .form-block__tabs .employe-wrapper .single-employe p {
        color: #333333; }
      @media only screen and (max-width: 767px) {
        .form-block__wrapper .employe-wrapper .single-employe, .form-block__tabs .employe-wrapper .single-employe {
          margin-bottom: 40px; } }
  .form-block__wrapper .form-block__forms[data-id='3'] .gform_wrapper, .form-block__tabs .form-block__forms[data-id='3'] .gform_wrapper {
    margin-top: 40px; }
  .form-block__wrapper .form-block__forms .charleft, .form-block__tabs .form-block__forms .charleft {
    display: none; }
  .form-block__wrapper .form-block__forms .charleft:nth-of-type(1), .form-block__tabs .form-block__forms .charleft:nth-of-type(1) {
    display: block; }
  .form-block__wrapper .phone-wrapper, .form-block__tabs .phone-wrapper {
    lost-utility: clearfix;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0; }
    .form-block__wrapper .phone-wrapper .left, .form-block__wrapper .phone-wrapper .right, .form-block__tabs .phone-wrapper .left, .form-block__tabs .phone-wrapper .right {
      lost-column: 1/2 2 20px; }
      @media only screen and (max-width: 767px) {
        .form-block__wrapper .phone-wrapper .left, .form-block__wrapper .phone-wrapper .right, .form-block__tabs .phone-wrapper .left, .form-block__tabs .phone-wrapper .right {
          lost-column: 1/1 1 0px; } }
    @media only screen and (max-width: 767px) {
      .form-block__wrapper .phone-wrapper .left, .form-block__tabs .phone-wrapper .left {
        margin-bottom: 20px; } }
    .form-block__wrapper .phone-wrapper h4, .form-block__tabs .phone-wrapper h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      font-size: 11px;
      margin-bottom: 10px; }
      .form-block__wrapper .phone-wrapper h4.-line:before, .form-block__tabs .phone-wrapper h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .form-block__wrapper .phone-wrapper h4.-line:before, .form-block__tabs .phone-wrapper h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .form-block__wrapper .phone-wrapper span.tel, .form-block__tabs .phone-wrapper span.tel {
      font-family: "ReneBold", sans-serif;
      font-size: 35px;
      color: #333333; }
      @media only screen and (max-width: 767px) {
        .form-block__wrapper .phone-wrapper span.tel, .form-block__tabs .phone-wrapper span.tel {
          font-size: 28px; } }
  .form-block__wrapper .forms, .form-block__tabs .forms {
    lost-utility: clearfix; }
  .form-block__wrapper .form-block__forms, .form-block__tabs .form-block__forms {
    padding-top: 100px;
    position: absolute;
    left: -999em;
    width: 70vw; }
    @media only screen and (max-width: 767px) {
      .form-block__wrapper .form-block__forms, .form-block__tabs .form-block__forms {
        padding-top: 50px; } }
    .form-block__wrapper .form-block__forms > .wrapper, .form-block__tabs .form-block__forms > .wrapper {
      lost-column: 12/16 1 0;
      margin: auto !important;
      float: none !important;
      padding-bottom: 100px;
      position: relative;
      z-index: 5; }
      .form-block__wrapper .form-block__forms > .wrapper iframe, .form-block__tabs .form-block__forms > .wrapper iframe {
        width: 100%; }
      @media only screen and (max-width: 767px) {
        .form-block__wrapper .form-block__forms > .wrapper, .form-block__tabs .form-block__forms > .wrapper {
          lost-column: 1/1 1 0;
          padding-bottom: 50px; } }
    .form-block__wrapper .form-block__forms.active, .form-block__tabs .form-block__forms.active {
      position: relative;
      left: 0;
      width: auto; }

/* -----------------------*/
/* [OFF] ==== Office box
/*------------------------*/
.page-contact .office-block {
  height: 640px;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5; }
  @media only screen and (max-width: 767px) {
    .page-contact .office-block {
      height: 440px; } }
  .page-contact .office-block .wrapper {
    width: 100%; }
  .page-contact .office-block a:not(.btn) {
    color: #fff;
    transition: color .3s ease; }
    .desktop .page-contact .office-block a:not(.btn):hover {
      color: #ffb900; }
  .page-contact .office-block h3 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: #fff;
    margin-bottom: 20px; }
    .page-contact .office-block h3.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .page-contact .office-block h3.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .page-contact .office-block h4 {
    font-family: "ReneExtraBold", sans-serif;
    font-size: 33px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .page-contact .office-block .cols {
    width: 100%;
    lost-utility: clearfix;
    margin-bottom: 40px; }
    .page-contact .office-block .cols p {
      color: #fff;
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      lost-column: 1/2 2 10px;
      max-width: 225px;
      float: left !important;
      line-height: 1.6em; }
      @media only screen and (max-width: 767px) {
        .page-contact .office-block .cols p {
          lost-column: 1/1 1 0px; }
          .page-contact .office-block .cols p:first-of-type {
            margin-bottom: 20px; } }
    .page-contact .office-block .cols .-bold {
      font-family: "ReneBold", sans-serif;
      color: #fff; }

.location-block .col-wrapper {
  lost-utility: clearfix;
  margin-top: 100px; }
  .location-block .col-wrapper .preview-block {
    lost-column: 1/2 2 80px;
    margin-bottom: 120px; }
    @media only screen and (max-width: 900px) {
      .location-block .col-wrapper .preview-block {
        lost-column: 1/2 2 20px; }
        .location-block .col-wrapper .preview-block .text-wrapper {
          padding-left: 15px; }
          .location-block .col-wrapper .preview-block .text-wrapper:before {
            left: 5px; } }
    @media only screen and (max-width: 767px) {
      .location-block .col-wrapper .preview-block {
        lost-column: 1/1 1 0px;
        margin-bottom: 50px; } }
    .location-block .col-wrapper .preview-block h4 {
      margin-top: 10px;
      margin-bottom: 10px; }
    .location-block .col-wrapper .preview-block .preview-block__adresse a {
      color: #333333;
      transition: color .3s ease; }
      .desktop .location-block .col-wrapper .preview-block .preview-block__adresse a:hover {
        color: #ffb900; }
    .location-block .col-wrapper .preview-block .text-wrapper {
      pointer-events: auto;
      padding-top: 40px; }
    .location-block .col-wrapper .preview-block .img-wrapper {
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat; }
    .location-block .col-wrapper .preview-block .preview-block__adresse {
      width: 100%;
      lost-utility: clearfix;
      margin-bottom: 30px;
      margin-top: 20px; }
      .location-block .col-wrapper .preview-block .preview-block__adresse p {
        color: #333333;
        font-size: 15px;
        font-family: "ReneBook", sans-serif;
        lost-column: 1/2 2 10px;
        float: left !important;
        line-height: 1.8em; }
        @media only screen and (max-width: 767px) {
          .location-block .col-wrapper .preview-block .preview-block__adresse p {
            lost-column: 1/1 1 0px; }
            .location-block .col-wrapper .preview-block .preview-block__adresse p:first-of-type {
              margin-bottom: 20px; } }
      .location-block .col-wrapper .preview-block .preview-block__adresse .-bold {
        font-family: "ReneBold", sans-serif; }
    .location-block .col-wrapper .preview-block .see-map {
      font-family: "ReneBold", sans-serif;
      font-size: 11px;
      color: #333333;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative; }
      .location-block .col-wrapper .preview-block .see-map:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #333333;
        left: 0;
        bottom: 0;
        opacity: 0;
        transform: translateY(1px) scaleX(0);
        transition: transform .3s ease-out,opacity .3s ease-out;
        transform-origin: left center; }
      .desktop .location-block .col-wrapper .preview-block .see-map:hover:before, .location-block .col-wrapper .preview-block .see-map.active:before {
        opacity: 1;
        transform: translateY(1px) scale(1); }
      .location-block .col-wrapper .preview-block .see-map svg {
        position: relative;
        top: 1px;
        width: 20px;
        left: 5px; }

/* ========================*/
/* ==[ Support ]== */
/*========================*/
/* [HER] ==== Hero
/* ========================*/
/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-support {
  background-color: #eeeeee; }
  .page-support .hero-support {
    padding-top: 170px;
    background-color: #eeeeee;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .page-support .hero-support {
        padding-top: 110px; } }
    .page-support .hero-support h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 20px; }
      .page-support .hero-support h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-support .hero-support h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .page-support .hero-support h1 {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      font-size: 80px;
      text-transform: uppercase;
      padding-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .page-support .hero-support h1 {
          font-size: 20px; } }
      @media only screen and (max-width: 767px) {
        .page-support .hero-support h1 {
          font-size: 35px; } }
    .page-support .hero-support .v-line {
      width: 2px;
      height: 50px;
      display: block;
      margin: auto;
      background-color: #000;
      opacity: .2;
      margin-bottom: 50px; }

.page-support .collapse-block {
  padding-bottom: 100px;
  background-color: #eeeeee; }
  @media only screen and (max-width: 767px) {
    .page-support .collapse-block {
      padding-bottom: 50px; } }
  .page-support .collapse-block > .wrapper {
    background-color: #fff; }
  .page-support .collapse-block .collapse-block__header {
    padding: 100px 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    @media only screen and (max-width: 767px) {
      .page-support .collapse-block .collapse-block__header {
        padding: 50px 20px; } }
    .page-support .collapse-block .collapse-block__header h2 {
      font-family: "ReneExtraBold", sans-serif;
      text-transform: uppercase;
      font-size: 33px;
      color: #1f1f1f;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .page-support .collapse-block .collapse-block__header h2 {
          font-size: 30px; } }
    .page-support .collapse-block .collapse-block__header p {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      letter-spacing: 1px; }
      .page-support .collapse-block .collapse-block__header p.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-support .collapse-block .collapse-block__header p.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
  .page-support .collapse-block .collapse-block__content a {
    color: #fff;
    transition: color .3s ease; }
    .page-support .collapse-block .collapse-block__content a:hover {
      color: #000; }
  .page-support .collapse-block .collapse-block__single {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    transition: background-color .3s ease;
    position: relative;
    overflow: hidden; }
    .page-support .collapse-block .collapse-block__single.active {
      background-color: #ffb900; }
      .page-support .collapse-block .collapse-block__single.active .icon-plus {
        transform: rotate(90deg); }
      .page-support .collapse-block .collapse-block__single.active .icon-plus .line:nth-of-type(1) {
        opacity: 0; }
    .desktop .page-support .collapse-block .collapse-block__single:hover .icon-plus {
      transform: rotate(90deg); }
    .page-support .collapse-block .collapse-block__single .wrapper-single {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer; }
    .page-support .collapse-block .collapse-block__single header {
      padding: 60px 70px;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .page-support .collapse-block .collapse-block__single header {
          padding: 30px 20px; } }
    .page-support .collapse-block .collapse-block__single .content {
      padding: 0px 70px 60px; }
      @media only screen and (max-width: 767px) {
        .page-support .collapse-block .collapse-block__single .content {
          padding: 0px 20px 20px; } }
    .page-support .collapse-block .collapse-block__single p {
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666;
      color: #333333;
      margin-bottom: 30px; }
      .page-support .collapse-block .collapse-block__single p:last-of-type {
        margin-bottom: 0px; }
    .page-support .collapse-block .collapse-block__single h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      color: #333333;
      font-size: 13px;
      margin-bottom: 20px; }
      .page-support .collapse-block .collapse-block__single h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-support .collapse-block .collapse-block__single h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .desktop .page-support .collapse-block .collapse-block__single:hover {
      background-color: #ffb900; }
    .page-support .collapse-block .collapse-block__single h3 {
      font-family: "ReneExtraBold", sans-serif;
      font-size: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #1f1f1f; }
      @media only screen and (max-width: 767px) {
        .page-support .collapse-block .collapse-block__single h3 {
          font-size: 15px; } }
    .page-support .collapse-block .collapse-block__single .icon-plus {
      transition: transform .3s ease; }
      @media only screen and (max-width: 767px) {
        .page-support .collapse-block .collapse-block__single .icon-plus {
          position: relative;
          right: -5px; } }
    .page-support .collapse-block .collapse-block__single .icon-plus .wrapper {
      height: 20px;
      width: 20px;
      position: relative; }
      .page-support .collapse-block .collapse-block__single .icon-plus .wrapper .line {
        transform-origin: center center;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-color: #333333;
        width: 100%;
        height: 2px;
        transition: opacity .3s ease; }
        .page-support .collapse-block .collapse-block__single .icon-plus .wrapper .line:nth-of-type(2) {
          transform: translate(-50%, -50%) rotate(90deg); }

.page-projects {
  background-color: #eeeeee; }
  .page-projects .list-projects {
    lost-utility: clearfix;
    margin-top: 60px; }
    @media only screen and (max-width: 767px) {
      .page-projects .list-projects {
        z-index: 5;
        position: relative; } }
    .page-projects .list-projects .preview-block {
      lost-column: 1/2 2 80px;
      margin-bottom: 120px; }
      @media only screen and (max-width: 767px) {
        .page-projects .list-projects .preview-block {
          lost-column: 1/1 1 0px;
          margin-bottom: 35px; } }
  .page-projects .v-line-projects {
    width: 2px;
    height: 50px;
    display: block;
    margin: auto;
    background-color: #000;
    opacity: .2;
    transform-origin: top center; }
  .page-projects .hero-filtered {
    background-color: #fff;
    padding: 0 13vw;
    padding-top: 200px; }
    @media only screen and (max-width: 1600px) {
      .page-projects .hero-filtered {
        padding: 0 9vw;
        padding-top: 170px; } }
    @media only screen and (max-width: 1300px) {
      .page-projects .hero-filtered {
        padding: 0 7vw;
        padding-top: 170px; } }
    @media only screen and (max-width: 1024px) {
      .page-projects .hero-filtered {
        padding: 0 40px;
        padding-top: 170px; } }
    @media only screen and (max-width: 767px) {
      .page-projects .hero-filtered {
        padding: 0 20px;
        padding-top: 110px; } }
    .page-projects .hero-filtered .wrapper {
      width: 100%;
      max-width: 1600px;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .page-projects .hero-filtered .wrapper {
          text-align: center; } }
    .page-projects .hero-filtered h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 30px; }
      .page-projects .hero-filtered h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-projects .hero-filtered h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .page-projects .hero-filtered h1 {
      color: #333333;
      text-transform: uppercase;
      font-family: "ReneBold", sans-serif;
      font-size: 65px;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .page-projects .hero-filtered h1 {
          font-size: 40px; } }
    .page-projects .hero-filtered p {
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666;
      padding-bottom: 80px; }
      @media only screen and (max-width: 767px) {
        .page-projects .hero-filtered p {
          padding-bottom: 50px; } }
  .page-projects .btn {
    margin-bottom: 60px !important; }
  .page-projects .next-project {
    margin: auto;
    margin-bottom: 120px;
    width: 50%;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .page-projects .next-project {
        width: 90%;
        margin-bottom: 60px; } }
    .page-projects .next-project h5 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      font-size: 11px;
      margin-bottom: 30px; }
      .page-projects .next-project h5.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-projects .next-project h5.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .page-projects .next-project h4 {
      font-family: "ReneBold", sans-serif;
      color: #333333;
      font-size: 22px;
      margin-bottom: 30px; }
    .page-projects .next-project .btn {
      margin: auto; }
    .page-projects .next-project .btn-flat .label {
      width: 15px; }
      .page-projects .next-project .btn-flat .label svg {
        position: relative;
        top: 1px; }
    .page-projects .next-project .btn-flat .label svg * {
      fill: #ffb900;
      transition: .5s ease fill; }
    .desktop .page-projects .next-project .btn-flat:hover .label svg * {
      fill: #333333; }

/* ========================*/
/* ==[ Projects Single ]== */
/*========================*/
/* [GEN] ==== General
/* [HRO] ==== Hero
/* [INF] ==== Info Block
/* [IGB] ==== ImageGrid Block
/* [STA] ==== Stats Block
/* [SLI] ==== Slider Block
/* [MAP] ==== Maps Sinple Block
/* ========================*/
/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-project-single {
  background-color: #eeeeee; }

/* -----------------------*/
/* [HRO] ==== Hero
/*------------------------*/
.page-project-single .hero-full h1 {
  max-width: 500px; }
  @media only screen and (max-width: 767px) {
    .page-project-single .hero-full h1 {
      margin: auto; } }

/* -----------------------*/
/* [INF] ==== Info Block
/*------------------------*/
.page-project-single .single-info {
  background-color: #eeeeee;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .page-project-single .single-info {
      z-index: 10;
      margin-bottom: 50px; } }
  .page-project-single .single-info .bg {
    position: absolute;
    width: 120%;
    height: 120%;
    background-size: cover;
    background-position: center center;
    left: 50%;
    top: 50%;
    transform: translateX(-50%); }
  .page-project-single .single-info .right {
    height: 360px;
    overflow: hidden; }
  .page-project-single .single-info .wrapper {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .wrapper {
        display: block; } }
  .page-project-single .single-info .left, .page-project-single .single-info .right {
    width: 50%;
    display: inline-block; }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .left, .page-project-single .single-info .right {
        width: 100%; } }
  .page-project-single .single-info .right {
    align-self: flex-end;
    top: -90px;
    position: relative; }
    @media only screen and (max-width: 1300px) {
      .page-project-single .single-info .right {
        width: 40%; } }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .right {
        display: none; } }
  .page-project-single .single-info .left {
    position: relative;
    top: -90px;
    z-index: 10;
    margin-bottom: -60px; }
    @media only screen and (max-width: 1300px) {
      .page-project-single .single-info .left {
        width: 60%; } }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .left {
        width: 100%;
        top: 0;
        margin-top: -60px; } }
    .page-project-single .single-info .left .mobile {
      display: none; }
      @media only screen and (max-width: 767px) {
        .page-project-single .single-info .left .mobile {
          display: block; } }
  .page-project-single .single-info .citation-block {
    padding: 60px 10% 0; }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .citation-block {
        padding: 60px 14% 0; } }
    .page-project-single .single-info .citation-block .quote-icon {
      top: 60px; }
  .page-project-single .single-info .header-description {
    width: 100%;
    margin: auto;
    padding: 60px 10%;
    background-color: #FFF; }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .header-description {
        padding: 30px 20px; } }
    .page-project-single .single-info .header-description p {
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666; }
  .page-project-single .single-info .header-info {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    padding: 0px 10%;
    background-color: #FFF; }
    @media only screen and (max-width: 767px) {
      .page-project-single .single-info .header-info {
        padding: 0;
        height: 60px; } }
    .page-project-single .single-info .header-info ul {
      font-size: 0;
      margin: auto;
      text-align: center;
      width: 100%;
      display: flex; }
      @media only screen and (max-width: 767px) {
        .page-project-single .single-info .header-info ul {
          display: block; } }
    .page-project-single .single-info .header-info li {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      text-transform: uppercase;
      font-size: 11px;
      text-align: center;
      height: 100%;
      padding: 0 10px;
      height: 90px;
      display: inline-flex;
      align-items: center;
      width: 25%;
      justify-content: center;
      border-right: 1px solid #eeeeee; }
      .page-project-single .single-info .header-info li.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-project-single .single-info .header-info li.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      @media only screen and (max-width: 1024px) {
        .page-project-single .single-info .header-info li {
          justify-content: space-between;
          width: 37%; }
          .page-project-single .single-info .header-info li:nth-of-type(3) {
            border-right: none; }
            .page-project-single .single-info .header-info li:nth-of-type(3) span {
              text-align: right; }
          .page-project-single .single-info .header-info li:last-of-type {
            display: none; } }
      @media only screen and (max-width: 767px) {
        .page-project-single .single-info .header-info li {
          border-right: none;
          width: auto;
          padding: 10px;
          width: 37%;
          height: 60px;
          vertical-align: top; }
          .page-project-single .single-info .header-info li:first-of-type {
            padding-left: 20px !important; }
          .page-project-single .single-info .header-info li:nth-of-type(3) {
            padding-right: 20px !important; }
          .page-project-single .single-info .header-info li:last-of-type {
            display: none;
            position: absolute; } }
      .page-project-single .single-info .header-info li span {
        text-align: center;
        width: 100%; }
        @media only screen and (max-width: 1024px) {
          .page-project-single .single-info .header-info li span {
            font-size: 11px;
            letter-spacing: 0px; } }
      .page-project-single .single-info .header-info li:nth-of-type(2) {
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee; }
      .page-project-single .single-info .header-info li:last-of-type {
        border-right: 1px solid transparent;
        padding-right: 0;
        float: right; }
        @media only screen and (max-width: 767px) {
          .page-project-single .single-info .header-info li:last-of-type {
            float: none;
            width: 35%; } }
        .page-project-single .single-info .header-info li:last-of-type span {
          text-align: right; }
      .page-project-single .single-info .header-info li:first-of-type {
        border-right: 1px solid transparent;
        padding-left: 0;
        float: left; }
        @media only screen and (max-width: 767px) {
          .page-project-single .single-info .header-info li:first-of-type {
            padding: 10px;
            float: none;
            width: 25%; } }
        .page-project-single .single-info .header-info li:first-of-type span {
          text-align: left; }

/* -----------------------*/
/* [IGB] ==== ImageGrid Block
/*------------------------*/
.page-project-single .imagegrid-block {
  background-color: #eeeeee; }
  .page-project-single .imagegrid-block + .imagegrid-block {
    padding-top: 40px; }

/* -----------------------*/
/* [STA] ==== Stats Block
/*------------------------*/
.page-project-single .stats-block {
  background-color: #eeeeee;
  top: 0;
  margin-top: 0;
  margin-bottom: 20px; }
  .page-project-single .stats-block .container-inner {
    background-color: #eeeeee; }

/* -----------------------*/
/* [SLI] ==== Slider Block
/*------------------------*/
.page-project-single .slider-techno-product {
  background-color: #860c01;
  color: #fff; }

/* -----------------------*/
/* [MAP] ==== Maps Sinple Block
/*------------------------*/
.page-project-single .map-simple-block {
  height: 640px;
  background-color: #333333; }
  @media only screen and (max-width: 767px) {
    .page-project-single .map-simple-block {
      height: 450px; } }
  .page-project-single .map-simple-block h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    color: #fff;
    text-align: center;
    padding-top: 100px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .page-project-single .map-simple-block h3 {
        font-size: 26px; } }
    .page-project-single .map-simple-block h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .page-project-single .map-simple-block h3 {
        padding-top: 50px; } }
  .page-project-single .map-simple-block #gmap {
    height: 100%;
    z-index: 1;
    position: relative; }

/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-product-single {
  background-color: #eeeeee; }

/* -----------------------*/
/* [STA] ==== Stats Block
/*------------------------*/
.page-product-single .stats-block {
  background-color: #eeeeee;
  margin-top: 0;
  margin-bottom: 20px; }
  .page-product-single .stats-block .container-inner {
    background-color: #eeeeee; }

/* -----------------------*/
/* [IGB] ==== Image Grid Block
/*------------------------*/
.page-product-single .imagegrid-block {
  background-color: #eeeeee; }

/* ========================*/
/* ==[ Products ]== */
/*========================*/
/* [GEN] ==== General
/* ========================*/
/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-products {
  background-color: #eeeeee; }
  .page-products .hero-gray .hero-text {
    margin-bottom: 80px; }
    @media only screen and (max-width: 767px) {
      .page-products .hero-gray .hero-text {
        margin-bottom: 30px; } }
  .page-products .products-list {
    background-color: #eeeeee;
    lost-utility: clearfix; }
    .page-products .products-list:last-of-type .v-line {
      display: none; }
    .page-products .products-list .preview-block {
      lost-column: 1/2 2 80px;
      margin-bottom: 120px; }
      @media only screen and (max-width: 1024px) {
        .page-products .products-list .preview-block {
          margin-bottom: 60px; } }
      @media only screen and (max-width: 767px) {
        .page-products .products-list .preview-block {
          lost-column: 1/1 1 0px;
          margin-bottom: 35px; } }
    .page-products .products-list .products-list__header {
      text-align: center;
      padding-top: 100px;
      padding-bottom: 100px;
      z-index: 40;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .page-products .products-list .products-list__header {
          padding-top: 40px;
          padding-bottom: 40px; } }
      .page-products .products-list .products-list__header h3 {
        font-size: 65px;
        font-family: "ReneExtraBold", sans-serif;
        text-transform: uppercase;
        color: #333333;
        line-height: 1.1em; }
        @media only screen and (max-width: 1300px) {
          .page-products .products-list .products-list__header h3 {
            font-size: 55px; } }
        @media only screen and (max-width: 767px) {
          .page-products .products-list .products-list__header h3 {
            font-size: 40px; } }
        .page-products .products-list .products-list__header h3.-white {
          color: #fff; }
        @media only screen and (max-width: 767px) {
          .page-products .products-list .products-list__header h3 {
            font-size: 30px; } }
      .page-products .products-list .products-list__header p {
        margin-top: 20px;
        font-size: 16px;
        color: #666666;
        font-family: "ReneBook", sans-serif; }
  @media only screen and (max-width: 767px) {
    .page-products .products-list__list {
      z-index: 5;
      position: relative; } }
  .page-products .products-list__list .cols {
    lost-utility: clearfix; }
  .page-products .products-list__list .v-line {
    width: 2px;
    height: 50px;
    display: block;
    margin: auto;
    background-color: #000;
    opacity: .2; }

/* ========================*/
/* ==[ About ]== */
/*========================*/
/* [HER] ==== Hero
/* [MAP] ==== Map Block
/* [INF] ==== Information Section
/* [INB] ==== Information Block
/* [LEA] ==== Leaders Block
/* [FUL] ==== Full image slider
/* ========================*/
/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-about .hero-gray .v-line {
  height: 120px; }

.page-about .stats-block {
  pointer-events: none; }
  .page-about .stats-block .container-inner {
    padding-top: 0; }
    @media only screen and (max-width: 1300px) {
      .page-about .stats-block .container-inner .top-block {
        height: 120px; } }
    @media only screen and (max-width: 1024px) {
      .page-about .stats-block .container-inner {
        padding-top: 20px; }
        .page-about .stats-block .container-inner .top-block {
          height: 80px; } }

/* -----------------------*/
/* [MAP] ==== Map Block
/*------------------------*/
.map-block {
  height: 640px;
  background-color: #1f1f1f; }
  @media only screen and (max-width: 767px) {
    .map-block {
      height: 450px; } }
  .map-block h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    color: #fff;
    text-align: center;
    padding-top: 100px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2;
    will-change: transform; }
    @media only screen and (max-width: 767px) {
      .map-block h3 {
        font-size: 26px; } }
    .map-block h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .map-block h3 {
        padding-top: 0px;
        margin-top: 50px; } }
  .map-block #gmap {
    height: 100%;
    z-index: 1;
    position: relative; }

/* -----------------------*/
/* [INF] ==== Information Section
/*------------------------*/
.page-about .information-section {
  background-color: #eeeeee; }

/* -----------------------*/
/* [LEA] ==== Leaders Block
/*------------------------*/
.page-about .leaders-block.container-main {
  padding-top: 100px;
  padding-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .page-about .leaders-block.container-main {
      padding-top: 50px;
      padding-bottom: 10px; } }

.page-about .leaders-block .leaders-block__header {
  text-align: center;
  margin-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .page-about .leaders-block .leaders-block__header {
      margin-bottom: 50px; } }
  .page-about .leaders-block .leaders-block__header h2 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    position: relative;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .page-about .leaders-block .leaders-block__header h2 {
        font-size: 26px; } }
    .page-about .leaders-block .leaders-block__header h2.-white {
      color: #fff; }
  .page-about .leaders-block .leaders-block__header p {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666; }

.page-about .leaders-block .leaders-block__list {
  lost-utility: clearfix; }
  @media only screen and (max-width: 767px) {
    .page-about .leaders-block .leaders-block__list {
      padding: 0 20px; } }

/* -----------------------*/
/* [FUL] ==== Full image slider
/*------------------------*/
.page-about .fullimage-slider {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-bottom: 55%; }
  .page-about .fullimage-slider .fullimage-slider__header {
    width: 100%;
    text-align: center;
    z-index: 2;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%; }
    .page-about .fullimage-slider .fullimage-slider__header a {
      font-size: 62px;
      color: #fff;
      position: relative;
      transition: color .3s ease;
      text-transform: uppercase;
      margin: 0 20px;
      font-family: "ReneExtraBold", sans-serif;
      letter-spacing: 1px; }
      @media only screen and (max-width: 767px) {
        .page-about .fullimage-slider .fullimage-slider__header a {
          font-size: 15px;
          margin: 0 5px; } }
      .page-about .fullimage-slider .fullimage-slider__header a:before {
        content: '';
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: -5px;
        left: 0;
        display: block;
        background-color: #fff;
        transform: scaleX(0);
        transform-origin: center left;
        transition: background-color .3s ease,transform .3s ease; }
        @media only screen and (max-width: 767px) {
          .page-about .fullimage-slider .fullimage-slider__header a:before {
            height: 2px; } }
      .page-about .fullimage-slider .fullimage-slider__header a.active, .desktop .page-about .fullimage-slider .fullimage-slider__header a:hover {
        color: #ffb900; }
        .page-about .fullimage-slider .fullimage-slider__header a.active:before, .desktop .page-about .fullimage-slider .fullimage-slider__header a:hover:before {
          background-color: #ffb900;
          transform: scaleX(1); }
  .page-about .fullimage-slider .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity .8s ease;
    background-size: cover;
    background-position: center center; }
    .page-about .fullimage-slider .background.active {
      opacity: 1; }

/* ========================*/
/* ==[ Career ]== */
/*========================*/
/* [GEN] ==== General
/* [INF] ==== Info block
/* [MAP] ==== Map
/* [TEXT] ==== Text Image Block
/* [HIR] ==== Hiring Block
/* [ALL] ==== All Jobs Block
/* ========================*/
/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-career {
  background-color: #eeeeee; }
  .page-career .preview-block {
    perspective: none !important; }
  .page-career .location-block .title-other-offices {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: #333333;
    text-align: center;
    padding-top: 100px;
    z-index: 2;
    pointer-events: none; }
  .page-career .btn-sound-video {
    width: 60px;
    height: 60px;
    position: absolute;
    background: #ffb900;
    bottom: 70px;
    right: 0;
    cursor: pointer;
    z-index: 2; }
    .page-career .btn-sound-video .sound-on svg, .page-career .btn-sound-video .sound-off svg {
      width: 30px;
      height: 30px;
      margin-left: 15px;
      margin-top: 15px; }
      .page-career .btn-sound-video .sound-on svg path, .page-career .btn-sound-video .sound-off svg path {
        fill: #000; }
    .page-career .btn-sound-video .sound-off {
      display: none; }
  .page-career .btn {
    position: relative;
    left: auto;
    display: block; }
    @media only screen and (max-width: 1024px) {
      .page-career .btn {
        position: absolute; } }
  .page-career .btn-scroll {
    position: absolute; }
    @media only screen and (max-width: 1024px) {
      .page-career .btn-scroll {
        position: relative;
        left: auto;
        display: block; } }
  .page-career .btn-scroll .arrow-down {
    position: absolute;
    left: 15px;
    transform: translateY(-50%) rotate(90deg);
    top: calc(50% + 2px); }
  .page-career .filter-bg {
    width: 100%;
    background-color: #333333;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .4;
    transform: translateZ(0); }
  @media only screen and (max-width: 767px) {
    .page-career {
      min-height: 540px; } }
  .page-career .video {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .page-career .home-hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center; }
  .page-career .header-img-mobile {
    height: 100%;
    width: 100%;
    object-fit: cover; }

/* -----------------------*/
/* [INF] ==== Info block
/*------------------------*/
.page-career .info-block {
  padding-top: 80px;
  padding-bottom: 150px;
  lost-utility: clearfix;
  background-color: #fff; }
  .page-career .info-block h3 {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: #333333;
    text-align: center;
    padding-bottom: 90px;
    z-index: 2;
    pointer-events: none; }
  @media only screen and (max-width: 767px) {
    .page-career .info-block {
      padding-top: 40px;
      padding-bottom: 40px; } }
  .page-career .info-block .intro {
    lost-column: 6/16 2 20px; }
    @media only screen and (max-width: 767px) {
      .page-career .info-block .intro {
        lost-column: 1/1 1 0px;
        text-align: center;
        margin-bottom: 40px; } }
    .page-career .info-block .intro p {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      line-height: 1.6em;
      letter-spacing: .5px; }
      @media only screen and (max-width: 767px) {
        .page-career .info-block .intro p {
          font-size: 20px; } }
      @media only screen and (max-width: 767px) {
        .page-career .info-block .intro p {
          font-size: 19px; } }
  .page-career .info-block .info-list {
    lost-column: 1/1 2 20px;
    lost-utility: clearfix; }
    @media only screen and (max-width: 767px) {
      .page-career .info-block .info-list {
        lost-column: 1/1 1 0px; } }
    .page-career .info-block .info-list .single-info {
      lost-column: 1/2 2 10%;
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      align-items: flex-start; }
      .page-career .info-block .info-list .single-info h4 {
        margin-top: 0; }
        @media only screen and (max-width: 1024px) {
          .page-career .info-block .info-list .single-info h4 {
            margin-top: 20px; } }
      .page-career .info-block .info-list .single-info .infos-description {
        margin-left: 25px; }
        @media only screen and (max-width: 1024px) {
          .page-career .info-block .info-list .single-info .infos-description {
            margin-left: 0px; } }
        @media only screen and (max-width: 767px) {
          .page-career .info-block .info-list .single-info .infos-description {
            margin-left: auto;
            margin-right: auto; } }
      @media only screen and (max-width: 1024px) {
        .page-career .info-block .info-list .single-info {
          flex-direction: column; } }
      @media only screen and (max-width: 767px) {
        .page-career .info-block .info-list .single-info {
          lost-column: 1/1 1 0px;
          text-align: center; }
          .page-career .info-block .info-list .single-info img {
            display: block;
            margin-left: auto;
            margin-right: auto; } }

/* -----------------------*/
/* [MAP] ==== Map
/*------------------------*/
.page-career .map-icons {
  height: 640px;
  background-color: #1f1f1f;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .page-career .map-icons {
      height: 450px; } }
  .page-career .map-icons h3 {
    font-size: 33px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    color: #fff;
    text-align: center;
    padding-top: 100px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2;
    pointer-events: none; }
    @media only screen and (max-width: 767px) {
      .page-career .map-icons h3 {
        font-size: 26px; } }
    .page-career .map-icons h3.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .page-career .map-icons h3 {
        padding-top: 50px; } }
  .page-career .map-icons #gmap {
    height: 100%;
    z-index: 1;
    position: relative; }
  .page-career .map-icons .pins {
    display: none; }

/* -----------------------*/
/* [TEXT] ==== Text Image Block
/*------------------------*/
.page-career .textimage-block {
  padding: 150px 40px;
  background-color: #fff;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .page-career .textimage-block {
      padding: 20px 0px; } }
  .page-career .textimage-block .container-main {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .page-career .textimage-block .container-main {
        flex-direction: column; } }
  .page-career .textimage-block .left {
    lost-column: 6/16 2 0px;
    left: 4vw;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .page-career .textimage-block .left {
        lost-column: 1/1 1 0px;
        order: 2;
        left: 0; } }
    .page-career .textimage-block .left h3 {
      font-family: "ReneExtraBold", sans-serif;
      letter-spacing: 1px;
      font-size: 35px;
      text-transform: uppercase;
      display: inline-block;
      color: #333333;
      top: 3px;
      position: relative;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .page-career .textimage-block .left h3 {
          font-size: 21px; } }
    .page-career .textimage-block .left p {
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666;
      margin-bottom: 30px; }
    .page-career .textimage-block .left ul li {
      line-height: 3em;
      font-family: "ReneBold", sans-serif;
      font-size: 11px;
      color: #9a9a9a;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      margin-left: 15px;
      margin-bottom: 10px;
      line-height: 1.5em; }
      .page-career .textimage-block .left ul li:before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        left: -15px;
        top: 5px;
        background-color: #ffb900; }
  .page-career .textimage-block .right {
    lost-column: 1/2 2 20px;
    height: 330px;
    overflow: hidden;
    order: 1; }
    @media only screen and (max-width: 767px) {
      .page-career .textimage-block .right {
        lost-column: 1/1 1 0px;
        height: 200px;
        margin-bottom: 30px; } }
    .page-career .textimage-block .right img {
      width: 130%;
      max-width: none;
      top: -30px;
      position: relative; }

/* -----------------------*/
/* [HIR] ==== Hiring Block
/*------------------------*/
.page-career .career-hiring-block {
  padding-top: 150px;
  position: relative;
  z-index: 2; }
  @media only screen and (max-width: 767px) {
    .page-career .career-hiring-block {
      padding-top: 50px;
      padding-left: 0;
      padding-right: 0; } }
  .page-career .career-hiring-block .container-inner {
    text-align: center; }
  .page-career .career-hiring-block h1 {
    font-size: 65px;
    font-family: "ReneExtraBold", sans-serif;
    text-transform: uppercase;
    color: #333333;
    line-height: 1.1em;
    font-size: 80px;
    padding-bottom: 80px; }
    @media only screen and (max-width: 1300px) {
      .page-career .career-hiring-block h1 {
        font-size: 55px; } }
    @media only screen and (max-width: 767px) {
      .page-career .career-hiring-block h1 {
        font-size: 40px; } }
    .page-career .career-hiring-block h1.-white {
      color: #fff; }
    @media only screen and (max-width: 767px) {
      .page-career .career-hiring-block h1 {
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px; } }
  .page-career .career-hiring-block .career-hiring-block__list {
    background-color: #eeeeee;
    lost-utility: clearfix;
    padding-bottom: 200px;
    padding-top: 100px; }
    @media only screen and (max-width: 767px) {
      .page-career .career-hiring-block .career-hiring-block__list {
        padding-top: 50px;
        padding-bottom: 50px; } }
    .page-career .career-hiring-block .career-hiring-block__list .right {
      lost-column: 9/16 1 0;
      lost-offset: 7/16; }
      @media only screen and (max-width: 1024px) {
        .page-career .career-hiring-block .career-hiring-block__list .right {
          lost-column: 8/16 1 0; } }
      @media only screen and (max-width: 767px) {
        .page-career .career-hiring-block .career-hiring-block__list .right {
          lost-column: 1/1 1 0px;
          lost-offset: 1/1; } }
    .page-career .career-hiring-block .career-hiring-block__list header {
      lost-utility: clearfix; }
      @media only screen and (max-width: 767px) {
        .page-career .career-hiring-block .career-hiring-block__list header {
          display: flex; } }
    .page-career .career-hiring-block .career-hiring-block__list .tab {
      lost-column: 1/2 2 0;
      background-color: #f2f2f2;
      text-align: center;
      padding: 20px 0;
      transition: background-color .3s ease;
      cursor: pointer; }
      @media only screen and (max-width: 1024px) {
        .page-career .career-hiring-block .career-hiring-block__list .tab {
          padding: 10px; } }
      @media only screen and (max-width: 767px) {
        .page-career .career-hiring-block .career-hiring-block__list .tab {
          padding: 10px;
          text-align: left; } }
      .page-career .career-hiring-block .career-hiring-block__list .tab .icon-plus {
        display: none; }
      .desktop .page-career .career-hiring-block .career-hiring-block__list .tab:hover {
        background-color: #333333; }
        .desktop .page-career .career-hiring-block .career-hiring-block__list .tab:hover span {
          color: #fff; }
      .page-career .career-hiring-block .career-hiring-block__list .tab span {
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        color: #999999;
        font-size: 13px;
        transition: color .3s ease; }
        .page-career .career-hiring-block .career-hiring-block__list .tab span.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .page-career .career-hiring-block .career-hiring-block__list .tab span.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
        @media only screen and (max-width: 767px) {
          .page-career .career-hiring-block .career-hiring-block__list .tab span {
            font-size: 11px;
            letter-spacing: 1px; } }
      .page-career .career-hiring-block .career-hiring-block__list .tab.active {
        background-color: #ffb900; }
        .page-career .career-hiring-block .career-hiring-block__list .tab.active span {
          color: #333333; }
    .page-career .career-hiring-block .career-hiring-block__list .tab-content {
      background-color: #fff;
      display: none; }
      @media only screen and (max-width: 767px) {
        .page-career .career-hiring-block .career-hiring-block__list .tab-content .icon-plus {
          display: block;
          position: absolute;
          left: 10px; }
          .page-career .career-hiring-block .career-hiring-block__list .tab-content .icon-plus .wrapper {
            height: 20px;
            width: 20px;
            position: relative; }
            .page-career .career-hiring-block .career-hiring-block__list .tab-content .icon-plus .wrapper .line {
              transform-origin: center center;
              position: absolute;
              transform: translate(-50%, -50%);
              left: 50%;
              top: 50%;
              background-color: #333333;
              width: 100%;
              height: 2px;
              transition: opacity .3s ease; }
              .page-career .career-hiring-block .career-hiring-block__list .tab-content .icon-plus .wrapper .line:nth-of-type(2) {
                transform: translate(-50%, -50%) rotate(90deg); } }
      .page-career .career-hiring-block .career-hiring-block__list .tab-content.active {
        display: block; }
      .page-career .career-hiring-block .career-hiring-block__list .tab-content ul {
        padding: 40px 80px 40px 0; }
        @media only screen and (max-width: 1024px) {
          .page-career .career-hiring-block .career-hiring-block__list .tab-content ul {
            padding: 20px 40px 20px 0; } }
        @media only screen and (max-width: 767px) {
          .page-career .career-hiring-block .career-hiring-block__list .tab-content ul {
            padding: 0; } }
      .page-career .career-hiring-block .career-hiring-block__list .tab-content li {
        text-align: left;
        width: 100%;
        padding: 30px 20%;
        position: relative;
        cursor: pointer; }
        @media only screen and (max-width: 767px) {
          .page-career .career-hiring-block .career-hiring-block__list .tab-content li {
            padding: 20px 40px; } }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 4px + 80px);
          height: calc(100% - 4px);
          border: 2px solid #ffb900;
          opacity: 0;
          transition: opacity .3s ease;
          transform: translateX(-80px);
          z-index: 4; }
          @media only screen and (max-width: 767px) {
            .page-career .career-hiring-block .career-hiring-block__list .tab-content li:before {
              display: none; } }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li:after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          background-color: #eeeeee;
          width: calc(100% - 20%);
          left: 20%; }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li h5, .page-career .career-hiring-block .career-hiring-block__list .tab-content li span {
          transition: color .3s ease; }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active {
          background-color: transparent; }
          .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active:before {
            opacity: 1; }
          .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active h5, .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active span {
            color: #ffb900; }
          @media only screen and (max-width: 767px) {
            .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active {
              background-color: #1f1f1f; }
              .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active h5, .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active span {
                color: #fff; }
              .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active .icon-plus .line {
                background-color: #fff; }
              .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active .icon-plus {
                transform: rotate(90deg); }
              .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active .icon-plus .line:nth-of-type(1) {
                opacity: 0; } }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li h5 {
          font-family: "ReneBold", sans-serif;
          color: #333333;
          font-size: 18px;
          margin-bottom: 10px;
          text-transform: uppercase; }
          @media only screen and (max-width: 767px) {
            .page-career .career-hiring-block .career-hiring-block__list .tab-content li h5 {
              font-size: 15px;
              margin-bottom: 0px; } }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li span {
          font-family: "ReneBold", sans-serif;
          color: #9a9a9a;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          position: relative;
          color: #9a9a9a; }
          .page-career .career-hiring-block .career-hiring-block__list .tab-content li span.-line:before {
            content: '';
            position: absolute;
            transform: translateY(-50%);
            top: calc(50% - 1px);
            background-color: #9a9a9a;
            height: 2px;
            width: 55vw;
            transform-origin: right center;
            left: -56vw; }
            @media only screen and (max-width: 767px) {
              .page-career .career-hiring-block .career-hiring-block__list .tab-content li span.-line:before {
                height: 50px;
                width: 1px;
                transform: translateX(-50%);
                left: 50%;
                top: -70px; } }
        .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active .details-box {
          pointer-events: auto;
          opacity: 1;
          transform: translate(0px, -50%); }
          @media only screen and (max-width: 767px) {
            .page-career .career-hiring-block .career-hiring-block__list .tab-content li.active .details-box {
              transform: none;
              display: block; } }
    .page-career .career-hiring-block .career-hiring-block__list .details-box {
      position: absolute;
      left: calc(-40% - 60px);
      lost-offset: -4/16;
      width: 70%;
      top: 50%;
      transition: transform .3s ease,opacity .3s ease;
      opacity: 0;
      transform: translate(0, -40%);
      font-size: 0;
      pointer-events: none; }
      @media only screen and (max-width: 1024px) {
        .page-career .career-hiring-block .career-hiring-block__list .details-box {
          width: 100%;
          lost-offset: -6/16;
          left: calc(-50% - 60px); } }
      @media only screen and (max-width: 767px) {
        .page-career .career-hiring-block .career-hiring-block__list .details-box {
          position: relative;
          left: -20px;
          transform: none;
          top: 0;
          lost-offset: none;
          width: calc(100%  + 40px);
          margin-top: 20px;
          margin-bottom: 40px;
          display: none; } }
      .page-career .career-hiring-block .career-hiring-block__list .details-box .wrapper {
        background-color: #1f1f1f;
        padding: 40px 55px; }
        @media only screen and (max-width: 1024px) {
          .page-career .career-hiring-block .career-hiring-block__list .details-box .wrapper {
            padding: 20px 25px; } }
        @media only screen and (max-width: 767px) {
          .page-career .career-hiring-block .career-hiring-block__list .details-box .wrapper {
            padding: 20px 0px; } }
      .page-career .career-hiring-block .career-hiring-block__list .details-box img {
        width: 100%;
        display: block;
        pointer-events: none; }
      .page-career .career-hiring-block .career-hiring-block__list .details-box p {
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666;
        color: #fff;
        pointer-events: none; }
      .page-career .career-hiring-block .career-hiring-block__list .details-box a {
        margin-top: 20px;
        display: inline-block;
        font-family: "ReneBold", sans-serif;
        color: #9a9a9a;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        color: #fff;
        transition: color .3s ease; }
        .page-career .career-hiring-block .career-hiring-block__list .details-box a.-line:before {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: calc(50% - 1px);
          background-color: #9a9a9a;
          height: 2px;
          width: 55vw;
          transform-origin: right center;
          left: -56vw; }
          @media only screen and (max-width: 767px) {
            .page-career .career-hiring-block .career-hiring-block__list .details-box a.-line:before {
              height: 50px;
              width: 1px;
              transform: translateX(-50%);
              left: 50%;
              top: -70px; } }
        .desktop .page-career .career-hiring-block .career-hiring-block__list .details-box a:hover {
          color: #ffb900; }
          .desktop .page-career .career-hiring-block .career-hiring-block__list .details-box a:hover svg * {
            fill: #ffb900; }
        .page-career .career-hiring-block .career-hiring-block__list .details-box a svg * {
          fill: #fff;
          transition: fill .3s ease; }

/* -----------------------*/
/* [ALL] ==== All Jobs Block
/*------------------------*/
.page-career .all-jobs-block {
  background-color: #fff;
  padding: 100px 0 0;
  text-align: center;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .page-career .all-jobs-block {
      padding: 50px 0; } }
  .page-career .all-jobs-block h4 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 30px; }
    .page-career .all-jobs-block h4.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .page-career .all-jobs-block h4.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .page-career .all-jobs-block p {
    font-size: 25px;
    font-family: "ReneExtraBold", sans-serif;
    color: #333333;
    line-height: 1.6em;
    letter-spacing: .5px;
    max-width: 600px;
    margin: auto; }
    @media only screen and (max-width: 767px) {
      .page-career .all-jobs-block p {
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .page-career .all-jobs-block p {
        font-size: 20px; } }
  .page-career .all-jobs-block .all-jobs-block__list {
    width: 100%; }
    .page-career .all-jobs-block .all-jobs-block__list ul {
      width: 100%;
      margin-top: 100px; }
      @media only screen and (max-width: 767px) {
        .page-career .all-jobs-block .all-jobs-block__list ul {
          margin-top: 50px; } }
      .page-career .all-jobs-block .all-jobs-block__list ul li {
        border-top: 1px solid #9a9a9a;
        width: 100%;
        lost-utility: clearfix;
        transition: background-color .3s ease;
        cursor: pointer;
        overflow: hidden; }
        .page-career .all-jobs-block .all-jobs-block__list ul li:last-of-type {
          border-bottom: 1px solid #9a9a9a; }
        .page-career .all-jobs-block .all-jobs-block__list ul li.active .content {
          height: auto;
          transform: scaleY(1); }
        .page-career .all-jobs-block .all-jobs-block__list ul li.active .icon-plus .line:nth-of-type(1) {
          opacity: 0; }
        .desktop .page-career .all-jobs-block .all-jobs-block__list ul li:hover, .page-career .all-jobs-block .all-jobs-block__list ul li.active {
          background-color: #ffb900;
          border-color: transparent; }
          .desktop .page-career .all-jobs-block .all-jobs-block__list ul li:hover .icon-plus, .page-career .all-jobs-block .all-jobs-block__list ul li.active .icon-plus {
            transform: rotate(90deg); }
          .desktop .page-career .all-jobs-block .all-jobs-block__list ul li:hover .circle, .page-career .all-jobs-block .all-jobs-block__list ul li.active .circle {
            background-color: #333333; }
            .desktop .page-career .all-jobs-block .all-jobs-block__list ul li:hover .circle span, .page-career .all-jobs-block .all-jobs-block__list ul li.active .circle span {
              color: #ffb900; }
    .page-career .all-jobs-block .all-jobs-block__list header {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center; }
      .page-career .all-jobs-block .all-jobs-block__list header.container-main {
        padding-top: 50px;
        padding-bottom: 50px; }
        @media only screen and (max-width: 767px) {
          .page-career .all-jobs-block .all-jobs-block__list header.container-main {
            padding-top: 30px;
            padding-bottom: 30px; } }
      .page-career .all-jobs-block .all-jobs-block__list header h5 {
        font-size: 33px;
        font-family: "ReneBold", sans-serif;
        text-transform: uppercase;
        color: #333333;
        font-size: 20px;
        width: 70%; }
        @media only screen and (max-width: 767px) {
          .page-career .all-jobs-block .all-jobs-block__list header h5 {
            font-size: 26px; } }
        .page-career .all-jobs-block .all-jobs-block__list header h5.-white {
          color: #fff; }
        @media only screen and (max-width: 767px) {
          .page-career .all-jobs-block .all-jobs-block__list header h5 {
            font-size: 16px; } }
      .page-career .all-jobs-block .all-jobs-block__list header .icon-plus {
        transition: transform .3s ease; }
      .page-career .all-jobs-block .all-jobs-block__list header .circle {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
        transition: background-color .3s ease; }
        .page-career .all-jobs-block .all-jobs-block__list header .circle span {
          font-family: "ReneExtraBold", sans-serif;
          font-size: 13px;
          line-height: 1em;
          color: #9a9a9a;
          transition: color .3s ease;
          position: relative;
          top: 1px; }
    .page-career .all-jobs-block .all-jobs-block__list .content {
      width: 100%;
      overflow: hidden;
      transition: transform .3s ease; }
      .page-career .all-jobs-block .all-jobs-block__list .content .wrapper {
        padding-bottom: 40px;
        lost-utility: clearfix; }
      .page-career .all-jobs-block .all-jobs-block__list .content span {
        text-align: center;
        lost-column: 1/3 3 20px;
        font-family: "ReneExtraBold", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
        color: #333333;
        margin: 10px 0; }
        @media only screen and (max-width: 767px) {
          .page-career .all-jobs-block .all-jobs-block__list .content span {
            lost-column: 1/1 1 0px; } }
  .page-career .all-jobs-block .icon-plus .wrapper {
    height: 20px;
    width: 20px;
    position: relative; }
    .page-career .all-jobs-block .icon-plus .wrapper .line {
      transform-origin: center center;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #333333;
      width: 100%;
      height: 2px;
      transition: opacity .3s ease; }
      .page-career .all-jobs-block .icon-plus .wrapper .line:nth-of-type(2) {
        transform: translate(-50%, -50%) rotate(90deg); }

/* ========================*/
/* ==[ Home ]== */
/*========================*/
/* [HER] ==== Hero
/* [SIN] ==== Single News Preview
/* [NEW] ==== News List
/* ========================*/
/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-news-archives {
  background-color: #eeeeee; }
  .page-news-archives .v-line-news {
    width: 2px;
    height: 50px;
    display: block;
    margin: auto;
    background-color: #000;
    opacity: .2; }

/* -----------------------*/
/* [SIN] ==== Single News Preview
/*------------------------*/
.page-news-archives .single-news-preview {
  display: block;
  width: 100%;
  position: relative;
  margin: 50px 0;
  padding-bottom: 50px;
  font-size: 0; }
  body:not(.no-perspective) .page-news-archives .single-news-preview {
    perspective: 1000px; }
  .desktop .page-news-archives .single-news-preview:hover .h-line {
    transform: translateX(50px); }
  .page-news-archives .single-news-preview .single-news-preview-wrapper {
    lost-utility: clearfix;
    transform-style: preserve-3d; }
  .page-news-archives .single-news-preview:not(:last-of-type):before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: #333333;
    opacity: .2; }
  .page-news-archives .single-news-preview .left {
    height: 340px;
    width: calc(50% - 10px);
    display: inline-block;
    background-size: cover;
    background-position: center center;
    float: left;
    pointer-events: none;
    transform: translateZ(0px); }
    @media only screen and (max-width: 767px) {
      .page-news-archives .single-news-preview .left {
        width: 100%;
        height: 250px; } }
  .page-news-archives .single-news-preview .right {
    height: 340px;
    width: calc(50% - 10px);
    display: inline-block;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none; }
    body:not(.no-perspective) .page-news-archives .single-news-preview .right {
      transform: translateZ(60px); }
    @media only screen and (max-width: 767px) {
      .page-news-archives .single-news-preview .right {
        width: 100%;
        transform: none;
        height: auto;
        padding-left: 20px;
        position: relative; } }
  .page-news-archives .single-news-preview .h-line {
    position: relative;
    left: -50px;
    width: 120px;
    height: 2px;
    background-color: #000;
    pointer-events: none;
    transition: transform .4s ease; }
    @media only screen and (max-width: 767px) {
      .page-news-archives .single-news-preview .h-line {
        width: 2px;
        height: 100%;
        position: absolute;
        lefT: 8px;
        bottom: 5px; } }
  .page-news-archives .single-news-preview .sub-title {
    margin: 18px 0 10px;
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative; }
    .page-news-archives .single-news-preview .sub-title.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .page-news-archives .single-news-preview .sub-title.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .page-news-archives .single-news-preview .title {
    font-family: "ReneBold", sans-serif;
    font-size: 20px;
    color: #333333; }

/* -----------------------*/
/* [NEW] ==== News List
/*------------------------*/
.news-list {
  z-index: 5;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .news-list {
      padding: 0 20px; } }

/* -----------------------*/
/* [PAG] ==== Pagination
/*------------------------*/
.page-news-archives .pagination {
  padding: 45px 0;
  text-align: center;
  position: relative;
  margin-bottom: 100px; }
  .page-news-archives .pagination .screen-reader-text {
    display: none; }
  .page-news-archives .pagination:before, .page-news-archives .pagination:after {
    content: '';
    height: 2px;
    background-color: #333333;
    opacity: .2;
    width: 100%;
    left: 0;
    position: absolute; }
  .page-news-archives .pagination:before {
    top: 0; }
  .page-news-archives .pagination:after {
    bottom: 0; }
  .page-news-archives .pagination .page-numbers {
    width: 28px;
    height: 28px;
    display: inline-flex;
    margin: 0 5px;
    border-radius: 50%;
    transition: background-color .4s ease;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 13px;
    color: #9a9a9a;
    font-family: "ReneBold", sans-serif;
    transition: color .4s ease,background-color .4s ease;
    line-height: 1em; }
    .page-news-archives .pagination .page-numbers.next, .page-news-archives .pagination .page-numbers.prev {
      display: none; }
    .desktop .page-news-archives .pagination .page-numbers:hover, .page-news-archives .pagination .page-numbers.current {
      background-color: #ffb900;
      color: #fff; }

/* ========================*/
/* ==[ News Single ]== */
/*========================*/
/* [GEN] ==== General
/* ========================*/
/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-news-single {
  background-color: #eeeeee; }
  .page-news-single .hero-full {
    flex-direction: column;
    align-items: center;
    height: auto; }
    .page-news-single .hero-full.no-bg h1, .page-news-single .hero-full.no-bg h4 {
      color: #333333; }
      .page-news-single .hero-full.no-bg h1:before, .page-news-single .hero-full.no-bg h4:before {
        background-color: #333333; }
    .page-news-single .hero-full .wrapper {
      padding: 260px 0; }
      @media only screen and (max-width: 767px) {
        .page-news-single .hero-full .wrapper {
          padding: 150px 0; } }
    .page-news-single .hero-full h1 {
      text-transform: none;
      max-width: 1000px;
      width: 100%;
      font-size: 45px; }
      @media only screen and (max-width: 767px) {
        .page-news-single .hero-full h1 {
          font-size: 32px; } }
  .page-news-single .single-info-general {
    margin-bottom: 0px;
    position: relative;
    z-index: 10; }
    .page-news-single .single-info-general .yellow-text-block p {
      font-size: 18px; }
    .page-news-single .single-info-general .wrapper .left .content {
      padding-bottom: 40px; }
  .page-news-single .btn-link {
    background-color: #ffb900;
    border: none;
    padding: 20px 30px;
    font-family: "ReneBold", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    transition: background-color .3s ease, color .3s ease;
    line-height: 1em;
    color: #333333;
    margin: auto;
    display: inline-block; }
    .desktop .page-news-single .btn-link:hover {
      background-color: #333333;
      color: #ffb900; }
  .page-news-single .btn-block + .next-post {
    margin-top: 60px; }
  .page-news-single .btn-block {
    margin: 0px 0 0px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .page-news-single .btn-block {
        margin-bottom: 25px; } }
    .page-news-single .btn-block a {
      margin: auto; }
  .page-news-single .pardotform {
    width: 70%;
    max-width: 600px;
    margin: auto;
    display: block; }
  .page-news-single iframe:not(.pardotform) {
    width: 100%;
    height: 480px; }
    @media only screen and (max-width: 1024px) {
      .page-news-single iframe:not(.pardotform) {
        height: 400px; } }
    @media only screen and (max-width: 767px) {
      .page-news-single iframe:not(.pardotform) {
        height: 300px; } }

.page-news-single .single-content {
  background-color: #eeeeee; }
  .page-news-single .single-content .form-pardot {
    width: 50%;
    min-width: 600px; }
    @media only screen and (max-width: 1024px) {
      .page-news-single .single-content .form-pardot {
        width: 100%; } }
  .page-news-single .single-content .wrapper-wysiwyg {
    padding-top: 50px;
    max-width: 1200px;
    margin: auto; }
    @media only screen and (max-width: 767px) {
      .page-news-single .single-content .wrapper-wysiwyg {
        padding-top: 20px; } }
    .page-news-single .single-content .wrapper-wysiwyg blockquote {
      position: relative; }
      .page-news-single .single-content .wrapper-wysiwyg blockquote p:before {
        content: '“';
        position: absolute;
        border: 3px solid #333333;
        left: 0;
        top: 0;
        width: 40px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        padding-top: 15px; }
      .page-news-single .single-content .wrapper-wysiwyg blockquote p {
        position: relative;
        font-size: 25px;
        font-family: "ReneExtraBold", sans-serif;
        color: #333333;
        font-size: 21px;
        line-height: 2em;
        margin-bottom: 35px;
        padding-left: 60px; }
        @media only screen and (max-width: 767px) {
          .page-news-single .single-content .wrapper-wysiwyg blockquote p {
            font-size: 20px; } }
        @media only screen and (max-width: 767px) {
          .page-news-single .single-content .wrapper-wysiwyg blockquote p {
            font-size: 15px;
            margin-bottom: 20px; } }
    .page-news-single .single-content .wrapper-wysiwyg img {
      width: 100%;
      margin: 50px auto 0px;
      height: auto; }
      @media only screen and (max-width: 767px) {
        .page-news-single .single-content .wrapper-wysiwyg img {
          margin: 50px auto 0px; } }
    .page-news-single .single-content .wrapper-wysiwyg h2, .page-news-single .single-content .wrapper-wysiwyg h3, .page-news-single .single-content .wrapper-wysiwyg h4, .page-news-single .single-content .wrapper-wysiwyg h5, .page-news-single .single-content .wrapper-wysiwyg h6, .page-news-single .single-content .wrapper-wysiwyg p, .page-news-single .single-content .wrapper-wysiwyg ul {
      width: 70%;
      margin: auto;
      min-width: 600px; }
      @media only screen and (max-width: 767px) {
        .page-news-single .single-content .wrapper-wysiwyg h2, .page-news-single .single-content .wrapper-wysiwyg h3, .page-news-single .single-content .wrapper-wysiwyg h4, .page-news-single .single-content .wrapper-wysiwyg h5, .page-news-single .single-content .wrapper-wysiwyg h6, .page-news-single .single-content .wrapper-wysiwyg p, .page-news-single .single-content .wrapper-wysiwyg ul {
          width: 100%;
          min-width: 0; } }
    .page-news-single .single-content .wrapper-wysiwyg p {
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666;
      margin-bottom: 40px; }
      .page-news-single .single-content .wrapper-wysiwyg p + img {
        margin-top: 40px; }
    .page-news-single .single-content .wrapper-wysiwyg a {
      color: #ffb900;
      text-decoration: underline;
      transition: color .3s ease; }
      .page-news-single .single-content .wrapper-wysiwyg a:hover {
        color: #333333;
        text-decoration: none; }
    .page-news-single .single-content .wrapper-wysiwyg ul {
      margin: 20px auto; }
      .page-news-single .single-content .wrapper-wysiwyg ul li {
        left: 20px;
        position: relative;
        font-size: 16px;
        font-family: "ReneBook", sans-serif;
        line-height: 2em;
        color: #666666; }
        .page-news-single .single-content .wrapper-wysiwyg ul li:before {
          content: '';
          position: absolute;
          top: .8em;
          border-radius: 50%;
          width: 5px;
          height: 5px;
          background-color: #ffb900;
          left: -15px; }
    .page-news-single .single-content .wrapper-wysiwyg h2, .page-news-single .single-content .wrapper-wysiwyg h3, .page-news-single .single-content .wrapper-wysiwyg h4, .page-news-single .single-content .wrapper-wysiwyg h5, .page-news-single .single-content .wrapper-wysiwyg h6 {
      font-size: 28px;
      font-family: "ReneBold", sans-serif;
      color: #333333;
      margin-bottom: 20px; }
    .page-news-single .single-content .wrapper-wysiwyg h3 {
      font-size: 24px; }
    .page-news-single .single-content .wrapper-wysiwyg h4 {
      font-size: 20px; }
    .page-news-single .single-content .wrapper-wysiwyg h5 {
      font-size: 18px; }
    .page-news-single .single-content .wrapper-wysiwyg h5 {
      font-size: 16px; }
    .page-news-single .single-content .wrapper-wysiwyg h6 {
      font-size: 14px; }
    .page-news-single .single-content .wrapper-wysiwyg hr {
      margin: 0;
      margin-top: 100px;
      margin-bottom: 100px; }
      @media only screen and (max-width: 767px) {
        .page-news-single .single-content .wrapper-wysiwyg hr {
          margin-top: 50px;
          margin-bottom: 50px; } }
    .page-news-single .single-content .wrapper-wysiwyg .citation {
      font-size: 20px;
      padding: 30px 0 0px;
      color: #333333; }
      @media only screen and (max-width: 767px) {
        .page-news-single .single-content .wrapper-wysiwyg .citation {
          text-align: center;
          line-height: 1.5em; } }
  .page-news-single .single-content .next-project {
    margin: auto;
    margin-bottom: 120px;
    width: 50%;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .page-news-single .single-content .next-project {
        width: 90%;
        margin-bottom: 60px; } }
    .page-news-single .single-content .next-project h5 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      font-size: 11px;
      margin-bottom: 30px; }
      .page-news-single .single-content .next-project h5.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-news-single .single-content .next-project h5.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .page-news-single .single-content .next-project h4 {
      font-family: "ReneBold", sans-serif;
      color: #333333;
      font-size: 22px;
      margin-bottom: 30px; }
    .page-news-single .single-content .next-project .btn {
      margin: auto; }
  .page-news-single .single-content .btn-flat .label {
    width: 15px; }
    .page-news-single .single-content .btn-flat .label svg {
      position: relative;
      top: 1px; }
  .page-news-single .single-content .btn-flat .label svg * {
    fill: #ffb900;
    transition: .5s ease fill; }
  .desktop .page-news-single .single-content .btn-flat:hover .label svg * {
    fill: #333333; }

/* ========================*/
/* ==[ Personna ]== */
/*========================*/
/* [GEN] ==== General
/* [STA] ==== Stats block
/* [IGB] ==== Image grid block
/* [TLB] ==== Title list block
/* ========================*/
/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
@media only screen and (max-width: 767px) {
  .page-segment .hero-full .wrapper h1 {
    font-size: 30px; } }

.page-segment .segment-info {
  background-color: #eeeeee;
  position: relative;
  z-index: 5; }
  .page-segment .segment-info .wrapper {
    lost-utility: clearfix; }
    .page-segment .segment-info .wrapper h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      padding-bottom: 50px; }
      .page-segment .segment-info .wrapper h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-segment .segment-info .wrapper h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      @media only screen and (max-width: 767px) {
        .page-segment .segment-info .wrapper h4 {
          padding-bottom: 30px; } }
  .page-segment .segment-info .left, .page-segment .segment-info .right {
    lost-column: 1/2 2 0; }
    @media only screen and (max-width: 767px) {
      .page-segment .segment-info .left, .page-segment .segment-info .right {
        lost-column: 1/1 1 0; } }
  .page-segment .segment-info .left {
    position: relative;
    margin-top: -100px; }
    @media only screen and (max-width: 767px) {
      .page-segment .segment-info .left {
        margin-top: -60px; } }
    .page-segment .segment-info .left .content {
      padding: 40px 10% 80px 10%;
      background-color: #fff; }
      @media only screen and (max-width: 767px) {
        .page-segment .segment-info .left .content {
          padding: 25px 20px 30px 20px; } }
    .page-segment .segment-info .left .header-description {
      font-size: 25px;
      font-family: "ReneExtraBold", sans-serif;
      color: #333333;
      line-height: 1.5em;
      font-size: 28px; }
      @media only screen and (max-width: 767px) {
        .page-segment .segment-info .left .header-description {
          font-size: 20px; } }
  .page-segment .segment-info .right {
    background-color: #1f1f1f; }
    .page-segment .segment-info .right .yellow-text-block {
      padding: 60px 10%; }
      @media only screen and (max-width: 767px) {
        .page-segment .segment-info .right .yellow-text-block {
          padding: 30px 20px; } }
      .page-segment .segment-info .right .yellow-text-block p {
        font-size: 16px;
        font-family: "ReneBold", sans-serif;
        line-height: 2em;
        color: #c0c0c0; }

/* -----------------------*/
/* [STA] ==== Stats block
/*------------------------*/
.page-segment .stats-block {
  top: 0;
  padding-top: 60px;
  background-color: #eeeeee; }
  @media only screen and (max-width: 767px) {
    .page-segment .stats-block {
      padding-top: 30px; } }
  .page-segment .stats-block .container-inner {
    background-color: #eeeeee; }

/* -----------------------*/
/* [IGB] ==== Image grid block
/*------------------------*/
.page-segment .imagegrid-block {
  background-color: #eeeeee;
  padding-top: 70px;
  padding-bottom: 70px; }
  @media only screen and (max-width: 767px) {
    .page-segment .imagegrid-block {
      padding-top: 30px;
      padding-bottom: 30px; } }
  .page-segment .imagegrid-block .slider-center-imagegrid {
    margin-top: 60px; }

/* -----------------------*/
/* [TLB] ==== Title list block
/*------------------------*/
.page-segment .title-list-block {
  background-color: #eeeeee; }
  .page-segment .title-list-block.container-main {
    padding-top: 80px;
    padding-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .page-segment .title-list-block.container-main {
        padding-top: 50px;
        padding-bottom: 50px; } }
  .page-segment .title-list-block .header {
    text-align: center;
    padding-bottom: 50px; }
    .page-segment .title-list-block .header h3 {
      font-size: 33px;
      font-family: "ReneBold", sans-serif;
      text-transform: uppercase;
      color: #333333;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .page-segment .title-list-block .header h3 {
          font-size: 26px; } }
      .page-segment .title-list-block .header h3.-white {
        color: #fff; }
    .page-segment .title-list-block .header p {
      max-width: 700px;
      margin: auto;
      margin-bottom: 40px;
      font-size: 16px;
      font-family: "ReneBook", sans-serif;
      line-height: 2em;
      color: #666666; }
      @media only screen and (max-width: 767px) {
        .page-segment .title-list-block .header p {
          margin-bottom: 0px; } }
  .page-segment .title-list-block .list {
    lost-utility: clearfix;
    margin-bottom: 20px; }
    .page-segment .title-list-block .list .preview-block {
      lost-column: 1/2 2 80px;
      margin-bottom: 80px; }
      @media only screen and (max-width: 767px) {
        .page-segment .title-list-block .list .preview-block {
          lost-column: 1/1 1 0px;
          margin-bottom: 40px; } }
  .page-segment .title-list-block .wrapper {
    text-align: center; }
  .page-segment .title-list-block .view-more {
    text-align: center;
    font-family: "ReneBold", sans-serif;
    font-size: 11px;
    color: #333333;
    margin: auto;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    position: relative; }
    .page-segment .title-list-block .view-more:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #333333;
      left: 0;
      bottom: 0;
      opacity: 0;
      transform: translateY(1px) scaleX(0);
      transition: transform .3s ease-out,opacity .3s ease-out;
      transform-origin: left center; }
    .desktop .page-segment .title-list-block .view-more:hover:before, .page-segment .title-list-block .view-more.active:before {
      opacity: 1;
      transform: translateY(1px) scale(1); }

/* ========================*/
/* ==[ Style guide ]== */
/*========================*/
.page-style-guide {
  background-color: #eeeeee; }
  .page-style-guide .container-main {
    margin: auto; }
  .page-style-guide .hero-style {
    padding-top: 200px;
    position: relative;
    z-index: 1;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .hero-style {
        padding-top: 120px; } }
    .page-style-guide .hero-style h4 {
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      font-size: 13px;
      margin-bottom: 40px; }
      .page-style-guide .hero-style h4.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-style-guide .hero-style h4.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      .page-style-guide .hero-style h4 img {
        max-width: 600px;
        max-height: 150px; }
        @media only screen and (max-width: 767px) {
          .page-style-guide .hero-style h4 img {
            max-width: 250px; } }
      .page-style-guide .hero-style h4.-line:before {
        opacity: .3; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .hero-style .btn .label {
        font-size: 10px; } }
  .page-style-guide .bg-svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 800px;
    z-index: 1;
    pointer-events: none;
    transform: translateX(-50%);
    left: 50%;
    min-width: 1700px; }
    .page-style-guide .bg-svg svg {
      width: 100%;
      height: 800px; }
      .page-style-guide .bg-svg svg * {
        fill: #fff; }
  .page-style-guide h1 {
    font-family: "ReneExtraBold", sans-serif;
    font-size: 80px;
    color: #333333;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-bottom: 30px;
    max-width: 800px; }
    @media only screen and (max-width: 1024px) {
      .page-style-guide h1 {
        font-size: 60px; } }
    @media only screen and (max-width: 767px) {
      .page-style-guide h1 {
        font-size: 30px; } }
  .page-style-guide .wrapper {
    width: 100%;
    max-width: 1600px;
    position: relative;
    z-index: 20;
    padding-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .wrapper {
        padding-bottom: 50px; } }
  .page-style-guide .img {
    position: relative;
    width: 100%; }
    .page-style-guide .img .description {
      bottom: 20px;
      left: 20px;
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      position: absolute; }
      .page-style-guide .img .description.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .page-style-guide .img .description.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
    .page-style-guide .img img {
      width: 100%; }

.page-style-guide .navigation {
  position: relative;
  z-index: 2;
  margin: auto;
  max-width: 1600px; }
  .page-style-guide .navigation .wrapper-nav {
    lost-utility: clearfix;
    margin-bottom: 80px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .navigation .wrapper-nav {
        margin-bottom: 0px; } }
  .page-style-guide .navigation .col {
    lost-column: 1/3 3 20px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .navigation .col {
        lost-column: 1/1 1 0px;
        margin-bottom: 30px; } }
  .page-style-guide .navigation h4 {
    font-size: 20px;
    font-family: "ReneExtraBold", sans-serif;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 25px; }
  .page-style-guide .navigation li a {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    display: block;
    margin-bottom: 15px;
    transition: color .3s ease; }
    .page-style-guide .navigation li a.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .page-style-guide .navigation li a.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
    .desktop .page-style-guide .navigation li a:hover {
      color: #000; }

.section-block .container-main {
  padding-top: 90px; }
  @media only screen and (max-width: 767px) {
    .section-block .container-main {
      padding-top: 50px; } }
  .section-block .container-main:before {
    content: '';
    height: 2px;
    width: calc(100% - 26vw);
    background-color: #c9c9c9;
    position: absolute;
    top: 0; }
    @media only screen and (max-width: 1600px) {
      .section-block .container-main:before {
        width: calc(100% - 24vw); } }
    @media only screen and (max-width: 1300px) {
      .section-block .container-main:before {
        width: calc(100% - 14vw); } }
    @media only screen and (max-width: 1024px) {
      .section-block .container-main:before {
        width: calc(100% - 40px); } }

.section-block h2 {
  font-family: "ReneExtraBold", sans-serif;
  font-size: 80px;
  color: #333333;
  text-transform: uppercase;
  line-height: 1.1em;
  margin-bottom: 60px;
  width: calc(100% + 160px);
  position: relative;
  left: -80px; }
  @media only screen and (max-width: 1024px) {
    .section-block h2 {
      font-size: 60px; } }
  @media only screen and (max-width: 767px) {
    .section-block h2 {
      font-size: 30px;
      margin-bottom: 40px;
      left: 0;
      width: 100%; } }

.section-block .wysiwyg {
  width: calc(100% - 160px);
  margin: auto; }
  .section-block .wysiwyg p:last-of-type {
    margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .section-block .wysiwyg {
      width: 100%; } }
  .section-block .wysiwyg strong {
    color: #000;
    font-family: "ReneBold", sans-serif; }
  .section-block .wysiwyg img {
    width: 100%;
    height: auto; }
  .section-block .wysiwyg h6 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: #333333;
    letter-spacing: 1px;
    line-height: 1.7em;
    margin: 25px 0 20px;
    font-size: 13px; }
    .section-block .wysiwyg h6.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .section-block .wysiwyg h6.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .section-block .wysiwyg .wp-caption {
    width: calc(100% + 160px) !important;
    height: auto !important;
    left: -80px;
    position: relative;
    margin-top: 40px; }
    @media only screen and (max-width: 767px) {
      .section-block .wysiwyg .wp-caption {
        width: 100% !important;
        left: 0; } }
    .section-block .wysiwyg .wp-caption img {
      width: 100%;
      height: auto !important; }
    .section-block .wysiwyg .wp-caption .wp-caption-text {
      bottom: 15px;
      left: 20px;
      font-family: "ReneBold", sans-serif;
      color: #9a9a9a;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      position: absolute; }
      .section-block .wysiwyg .wp-caption .wp-caption-text.-line:before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 1px);
        background-color: #9a9a9a;
        height: 2px;
        width: 55vw;
        transform-origin: right center;
        left: -56vw; }
        @media only screen and (max-width: 767px) {
          .section-block .wysiwyg .wp-caption .wp-caption-text.-line:before {
            height: 50px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -70px; } }
      @media only screen and (max-width: 767px) {
        .section-block .wysiwyg .wp-caption .wp-caption-text {
          font-size: 10px;
          left: 10px;
          bottom: 10px; } }
  .section-block .wysiwyg h3 {
    position: relative;
    margin-bottom: 30px;
    margin-top: 80px;
    font-family: "ReneBold", sans-serif;
    font-size: 28px;
    color: #333333; }
    @media only screen and (max-width: 767px) {
      .section-block .wysiwyg h3 {
        margin-top: 40px; } }
  .section-block .wysiwyg p {
    font-size: 16px;
    font-family: "ReneBook", sans-serif;
    line-height: 2em;
    color: #666666;
    margin-bottom: 30px; }
  .section-block .wysiwyg blockquote {
    position: relative;
    margin-bottom: 30px; }
    .section-block .wysiwyg blockquote p {
      font-size: 20px;
      font-family: "ReneBook", sans-serif;
      line-height: 1.7em;
      color: #333333; }
    .section-block .wysiwyg blockquote:before {
      content: '';
      position: absolute;
      background-color: #ffb900;
      left: -80px;
      width: 10px;
      height: 100%; }

.page-style-guide .two-col-block {
  margin-top: 100px;
  margin-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .page-style-guide .two-col-block {
      margin-top: 50px;
      margin-bottom: 50px; } }
  .page-style-guide .two-col-block img {
    width: 100%; }
  .page-style-guide .two-col-block .wysiwyg {
    width: 100%; }
  .page-style-guide .two-col-block .wrapper-two-col {
    lost-utility: clearfix; }
  .page-style-guide .two-col-block .col {
    lost-column: 1/2 2 50px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .two-col-block .col {
        lost-column: 1/1 1 0px; }
        .page-style-guide .two-col-block .col.right {
          margin-top: 40px; } }
  .page-style-guide .two-col-block h3 {
    margin-top: 0; }

.page-style-guide .three-col-block {
  lost-utility: clearfix;
  margin-bottom: 100px;
  margin-top: 50px; }
  @media only screen and (max-width: 767px) {
    .page-style-guide .three-col-block {
      margin-bottom: 50px; } }
  .page-style-guide .three-col-block + .three-col-block {
    margin-top: -80px; }
  .page-style-guide .three-col-block .col {
    lost-column: 1/3 3 20px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .three-col-block .col {
        lost-column: 1/1 1 0px;
        margin-bottom: 20px; } }
    .page-style-guide .three-col-block .col .img {
      width: 100%; }
      .page-style-guide .three-col-block .col .img img {
        width: 100%; }

.page-style-guide .five-col-block {
  lost-utility: clearfix;
  margin-top: 50px; }
  @media only screen and (max-width: 767px) {
    .page-style-guide .five-col-block {
      margin-bottom: 20px; } }
  .page-style-guide .five-col-block + .five-col-block {
    margin-top: -80px; }
  .page-style-guide .five-col-block .col {
    lost-column: 1/5 5 20px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .five-col-block .col {
        lost-column: 1/2 2 20px;
        margin-bottom: 20px; } }
    .page-style-guide .five-col-block .col .img {
      width: 100%; }
      .page-style-guide .five-col-block .col .img img {
        width: 100%; }

.page-style-guide .color-block {
  margin-top: 40px;
  lost-utility: clearfix; }
  @media only screen and (max-width: 767px) {
    .page-style-guide .color-block {
      margin-bottom: 50px; } }
  .page-style-guide .color-block .col {
    lost-column: 1/3 3 20px; }
    @media only screen and (max-width: 767px) {
      .page-style-guide .color-block .col {
        lost-column: 1/1 1 0px;
        margin-bottom: 40px; } }
  .page-style-guide .color-block .color-bg {
    height: 140px;
    width: 100%;
    position: relative; }
    .page-style-guide .color-block .color-bg img {
      width: 100%;
      height: 100%; }
  .page-style-guide .color-block h6 {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: #333333;
    letter-spacing: 2px;
    margin: 25px 0 20px;
    font-size: 13px; }
    .page-style-guide .color-block h6.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .page-style-guide .color-block h6.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }
  .page-style-guide .color-block p {
    font-family: "ReneBold", sans-serif;
    color: #9a9a9a;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    text-transform: uppercase;
    color: #9a9a9a;
    margin-bottom: 15px; }
    .page-style-guide .color-block p.-line:before {
      content: '';
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 1px);
      background-color: #9a9a9a;
      height: 2px;
      width: 55vw;
      transform-origin: right center;
      left: -56vw; }
      @media only screen and (max-width: 767px) {
        .page-style-guide .color-block p.-line:before {
          height: 50px;
          width: 1px;
          transform: translateX(-50%);
          left: 50%;
          top: -70px; } }

.page-404 .wrapper {
  height: 800px;
  background-color: #eeeeee;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .page-404 .wrapper {
      height: 540px; } }

.page-404 .container-main {
  position: relative; }

.page-404 h1 {
  color: #ffb900;
  font-size: 425px;
  font-family: "ReneExtraBold", sans-serif;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 1;
  letter-spacing: 10px; }
  @media only screen and (max-width: 767px) {
    .page-404 h1 {
      font-size: 40vw;
      top: 80px;
      transform: translate(0, 0);
      position: relative;
      top: auto;
      left: auto;
      padding-top: 80px; } }

.page-404 img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 60%;
  z-index: 5; }
  @media only screen and (max-width: 767px) {
    .page-404 img {
      width: 40%;
      position: absolute;
      transform: translate(0, -50%);
      right: -5vw;
      left: auto;
      top: 70%; } }

.page-404 .footer {
  position: absolute;
  bottom: 100px;
  width: calc(100% - 26vw);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
  padding-bottom: 30px;
  z-index: 4; }
  @media only screen and (max-width: 1600px) {
    .page-404 .footer {
      width: calc(100% - 18vw); } }
  @media only screen and (max-width: 1300px) {
    .page-404 .footer {
      width: calc(100% - 14vw); } }
  @media only screen and (max-width: 1024px) {
    .page-404 .footer {
      width: calc(100% - 80px); } }
  @media only screen and (max-width: 767px) {
    .page-404 .footer {
      width: 70%;
      display: block;
      position: relative;
      bottom: auto; } }
  .page-404 .footer p {
    text-transform: uppercase;
    font-family: "ReneExtraBold", sans-serif;
    font-size: 20px;
    max-width: 250px;
    color: #333333;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .page-404 .footer p {
        max-width: none;
        width: 100%;
        text-align: center;
        margin-bottom: 30px; } }
  @media only screen and (max-width: 767px) {
    .page-404 .footer .btn {
      margin: auto;
      margin-top: 30px; } }
  .page-404 .footer .arrow svg {
    fill: #fff; }
