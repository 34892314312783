/* ========================*/
/* ==[ Related Block ]== */
/*========================*/

.related-block{
	background-color:#fff;
	overflow:hidden;
	position: relative;
	
	&:before{
		content:'';
		position:absolute;
		top:400px;
		left:0;
		background-color:$c-gray-bg;
		width:100%;
		height:600px;
	}
	
	&.container-main{
		padding-top:100px;
		padding-bottom:100px;

		@media #{$mobile}{
			padding-top:50px;
			padding-bottom:50px;
		}
	}

	.title{
		@include sectionTitle;		
		text-align: center;
		margin-bottom:60px;
	}

	.wrapper{
		lost-utility:clearfix;
		margin-bottom: 100px;

		@media #{$mobile}{
			margin-bottom: 50px;
		}
	}

	.wrapper .preview-block{
		lost-column:1/2 2 80px;

		@media #{$mobile}{
			lost-column:1/1 1 0px;

			&:first-of-type{
				margin-bottom: 50px;
			}
		}
	}

	.view-more{
		text-align: center;
		@include MainBold;
		font-size:11px;
		color:$c-gray-text;
		margin:auto;
		display: block;
		text-transform: uppercase;
		letter-spacing: 1px;
		position: relative;
		svg{
			margin-left:5px;
		}
		svg *{
			fill:$c-gray-text;
		}

		span{
			@include underlineHover(10px,1px,1px,$c-gray-text);	
		}
	}
}