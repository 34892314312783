// DEBUG
.page-debug.barba-container{
	background-color : lighten(black,25);
	width:100%;
	min-height:100vh;
	padding:60px 0;
	opacity:0;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	
	h1{
		font-size:40px;
		margin-bottom:20px;
	}

	.button-test{
		margin-top:40px;
		padding:50px;
		border:2px solid gray;
		width:100%;
		text-align:center;

		.btn:first-of-type{
			margin-bottom:20px;
		}
	}
	
	.yellow-text-block{
		width:555px;
		max-width:100%;
		text-align:center;
		margin:40px 0 ;
		font-size:25px;
		line-height:1.5em;
	}

	.listing{
		width:1200px;
		padding:100px;
		margin:auto;
		background-color:white;

		.col-1{
			lost-column:1/2 2 40px;
			margin-bottom:100px;
		}	
	}
}



