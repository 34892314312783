/* ========================*/
/* ==[ Slider specs ]== */
/*========================*/

.slider-specs{
	height:670px;
	width: 100%;
	background-color:$c-main;
	position:relative;

	@media #{$mobile}{
		height:auto;
		padding-bottom:50px;
	}
	
	h3{
		@include sectionTitle;
		padding-top:70px;
		text-align:center;
		margin-bottom:30px;

		@media #{$mobile}{
			padding-top:50px;
			margin-bottom:40px;
		}
	}

	.swiper-container{
		height:400px;
		width:100%;

		@media #{$mobile}{
			height:auto;
			width:90%;
		}

		.swiper-slide{
			position:relative;
			width:90%;
			text-align:center;
			padding:0 3%;
			max-width:1600px;

			@media #{$mobile}{
				width:100%;
			}
		}

		img{
			// max-width:500px;
			// min-width:300px;
			height:100%;
			margin:auto;

			@media #{$mobile}{
				width:100%;
				min-width: 0;
				max-width: none;
			}
		}

		.left,.right{
			lost-column:1/2 2 0px;
			height:100%;

			@media #{$mobile}{
				lost-column:1/1 1 0px;
				height:auto;
			}
		}

		.left{
			display:flex;
			align-items:center;
		    justify-content: flex-end;
		    display:block;

		    @media #{$mobile}{
		    	margin-bottom:40px;
		    }
		}

		.right{
			text-align:left;
			display:flex;
			align-items:center;
			max-width: 500px;
			float:left !important;
			padding-left:80px;

			@media #{$mobile}{
				padding-left:0px;
				max-width: none;
			}

			.wrapper{
				position: relative;
				
				ul{
					margin-bottom:20px;
				}

				li{
					margin-bottom:5px;
					@include mainText;
					color:$c-gray-text;

					strong{
						@include MainBold;	
					}
				}
			}

			h4{
				@include MainBold;
				color:$c-gray-text;
				text-transform: uppercase;
				font-size:20px;
				margin-bottom:20px;
			}

			p{
				@include mainText;
				@media #{$tablet}{
					line-height:1.5em;
				}
				margin-bottom:20px;
				color:$c-gray-text;
			}

			a{
				@include subTitle;
				color:$c-gray-text;
				transition:color .3s ease;

				svg{
					margin-left:10px;
				}

				svg *{
					fill:$c-gray-text;
					transition:fill .3s ease;
				}

				.desktop &:hover{
					color:#fff;

					svg *{
						fill:#fff;
					}
				}
			}
		}
	}

	.swiper-pagination{
		position:relative;
		margin-top:40px;
		display: inline-block;
		left:calc(50% - 26px);
		transition:none;

		.swiper-pagination-bullet{
			padding:25px;
			display: inline-flex;
			align-items:center;
			justify-content: center;
			margin-right:30px;
			position:relative;
			@include subTitle;
			color:$c-gray-text;
			border:1px solid $c-gray-text;
			opacity:.7;
			background-color:$c-main;

			span{
				position:absolute;
				transform:translate(-50%,-50%);
				top:50%;
				left:50%;
			}

			&:not(:last-of-type):before{
				content:'';
				width:32px;
				background-color:$c-gray-text;
				height:2px;
				position:absolute;
				transform:translateY(-50%);
				top:50%;
				right:-32px;
				opacity:.7;
				z-index: 20;
				will-change:transform;
			}

			&.swiper-pagination-bullet-active{
				background-color:$c-gray-text;
				color:$c-main;
				opacity:1;
				
				&:before{
					opacity:.5;
				}
			}
		}
	}
}