/* ========================*/
/* ==[ Newsletter block ]== */
/*========================*/

.newsletter-block{
	padding:100px 0;
	background-color:#fff;
	display: flex;
	align-items:center;
	justify-content:center;
	position: relative;

	

	@media #{$mobile}{
		padding:30px 0 70px;
	}
	
	&.success{
		.success-msg{
			transform:translate(-50%,-50%);	
			opacity:1;
		}

		.wrapper{
			opacity: 0 !important;
		}
	}

	&.email-error{
		.email-error-msg{
			transform:translate(-50%,calc(-50% + 60px));	
			opacity:1;
			@media #{$tablet}{
				transform:translate(-50%,calc(-50% + 80px));	
			}
			@media #{$mobile}{
				transform:translate(-50%,calc(-50% + 60px));	
			}
		}		
	}

	&.server-error{
		.server-error-msg{
			transform:translate(-50%,calc(-50% + 60px));	
			opacity:1;
		}		
	}

	&.loading{
		.loading-msg{
			transform:translate(-50%,calc(-50% + 60px));	
			opacity:1;
		}
	}

	.loading-msg,.server-error-msg,.email-error-msg{
		position:absolute;
		transform:translate(-50%,calc(-50% + 120px));
		transition:opacity .3s ease,transform .3s ease;
		top:50%;
		left:50%;
		color:#c63630;
		opacity:0;
		@include MainBook;

		@media #{$mobile}{
			width: 100%;
			text-align:center;	
		}
	}

	.success-msg{
		position:absolute;
		transform:translate(-50%,calc(-50% + 50px));
		top:50%;
		left:50%;
		opacity: 0;
		transition:opacity .3s ease,transform .3s ease;
		width: 100%;
		text-align: center;
	}

	h3{
		@include MainExtraBold;	
		letter-spacing: 1px;
		font-size: 25px;
		text-transform: uppercase;
		display: inline-block;
		color:$c-gray-text;
		top:3px;
		position:relative;

		@media #{$mobile}{
			font-size:14px;
		}
	}

	input[type="text"]{
		background-color: $c-gray-bg;
		border:none;
		font-size: 13px;
		color:$c-gray-light-text;
		padding:20px 20px;
		@include MainBold;
		letter-spacing: 1px;
		width:370px;
		margin-left:30px;
		padding-right:50px;

		@media #{$mobile}{
			width:280px;
			margin: auto;
			padding:15px 20px;
		}
	}

	.placeholder{
		color:$c-gray-light-text;
		@include MainBold;
		letter-spacing: 1px;
		font-size: 11px;
		position:absolute;
		left:50px;
		transform:translateY(-50%);
		top:50%;
		text-transform: uppercase;
		pointer-events: none;
		
		@media #{$mobile}{
			left:20px;
		}
	}

	.submit{
		position:absolute;
		right:20px;
		transform:translateY(calc(-50% - 1px));
		top:50%;
		background-color:transparent;
	}

	.input-wrapper{
		position:relative;
		display: inline-block;

		@media #{$tablet}{
			margin-top:20px;
		}
	}

	.wrapper{
		transition:opacity .3s ease;
		position: relative;

		@media #{$tablet}{
			display: flex;
		    flex-direction: column;
		    align-items: center;	
		}
	}
}