/* ========================*/
/* ==[ Contact ]== */
/*========================*/
/* [HER] ==== Hero
/* [TAB] ==== Tabs
/* [OFF] ==== Office box
/* ========================*/

/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-contact{
	.hero-gray{
		padding-bottom: 60px;
		
		.v-line{
			display: none;
		}

		.overlay-video-btn{
			position: absolute;
			left:-999em;
	
			@media #{$tablet}{
				position: relative;
				left:auto;
				display:block;
			}
		}	
	
		.overlay-video-btn .arrow{
			position: absolute;
				left: 25px;
				transform: translateY(-50%);
				top: calc(50% + 2px);	
		}
	}
	
	.single-employe{
		a{
			color:$c-gray-text;
			transition:opacity .3s ease;

			.desktop &:hover{
				opacity: .7;
			}
		}

		*{
			pointer-events:auto;
		}

		@media #{$mobile}{
			opacity:1;
			transform:none;
		}
	}

	.preview-block{
	    perspective: none !important;

	    .text-wrapper{
	    	left:0;
	    }

		.preview-wrapper{
			transform-style: flat;	
		}
	}
}


/* -----------------------*/
/* [TAB] ==== Tabs
/*------------------------*/
.page-contact .form-block{
	position: relative;
	background-color:transparent;
	position: relative;
	z-index: 5;

	@media #{$mobile}{
		padding:0;
	}
		
	.icon-plus {
		display:none;

		@media #{$mobile}{
			display:block;
			position: absolute;
			right:20px;
			transform:translateY(-50%);
			top:50%;

			.wrapper{
				height:20px;
				width:20px;
				position:relative;

				.line{
					transform-origin:center center;
					position:absolute;
					transform:translate(-50%,-50%);
					left:50%;
					top:50%;
					background-color:$c-gray-text;
					width:100%;
					height:2px;
					transition:opacity .3s ease;

					&:nth-of-type(2){
						transform:translate(-50%,-50%) rotate(90deg);
					}
				}
			}
		}
	}

	.form-block__tabs{
		width:100%;

		ul{
			display: flex;	
			width:100%;
			justify-content: space-between;

			@media #{$mobile}{
				flex-direction:column;
			}
		}
		
		li{
			width:100%;
			text-align: center;
			@include MainBold;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size:13px;
			
			cursor:pointer;
			@media #{$mobile}{
				text-align: left;
			}

			.wrapper-header{
				padding:25px 0;
				background-color: $c-main; 
				position: relative;
				transition:color .3s ease,background-color .3s ease;
				@media #{$mobile}{
					text-align:left;
					padding-left:20px;
				}
			}
			
			.form-block__forms{
				padding:0 20px;
			}
			

			&.active,.desktop &:hover{
				.wrapper-header{
					background-color:#fff;
					color:$c-main;	
				}
				
				.icon-plus .wrapper .line:nth-of-type(2){
					opacity:0;
				}
			}

			&:nth-of-type(2){
				border-left:2px solid #fff;
				border-right:2px solid #fff;

				@media #{$mobile}{
					border-left:none;
					border-right:none;
				}
			}
		}
	}
}

.form-block__container{
	// padding-bottom:100px;
	background-color:$c-gray-bg;
	@media #{$mobile}{
		padding-bottom:0;
	}
}

.form-block__wrapper,.form-block__tabs{
	lost-utility:clearfix;
	background-color:#fff;

	.gform_ajax_spinner {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid #ccc;
		border-top-color: #333;
		background:none !important;
		animation: spinner .6s linear infinite;
		z-index: 100;
	}
	@keyframes spinner {
		to {transform: rotate(360deg);}
	}

	.gform_confirmation_message{
		@include MainBook;
	}

	.employe-wrapper{
		lost-utility:clearfix;
	    width: calc(100% + 26vw);
	    padding: 100px 13vw 0;
	    left: -13vw;
	    position: relative;
	    background-color:$c-gray-bg;

	    @media #{$mediumDesktop}{
			padding:100px 9vw 0;	
			width: calc(100% + 24vw);	
			left: -12vw;
		}

		@media #{$smallDesktop}{
			padding:100px 7vw 0;		
			width: calc(100% + 14vw);
			left: -7vw;
		}

		@media #{$tablet}{
			padding:100px 40px 0;		
			width: calc(100% + 80px);
			left: -40px;
		}

		@media #{$mobile}{
			padding:100px 40px 0;		
			width: calc(100% + 40px);
			left: -20px;
		}

	    .single-employe{
			margin-bottom:60px;
			p{
				color:$c-gray-text;
			}

			@media #{$mobile}{
				margin-bottom:40px;	
			}
		}
	}

	.form-block__forms[data-id='3']{
		.gform_wrapper{
			margin-top:40px;
		}
	}

	.form-block__forms{
		.charleft{
			display:none;
		}
		.charleft:nth-of-type(1){
			display:block;
		}
	}

	
	
	.phone-wrapper{
		lost-utility:clearfix;
		padding-bottom:20px;
		width: 100%;
		border-bottom:1px solid #e0e0e0;

		.left,.right{
			lost-column:1/2 2 20px;

			@media #{$mobile}{
				lost-column:1/1 1 0px;
			}
		}

		.left{
			@media #{$mobile}{
				margin-bottom:20px;
			}	
		}

		h4{
			@include subTitle;
			font-size:11px;
			margin-bottom: 10px;
		}

		span.tel{
			@include MainBold;
			font-size:35px;
			color:$c-gray-text;
			
			@media #{$mobile}{
				font-size:28px;
			}
		}
	}

	.forms{
		lost-utility:clearfix;
	}
	
	.form-block__forms{
		padding-top:100px;
		position: absolute; 
		left: -999em;
		width:70vw;

		@media #{$mobile}{
			padding-top:50px;
		}

		& > .wrapper{
			lost-column:12/16 1 0;	
			margin:auto !important;
			float:none !important;
			padding-bottom:100px;
			position: relative;
			z-index: 5;
			
			iframe{
				width:100%;
			}

			@media #{$mobile}{
				lost-column:1/1 1 0;
				padding-bottom:50px;
			}
		}

		&.active{
			position:relative;
			left:0;
			width:auto;
		}
	}
}


/* -----------------------*/
/* [OFF] ==== Office box
/*------------------------*/
.page-contact .office-block{
	height:640px;
	background-size:cover;
	background-position:center center;
	display: flex;
	align-items:center;
	position: relative;
	z-index: 5;

	@media #{$mobile}{
		height:440px;
	}

	.wrapper{
		width:100%;
	}

	a:not(.btn){
		color:#fff;
		transition:color .3s ease;

		.desktop &:hover{
			color: $c-main;
		}
	}

	h3{
		@include subTitle;
		color:#fff;
		margin-bottom:20px;
	}

	h4{
		@include MainExtraBold;
		font-size:$fs-text-bigger;
		color:#fff;
		text-transform: uppercase;
		margin-bottom:10px;
	}

	.cols{
		width: 100%;
		lost-utility:clearfix;
		margin-bottom: 40px;

		p{
			color:#fff;
			font-size:16px;
			@include MainBook;
			lost-column:1/2 2 10px;
			max-width:225px;
			float:left !important;
			line-height: 1.6em;

			@media #{$mobile}{
				lost-column:1/1 1 0px;

				&:first-of-type{
					margin-bottom:20px;
				}
			}
		}

		.-bold{
			@include MainBold;	
			color:#fff;
		}
	}
}

.location-block{
	.col-wrapper{
		lost-utility:clearfix;
		margin-top: 100px;
		
		.preview-block{
			lost-column:1/2 2 80px;
			margin-bottom:120px;

			@media #{$tabletPortrait}{
				lost-column:1/2 2 20px;
				.text-wrapper{
					padding-left:15px;
					&:before{
						left:5px
					}
				}
			}


			@media #{$mobile}{
				lost-column:1/1 1 0px;
				margin-bottom:50px;
			}

			h4{
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.preview-block__adresse a{
				color:$c-gray-text;
				transition:color .3s ease;

				.desktop &:hover{
					color:$c-main;
				}
			}

			.text-wrapper{
				pointer-events:auto;
				padding-top:40px;
			}

			.img-wrapper{
				overflow: hidden;
				background-size:cover;
				background-position:center center;
				background-repeat:no-repeat;
			}

			.preview-block__adresse{
				width: 100%;
				lost-utility:clearfix;
				margin-bottom: 30px;
				margin-top: 20px;

				p{
					color:$c-gray-text;
					font-size:15px;
					@include MainBook;
					lost-column:1/2 2 10px;
					float:left !important;
					line-height: 1.8em;

					@media #{$mobile}{
						lost-column:1/1 1 0px;

						&:first-of-type{
							margin-bottom:20px;
						}
					}
				}

				.-bold{
					@include MainBold;	
				}
			}

			.see-map{
				@include MainBold;
				font-size:11px;
				color:$c-gray-text;
				text-transform: uppercase;
				letter-spacing: 1px;
				@include underlineHover(10px,1px,1px,$c-gray-text);
				
				svg{
					position: relative;
					top:1px;
					width:20px;
					left:5px;
				}
			}
		}
	}
}