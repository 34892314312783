/* ========================*/
/* ==[ Shared Styles ]== */
/*========================*/
/* [YTB] ==== Yellow text
/* [IBL] ==== Image para bg
/* [REV] ==== Reveals
/* [INF] ==== Info Icon block
/* [ICO] ==== Icon cross
/* [SEL] ==== Select
/* [INF] ==== Infobox
/* ========================*/

/* -----------------------*/
/* [YTB] ==== Yellow text
/*------------------------*/
.yellow-text-block{
	p{
		@include MainExtraBold;	
		color:#fff;
		line-height: 1.5em;
		
		@media #{$mobile}{
			line-height: 1.6em;	
		}
		
		.last + span{
			padding-left:2px;
		}

		.special{
			padding:0 9px;
			color:$c-main;

			&.active{
				padding:3px 0 0px;
				border-top:2px solid $c-main;	
				border-bottom:2px solid $c-main;	
				@media #{$tablet}{
					padding:5px 0 0px;
				}
			}

			&:first-of-type{
				border-left:2px solid $c-main;
				padding-left:5px;
			}

			&:last-of-type{
				border-right:2px solid $c-main;
				padding-right:5px;
				margin-right:5px;
			}

			&.first{
				border-left:2px solid $c-main;
				padding-left:5px;
			}

			&.last{
				border-right:2px solid $c-main;
				padding-right:5px;
			}
		}
	}
}

.yellow-underline{
	text-decoration: underline;
	color:$c-main;
	font-weight: 700;
	a{
		text-decoration: underline;
		color:$c-main;
		font-weight: 700;	
	}
}

/* -----------------------*/
/* [IBL] ==== Image para bg
/*------------------------*/
.image-para-bg{
	overflow:hidden;
	position:relative;

	.image-wrapper{
		height:calc(100% + 100px);
		position:relative;
		top:-50px;
		background-size:cover;
		background-repeat:no-repeat;
		transform:translateY(0px);
		width:100%;
	}
}

/* -----------------------*/
/* [REV] ==== Reveals
/*------------------------*/
.reveal-x,.reveal-x-footer{
	opacity:0;
	transform:translateX(-100px);
	will-change:transform;
}

.reveal-y,.reveal-y-footer{
	opacity:0;
	transform:translateY(100px);
	will-change:transform;
}

/* -----------------------*/
/* [INF] ==== Info Icon block
/*------------------------*/
.info-icon-block{
	img, .animated-icon{
		width:135px;
		max-width:135px;
		min-width:135px;
		height:135px;
		position: relative;


		@media #{$mobile}{
			margin-left: auto;
			margin-right: auto;

		}
		svg{
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.animated-icon{
		//background: red;
	}

	h4{
		@include subTitle;
		color:$c-gray-text;
		margin-top:20px;
		margin-bottom:25px;
	}

	p{
		@include mainText;	
	}	
}


/* -----------------------*/
/* [BPB] ==== Big para block
/*------------------------*/
.big-para-block{
	font-size:25px;
	margin:auto;
	text-align:center;
	padding-bottom:140px;

	@media #{$mobile}{
		padding-bottom:50px;
	}

	p{
		line-height:1.7em;	
	}
	
	span{
		color:$c-gray-text;	
		letter-spacing: .5px;

		&.special{
			color:$c-main;
		}
	}
}

/* -----------------------*/
/* [ICO] ==== Icon cross
/*------------------------*/
.icon-cross{
	width:20px;
	height:20px;
	position:relative;
	display: block;
	transition:transform .4s ease;
	
	span{
		position:absolute;
		transform:translate(-50%,-50%);
		top:50%;
		left:50%;
		height:2px;
		width:20px;
		background-color: #000;
		transition : background-color .4s ease,opacity .4s ease;
	}

	.line-2{
		transform:translate(-50%,-50%) rotate(90deg);
	}
}

/* -----------------------*/
/* [SEL] ==== Select
/*------------------------*/
.ginput_container_select{
	position: relative;
	
	@media #{$tablet}{
		&:after{
			pointer-events:none;
			content:'';
			border-radius:50%;
			background-color: $c-main;
			position:absolute;
			right:0px;
			top:0px;
			width: 52px !important;
		    height: 52px !important;
		    position: absolute !important;
		    transition: all .3s ease-in-out;
		    background: url(../img/icon-dropdown.png) no-repeat 0px 0px;
		    opacity: 0.5;
		    background-position:center center;
		}
	}
}


.gfield_error{
	.chosen-container .chosen-single{
		background:#f7e1e0 !important;
	}
}

@media #{$tablet}{
	select{
		display:block !important;
	}
}


.chosen-container{
	width:100% !important;
	border:none !important;
	background-color: $c-gray-bg;
	box-sizing:border-box;
	@include MainBold;
	color:$c-gray-text;
	height: 56px;
	letter-spacing:1px;
	padding:17px 15px !important;
	transition: all .3s ease-in-out;
	position:relative;

	@media #{$tablet}{
		display:none !important;
	}
	
	b{ display:none !important; }
	
	&.chosen-with-drop{
		border-bottom-right-radius:0px;
		border-bottom-left-radius:0px;

		.chosen-single > div{ transform:rotate(90deg); }
	}
	
	.chosen-single{
		@include MainBold;
		font-size:13px !important;
		background: $c-gray-bg !important;
		border:none !important;
		line-height:1em !important;
		height:auto !important;
		box-shadow:none !important;
		overflow:visible !important;
		position:relative;
		top:4px;


		& > div{
			width:56px !important;
			height:56px !important;
			position:absolute !important;
			top:0 !important;
			transition: transform .3s ease-in-out;

			span{
				background-size: 100% 100%;
				backgorund-position:center center;
			}
		}
	}

	.chosen-drop{
		left: 0;
		border:none !important;
		background: $c-gray-bg !important;
		box-shadow:none !important;
		margin-top:-1px  !important;
	}

	.chosen-results li{
		padding:17px 15px !important;
	}

	&.chosen-with-drop:after{ transform:rotate(180deg); }

	&:after{
		pointer-events:none;
		content:'';
		border-radius:50%;
		background-color: $c-main;
		position:absolute;
		right:0px;
		top:0px;
		width: 52px !important;
	    height: 52px !important;
	    position: absolute !important;
	    transition: all .3s ease-in-out;
	    background: url(../img/icon-dropdown.png) no-repeat 0px 0px;
	    opacity: 0.5;
	    background-position:center center;
	}
	
	.desktop &:hover{
		&:after{ opacity: 1; }
	}
}

/* -----------------------*/
/* [INF] ==== Infobox
/*------------------------*/
.infoBox{
	width:300px;
	text-align:center;
	font-size:0;
	
	@media #{$mobile}{
		width:200px;
	}

	&:before{
		content:'';
		position:absolute;
		bottom:0;
		background-color:$c-main;
		width:20px;
		height:20px;
		transform:translate(-50%,10px) rotate(45deg);
		left:50%;
	}

	img:first-child{
		position: absolute !important;
		top:0;
		right:0;
		z-index: 2;
	}

	img:first-child.thumb{
		position:relative !important;
		width:100%;
		z-index: 1;
	}	

	.category{
		@include subTitle;
		font-size:11px;
		margin-top:10px;
		display: block;
	}

	h4{
		@include subTitle;
		color:$c-gray-text;
	}

	.wrapper{
		padding:20px;
	}
}

.swiper-button-disabled{
	.swiper-button-prev,
	.swiper-button-next{
		cursor:default;	
	}
	
	div{
		opacity:.4;	
	}
}

@keyframes spinner {
	to {transform: rotate(360deg);}
}
 
.spinner:before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 2px solid #ccc;
	border-top-color: #333;
	animation: spinner .6s linear infinite;
}

.apply-now{
	width:100%;
	height:50px;
	background-color:$c-main;
	position:fixed;
	bottom:0;
	z-index: 9998;
	display: block;
	display:none;
	&.active{
		@media #{$mobile}{
			display: block;	
		}
		
	}

	span{
		position: absolute;
		transform:translate(-50%,-50%);
		left:50%;
		top:50%;
		color:#000;
		@include MainBold;
		text-transform: uppercase;
		font-size:11px;
	}
}

.cta-contact{
	background-color:white;
	user-select:none;
	display:block;
	position: relative;
	
	&:before{
		content:'';
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		background-color:$c-main;
		transform-origin: top center;
		transform:scaleY(0);
		transition:transform .4s ease;
	}

	.desktop &:hover{
		&:before{
			transform:scaleY(1);
		}	
		.container-inner p{
			span.special{
				color:$c-gray-text;
				border-color:$c-gray-text;
			}
		}
	}

	.select-navigation__closed{
		width: 100%;
		height:200px;
		cursor:pointer;

		@media #{$mobile}{
			height:120px;
		}

		.container-inner{
			height:100%;
			display: flex;
			align-items:center;
			justify-content: space-between;	
		}
		
		.yellow-text-block{
			margin:auto;
			@media #{$tablet}{
				text-align:center;
				padding: 0 20px;
			}
	
			@media #{$mobile}{
				p{
					padding: 0px;
					font-size:16px;
					line-height:1.8em;
				}
			}
		}

		p{
			@include mainTextBig;
			text-transform: uppercase;

			span.special{
				transition:color .4s ease, border-color .4s ease;
			}
		}

		.desktop &:hover{
			p{
				span.special{
					color:$c-gray-text;
					border-color:$c-gray-text;
				}
			}
		}
	}
}
