/* ========================*/
/* ==[ Information Block ]== */
/*========================*/

.information-block{
	padding-top:90px !important; 
	background-color:$c-gray-bg;

	@media #{$mobile}{
		margin-top:30px;		
	}
	
	.big-para-block{
		padding-bottom:80px;
	}

	&.container-main{
		padding-bottom:90px;

		@media #{$mobile}{
			padding-bottom:00px;
		}
	}
	
	.yellow-text-block{
		max-width:750px;

		p{
			color:$c-gray-text;	

			@media #{$mobile}{
				font-size:18px;
			}
		}
	}

	.point-container{
		margin-bottom:120px;
	}

	.point-wrapper{
		width:550px;
		display:block;
		margin:auto;
		margin-bottom: 40px;

		@media #{$mobile}{
			width:100%;
		}

		.left{
			height:50px;
			width:50px;
			display:inline-block;
			vertical-align: top;
		}

		.right{
			display:inline-block;
			padding-left:10px;
			width:calc(100%	- 55px);

			h4{
				@include subTitle;
				color:$c-gray-text;
				margin-bottom:10px;
			}
			p{
				@include mainText;
				line-height: 1.5em;
			}
		}
	}

	.graph{
		margin:auto;
		display: block;
		padding-bottom:80px;
		width:450px;
		
		@media #{$mobile}{
			padding-bottom:50px;
		}
	}

	.information-block__process{
		lost-utility:clearfix;

		.col{
			lost-column:1/3 3 40px;
			margin-bottom:50px;

			@media #{$mobile}{
				lost-column:1/1 1 0;
				text-align:center;
			}
		}
	}
}