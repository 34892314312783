/* ========================*/
/* ==[ Products ]== */
/*========================*/
/* [GEN] ==== General
/* ========================*/

/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-products{
	background-color:$c-gray-bg;
	
	.hero-gray .hero-text{
		margin-bottom:80px;

		@media #{$mobile}{
			margin-bottom:30px;
		}
	}

	.products-list{
		background-color:$c-gray-bg;
		lost-utility:clearfix;

		&:last-of-type{
			.v-line{
				display: none;
			}
		}
		
		.preview-block{
			lost-column:1/2 2 80px;
			margin-bottom:120px;

			@media #{$tablet}{
				margin-bottom:60px;				
			}

			@media #{$mobile}{
				lost-column:1/1 1 0px;	
				margin-bottom:35px;
			}
		}
	
		.products-list__header{
			text-align:center;
			padding-top:100px;
			padding-bottom:100px;
			z-index: 40;
			position: relative;

			@media #{$mobile}{
				padding-top:40px;
				padding-bottom:40px;
			}

			h3{
				@include mainTitle;

				@media #{$mobile}{
					font-size:30px;
				}
			}

			p{
				margin-top:20px;
				font-size:16px;
				color:$c-gray-light;
				@include MainBook;
			}
		}
	}

	.products-list__list{
		@media #{$mobile}{
			z-index: 5;
			position: relative;
		}
		
		.cols{
			lost-utility:clearfix;
		}

		.v-line{
			width:2px;
			height:50px;
			display: block;
			margin:auto;
			background-color:#000;
			opacity:.2;
		}
	}
}