/* ========================*/
/* ==[ Slider Center Pagination ]== */
/*========================*/

.slider-center-pagination{
	height:670px;
	width: 100%;
	background-color:$c-main;
	position:relative;
	
	@media #{$mobile}{
		padding-bottom:120px;
		height:auto;
	}

	h3{
		@include sectionTitle;
		padding-top:70px;
		text-align:center;
		margin-bottom:80px;
	    position: relative;
		z-index: 23;

		@media #{$mobile}{
			padding-top:50px;
			margin-bottom:30px;
		}
	}

	.swiper-container{
		height:100%;
		width:100%;
	    position: absolute;
    	top: 0;

		@media #{$mobile}{
			height:auto;
			width:100%;
			position: relative;
		}	

		.swiper-slide{
			position:relative;
			width:90%;
			text-align:center;
			padding:0 3%;
			max-width:1600px;
			@media #{$mobile}{
				width:100%;
			}
		}

		img{
			width:100%;
			max-width:600px;
		}

		.left,.right{
			lost-column:1/2 2 60px;
			height:100%;

			@media #{$mobile}{
				lost-column:1/1 1 0px;
				height:auto;
			}
		}

		.left{
			display:flex;
			align-items:center;
			height:100%;
			@media #{$mobile}{
				height:350px;
				margin-bottom: 20px;
			}

			img{
				display: block;

				@media #{$mobile}{
					display:none;
				}

				&.mobile-only{
					display:none;
					@media #{$mobile}{
						display: block;
					}
				}	
			}

			
			img{
			    height: 100%;
			    max-width: none;
			    min-width: 0;
			    width: auto;
			    position: absolute;
			    transform: translate(-50%, -50%);
			    // transform: translateY(-50%);
			    top: 50%;
			    left: 50%;
			    // right:0;
			    z-index: 2;

			    @media #{$mobile}{
		    	    height: 100%;
  					width: auto;
  					// margin-top:-40px;
  					// transform: translate(-50%, -50%);
  					// top: 50%;
			    // 	left: 50%;
			    }
			}
		}

		.right{
			text-align:left;
			display:flex;
			align-items:center;
			max-width: 500px;
			float:left !important;

			@media #{$mobile}{
				// margin-top:50px;
			}

			h4{
				@include MainBold;
				color:$c-gray-text;
				text-transform: uppercase;
				font-size:20px;
				margin-bottom:20px;
			}

			p{
				@include mainText;
				margin-bottom:20px;
				color:$c-gray-text;
			}

			a{
				@include subTitle;
				color:$c-gray-text;
				transition: color .3s ease;

				.desktop &:hover{
					color:#fff;

					svg *{
						fill:#fff;
					}	
				}

				svg{
					margin-left:10px;
				}

				svg *{
					fill:$c-gray-text;
					transition:fill .3s ease;
				}
			}
		}
	}

	.swiper-pagination{
		position:absolute;
		bottom:40px;
		margin-top:40px;
		display: inline-block;
		left:calc(50% - 26px);
		transition:none;
		text-align:left;

		.swiper-pagination-bullet{
			padding:25px;
			display: inline-flex;
			align-items:center;
			justify-content: center;
			margin-right:30px;
			position:relative;
			@include subTitle;
			color:$c-gray-text;
			border:1px solid $c-gray-text;
			opacity:.7;
			background-color:$c-main;

			span{
				position:absolute;
				transform:translate(-50%,-50%);
				left:50%;
				top:50%;
				line-height:17px;
			}

			&:not(:last-of-type):before{
				content:'';
				width:32px;
				background-color:$c-gray-text;
				height:2px;
				position:absolute;
				transform:translateY(-50%);
				top:50%;
				right:-32px;
				opacity:.7;
			}

			&.swiper-pagination-bullet-active{
				background-color:$c-gray-text;
				color:$c-main;
				opacity:1;
				
				&:before{
					opacity:.5;
				}
			}
		}
	}
}



.page-career{

	.slider-center-pagination{
	

		.swiper-container{

			.left{
				img{

					max-width: 574px;

					width: auto;
					height: auto;
					position: absolute;
					transform: translate(-50%, -50%);
					top: 50%;
					left: 50%;
					z-index: 2;


					@media #{$tablet}{
						max-width: 400px;
						//max-height: 375px;
					}
		

					@media #{$mobile}{
						max-width: 400px;
						// margin-top:50px;
					}
		

				}
			}
		}
	}
}