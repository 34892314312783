/* ========================*/
/* ==[ Home ]== */
/*========================*/
/* [HER] ==== Hero
/* [SEL] ==== Select Navigation
/* [HBL] ==== Hiring Block
/* [SLI] ==== Slider Fullheight
/* ========================*/

/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/

.home-hero{
	height:100vh;
	min-height:600px;
	width:100%;
	position: relative;
	z-index: 2;
	display: flex;
	align-items:center;
	justify-content:center;
	overflow:hidden;
	

	&.draw-line:before{
		animation: drawLine 2s infinite;
	}
	@keyframes drawLine{
		0%   { transform:translateX(-50%) scale(0); }
		50% { transform:translateX(-50%) scale(1); }
		100% { transform:translateX(-50%) scale(0); }
	}

	&:before{
		content:'';
		transform-origin:bottom center;
		position:absolute;
		bottom:0;
		width:2px;
		height:60px;
		background-color:#fff;
		opacity: 1;
		transform:translateX(-50%) scale(0);
		left:50%;
		display: block;
		z-index: 600;
		transition:transform .6s ease;
	}
	
	.btn{
		position: relative;
		left:auto;
		display:block;

		@media #{$tablet}{
			position: absolute;
			left:-999em;
		}
	}

	.overlay-video-btn{
		position: absolute;
		left:-999em;

		@media #{$tablet}{
			position: relative;
			left:auto;
			display:block;
		}
	}	

	.overlay-video-btn .arrow{
		position: absolute;
	    left: 25px;
	    transform: translateY(-50%);
	    top: calc(50% + 2px);	
	}

	.filter-bg{
		width:100%;
		background-color:$c-gray-text;
		height:100%;
		position: absolute;
		top:0;
		left:0;
		z-index: 1;
		opacity:.4;
		transform:translateZ(0);
	}

	@media #{$mobile}{
		min-height:540px;
	}

	.video{
		height:100%;
		width:100%;
		object-fit:cover;

		@media #{$tablet}{
			display:none;
		}
	}

	.home-hero__bg{
		position : absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-size:cover;
		background-position:center center;
		// transform:translateZ(0);
	}

	.content-wrapper{
		position:relative;
		text-align:center;
		z-index: 10;
		transform:translateZ(0);

		.circle{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate3d(-50%,-50%,0) rotate(90deg);
			display:block;
			width:660px;
			height:660px;
			pointer-events: none;
			
			@media #{$tablet}{
				transform:translate3d(-50%,-50%,0) rotate(90deg) scale(.9);
			}			

			@media #{$mobile}{
				transform:translate3d(-50%,-50%,0) rotate(90deg) scale(.8);
			}

			.start-circle{
				width: 20px;
			    height: 20px;
			    background-color: #ffb900;
			    position: absolute;
			    transform: translateY(-50%);
			    display: block;
			    border-radius: 50%;
			    top: 50%;
			    left: calc(100% - 20px);

			    @media #{$mobile}{
			    	display:none;
			    }

			    &:before{
			    	content:'';
			    	position:absolute;
			    	transform:translate(-50%,-50%);
			    	top:50%;
			    	left:50%;
			    	background-color:#000;
			    	width:50%;
			    	height:50%;
			    	display:block;
			    	border-radius:50%;
			    }
			}
		}

		.btn{
			margin:auto;
			margin-top:7vh;
			transform:translateZ(0);
		}

		.text-wrapper{
			margin-bottom:7vh;
		}

		.subtitle{
			font-size:13px;
			color:#fff;
			@include MainBold;
			text-transform:uppercase;
			letter-spacing: 1px;
            max-width: 40%;
            margin: 0 auto;
            line-height: 2em;

			&:first-of-type{
				margin-bottom:1vh;
			}

			@media #{$mobile}{
				max-width:300px;
				letter-spacing: 0px;
				font-size:9px;
				line-height:2em;
			}
		}

		h1{
			position:relative;
			z-index: 2;
			font-size:116px;
			color:#fff;
			text-transform: uppercase;
			@include MainExtraBold;

			@media #{$mediumDesktop}{
				font-size:100px;	
			}

			@media #{$smallDesktop}{
				font-size:80px;	
			}

			@media #{$mobile}{
				font-size:40px;
			}

			.-yellow{
				color:$c-main;
			}

			.-small{
				font-size:63px;
				vertical-align: middle;
				margin:0 20px;

				@media #{$mobile}{
					font-size:27px;
					display: block;
				}
			}
		}
	}

	.dualvideo-overlay{
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		background-size: cover;
		opacity:0;
		pointer-events:none;
		z-index: 555;
		@media #{$mobile}{
			display:none;
		}
		.btn-sound{
			&.off{
				opacity: 1;
			}
			&.on{
				opacity: 0;
			}
		}
		&.sound-on{
			.btn-sound{
				&.off{
					opacity: 0 ;
				}
				&.on{
					opacity: 1;	
				}
			}
		}

		.btn-sound{
			position:absolute;
			bottom:10px;
			right:30px;
			z-index: 20;

			svg{
				width:30px;
				height:30px;
				* {
					transition: fill .3s ease;
				}
			}
			.desktop &:hover{
				svg *{
					fill:#1c1c1c;
				}
			}
		}

		.wrapper{
			height:100%;
			display: flex;
			align-items:center;
			justify-content: center;
		}

		.overlay-canvas{
			position: absolute;
			height:100%;
			width:100%;
			top:0;
			left:0;
			background-color: #000;

			canvas{
				z-index: 2;
				position: relative;
			}
		}

		.video-hero{
			position:absolute;
			width:100%;
			height:100%;
			top:0;
			left:0;
			z-index: 1;

			video{
				width:100%;
				position:absolute;
				top:0;
				left:0;
				height:100%;
				object-fit: cover;
				transition:opacity .3s ease;
			}
		}
	}
}

/* -----------------------*/
/* [SEL] ==== Select Navigation
/*------------------------*/
.select-navigation{
	position:relative;
	z-index: 5;
	
	&:before{
		content:'';
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		background-color:$c-main;
		transform-origin: top center;
		transform:scaleY(0);
		transition:transform .4s ease;
	}

	.desktop &:hover{
		&:before{
			transform:scaleY(1);
		}	
		.container-inner p{
			span.special{
				color:$c-gray-text;
				border-color:$c-gray-text;
			}
		}
	}
	
	
	.container-main{
		max-width:none;
	}

	.select-navigation__closed{
		width: 100%;
		height:200px;
		cursor:pointer;

		@media #{$mobile}{
			height:120px;
		}

		.container-inner{
			height:100%;
			display: flex;
			align-items:center;
			justify-content: space-between;	
		}
		
		.yellow-text-block{
			@media #{$tablet}{
				text-align:center;
				padding: 0 20px;
			}

			@media #{$mobile}{
				p{
					padding: 0px;
					font-size:16px;
					line-height:1.8em;
				}
			}
		}

		p{
			@include mainTextBig;
			text-transform: uppercase;

			span.special{
				transition:color .4s ease, border-color .4s ease;
			}
		}

		.desktop &:hover{
			p{
				span.special{
					color:$c-gray-text;
					border-color:$c-gray-text;
				}
			}
		}
	}

	.select-navigation__opened{
		height:100vh;
		width:100%;
		transform:scaleY(0);
		position:absolute;
		top:0;
		left:0;
		// background-color: $c-main;
		transform-origin: top center;
		z-index: 3;

		&:before{
			content:'';
			width:100%;
			height:140%;
			position:absolute;
			background-color: $c-main;
			transform:translate(-50%,-50%);
			left:50%;
			top:50%;
		}
	}

	.select-navigation__content{
		height:100vh;
		width:100%;
		position: absolute;
		top:0;
		left:0;
		display: flex;
		align-items:center;
		justify-content: center;
		flex-direction: column;
		opacity:0;
		pointer-events:none;
		z-index: 4000;
		padding:90px 0;
		text-align:center;
		background-color:$c-main;

		a,h3{
			@include MainExtraBold;
			color:$c-gray-text;
			font-size:$fs-text-bigger;
			text-transform: uppercase;

			@media #{$mobile}{
				font-size:20px;
			}
		}

		h3{
			font-size:$fs-text-big;
		}

		ul{
			width:100%;
			margin-top:4vh;

			@media #{$mobile}{
				margin-top:10px;				
			}

			a{
				width:100%;
				display: block;
				padding:4vh 0;
				transition:background-color .9s ease;
				position: relative;

				span.label{
					padding:0 30px;
					background-color:$c-main;
					z-index: 2;
					position: relative;
					transition:background-color .9s ease;
					display: inline-block;

					@media #{$mobile}{
						padding:0 10px;
						text-align: center;
					}
				}

				.line{
					content:'';
					z-index: 1;
					height:2px;
					background-color:$c-main;
					position:absolute;
					transform:translateY(-50%) scaleX(0);
					top:50%;
					left:90px;
					right:60px;
					transition:transform 1s ease;
					transition-delay: .3s;
					transform-origin:left center;
				}

				.msg{
					@include MainExtraBold;
					font-size:12px;
					color:$c-gray-text;
					position: absolute;
					transform:translateY(-95%);
					top:50%;
					left:30px;
					letter-spacing: 1px;
					opacity:0;
					transition:transform .4s ease,opacity .4s ease;
					transition-delay: .2s
				}

				.desktop &:hover{
					background-color:#fff;

					.label{
						background-color:#fff;
					}

					.msg{
						transform:translateY(-50%);
						opacity:1;
					}

					.line{
						transform:translateY(-50%) scaleX(1);
					}
				}
			}
		}

		.h-separator{
			height:7vh;
			width:2px;
			background-color:#000;
			opacity:.1;
			display: inline-block;
			margin-top:30px;

			@media #{$mobile}{
				height:30px;
				margin-top:20px;	
			}
		}

		.btn-close{
			position:absolute;
			top:0;
			right:0;
			width:60px;
			height:100px;
			background-color:rgba(31,31,31,.1);
			transition: background-color .3s ease;

			@media #{$mobile}{
				height:60px;
			}

			.desktop &:hover{
				background-color:rgba(31,31,31,.9);

				.line:nth-of-type(1){
					transform:translate(-50%,-50%) rotate(-45deg);
					background-color:#fff;
				}

				.line:nth-of-type(2){
					transform:translate(-50%,-50%) rotate(45deg);
					background-color:#fff;
				}
			}	

			.line{
				width:20px;
				height:2px;
				position:absolute;
				top:50%;
				left:50%;
				background-color:$c-gray-text;
				transition:transform .5s ease, background-color .3s ease;

				&:nth-of-type(1){
					transform-origin:center center;
					transform:translate(-50%,-50%) rotate(135deg);
				}

				&:nth-of-type(2){
					transform-origin:center center;
					transform:translate(-50%,-50%) rotate(-135deg);	
					transition-delay:.1s;
				}
			}
		}
	}
}



/* -----------------------*/
/* [SLI] ==== Slider Fullheight
/*------------------------*/
.slider-fullheight{
	padding-top:130px;
	padding-bottom:150px;
	background-color:$c-gray-bg;
	overflow:hidden;

	@media #{$mobile}{
		padding-top:90px;	
	}


	&.container-main{
		max-width:none;
		padding-left:12vw;
		padding-right:12vw;

		@media #{$mediumDesktop}{
			padding-left:10vw;
			padding-right:10vw;		
		}

		@media #{$smallDesktop}{
			padding-left:7vw;
			padding-right:7vw;		
		}

		@media #{$tablet}{
			padding-left:40px;
			padding-right:40px;			
		}

		@media #{$mobile}{
			padding-left:20px;
			padding-right:20px;				
		}
	}

	h4{
		@include subTitle;
		margin-bottom:30px;
		left:70px;

		@media #{$mobile}{
			left:0;
			text-align: center;
		}
	}

	.swiper-container{
		overflow: visible;
		pointer-events:auto;
	}

	.swiper-wrapper{
		pointer-events:none;
	}

	.big-wrapper{
		@media #{$mobile}{
			height:100%;
		}
	}

	.swiper-pagination-full{
		cursor:default;
		// display:none;
	
		// @media #{$mobile}{
			width: 100%;
			display:block;
			position: absolute;
			transform:translateX(-50%);
			left:50%;
			bottom:-70px;
			z-index: 2;
			text-align: center;
			pointer-events:auto;

			.swiper-pagination-bullet{
				margin:0 20px;
				opacity:1;
				background-color:#9a9a9a;

				&.swiper-pagination-bullet-active{
					background-color:$c-main;
					position:relative;

					&:before{
						border:2px solid $c-main;
						border-radius:50%;
						content:'';
						position:absolute;
						transform:translate(-50%,-50%);
						top:50%;
						left:50%;
						width:200%;
						height:200%;
					}
				}
			// }
		}
	}

	.slider-wrapper{
		height:100%;
		pointer-events:auto;
	}

	.swiper-slide{
		width:85%;
		height:auto;
		position:relative;
		cursor:inherit;
		pointer-events:auto;

		&.swiper-slide-active{
			.vert-title{
				opacity: 0;
				transform:translateX( -70px);
			}
		}

		.vert-title{
			pointer-events:none;
			position:absolute;
			transform-origin:center center;
			transform: translateY(-50%);
			left:0px;
			top:50%;
			text-transform: uppercase;
			color:#fff;
			opacity:1;
			transition:opacity .7s ease-in-out,transform .7s ease-in-out;
			@include MainBold;
	        width: 100%;
		    text-align: center;
		    margin-left: -45%;

		    span{
	    	    transform: rotate(90deg);
			    position: relative;
			    display: block;
		    }
		}

		// @media #{$smallDesktop}{
		// 	height:400px;
		// }

		@media #{$mobile}{
			width:100%;
			height:450px;
		}

		.video-wrapper{
			width: 90%;
			height: 100%;
			position: absolute;
			top:0;
			bottom:0;
			overflow:hidden;
			pointer-events:none;
			
			video{
				object-fit: cover;
				width:100%;
				height:100%;
				opacity:0;
				transition:opacity .3s ease;

				body:not(.desktop) &{
					@media #{$tablet}{
						display:none;
					}	
				}
			}
		}

		@media #{$mobile}{
			.btn{
				margin:auto;
				transform:scale(.9);
			}	
		}
		

		.bg{
			transition:opacity .3s ease;
			width: 90%;
			height: auto;
			background-size:cover;
			background-position:center center;
			position:relative;
		
			img{
				width:100%;
			}

			@media #{$smallDesktop}{
				width:100%;
			}

			@media #{$mobile}{
				height:100%;

				img{
					height:100%;
					object-fit: cover;
					max-width:none;
				}
			}
			
			&:before{
				content:'';
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				// background-color: rgba(0,0,0,0.3);
			}
		}
	}

	.text-wrapper{
		position:absolute;
		transform:translateY(-50%);
		top:50%;
		left:70px;
		pointer-events:none;

		@media #{$mediumDesktop}{
			left:30px;
		}

		@media #{$mobile}{
			left:50%;
			transform:translateX(-50%) !important;
			top: 50px;
			width:90%;
			z-index: 2;
		}	

		h2{
			@include mainTitle;
			margin-bottom:20px;
			width:70%;
			pointer-events:none;

			@media #{$mobile}{
				text-align: center;
				width:90%;
				margin:auto;
				font-size:26px;
				margin-bottom:20px;
			}
		}
	}

	.img-wrapper{
		position:absolute;
		right:-30%;
		width:70%;
		height:130%;
		transform:translateY(-50%);
		top:53%;
		pointer-events:none;
		z-index: 10;
		will-change:transform;
	
		@media #{$bigDesktop}{
			right:-15vw;
		}

		// @media #{$smallDesktop}{
		// 	right:-20vw;
		// 	height:100%;
		// }

		@media #{$mobile}{
			left:50%;
			right:auto;
			width:280px;
			z-index: 1;
		}

		img{
			width:auto;
			height:100%;
			max-width:none;

			@media #{$mobile}{
				width:280px;
				height:auto;
				margin:auto;
				top: 10px;
    			position: relative;
			}
		}
	}

	.perspective-wrapper{
		body:not(.no-perspective) &{
			perspective: 5800px;
			transform-style: preserve-3d;
		}
		
	}

	.preserve-3d{
		body:not(.no-perspective) &{
			transform-style: preserve-3d;
		}
	}

	.mid-z{
		transform: translate3d(0,-50%, 120px ) ;

		@media #{$tablet}{
			transform:translateY(-50%)
		}

		@media #{$mobile}{
			transform:translateX(-50%)
		}
	}

	.back-z{
		body:not(.no-perspective) &{
			transform: translateZ( -30px );
		}

		@media #{$mobile}{
			transform:none;
		}
	}

	.front-z{
		transform: translate3d( 5%,-50%, 130px );

		@media #{$mobile}{
			transform:none;
		}
	}

	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right{
		display: none;
	}
}

/* -----------------------*/
/* [WOR] ==== World Block
/*------------------------*/
.world-block{
	padding:80px 0;
	height:800px;
	position:relative;
	overflow:hidden;

	@media #{$mobile}{
		height:630px;
	}
		
	.world-para{
		@include mainText;
		position: relative;
		max-width:600px;
		display: block;
		margin:auto;
		text-align: center;
		margin-bottom:50px;
		color:#fff;
		z-index: 10;
		@media #{$mobile}{
			margin-bottom: 30px;
		}
	}

	.bg-world{
		position:absolute;
		background-position:bottom center;
		background-repeat:no-repeat;
		height:100%;
		width:100%;
		z-index: 3;
		bottom:0;
		pointer-events:none;

		@media #{$mobile}{
			background-size:180% auto;
		}
	}

	.bg-gradient{
		// background: radial-gradient(ellipse at center, #2f2f2f 1%,#161616 100%);
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-size:cover;
		background-position:center center;
		z-index: 1;
		pointer-events:none;
	}

	h4{
		@include subTitle;
		margin-bottom:40px;
		width: 100%;
		text-align:center;
		position:relative;
		z-index: 4;
		@media #{$mobile}{
			margin-bottom: 20px;
		}
	}

	h2{
		@include mainTitle;
		margin-bottom:40px;
		text-align:center;
		position:relative;
		z-index: 4;
		@media #{$mobile}{
			margin-bottom: 20px;
			font-size:30px;
		}
	}
	
	.btn{
		margin:auto;
		position:relative;
		z-index: 4;
		// pointer-events:none;
	}

	.circles{
		position: absolute;
		z-index: 2;
		width:100%;
		height:100%;
		bottom:0;

		img{
			position: absolute;
			width:1000px;
			height:1000px;
			display: block;
			transform:translate(-50%,-0%);
			transform-origin: center center;
			left:50%;
			top:50%;

			@media #{$mobile}{
				max-width:none;
				height:600px;
				width:600px;
				transform:translate(-50%,-50%);
				top:120%;
			}
		}
	}
}

/* -----------------------*/
/* [HBL] ==== Hiring-block
/*------------------------*/
.hiring-block{
	width:100%;
	background-color:$c-gray-bg;

	.career-paragraph{
		@include mainText;
		width:90%;
		margin-bottom:40px;
		@media #{$mobile}{
			width:100%;
		}
	}

	&.container-main{
		padding-top:130px;
		padding-bottom:130px;

		@media #{$mobile}{	
			padding-bottom:0px;	
			padding-top:100px;
		}
	}

	.container-inner{
		lost-utility:clearfix;	
	}

	.hiring-block__right,
	.hiring-block__left,{
		lost-column:1/2 2 0;
		position:relative;

		@media #{$mobile}{
			lost-column:1/1 1 0;
			text-align: center;
		}
	}

	.hiring-block__left{
		z-index: 2;
		position: relative;

		.text-wrapper{
			padding-left:10%;

			@media #{$mobile}{
				padding-left:0;
			}
		}

		.image-para-bg{
			width:100%;
			height:280px;
			.image-wrapper{
				background-position:center center;
			}

			@media #{$mobile}{
				// height:150px;
			}
		}

		.btn{
			margin-bottom:50px;

			@media #{$mobile}{
				margin:auto;
				margin-bottom:50px;
			}
		}
	}

	.hiring-block__right{
		z-index: 1;
		position: relative;

		@media #{$mobile}{
			margin-top:-30px;
		}

		.image-para-bg{
			width:100%;
			height:470px;

			.image-wrapper{
				background-position:center center;
			}

			@media #{$mobile}{
				height:230px;
				width:calc(100% + 40px);
				position: relative;
				left:-20px;
			}
		}
	}

	h3{
		@include mainTitle;
		margin-bottom:20px;
		width:90%;
		
		@media #{$mobile}{
			width:100%;
		}
	}

	h4{
		@include subTitle;
		margin-bottom:30px;
	}
}
