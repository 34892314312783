/* ========================*/
/* ==[ News Single ]== */
/*========================*/
/* [GEN] ==== General
/* ========================*/

/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-news-single {
	background-color:$c-gray-bg;

	

	.hero-full{
		flex-direction: column;
		align-items: center;
		height:auto;

		&.no-bg{
			h1,h4{
				color:$c-gray-text;
				&:before{
					background-color:$c-gray-text;
				}
			}
		}

		.wrapper{
			padding:260px 0;
			@media #{$mobile}{
				padding:150px 0;
			}
		}

		h1{
			text-transform: none;
			max-width: 1000px;
			width:100%;
			font-size:45px;
			@media #{$mobile}{
				font-size:32px;
			}
		}
	}

	.single-info-general{
		margin-bottom: 0px;
		position: relative;
		z-index: 10;
		.yellow-text-block p {
			font-size:18px;
		}
		.wrapper .left .content{
			padding-bottom:40px;
		}
	}

	.btn-link{
		background-color:$c-main;
		border:none;
		padding:20px 30px;
		@include MainBold;
		text-transform: uppercase;
		font-size:13px;
		letter-spacing: 1px;
		transition: background-color .3s ease, color .3s ease;
		line-height:1em;
		color:$c-gray-text;
		margin:auto;
		display:inline-block;


		.desktop &:hover{
			background-color:$c-gray-text;
			color:$c-main;
		}
	}
	
	.btn-block + .next-post{
		margin-top: 60px;
	}

	.btn-block{
		margin:0px 0 0px;
		text-align: center;
		@media #{$mobile}{
			margin-bottom:25px;
		}
		a{
			margin:auto;
		}
	}
	
	.pardotform{
		width:70%;
		max-width:600px;
		margin:auto;
		display:block;
	}

	iframe:not(.pardotform){
		width:100%;
		height:480px;

		@media #{$tablet}{
			height:400px;
		}
		@media #{$mobile}{
			height:300px;
		}
	}

	// .single-hero{
	// 	padding-top:250px;

	// 	@media #{$mobile}{
	// 		padding-top:150px;
	// 		text-align:center;

	// 		&:before{
	// 			content:'';
	// 			width:1px;
	// 			background-color:$c-gray-light-text;
	// 			height:120px;
	// 			position:absolute;
	// 			top:0;
	// 			transform:translateX(-50%);
	// 			left:50%;
	// 		}
	// 	}
	// }

	// .container{
	// 	width:75%;
	// 	margin:auto;

	// 	@media #{$mobile}{
	// 		width:100%;
	// 	}
	// }

	// iframe{
	// 	margin:60px auto;
	// 	display: block;
	// 	width:100%;
	// 	height:600px;
	// 	@media #{$tablet}{
	// 		height:470px;
	// 	}
	// 	@media #{$mobile}{
	// 		height:300px;
	// 	}

	// }

	// .single-hero h4{
	// 	@include subTitle;
	// 	margin-bottom:30px;
	// 	font-size:11px;
	// 	position:relative;

	// 	&:before{
	// 		content:'';
	// 		width:50%;
	// 		height:2px;
	// 		position:absolute;
	// 		transform:translateY(-50%);
	// 		top:50%;
	// 		left:calc(-100px - 50%);
	// 		background-color:#000;
	// 		opacity:.2;
	// 	}
	// }

	// h1{
	// 	font-size:40px;
	// 	color:#ffffff;
	// 	@include MainBold;

	// 	@media #{$mobile}{
	// 		font-size:26px;
	// 	}
	// }
}

.page-news-single .single-content{
	background-color:$c-gray-bg;

	.form-pardot{
		width:50%;
	    min-width: 600px;
		@media #{$tablet}{
			width:100%;
		}
	}

	.wrapper-wysiwyg{
		// padding-bottom:60px;
		padding-top:50px;
		max-width:1200px;
		margin:auto;
		@media #{$mobile}{
			padding-top:20px;
		}

		blockquote{
			position:relative;

			p:before{
				content: '“';
				position: absolute;
				border:3px solid $c-gray-text;
				left: 0;
				top: 0;
				width: 40px;
				height: 25px;
				display: flex;
				justify-content: center;
				align-items:center;
				font-size:30px;
				padding-top:15px;
			}

			p{
				position:relative;
				@include mainTextBig;
				font-size:21px;
				line-height: 2em;
				margin-bottom:35px;
				padding-left:60px;

				@media #{$mobile}{
					font-size:15px;
					margin-bottom:20px;
				}
			}
		}

		img{
			width:100%;
			margin:50px auto 0px;
			height:auto;

			@media #{$mobile}{
				margin:50px auto 0px;
			}
		}

		h2,h3,h4,h5,h6,p,ul{
			width:70%;
			margin:auto;
			min-width:600px;

			@media #{$mobile}{
				width:100%;
				min-width:0;
			}
		}

		p{
			@include mainText;
			margin-bottom:40px;

			& + img{
				margin-top:40px;	
			}
		}
		a{
			color:$c-main;
			text-decoration: underline;
			transition: color .3s ease;
			&:hover{
				color:$c-gray-text;
				text-decoration: none;
			}
		}

		ul{
			margin:20px auto;

			li{
				left:20px;
				position: relative;
				@include mainText;
				
				&:before{
					content:'';
					position:absolute;
					top:.8em;
					// left:50%;
					// transform:translate(-50%,-50%);
					border-radius: 50%;
					width:5px;
					height:5px;
					background-color:$c-main;
					left:-15px;
				}
			}
		}
	
		h2,h3,h4,h5,h6{
			font-size:28px;
			@include MainBold;
			color:$c-gray-text;
			margin-bottom:20px;
		}

		h3{
			font-size:24px;
		}
		h4{
			font-size:20px;	
		}
		h5{
			font-size:18px;	
		}
		h5{
			font-size:16px;	
		}
		h6{
			font-size:14px;	
		}

		hr{
			margin:0;
			margin-top:100px;
			margin-bottom:100px;

			@media #{$mobile}{
				margin-top:50px;
				margin-bottom:50px;
			}
		}

		.citation{
			font-size:20px;
			padding:30px 0 0px;
			color:$c-gray-text;
			@media #{$mobile}{
				text-align: center;
				line-height:1.5em;
			}
		}
	}

	.next-project{
		margin:auto;
		margin-bottom:120px;
		width:50%;
		text-align:center;

		@media #{$mobile}{
			width:90%;
			margin-bottom:60px;
		}

		h5{
			@include subTitle;
			font-size:11px;
			margin-bottom:30px;
		}

		h4{
			@include MainBold;
			color:$c-gray-text;
			font-size:22px;
			margin-bottom:30px;	
		}

		.btn{
			margin:auto;
		}
	}

	.btn-flat{
		.label{
			width:15px;

			svg{
				position: relative;
    			top: 1px;
			}
		}

		.label svg *{
			fill:$c-main;
			transition:.5s ease fill;
		}

		.desktop &:hover{
			.label svg *{
				fill:$c-gray-text;
			}
		}
	}
}