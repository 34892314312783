/* ===========================*/
/* ==[ Video Text Block ]== */
/*===========================*/

.video-text-block{
	lost-utility:clearfix;

	.container-inner{
		display:flex;
		align-items:center;	
		margin-bottom:50px;
		@media #{$mobile}{
			display:block;
			lost-utility:clearfix;
		}
	}
	
	.extra-citation{
		lost-utility:clearfix;
	}

	.col-single{
		margin-bottom:50px;
		@media #{$mobile}{
			margin-bottom:0px;
		}
		&:nth-of-type(even){
	    	lost-column:7/16 2 20px;
	    	float:left !important;
	    	@media #{$tablet}{
				// lost-column:1/2 2 20px;
				// lost-offset:0;
			}
			@media #{$mobile}{
				lost-column:1/1 1 0px;
				// lost-offset:1/1;
				margin-left:0 !important;
			}
		}
	    &:nth-of-type(odd){
	    	lost-column:6/16 2 20px;
	    	margin-right: 8% !important;	
			lost-offset:1/16;
			@media #{$tablet}{
				lost-column:1/2 2 20px;
				lost-offset:clear;
				// margin-right:0 !important;
			}
			@media #{$mobile}{
				lost-column:1/1 1 0px;
				lost-offset:1/1;
				margin-left:0 !important;
				margin-right:0 !important;
			}
	    }
		@media #{$mobile}{
			.citation-block .author{
				padding-left:0;
	    	}	
		}
	    
	}

	&.container-main{
		padding-top:150px;
		padding-bottom:100px;	

		@media #{$mobile}{
			padding-top:50px;
			padding-bottom:50px;	
		}
	}
	
	.left{
		lost-column:6/16 2 20px;
		lost-offset:1/16;
		margin-right: 8% !important;

		@media #{$tablet}{
			lost-column:1/2 2 20px;
			lost-offset:0;
		}

		@media #{$mobile}{
			lost-column:1/1 1 0px;
			lost-offset:1/1;
			margin-left:0 !important;
			margin-right:0 !important;
		}
	}

	.right{
		lost-column:1/2 2 20px;

		@media #{$mobile}{
			lost-column:1/1 1 0px;
		}
	}
	
	.left .wrapper{
		h3{
			@include sectionTitle;

			@media #{$mobile}{
				margin-bottom:20px;
			}
		}

		p{
			@include mainText;
			margin-bottom:40px;
		}
	}

	.right{
		position:relative;

		@media #{$mobile}{
			margin-top:40px;
		}

		img{
			width:100%;
		}
	}

	.btn{
		position:absolute;
		transform:translate(-50%,-50%);
		top:50%;
		left:50%;
		
		.arrow{
			position: absolute;
		    left: 25px;
		    transform: translateY(-50%);
		    top: calc(50% + 2px);	
		}
	}

	.citation-block{
		p{
			padding-left:14%;
		}
		.author{
			padding-left: 14%;
			display:block;
		}

		
		@media #{$mobile}{
			padding:40px;
			padding-top:0;

			p{
				padding-left:0%;
			}

			.author{
				position: relative;

			}
		}

		.quote-icon{
			left:0px;
		}
		
		.author:before{
			left:0px;

			@media #{$mobile}{
				left:-40px;
			}
		}
	}
}