/* ========================*/
/* ==[ Single Employe ]== */
/*========================*/

.single-employe{
	lost-column:1/4 4 20px;
	position:relative;
	margin-bottom:90px;
	body:not(.no-perspective) &{
		perspective: 600px;	
	}
	

	@media #{$mobile}{
		lost-column:1/1 1 0px;
		perspective: 0px;
		margin-bottom:40px;
	}
	
	*{
		pointer-events: none;
	}

	.hover-wrapper{
		transform-style: preserve-3d;
	}

	.desktop &:hover{
		img.hover{
			opacity:1;
		}		
	}

	img{
		width:100%;
		margin-bottom:25px;
		transform: translateZ( 0px );

		&.hover{
			position:absolute;
			top:0;
			left:0;
			opacity:0;
			transition:opacity .3s ease;
		}
	}

	h4,p{
		@include subTitle;
	}
	
	h4{
		color:$c-gray-text;
		font-size:13px;
		margin-bottom:5px;
	}

	p{
		color:$c-gray-light-text;
		font-size:11px;
	}

	.hover-wrapper .wrapper{
		transform: translateZ( 0px );
	}

	.initials{
		position: absolute;
		top:30px;
		left:-15px;
		body:not(.no-perspective) &{
			transform: translateZ( 60px );
		}

		span{
			color:$c-main;
			@include MainExtraBold;
			text-transform: uppercase;
			font-size:70px;

			@media #{$tablet}{
				font-size:60px;
			}

			@media #{$mobile}{
				font-size:80px;
			}

			&:nth-of-type(2){
				position:relative;
				top:20px;
				left:-20px;
			}
		}
	}
	
	.wrapper-extra{
		height:150px;
		position:relative;
		@media #{$mobile}{
			height:120px;
		}
	}

	.contact-info{
		position:absolute;
		bottom:0;
		left:0;
		margin-top:20px;
		line-height:1.5em;
		@include MainBook;
		font-weight: 600;
		color:$c-gray-text;
		font-size:16px;
	}
}