@mixin underlineHover($distance,$destination,$height, $color : #fff){
	position:relative;
	
	&:before{
		content:'';
		position:absolute;
		width:100%;
		height:$height;
		background-color:$color;
		left:0;
		bottom:0;
		opacity:0;
		transform:translateY($destination) scaleX(0);
		transition:transform .3s ease-out,opacity .3s ease-out;
		transform-origin:left center;
	}

	.desktop &:hover,&.active{
		&:before{
			opacity:1;
			transform:translateY($destination) scale(1);
		}
	}
}