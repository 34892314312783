/* ========================*/
/* ==[ Filter Block ]== */
/*========================*/

.filter-block{
	z-index: 2;
	position: relative;

	.filter-block__header{
		padding-top:30px;
		padding-bottom:30px;
		background-color:$c-main;
		cursor:pointer;

		@media #{$mobile}{
			padding-top:20px;
			padding-bottom:20px;
		}

		.desktop &:hover{
			.label{
				color:#fff;
			}

			.icon-cross{
				transform:rotate(90deg);
			}

			.icon-cross span{
				background-color:#fff;
			}
		}

		.container-inner{
			display: flex;
			justify-content: space-between;
			align-items: center;	
		}
		
		.label{
			@include MainBold;
			text-align:Center;
			text-transform: uppercase;
			font-size:13px;
			color:$c-gray-text;
			letter-spacing: 1px;
			transition:color .3s ease;
		}
	}

	&.active{
		.icon-cross{
			transform:rotate(90deg);
		}

		.icon-cross span:first-of-type{
			opacity:0;
		}
	}

	.container-list{
		background-color:$c-main;
		lost-utility:clearfix;
		text-align: left;
		width:100%;
		transform-origin:top center;
		transition: transform .4s ease,height .4s ease;
		overflow: hidden;
		
		.container-inner{
			display: block;
		}

		li{
			lost-utility:clearfix;

			@media #{$mobile}{
				text-align: center;
			}
		}

		a{
			lost-column:1/4 4 20px;
			text-align: left;
			@include subTitle;
			color:$c-gray-text;
			display: inline-block;
			position: relative;
			opacity:0;
			transform:translateY(40px);
			margin-bottom:20px;
			top:7px;

			@media #{$mobile}{
				lost-column:1/1 1 0;
				margin-bottom:10px;
				text-align: center;
				float:none !important;
			}

			span{
				display:inline-block;
				position:relative;
				line-height: 1em;
				transition:color .3s ease;
			}

			&.active{
				span:before{
					content:'';
					position:absolute;
					border:2px solid $c-gray-text;
					width:calc(100% + 10px);
					height:calc(100% + 11px);
					left:-7px;
					top:-8px;
					transition: .3s ease border-color;
				}
			}
		}
	}
	
	.col{
		lost-column:1/4 4 20px;

		@media #{$mobile}{
			lost-column:1/1 1 0px;
			margin-bottom:40px;
			text-align: center;
		}

		.title{
			font-size:20px;
			@include MainBold;
			text-transform: uppercase;
			color:$c-gray-text;
			margin-bottom:20px;
		}
		
		li{
			margin-bottom:10px;
			position:relative;
			display: block;
			width:100%;

			a{
				position:relative;
				width: auto;
				line-height: 1em;
				transition:color .3s ease;
			}

			&.active{
				a:before{
					content:'';
					position:absolute;
					border:2px solid $c-gray-text;
					width:calc(100% + 10px);
					height:calc(100% + 11px);
					left:-7px;
					top:-8px;
				}
			}

			.desktop &:hover a{
				color:#fff;
			}
		}
	}
}