.page-projects{
	background-color:$c-gray-bg;

	.list-projects{
		lost-utility:clearfix;
		margin-top: 60px;

		@media #{$mobile}{
			z-index: 5;
			position: relative;
		}
		
		.preview-block{
			lost-column:1/2 2 80px;
			margin-bottom:120px;

			@media #{$mobile}{
				lost-column:1/1 1 0px;	
				margin-bottom:35px;
			}
		}
	}
	
	.v-line-projects{
		width:2px;
		height:50px;
		display: block;
		margin:auto;
		background-color:#000;
		opacity:.2;
		transform-origin: top center;
	}

	.hero-filtered{
		background-color:#fff;
		padding:0 13vw;
		padding-top:200px;

		@media #{$mediumDesktop}{
			padding:0 9vw;		
			padding-top:170px;
		}

		@media #{$smallDesktop}{
			padding:0 7vw;		
			padding-top:170px;
		}

		@media #{$tablet}{
			padding:0 40px;		
			padding-top:170px;
		}

		@media #{$mobile}{
			padding:0 20px;		
			padding-top:110px;	
		}

		.wrapper{
			width: 100%;
			max-width:1600px;
			position: relative;

			@media #{$mobile}{
				text-align:center;
			}
		}

		h4{
			@include subTitle;
			margin-bottom:30px;
		}

		h1{
			color:$c-gray-text;
			text-transform: uppercase;
			@include MainBold;
			font-size:65px;
			margin-bottom:20px;

			@media #{$mobile}{
				font-size:40px;
			}
		}

		p{
			@include mainText;
			padding-bottom:80px;
			
			@media #{$mobile}{
				padding-bottom:50px;
			}
		}
	}

	.btn{
		margin-bottom:60px !important;
	}

	.next-project{
		margin:auto;
		margin-bottom:120px;
		width:50%;
		text-align:center;

		@media #{$mobile}{
			width:90%;
			margin-bottom:60px;
		}

		h5{
			@include subTitle;
			font-size:11px;
			margin-bottom:30px;
		}

		h4{
			@include MainBold;
			color:$c-gray-text;
			font-size:22px;
			margin-bottom:30px;	
		}

		.btn{
			margin:auto;
		}

		.btn-flat{
			.label{
				width:15px;

				svg{
					position: relative;
	    			top: 1px;
				}
			}

			.label svg *{
				fill:$c-main;
				transition:.5s ease fill;
			}

			.desktop &:hover{
				.label svg *{
					fill:$c-gray-text;
				}
			}
		}
	}

}