/* ========================*/
/* ==[ Forms ]== */
/*========================*/

.gform_wrapper{
	& form > .validation_error{
		@include subTitle;
		color:#c63630;
		margin-bottom:20px;
		font-size:13px;
	}

	.gform_heading{
		display:none;
	}

	.half{
		width:48%;
		display:inline-block;
		
		@media #{$mobile}{
			width:100%;
		}

		input,select{
			width:100%;
		}
	}

	.first{
		margin-right:4%;

		@media #{$mobile}{
			margin-right:0;
		}
	}

	.gfield_label{
		@include subTitle;
		color:$c-gray-text;
		margin-bottom:10px;
		display: block;
		@media #{$mobile}{
			font-size:9px;
		}
	}

	.gfield{
		margin-bottom:30px;
	}

	input[type="text"],select{
		background-color: $c-gray-bg;
		border:none;
		font-size: 13px;
		color:$c-gray-text;
		padding:20px 20px;
		@include MainBold;
		letter-spacing: 1px;
	}

	.full {
		textarea,input,select{
			width:100%;
		}
	}

	textarea{
		background-color:$c-gray-bg;
		border:none;
		@include mainText;
		line-height:1.2em;
		padding:20px;
	}

	.ginput_recaptcha{
		&>div{
			margin-left:auto;
		}
	}

	.ginput_counter{
		@include subTitle;
		font-size:10px;
		color:$c-gray-text;
	}
	
	.recaptcha-wrapper{
		.gfield_label{
			display:none;
		}
	}

	.validation_message{
		display:none;
	}

	.gfield_error{
		label{
			color:#c63630;
		}

		input,select,textarea,.chosen-container,.chosen-single span{
			background-color:#f7e1e0;
		}
	}
}


// Exception

#gform_wrapper_8 {
	margin-top:40px;
}

#gform_wrapper_7 .gform_footer,
#gform_wrapper_8 .gform_footer,
#gform_wrapper_5 .gform_footer,
#gform_wrapper_9 .gform_footer,{
	text-align:right;

	input[type="submit"]{
		background-color:$c-main;
		border:none;
		padding:20px 30px;
		@include MainBold;
		text-transform: uppercase;
		font-size:13px;
		letter-spacing: 1px;
		transition: background-color .3s ease, color .3s ease;
		line-height:1em;

		.desktop &:hover{
			background-color:$c-gray-text;
			color:$c-main;
		}
	}
}

#gform_wrapper_5.gform_wrapper .gform_heading{
	display:block;
	text-align: center;

	h3{
		@include subTitle;
		margin-bottom: 30px;
	}

	.gform_description{
		margin-bottom:120px;
		display: block;
		font-size:40px;
		@include MainBold;
		color:$c-gray-text;
		position:relative;

		@media #{$mobile}{
			font-size:28px;
		}

		&:before{
			content:'';
			position:absolute;
			transform:translateY(-50%);
			left:50%;
			width:2px;
			height:50px;
			background-color:#ccc;
			bottom:-100px;
		}
	}

	.v-line{
		width:2px;
		height:50px;
		display: block;
		margin:auto;
		background-color:#000;
		opacity:.2;
	}
}

.fr .gform_footer{
	position:relative;

	&:before{
		content:'*Champ(s) requis';
		@include subTitle;
		position:absolute;
		font-weight: bold;
		width:150px;
		left:0;
		text-align: left;
		color:$c-gray-text;
		font-size:12px;
		text-transform: initial;
	}
}

.en .gform_footer{
	position:relative;

	&:before{
		content:'*Field(s) required';
		@include subTitle;
		position:absolute;
		font-weight: bold;
		width:150px;
		left:0;
		text-align: left;
		color:$c-gray-text;
		font-size:12px;
		text-transform: initial;
	}
}

.gfield_captcha_container{
	display: flex;
	overflow:hidden;

	.gfield_captcha{
	    position: absolute;
    	right: 0;
	}

	input{
		height:42px;
		order:1;
	}
	img{
		order:2;
	}
}
