/* ========================*/
/* ==[ Support ]== */
/*========================*/
/* [HER] ==== Hero
/* ========================*/

/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-support{
	background-color:$c-gray-bg;

	.hero-support{
		padding-top:170px;
		background-color:$c-gray-bg;
		text-align:center;

		@media #{$mobile}{
			padding-top:110px;
		}

		h4{
			@include subTitle;
			margin-bottom:20px;
		}

		h1{
			@include mainTextBig;
			font-size:$fs-title-huge;
			text-transform: uppercase;
			padding-bottom:25px;

			@media #{$mobile}{
				font-size:35px;
			}
		}

		.v-line{
			width:2px;
			height:50px;
			display: block;
			margin:auto;
			background-color:#000;
			opacity:.2;
			margin-bottom:50px;
		}
	}
}

.page-support .collapse-block{
	padding-bottom:100px;
	background-color:$c-gray-bg;

	@media #{$mobile}{
		padding-bottom:50px;
	}

	& > .wrapper{
		background-color:#fff;
	}
	
	.collapse-block__header{
		padding:100px 70px;
		border-bottom:1px solid rgba(0,0,0,.12);

		@media #{$mobile}{
			padding:50px 20px;
		}

		h2{
			@include MainExtraBold;
			text-transform: uppercase;
			font-size: $fs-text-bigger;
			color:$c-gray-dark;
			margin-bottom:20px;

			@media #{$mobile}{
				font-size:30px;
			}
		}

		p{
			@include subTitle;
			letter-spacing: 1px;
		}
	}

	.collapse-block__content a{
		color:#fff;
		transition:color .3s ease;

		&:hover{
			color:#000;
		}
	}

	.collapse-block__single{
		border-bottom:1px solid rgba(0,0,0,.12);
		transition:background-color .3s ease;
		
		position: relative;
		overflow:hidden;
		
		&.active{
			background-color:$c-main;
			
			.icon-plus{
				transform:rotate(90deg);
			}

			.icon-plus .line:nth-of-type(1){
				opacity: 0;
			}	
		}

		.desktop &:hover{
			.icon-plus{
				transform:rotate(90deg);
			}
		}

		.wrapper-single{
			display:flex;
			justify-content: space-between;
			align-items:center;
			cursor: pointer;
		}

		header{
			padding:60px 70px;
			position:relative;

			@media #{$mobile}{
				padding:30px 20px;
			}
		}

		.content{
			padding:0px 70px 60px;

			@media #{$mobile}{
				padding:0px 20px 20px;
			}
		}

		p{
			@include mainText;
			color:$c-gray-text;
			margin-bottom:30px;

			&:last-of-type{
				margin-bottom:0px;
			}
		}


		h4{
			@include subTitle;
			color:$c-gray-text;
			font-size:13px;
			margin-bottom: 20px;
		}


		.desktop &:hover{
			background-color:$c-main;
		}

		h3{
			@include MainExtraBold;
			font-size:18px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color:$c-gray-dark;

			@media #{$mobile}{
				font-size:15px;
			}
		}

		.icon-plus{
			transition:transform .3s ease;
			@media #{$mobile}{
			    position: relative;
    			right: -5px;
			}
		}

		.icon-plus .wrapper{
			height:20px;
			width:20px;
			position:relative;

			.line{
				transform-origin:center center;
				position:absolute;
				transform:translate(-50%,-50%);
				left:50%;
				top:50%;
				background-color:$c-gray-text;
				width:100%;
				height:2px;
				transition:opacity .3s ease;

				&:nth-of-type(2){
					transform:translate(-50%,-50%) rotate(90deg);
				}
			}
		}
	}
}