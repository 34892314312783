/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-product-single{
	background-color:$c-gray-bg;
}

/* -----------------------*/
/* [STA] ==== Stats Block
/*------------------------*/
.page-product-single .stats-block{
	background-color:$c-gray-bg;
	margin-top:0;
	margin-bottom:20px;
	
	.container-inner{
		background-color:$c-gray-bg;
	}
}

/* -----------------------*/
/* [IGB] ==== Image Grid Block
/*------------------------*/
.page-product-single .imagegrid-block{
	background-color:$c-gray-bg;
}