/* ========================*/
/* ==[ Main ]== */
/*========================*/
/* [CON] ==== Containers
/* ========================*/

/* -----------------------*/
/* [CON] ==== Containers
/*------------------------*/
.container-main{
	width:100%;
	padding:0 13vw;

	@media #{$mediumDesktop}{
		padding:0 12vw;		
	}
	@media #{$smallDesktop}{
		padding:0 7vw;		
	}
	@media #{$tablet}{
		padding:0 20px;		
	}
}

.container-inner{
	max-width:1600px;
	margin:auto;
}

.container-medium{
	width:100%;
	max-width:1250px;
	margin:auto;
}

.container-small{
	width:100%;
	max-width:890px;
	margin:auto;
}

.barba-container{
	// overflow:hidden;
	width:100%;
}

html,body{
	// position: absolute;
 //    top: 0;
 //    left: 0;
    width: 100%;
    // height: 100%;
    // margin: 0;
    // padding: 0;
    overflow-x:hidden;
    height:auto;
}

body{
	position:fixed;

}


.scroll-wrapper{
	// height: 100vh; 
    // overflow: hidden;
    width:100vw;
}

#page{
	width:100vw;
	pointer-events:auto;
	@keyframes repaint {
	  from {
	    width: 99.999%
	  }
	  to {
	    width: 100%
	  }
	}

	.repaint {
	  animation: repaint 5000ms;
	}
}

.will-change{
	will-change:opacity, transform;
}


