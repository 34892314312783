/* ========================*/
/* ==[ Home ]== */
/*========================*/
/* [HER] ==== Hero
/* [SIN] ==== Single News Preview
/* [NEW] ==== News List
/* ========================*/

/* -----------------------*/
/* [HER] ==== Hero
/*------------------------*/
.page-news-archives{
	background-color:$c-gray-bg;
	
	.v-line-news{
		width:2px;
		height:50px;
		display: block;
		margin:auto;
		background-color:#000;
		opacity:.2;
	}
}

/* -----------------------*/
/* [SIN] ==== Single News Preview
/*------------------------*/
.page-news-archives .single-news-preview{
	display: block;
	width:100%;	
	position:relative;
	margin:50px 0;
	padding-bottom:50px;
	font-size:0;
	body:not(.no-perspective) &{
    	perspective: 1000px;
	}
	
    .desktop &:hover{
    	.h-line{
			transform:translateX(50px);
    	}
    }

	.single-news-preview-wrapper{
		lost-utility:clearfix;	
		transform-style: preserve-3d;
		
	}

	&:not(:last-of-type):before{
		content:'';
		position: absolute;
		width:100%;
		height:2px;
		bottom:0;
		background-color:$c-gray-text;
		opacity:.2;
	}

	.left{
		height:340px;
		width:calc(50% - 10px);
		display: inline-block;
		background-size:cover;
		background-position:center center;
		float:left;
		pointer-events: none;
		transform: translateZ(0px);

		@media #{$mobile}{
			width:100%;
			height:250px;
		}
	}

	.right{
		height:340px;
		width:calc(50% - 10px);
		display: inline-block;
		float:right;
		display:flex;
		flex-direction: column;
		justify-content: center;
		pointer-events: none;
		body:not(.no-perspective) &{
			transform: translateZ(60px);
		}

		@media #{$mobile}{
			width:100%;
			transform: none;
			height:auto;
			padding-left:20px;
			position: relative;
		}
	}

	.h-line{
		position:relative;
		left:-50px;
		width:120px;
		height:2px;
		background-color:#000;
		pointer-events: none;
		transition:transform .4s ease;

		@media #{$mobile}{
			width:2px;
			height:100%;
			position: absolute;
			lefT:8px;
			bottom:5px;

		}
	}

	.sub-title{
		margin:18px 0 10px;
		@include subTitle;
	}

	.title{
		@include MainBold;
		font-size:20px;
		color:$c-gray-text;
	}
}

/* -----------------------*/
/* [NEW] ==== News List
/*------------------------*/
.news-list{
	z-index: 5;
	position: relative;

	@media #{$mobile}{
		padding:0 20px;
	}
}

/* -----------------------*/
/* [PAG] ==== Pagination
/*------------------------*/
.page-news-archives .pagination{
	padding:45px 0;
	text-align:center;
	position:relative;
	margin-bottom: 100px;

	.screen-reader-text{
		display:none;
	}

	&:before,&:after{
		content:'';
		height:2px;
		background-color:$c-gray-text;
		opacity:.2;
		width:100%;
		left:0;
		position:absolute;
	}

	&:before{
		top:0;
	}

	&:after{
		bottom:0;
	}

	.page-numbers{
		width:28px;
		height:28px;
		display: inline-flex;
		margin:0 5px;
		border-radius:50%;
		transition: background-color .4s ease;
		justify-content: center;
		align-items:center;
		margin:auto;
		font-size:13px;
		color:$c-gray-light-text;
		@include MainBold;
		transition: color .4s ease,background-color .4s ease;
		line-height:1em;
		
		&.next,&.prev{
			display:none;
		}

		.desktop &:hover,&.current{
			background-color:$c-main;
			color:#fff;
		}
	}
}