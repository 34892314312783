/* ========================*/
/* ==[ Personna ]== */
/*========================*/
/* [GEN] ==== General
/* [STA] ==== Stats block
/* [IGB] ==== Image grid block
/* [TLB] ==== Title list block
/* ========================*/

/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-segment{
	.hero-full .wrapper h1{
		@media #{$mobile}{
			font-size:30px;
		}
	}

	.segment-info{
		background-color:$c-gray-bg;
		position:relative;
		z-index: 5;

		.wrapper{
			lost-utility:clearfix;

			h4{
				@include subTitle;
				padding-bottom:50px;

				@media #{$mobile}{
					padding-bottom:30px;
				}
			}
		}

		.left,.right{
			lost-column:1/2 2 0;

			@media #{$mobile}{
				lost-column:1/1 1 0;
			}
		}

		.left{
			position: relative;
			margin-top:-100px;
		    
		    @media #{$mobile}{
		    	margin-top:-60px;
		    }

			.content{
				padding:40px 10% 80px 10%;
				background-color:#fff;

				@media #{$mobile}{
					padding:25px 20px 30px 20px;
				}
			}

			.header-description{
				@include mainTextBig;
				line-height:1.5em;
				font-size: 28px;
			}
		}

		.right{
			background-color:$c-gray-dark;

			.yellow-text-block{
				padding:60px 10%;	

				@media #{$mobile}{
					padding:30px 20px;
				}

				p{
					font-size:$fs-text;
					@include MainBold;
					line-height:2em;
					color:#c0c0c0;
				}
			}
		}
	}
}

/* -----------------------*/
/* [STA] ==== Stats block
/*------------------------*/
.page-segment{
	.stats-block{
		top:0;
		padding-top:60px;
		background-color:$c-gray-bg;

		@media #{$mobile}{
			padding-top:30px;
		}

		.container-inner{
			background-color:$c-gray-bg;	
		}


	}
}

/* -----------------------*/
/* [IGB] ==== Image grid block
/*------------------------*/
.page-segment .imagegrid-block{
	background-color:$c-gray-bg;
	padding-top: 70px;
	padding-bottom: 70px;

	@media #{$mobile}{
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.slider-center-imagegrid{
		margin-top: 60px;
	}
}

/* -----------------------*/
/* [TLB] ==== Title list block
/*------------------------*/
.page-segment .title-list-block{
	background-color:$c-gray-bg;

	&.container-main{
		padding-top:80px;
		padding-bottom:60px;

		@media #{$mobile}{
			padding-top:50px;
			padding-bottom:50px;
		}
	}

	.header{
		text-align:center;
		padding-bottom:50px;

		h3{
			@include sectionTitle;	
			margin-bottom:20px;
		}

		p{
			max-width: 700px;
			margin:auto;
			margin-bottom:40px;
			@include mainText;

			@media #{$mobile}{
				margin-bottom:0px;
			}
		}
	}

	.list{
		lost-utility:clearfix;
		margin-bottom:20px;

		.preview-block{
			lost-column:1/2 2 80px;
			margin-bottom:80px;
			
			@media #{$mobile}{
				lost-column:1/1 1 0px;
				margin-bottom:40px;
			}
		}
	}
		
	.wrapper{
		text-align: center;
	}

	.view-more{
		text-align: center;
		@include MainBold;
		font-size:11px;
		color:$c-gray-text;
		margin:auto;
		display: inline-block;
		text-transform: uppercase;
		letter-spacing: 1px;
		position: relative;
		@include underlineHover(10px,1px,1px,$c-gray-text);
	}
}
