/* ========================*/
/* ==[ Movie slide ]== */
/*========================*/

.movie-slide{
	padding-top:100px;
	padding-bottom:0px;

	@media #{$mobile}{
		padding-top:50px;
		padding-bottom:40px;		
	}
	
	h3{
		@include sectionTitle;
		margin:auto;
		display:block;
		text-align:center;
		padding-bottom:60px;

		@media #{$mobile}{
			padding-bottom:30px;
		}
	}

	.perspective-wrapper{
		body:not(.no-perspective) &{
			perspective: 1000px;
			transform-style: preserve-3d;
		}
    		
	}

	.slider-wrapper{
		body:not(.no-perspective) &{
			transform-style: preserve-3d;		
		}
	}
    
	.arrows{
		width:100%;
		text-align:center;
		margin-top: 50px;
		font-size:0;

		.left,.right{
			height:40px;
			width:40px;
			position:relative;
			border:2px solid $c-main;
			display:inline-block;
			transition:background-color .3s ease;
			cursor:pointer;



			.desktop &:hover{
				background-color:$c-main;
				svg{
					*{
						fill:#000;
					}
				}
			}

			svg{
				position:absolute;
				transform:translate(-50%,-50%);
				top:50%;
				left:50%;
				width:20px;

				*{
					fill:$c-main;
					transition: fill .3s ease;
				}
			}
		}
		.left{
			svg{
				transform-origin:center center;
				transform: translate(-50%,-50%) rotate(180deg);
			}
			border-right:none;
		}
	}

	.swiper-slide{
		height:auto;
		width:280px;
		margin-left:30px;
		position:relative;
		body:not(.no-perspective) &{
			transform-style: preserve-3d;	
		}

		@media #{$mobile}{
			height: auto;
			width:90%;
			margin-left:0px;
			opacity:.3;
			transition:opacity .3s ease;

			&.swiper-slide-active{
				opacity:1;
			}

			img{
				width:100%;
			}
		}


		img{
			height:100%;
			
			@media #{$mobile}{
				height:auto;
			}
		}

		h4{
			color:$c-gray-text;
			font-size:20px;
			@include MainBold;
			text-transform: uppercase;
			margin-top:20px;
			position:relative;
			left:40px;
			body:not(.no-perspective) &{
				transform:translateZ(50px);
			}

			&:before{
				content:'';
				bottom:5px;
				position:absolute;
				left:-20px;
				width:2px;
				height:80px;
				background-color: $c-gray-text;
			}
		}
	}
}