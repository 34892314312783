/* ========================*/
/* ==[ Header ]== */
/*========================*/
/* [LOG] ==== Logo
/* [MEN] ==== Menu
/* ========================*/

/* -----------------------*/
/* [LOG] ==== Logo + Menu Btn
/*------------------------*/
.bg-menu{
	background-color:$c-gray-dark;
	width: 100%;
	position: absolute;
	top:0;
	left:200px;
	z-index: 2;
	display: block;
	transform:scaleX(0);
	transform-origin:left top;
	will-change:transform;
	pointer-events:none;
	height:150px;

	@media #{$mobile}{
		left:0;
		height:60px;
	}
}

.main-header{
	position:fixed;
	height:150px;
	top:0;
	z-index: 51;
	pointer-events:none;

	.back-btn{
		width:260px;
		background-color:$c-main;
		padding:10px;
		transform:translateY(150px);
		display:none;
		position: absolute;
		transition: background-color .3s ease,opacity .3s ease;
		pointer-events: auto;
		opacity:1;
		transition-delay: 0.8s;
		.menu-open &{
			opacity:0;
			transition-delay: 0s;
		}

		@media #{$mobile}{
			display:none !important;
		}

		&:hover{
			// background-color: #fff;

			.label{
				color:#fff;

				svg *{
					fill:#fff;
				}
			}
		}

		.label{
			width:100%;
			text-align:center;
			display:inline-block;
			position: relative;
			@include MainBold;
			font-size:11px;
			color:$c-gray-text;
			letter-spacing: 1px;
			transition:color .3s ease;
			text-transform: uppercase;

			svg{
				width: 20px;
				position: absolute;
				left:10px;
				top:0px;
				transform:rotate(180deg);

				*{
					transition:fill .3s ease;
				}
			}
		}
	}

	@media #{$mobile}{
		width:100%;

		.logo-bottom{
			width:100px;
			position: absolute;
			left:15px;
			transform:translateY(-50%);
			top:58%;
			display: block;
		}
	}

	.logo,.menu-btn{
		height:150px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		float:left;
	}

	.logo{
		width:200px;
		background-color: #000;
		z-index: 3;
		position: absolute;
		pointer-events:auto;

		@media #{$mobile}{
			width:calc(100% - 60px);
		}
	}

	.menu-btn{
		width:60px;
		background-color: $c-gray-dark;
		z-index: 3;
		position: absolute;
		left:200px;
		pointer-events:auto;
		cursor:pointer;

		@media #{$mobile}{
			height:60px;
			left:auto;
			right:0;
		}

		.desktop &:hover{
			.line{
				&:nth-of-type(1){
					transform:translateY(-4px);
				}

				&:nth-of-type(2){
					transition-delay:.2s;
					transform: translateY(-50%) rotate(180deg);
				}

				&:nth-of-type(3){
					transform:translateY(4px);
				}
			}
		}

		.menu-open &{
			.line{
				&:nth-of-type(1){
					transition-delay:.3s;
					transform:translate(4px,2px) rotate(45deg) !important;
				}

				&:nth-of-type(2){
					transition-delay:.2s;
					transform: translateY(calc(-8px))  rotate(45deg) !important;
					opacity:0;
				}

				&:nth-of-type(3){
					transition-delay:.3s;
					transform:translate(-3px,-16px) rotate(-45deg) !important;
				}
			}
		}

		.desktop.menu-open &:hover{
			.icon-menu{
				opacity:.5;
				transform:rotate(180deg) scale(1.2);
			}
		}
	}

	.icon-menu{
		width:20px;
		height:20px;
		position: relative;
		transition:opacity .3s ease,transform .3s ease;

		.line{
			height:2px;
			background-color: #fff;
			width:100%;
			display: block;
			position: absolute;
			left:0;

			&:nth-of-type(1){
				transform-origin: top left;
				background-color:#fff;
				transition:transform .3s ease;
				top:0;
			}

			&:nth-of-type(2){
				transform-origin: center center;
				background-color:#fff;
				transform:translateY(-50%);
				top:50%;
				opacity:1;
				transition:transform .3s ease,opacity .3s ease;
				transition-delay:.0s;
			}

			&:nth-of-type(3){
				transform-origin: top right;
				background-color:#fff;
				bottom:0;
				transition:transform .3s ease;
			}
		}
	}
}

/* -----------------------*/
/* [MEN] ==== Menu
/*------------------------*/
.overlay-menu{
	position: fixed;
	width:100%;
	height:100vh;
	z-index: 50;
	pointer-events:none;
	top:0;

	&:before{
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		opacity:0;
		background-color: #1f1f1f;
		transition:opacity .2s ease;
	}
	&.bg-active:before {
		opacity:1;
	}

}
.main-header{
    width: 100%;
}
.menu{
	pointer-events:none;
	min-height:680px;
	position: relative;
	z-index: 2;

	@media #{$mobile}{
		display:flex;
		flex-direction: column;
		overflow-y:scroll;
		height:100vh;
		min-height: 0;
	}

	.left,.right{
		position: absolute;
		top:0;
	}

	.left{
		width:200px;
		height:100vh;
		background-color:#000;
		transform-origin: top center;
		transform:scaleY(0);
		padding-top:22vh;
		padding-bottom:10vh;

		@media screen and ( max-height: 730px ){
			.desktop &{
				padding-top:26vh;
			}
		}

		@media #{$mobile}{
			position:relative;
			order:2;
			width:100%;
			height:auto;
			padding-top:40px;
			padding-bottom:60px;
		}

		ul{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items:center;
			height:100%;
			text-align:center;

			@media #{$mobile}{
				display:block;
				height:auto;
			}

			li{
				opacity:0;
				transform:translateX(-60px);

				@media #{$mobile}{
					lost-column:1/2 2 10px;
					margin-bottom:40px;
				}

				.desktop &:hover,
				&.active{
					img{
						opacity:0;
					}
					img.hover{
						opacity: 1;
					}
					.label:before{
						opacity:1;
					}
				}

				// .desktop &:hover .label,
				// &.active .label{
				// 	color:$c-main;
				// }
			}

			.circle-icon{
				width:100px;
				height:100px;
				display: block;
				border:2px solid $c-main;
				border-radius:50%;
				margin:auto;
				margin-bottom:10px;
			}

			img{
				width:100px;
				height:100px;
				display: block;
				margin:auto;
				opacity:1;
				transition:opacity .3s ease;
				&.hover{
					position:absolute;
					opacity:0;
					top:0;
					left:50%;
					transform:translateX(-50%);
				}

				@media screen and ( max-height: 730px ){
					.desktop &{
						width:70px;
						height:70px;
					}
				}
			}

			.label{
				text-transform: uppercase;
				color:#fff;
				@include MainBold;
				font-size:11px;
				transition: color .3s ease;
				display: block;
				margin-top:10px;
				@media #{$mobile}{
					font-size:10px;
				}
			}
		}
	}

	.right{
		left:200px;
		width:calc(100vw - 200px);
		height:100vh;
		transform-origin: top left;
		position: relative;
		padding-top:20vh;
		padding-bottom:10vh;
		padding-left:8vw;

		@media screen and ( max-height: 730px ){
			.desktop &{
				padding-top:15vh;

				.main-links a{
					font-size:50px;
				}
			}
		}

		@media #{$tablet}{
			padding-top:15vh;
		}

		@media #{$mobile}{
			left:0;
			width:100%;
			padding-left:20px;
			padding-top:17vh;
			transform-origin: top right;
			order:1;
			padding-bottom: 100px;
			max-height:600px;
			height:auto;
		}

		.main-links{
			a{
				@include MainExtraBold;
				font-size:$fs-title-big;
				color:#fff;
				display: inline-block;
				text-transform: uppercase;
				margin-bottom:20px;
				opacity:0;
				transform:translateX(-60px);
				position:relative;
				transition: color .5s ease;
				@include underlineHover(0px,-10px,3px,$c-main);

				.desktop &:hover,
				&.active{
					color:$c-main;
				}

				@media #{$mobile}{
					font-size:32px;
					@include underlineHover(0px,-4px,3px,$c-main);
				}
			}
		}

		.other-links{
			position: absolute;
			bottom:10vh;
			transform:translateY(100px);
			opacity:0;
			padding-right: inherit;
			line-height: inherit;

			@media #{$mobile}{
				bottom:60px;
				padding-right: inherit;
				line-height: inherit;
			}

			@media #{$cell} {
				bottom:30px;
				padding-right: 100px;
				line-height: 2em;
			}

			a{
				@include MainBold;
				font-size:$fs-btn;
				color:#fff;
				margin-right:50px;
				text-transform: uppercase;
				letter-spacing: 1px;
				position: relative;
				@include underlineHover(10px,1px,1px,$c-main);

				.desktop &:hover,
				&.active{
					color:$c-main;
				}

				@media #{$mobile}{
					margin-right:20px;
					font-size:11px;
				}
			}
		}

		.language-socials{
			position: absolute;
			right:8vw;
			top:20vh;
			transform:translateX(100px);
			opacity:0;

			@media #{$tablet}{
				top:calc(15vh - 16px);
			}

			@media #{$mobile}{
				top:calc(17vh - 16px);
				right:20px;
			}

			a,span{
				display: block;
				margin:30px 0;
				text-align:center;
				color:#fff;
				@include MainExtraBold;
				font-size:$fs-btn;
				letter-spacing: 1px;
				transition : color .3s ease;

				@media #{$mobile}{
					margin:20px 0;
				}

				svg * {
					transition:fill .3s ease;
				}

				.desktop &:hover{
					color:$c-main;
					svg * {
						fill:$c-main;
					}
				}
			}

			.external-link {
				position: relative;
				svg {
					fill: #fff;
					opacity: 0;
					width: 12px;
					height: 12px;
					position: absolute;
					top: 0;
					right: -25px;
					transition: opacity .3s ease;

					@media #{$mobile} {
						position: relative;
						opacity: 1;
						top: 1px;
						right: auto;
						margin-left: 5px;
					}
				}

				.desktop &:hover svg {
					opacity: 1;
				}
			}

			.v-line{
				height:120px;
				width:2px;
				background-color:#fff;
				opacity: .2;
				margin: auto;

				@media #{$mobile}{
					height:96px;
				}
			}
		}
	}
}

// Sticky btn container for projects/products page
.sticky-btn-container{
	position:fixed;
	right:0;
	bottom:10vh;
	z-index: 11;
	text-align:right;
	display:none;
	pointer-events:none;

	.btn-download,.btn-shares{
		background-color:$c-main;
		pointer-events:auto;
	}

	.btn-download{
		width:200px;
		height:60px;
		display: inline-flex;
		align-items:center;
		margin-bottom:5px;
		transform:translate(140px);
		transition:transform .3s ease;

		.fr &{
			width:250px;
			transform:translate(195px);
		}

		&:hover{
			transform:translate(0px);
		}

		span{
			text-transform: uppercase;
			@include MainBold;
			font-size:13px;
			color:$c-gray-text;
			transition: color .3s ease;
			pointer-events: auto;

			.desktop &:hover{
				color:#fff;
			}
		}

		.svg-wrapper{
			width:60px;
			display:flex;
			align-items:center;
			justify-content: center;
		}

		svg{
			width:24px;
			height:24px;
			position: relative;
			top:2px;
		}
	}

	.btn-shares{
		width:205px;
		height:60px;
		text-align:left;
		transform:translateX(145px);
		transition:transform .3s ease;

		.fr &{
			width:200px;
			transform:translate(195px);
		}

		&:hover{
			transform:translateX(50px);
		}

		a,span{
			padding:20px 10px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			pointer-events: auto;
		}

		span{
			padding:20px;
		}

		a svg{
			*{
				transition:fill .3s ease;
			}

			.desktop &:hover{
				*{
					fill:#fff;
				}
			}
		}

		svg{
			width:20px;
			height:20px;
			position: relative;

			*{
				fill:#000;
			}
		}
	}
}
