/* ========================*/
/* ==[ Preview Block ]== */
/*========================*/

.preview-block{
	width:100%;
	position:relative;
	z-index: 30;
	body:not(.no-perspective) &{
		perspective: 600px;	
	}

	.text-wrapper{
		padding-left:35px;
		padding-top:25px;
		position:relative;
		pointer-events:none;
		left:2vw;
		top:-20px;
		z-index: 30;
		@media #{$mobile}{
			width:80%;
		}
		body:not(.no-perspective) &{
			transform: translateZ( 80px );
		}

		body.no-perspective &{
			left:0;
			top:0;
		}

		&:before{
			content:'';
			position:absolute;
			left:20px;
			top:-30px;
			height:calc(100% + 30px);
			width:2px;
			background-color: $c-gray-text;
		}
	}

	.video-wrapper{
		position:absolute;
		z-index: 3;
		width:100%;
		height:100%;

		video{
			object-fit:cover;
			width:100%;
			height:100%;
			transition: opacity .5s ease;
			opacity:0;
		}
	}

	.preview-block__image{
		display: block;
		pointer-events:none;
		transform: translateZ( 0px );
		width: 100%;
	}

	.preview-block__caracter{
		position:absolute;
	    right: 10px;
		bottom:5vh;
		width:42%;
		pointer-events:none;
		z-index: 50;

		body:not(.no-perspective) &{
			transform: translateZ( 150px );	
		}

		body.no-perspective &{
			bottom:0;
			right:0;
			@media #{$mobile}{
				right:-30px;
			}
		}
	}

	.preview-block__logo{
		position: absolute;
		transform:translate3d(-50%,-50%,120px);
		top:50%;
		left:50%;
		width:30%;
	}

	.preview-block__title{
		position: absolute;
		transform:translate3d(-50%,-50%,120px);
		top:50%;
		left:50%;
		font-size:	37px;
		color:#fff;
		text-align:center;
		@media #{$mobile}{
			font-size:	25px;
		}
	}

	.title-wrapper{
		height:310px;
		width:100%;
		position:absolute;
		top:0;
		z-index: 20;	
		body:not(.no-perspective) &{
			transform:translateZ(50px);
		}

		@media #{$mobile}{
			height:155px;
		}
	}

	.img-wrapper{
		position:relative;
		height:310px;
		width:100%;
		background-repeat:no-repeat;
		background-size:cover;
		background-position: center center;
		overflow: hidden;

		@media #{$mobile}{
			height:155px;
		}
	}

	.preview-wrapper{
		transform-style: preserve-3d;
  		pointer-events:none;
	}

	h3{
		@include MainExtraBold;
		font-size:18px;
		color:$c-gray-text;
		margin-bottom:10px;
		text-transform:uppercase;
		letter-spacing: 1px;
		line-height:1em;
		width:70%;
		
		@media #{$mobile}{
			width:80%;
			font-size:15px;
		}
	}

	h4{
		@include MainBold;
		font-size: 10px;
		color:$c-gray-light-text;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}