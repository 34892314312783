/* ========================*/
/* ==[ Fonts ]== */
/*========================*/
/* [FON] ==== Font declaration 
/* [MIX] ==== Mixin
/* ========================*/

/* -----------------------*/
/* [FON] ==== Font declaration 
/*------------------------*/
@font-face {
	font-family: 'ReneExtraBold';
	src: url('./fonts/reneextrabold.woff2') format('woff2'),
			url('./fonts/reneextrabold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ReneBold';
	src: url('./fonts/renebold.woff2') format('woff2'),
			url('./fonts/renebold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ReneBook';
	src: url('./fonts/renebook.woff2') format('woff2'),
			url('./fonts/renebook.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

$ReneExtraBold : 'ReneExtraBold', sans-serif;
$ReneBold : 'ReneBold', sans-serif;
$ReneBook : 'ReneBook', sans-serif;


/* -----------------------*/
/* [MIX] ==== Mixin
/*------------------------*/
@mixin MainExtraBold{
	font-family: $ReneExtraBold;   
}

@mixin MainBold{
	font-family: $ReneBold;   
}

@mixin MainBook{
	font-family: $ReneBook;   
}



