/* ========================*/
/* ==[ Stats block ]== */
/*========================*/

.stats-block{
	position: relative;
	margin-top:-50px;
	lost-utility:clearfix;
	display: flex;
	align-items:center;
	text-align:center;
	justify-content:space-between;
	z-index: 3;
	
	.container-inner{
		padding:40px 0px 40px;
		width:100%;
		background-color:white;

		@media #{$mobile}{
			padding:40px 0px;
		}
	}

	.number{
		@include mainTitle;
		font-size:$fs-title-huge;
		display: block;
		letter-spacing: 2px;
		text-transform: none;
		@media #{$tablet}{
			font-size:45px;
		}
	}

	.label{
		@include MainBold;
		font-size:12px;
		text-transform: uppercase;
		color:$c-gray-text;
		letter-spacing: 0.5px;
        min-height: 2.5em;
    	display: block;
	}
	
	.single-stat{
		lost-column:1/4 4 20px;

		@media #{$mobile}{
			lost-column:1/2 2 0;		
			margin:20px 0;
			padding:0 10px;	
		}
	}

	.header{
		h3{
			margin-bottom:20px;
			@include sectionTitle;
		}
		
		p{
			@include mainText;
			max-width: 700px;
			margin:auto;
			margin-bottom:40px;
		}
	}

	.wrapper{
		display: flex;

		@media #{$mobile}{
			display:block;
		}
		
    	.image{
    		max-width: 200px;
    		max-height:150px;
    		margin:auto;
    	}
	}

	.top-block{
		height:150px;
		display:flex;
		justify-content: center;
		align-items:flex-end;
		@media #{$tablet}{
			height:80px;
		}
	}
}


.page-career{
	margin-top:-100px;
	.stats-block{
		.container-inner{
			padding-top: 0;
			background-color: $c-main;
		}
	}
}