/* ========================*/
/* ==[ Dual Block ]== */
/*========================*/

.dual-block{
	transform:translateZ(0);
	
	.wrapper{
		lost-utility:clearfix;
		padding-bottom:100px;

		@media #{$mobile}{
			padding-bottom:50px;
		}
	}

	.col{
		lost-column:1/2 2 0;
		padding: 60px 4vw;

		@media #{$mobile}{
			lost-column:1/1 1 0;
			padding: 30px 20px;
		}

		&.-left{
			background-color: #fff;
			position: relative;
			top:-90px;
			padding-top:40px;

			@media #{$mobile}{
				padding-top:20px;
				margin-top:-50px;
				top:auto;
			}

			h4{
				@include subTitle;
				margin-bottom:50px;

				@media #{$mobile}{
					margin-bottom:30px;
				}
			}

			p{
				@include mainTextBig;
				line-height: 1.6em;
				letter-spacing: .5px;
				
				@media #{$mobile}{
					line-height: 1.5em;
					font-size: 20px;
				}
			}
		}

		&.-right{
			background-color: $c-gray-dark;

			p{
				@include mainText;
				color:#fff;
			}
			
			span.special{
				font-weight:600;
			}
		}
	}
}