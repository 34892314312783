/* ========================*/
/* ==[ Full Cta Block ]== */
/*========================*/

.full-cta-block{
	height:800px;
	display: flex;
	justify-content:center;
	align-items:center;
	background-size:cover;
	position: relative;
	overflow:hidden;

	@media #{$mobile}{
		height:450px;
	}
	
	h4{
		@include subTitle;
		color:#fff;
		font-size:13px;
		margin-bottom: 20px;

		@media #{$mobile}{
			font-size:11px;
			padding:0 20px;
		}
	}

	h2{
		@include mainTextBig;
		font-size:$fs-title-huge;
		text-transform: uppercase;
		color:#fff;
		margin-bottom:20px;
		line-height:1.1em;
		
		@media  #{$mobile}{
			font-size:40px;
			p{
				padding:0 20px;
			}
		}
	}

	.wrapper{
		text-align:center;
	}

	.btn{
		margin:auto;
	}

	.bg{
		position:absolute;
		top:-10%;
		left:0;
		width:120%;
		height:120%;
		background-size:cover;

	}
}