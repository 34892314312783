/* ========================*/
/* ==[ Hero gray ]== */
/*========================*/

.hero-gray{
	padding-top:170px;
	background-color:$c-gray-bg;
	text-align:center;
	position: relative;
	z-index: 1;
	width:100%;

	@media #{$mobile}{
		padding-top:110px;
		padding:110px 20px 0 20px;
	}

	.bg-svg{
		position: absolute;
		top:0;
		width:100%;
		height:800px;
		z-index: 1;
		pointer-events: none;
		transform: translateX(-50%);
    	left: 50%;
	    min-width: 1700px;

		svg{
			width:100%;
			height:800px;

			*{
				fill:#fff;
			}
		}
	}

	h4{
		@include subTitle;
		margin-bottom:30px;
		z-index: 2;
	}

	.yellow-text-block{
		z-index: 2;
		position: relative;
	}

	p{
		color:$c-gray-text;
		@include MainExtraBold;
		font-size:$fs-text-bigger;
		
		@media #{$mobile}{
			font-size:20px;
		}
	}

	.hero-text{
		max-width:850px;
		margin:auto;
		margin-bottom:45px;
		
	}

	.v-line{
		width:2px;
		height:50px;
		display: block;
		margin:auto;
		background-color:#000;
		opacity:.2;
		z-index: 5;
		position: relative;
		transform-origin: bottom center;
	}
	
	.hero-gray__socials{
		padding-bottom:50px;
		display: flex;
		margin:auto;
		width:100%;
		justify-content: space-between;
		align-items:center;
		z-index: 5;
		position: relative;
		max-width:750px;
		@media #{$tablet}{
			max-width:500px;
		}
		@media #{$mobile}{
			max-width: 230px;
		}

		svg *{
			transition: fill .3s ease;
		}

		a{
			width:20px;

			.desktop &:hover{
				svg *{
					fill:$c-main;
				}
			}

			svg *{
				fill:$c-gray-light-text;
			}
		}
	}
}