/* ========================*/
/* ==[ Image Block Grid ]== */
/*========================*/

.overlay-movie-container,
.imagegrid-block{
	&.no-image-header{
		margin-top:140px;
		@media #{$mobile}{
			margin-top:0px;
		}
	}
	.full-image{
		height:800px;
		background-size:cover;
		width:120%;
		left:-10%;
		position:relative;
		overflow:hidden;
		
		@media #{$mobile}{
			height:200px;
		}

		.btn{
			position:absolute;
			transform:translate(-50%,-50%);
			top:50%;
			left:50%;

			.arrow{
				position: absolute;
			    left: 25px;
			    transform: translateY(-50%);
			    top: calc(50% + 2px);	
			}
		}

		.bg-image{
			position: relative;
			left:-10%;
			top:-10%;
			height:120%;
			width: 120%;
			background-size:cover;
			background-position:center center;
		}
	}

	.video-header{
		iframe{
			width: 100%;
		    height: 100%;
		    pointer-events: none;
		}
	}

	.side-image-wrapper{
		padding-bottom:120px;
		lost-utility:clearfix;

		@media #{$mobile}{
			padding-bottom:60px;
		}

		.img-wrapper{
			overflow: hidden;
			text-align: center;

			img{
				width:120%;
				max-width:none;
				left:50%;
				top:50%;
				position:absolute;
				@media #{$mobile}{
					left:50% !important;
					top:50% !important;
					transform:translate(-50%,-50%) !important;
				}
			}
		}

		.left{
			lost-column:1/2 2 0;
			padding-bottom:calc(78% /2);
			position: relative;
			top:-100px;

			img{
				left:50% ;
				top:50% ;
				transform:translate(-50%,-50%) ;
			}

			@media #{$mobile}{
				lost-column:1/1 1 0;
				top:0px;
				margin-top:50px;
				height:250px;
			}
		}
		
		.right{
			lost-column:7/16 2 0;
			position: relative;
			top:100px;
			// height:400px;
			padding-bottom : calc(76% /2);
			img{
				left:50% ;
				top:50% ;
				transform:translate(-50%,-50%) ;
			}

			@media #{$mobile}{
				lost-column:1/1 1 0;
				top:0px;
				margin-top:-40px;
				height:180px;

				img{
					width:calc(100% - 40px);
					left:-20px;
				}
			}
		}
	}

	.slider-image-wrapper{
		text-align:center;
		padding-bottom:140px;

		@media #{$mobile}{
			padding-bottom:50px;
		}
	}

	div[class^='swiper-container-imagegrid']{
		overflow:hidden;	
	}

	.slider-center-imagegrid,.swiper-container-imagegrid-movie{
		margin:auto;
		width:880px;
		height:520px;
		position:relative;
	    margin-bottom: 60px;

	    @media #{$mobile}{
	    	width:100%;
			height:200px;
	    }

	    .swiper-slide{
	    	position:relative;
			overflow:hidden;

			.placeholder{
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				transition:opacity .3s ease;
				transform:translateZ(0);
				pointer-events:none;

				img{
					width:100%;
					height:100%;
					object-fit: cover;
					transform:translateZ(0);
				}
			}

	    	.videos-wrapper,
	    	.video-wrapper{
	    		width:100%;
	    		height:100%;

	    		iframe{
	    			width:100%;
	    			height:100%;
	    			pointer-events:none;
	    		}

	    		a{
	    			position:absolute;
	    			top:50%;
	    			left:50%;
	    			transform:translate(-50%,-50%);
	    			z-index: 20;
	    			transition:opacity .3s ease;

	    			.arrow{
						position: absolute;
					    left: 25px;
					    transform: translateY(-50%);
					    top: calc(50% + 2px);	
					}
	    		}
	    	}

	    	video{
	    		object-fit:cover;
	    		height:520px;
	    		width:880px;

	    		@media #{$mobile}{
			    	width:100%;
					height:200px;
			    }
	    	}
	    }
	
		.image{
			width:880px;
			height:520px;
			background-size:cover;
			background-position:center center;
			background-repeat:no-repeat;

			@media #{$mobile}{
		    	width:100%;
				height:200px;
		    }
		}
	}

	div[class^='swiper-pagination-imagegrid']{
		position: absolute;
		transform:translateX(-50%);
		left:50%;
		bottom:30px;
		z-index: 2;
		
		@media #{$mobile}{
			display:none;
		}

		.swiper-pagination-bullet{
			margin:0 20px;
			opacity:1;
			background-color:#9a9a9a;

			@media #{$mobile}{
		    	margin:0 10px;
		    }

			&.swiper-pagination-bullet-active{
				background-color:$c-main;
				position:relative;

				&:before{
					border:2px solid $c-main;
					border-radius:50%;
					content:'';
					position:absolute;
					transform:translate(-50%,-50%);
					top:50%;
					left:50%;
					width:200%;
					height:200%;
				}
			}
		}
	}

	div[class^='arrow-wrapper-left']{
		span{
			transform:rotate(180deg);
		}

		&:not(.swiper-button-disabled){
			.swiper-button-prev{
				.desktop &:hover{
					transform:translateX(-5px);
					background-color:#fff;

					svg *{
						fill:$c-main;
					}
				}
			}
		}
	}

	div[class^='arrow-wrapper-right']{
		&:not(.swiper-button-disabled){
			.swiper-button-next{
				.desktop &:hover{
					transform:translateX(5px);
					background-color:#fff;

					svg *{
						fill:$c-main;
					}
				}
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next{
		background-image:none;
		background-color:$c-main;
		width:70px;
		height:70px;
		display: flex;
		align-items:center;
		justify-content: center;
		z-index: 5;
		transform:translateZ(0);
		transition:background-color .3s ease,transform .3s ease;
		
		svg *{
			transition: fill .3s ease;
		}

		@media #{$mobile}{
			width:50px;
			height:50px;
		}

	}

	.swiper-button-prev{
		left:-35px;

		@media #{$mobile}{
			left:0px;
		}
	}

	.swiper-button-next{
		right:-35px;
		
		@media #{$mobile}{
			right:0px;
		}
	}
}

.overlay-movie-container{
	.bg-preload{
		display:block !important;
	}
}