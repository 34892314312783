/* ========================*/
/* ==[ Variables ]== */
/*========================*/
/* [COL] ==== Color
/* [FSI] ==== Font size
/* [CON] ==== Config
/* [MED] ==== Media Queries
/* ========================*/

/* -----------------------*/
/* [COL] ==== Color
/*------------------------*/
$c-main : #ffb900;
$c-gray-light-text : #9a9a9a;
$c-gray-bg : #eeeeee;
$c-gray-text : #333333;
$c-gray-dark : #1f1f1f;
$c-gray-light : #666666;


/* -----------------------*/
/* [FSI] ==== Font size
/*------------------------*/
$fs-title-huge : 80px;
$fs-title-big : 65px;
$fs-text : 16px;
$fs-text-big : 25px;
$fs-text-bigger : 33px;
$fs-subtitle : 12px;
$fs-btn : 12px;

$fs-resize-title-big : 55px;


/* -----------------------*/
/* [CON] ==== Config
/*------------------------*/
@lost rounder 100;

/* -----------------------*/
/* [MED] ==== Media Queries
/*------------------------*/
$cell:     "only screen and (max-width:480px)";
$mobile:   "only screen and (max-width:767px)";
$tablet:   "only screen and (max-width:1024px)";
$tabletPortrait:   "only screen and (max-width:900px)";
$smallDesktop : "only screen and (max-width:1300px)";
$mediumDesktop : "only screen and (max-width:1600px)";
$bigDesktop : "only screen and (min-width:2000px)";




