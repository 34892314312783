/* ========================*/
/* ==[ Single info General ]== */
/*========================*/

.single-info-general{
	background-color:$c-gray-bg;
	margin-bottom:40px;

	.wrapper{
		lost-utility:clearfix;

		h4{
			@include subTitle;
			padding-bottom:50px;

			@media #{$mobile}{
				padding-bottom:30px;
			}
		}
	}

	.left,.right{
		lost-column:1/2 2 0;

		@media #{$mobile}{
			lost-column:1/1 1 0;
		}
	}

	.mobile{
		display:none;

		@media #{$mobile}{
			display:block;
		}
	}

	.left{
		position: relative;
		margin-top:-100px;

		@media #{$mobile}{
			margin-top:-60px;
		}
		
		.content{
			padding:40px 10% 80px 10%;
			background-color:#fff;

			@media #{$mobile}{
				padding:25px 20px 30px 20px;
			}
		}

		.header-description{
			@include mainTextBig;
			line-height:1.5em;
		}
		
		.citation-block{
			padding:60px 10% 0;
			 			 
			@media #{$tablet}{
				padding:60px 10% 0 14%;
			}

			@media #{$mobile}{
				padding:40px 14% 0;
			}

			.quote-icon{
				top:60px;

				@media #{$mobile}{
					top:40px;
				}
			}
		}

		&:not(.not-left){
			@media #{$mobile}{
				.citation-block{
					display:none;
				}
			}
		}

		&.not-left{
			margin-top:0;
		}
	}

	.right{
		background-color:$c-gray-dark;

		.yellow-text-block{
			padding:60px 10%;	
			
			@media #{$mobile}{
				padding:30px 20px;
			}

			p{
				font-size:$fs-text;
				@include MainBold;
				line-height:2em;
				color:#c0c0c0;
			}
		}
	}
}
