/* ========================*/
/* ==[ Citation Block ]== */
/*========================*/

.citation-block{
	position:relative;

	.quote-icon{
		height:40px;
		width:40px;
		display: flex;
		align-items:center;
		justify-content:center;
		border:3px solid $c-gray-text;
		position:absolute;
		left:0;

		@media #{$mobile}{
			height:30px;
			width:30px;
		}

		span{
			font-size:30px;
			@include MainBold;
			top:7px;
			position:relative;

			@media #{$mobile}{
				font-size:25px;
			}
		}
	}

	p{
		@include mainTextBig;
		font-size:21px;
		line-height: 2em;
		margin-bottom:35px;

		@media #{$mobile}{
			font-size:15px;
			margin-bottom:20px;
		}
	}

	.author{
		@include subTitle;
		font-size:11px;
		position: relative;

		&:before{
			position: absolute;
			content:'';
			width:40px;
			height:1px;
			background-color:$c-gray-light-text;
			left:-45px;
			top:5px;
			
			@media #{$mobile}{
				width:30px;				
			}
		}
	}
}
