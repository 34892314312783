/* ===========================*/
/* ==[ Slider Techno Product ]== */
/*===========================*/

.slider-techno-product{
	height:680px;
	width: 100%;
	background-color:$c-main;
	position:relative;
	
	@media #{$mobile}{
		height:750px;
	}

	.bg-gradient{
		width:780px;
		height: 780px;
		position: absolute;
		transform:translate(-50%,-50%);
		top:50%;
		left:50%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+66&0.26+0,0+67 */
		background: -moz-radial-gradient(center, ellipse cover,  rgba(217, 58, 46,0.26) 0%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 67%); /* FF3.6-15 */
		background: -webkit-radial-gradient(center, ellipse cover,  rgba(217, 58, 46,0.26) 0%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 67%); /* Chrome10-25,Safari5.1-6 */
		background: radial-gradient(ellipse at center,  rgba(217, 58, 46,0.26) 0%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 67%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		z-index: 1;

		@media #{$mobile}{
			width:480px;
			height: 480px;
		}
	}
	
	h3{
		@include sectionTitle;
		padding-top:70px;
		text-align:center;
		margin-bottom:80px;
		color:#fff;
		position: relative;
		z-index: 20;

		@media #{$mobile}{
			padding-top:40px;
		}
	}

	.swiper-container{
		height:680px;
		position: absolute;
		top:0;
		width:100%;

		.swiper-slide{
			position:relative;
			width:90%;
			text-align:center;
			padding:0 3%;
			max-width:1600px;

			@media #{$mobile}{
				height:auto;
				width: 100%;
				padding-top:30px;
			}
		}

		img{
			width:100%;
			max-width:600px;
		}

		.left,.right{
			lost-column:1/2 2 60px;
			height:100%;

			@media #{$mobile}{
				lost-column:1/1 1 0px;
				height:400px;
				display:block !important;
			}
		}

		.left{
			display:flex;
			align-items:center;
			position: relative;

			@media #{$mobile}{
				height:290px;
			}

			img{
				height:100%;
				max-width: none;
				min-width: 0;
				width:auto;
			    position: absolute;
			    transform: translate(-50%,-50%);
			    top: 50%;
			    left: 50%;
			    z-index: 2;

			    @media #{$mobile}{
		    	    height: 100%;
  					width: auto;
			    }
			}
		}

		.right{
			text-align:left;
			display:flex;
			align-items:center;
			max-width: 500px;
			float:left !important;

			@media #{$mobile}{
				margin-top:40px;
			}

			h4{
				@include MainBold;
				color:#fff;
				text-transform: uppercase;
				font-size:20px;
				margin-bottom:20px;
			}

			p{
				@include mainText;
				margin-bottom:20px;
				color:#fff;
			}

			a{
				@include subTitle;
				color:#fff;
				transition:color .3s ease;

				.desktop &:hover{
					color:#000;

					svg * {
						fill:#000;
					}
				}

				svg{
					margin-left:10px;
				}

				svg *{
					transition:fill .3s ease;
					fill:#fff;
				}
			}
		}
	}

	.swiper-pagination{
		position:absolute;
		margin-top:40px;
		display: inline-block;
		left:calc(50% - 26px);
		transition:none;
		bottom:40px;

		.swiper-pagination-bullet{
			padding:10px;
			display: inline-flex;
			align-items:center;
			justify-content: center;
			margin-right:30px;
			position:relative;
			@include subTitle;
			color:$c-gray-text;
			border:2px solid rgba(0,0,0,0.5);
			opacity:.7;
			background-color:transparent;
			width:52px;
			height:52px;

			span{
				position:absolute;
				transform:translate(-50%,-50%);
				left:50%;
				top:50%;
				width:28px !important;
				height:28px !important;
				display: block;
			}

			img{
				width:28px;
				height:28px;
			}

			&:not(:last-of-type):before{
				content:'';
				width:30px;
				background-color:rgba(0,0,0,0.5);
				height:2px;
				position:absolute;
				transform:translateY(-50%);
				top:50%;
				right:-32px;
				opacity:.7;
			}

			&.swiper-pagination-bullet-active{
				background-color:#fff;
				color:$c-main;
				opacity:1;
				border-color:transparent;

				&:before{
					opacity:.5;
				}
			}
		}
	}
}