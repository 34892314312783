/* ========================*/
/* ==[ Projects Single ]== */
/*========================*/
/* [GEN] ==== General
/* [HRO] ==== Hero
/* [INF] ==== Info Block
/* [IGB] ==== ImageGrid Block
/* [STA] ==== Stats Block
/* [SLI] ==== Slider Block
/* [MAP] ==== Maps Sinple Block
/* ========================*/


/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-project-single{
	background-color:$c-gray-bg;
}

/* -----------------------*/
/* [HRO] ==== Hero
/*------------------------*/
.page-project-single .hero-full{
	h1{
		max-width: 500px;
		@media #{$mobile}{
			margin:auto;
		}
	}	
}

/* -----------------------*/
/* [INF] ==== Info Block
/*------------------------*/
.page-project-single .single-info{
	background-color:$c-gray-bg;
	position: relative;
	
	@media #{$mobile}{
		z-index: 10;	
		margin-bottom:50px;
	}
	
	.bg{
		position:absolute;
		width:120%;
		height:120%;
		background-size:cover;
		background-position:center center;
		left:50%;
		top:50%;
		transform:translateX(-50%);
	}

	.right{
		height:360px;
		overflow: hidden;
	}
	
	.wrapper{
		display: flex;

		@media #{$mobile}{
			display:block;
		}
	}

	.left,.right{
		width:50%;
		display:inline-block;

		@media #{$mobile}{
			width:100%;
		}
	}

	.right{
		align-self:flex-end;
	    top: -90px;
  		position: relative;


  		@media #{$smallDesktop}{
  			width:40%;
  		}

  		@media #{$mobile}{
			display:none;
		}
	}

	.left{
		position:relative;
		top:-90px;
		z-index: 10;
		margin-bottom: -60px;
		@media #{$smallDesktop}{
  			width:60%;
  		}

  		@media #{$mobile}{
			width:100%;
			top:0;
			margin-top:-60px;
		}

		.mobile{
			display:none;

			@media #{$mobile}{
				display:block;
			}			
		}
	}

	.citation-block{
		padding:60px 10% 0;

		@media #{$mobile}{
			padding:60px 14% 0;
		}

		.quote-icon{
			top:60px;
		}
	}
		
	.header-description{
		width:100%;
		margin:auto;
		padding:60px 10%;
		background-color: #FFF;

		@media #{$mobile}{
			padding:30px 20px;
		}
		
		p{
			@include mainText;
		}
	}

	.header-info{
		height:90px;
		width:100%;
		display:flex;
		align-items:center;
		position: relative;
		border-bottom : 1px solid $c-gray-bg;
		padding:0px 10%;
		background-color: #FFF;

		@media #{$mobile}{
			padding:0;
			height:60px;
		}

		ul{
			font-size:0;
			margin:auto;
			text-align:center;
			width:100%;
			display: flex;

			@media #{$mobile}{
				display: block;
			}
		}

		li{
			@include subTitle;
			text-transform:uppercase;
			font-size:11px;
			text-align:center;
			height:100%;
			padding:0 10px;
			height:90px;
			display:inline-flex;
			align-items:center;
			width:25%;
			justify-content: center;
			border-right:1px solid $c-gray-bg;
			
			@media #{$tablet}{			
				justify-content: space-between;
				width:37%;
				&:nth-of-type(3){
					border-right:none;
					span{
						text-align: right;	
					}
					
				}
				&:last-of-type{
					display:none;
				}
			}


			@media #{$mobile}{
				border-right:none;
				width:auto;
				padding:10px;
				width:37%;
				height:60px;
				vertical-align: top;

				&:first-of-type{
					padding-left: 20px !important;
				}
				
				&:nth-of-type(3){
					padding-right: 20px !important;
				}

				&:last-of-type{
					display:none;
					position: absolute;
				}
			}

			span{
				text-align:center;
				width:100%;

				@media #{$tablet}{
					font-size:11px;
					letter-spacing: 0px;
				}
			}

			&:nth-of-type(2){
				border-left:1px solid $c-gray-bg;
				border-right:1px solid $c-gray-bg;
			}			
	
			&:last-of-type{
				border-right:1px solid transparent;
				padding-right:0;
				float:right;

				@media #{$mobile}{
					float:none;
					width:35%;
				}

				span{
					text-align:right;
				}
			}

			&:first-of-type{
				border-right:1px solid transparent;
				padding-left:0;	
				float:left;

				@media #{$mobile}{
					padding:10px;
					float:none;
					width:25%;
				}

				span{
					text-align:left;
				}
			}
		}
	}
}	

/* -----------------------*/
/* [IGB] ==== ImageGrid Block
/*------------------------*/
.page-project-single .imagegrid-block{
	background-color:$c-gray-bg;

	& + .imagegrid-block{
		padding-top:40px;
	}
}

/* -----------------------*/
/* [STA] ==== Stats Block
/*------------------------*/
.page-project-single .stats-block{
	background-color:$c-gray-bg;
	top:0;
	margin-top:0;
	margin-bottom:20px;
	
	.container-inner{
		background-color:$c-gray-bg;
	}
}


/* -----------------------*/
/* [SLI] ==== Slider Block
/*------------------------*/
.page-project-single .slider-techno-product{
	background-color:#860c01;
	color:#fff;
}

/* -----------------------*/
/* [MAP] ==== Maps Sinple Block
/*------------------------*/
.page-project-single .map-simple-block{
	height:640px;
	background-color:$c-gray-text;
	
	@media #{$mobile}{
		height:450px;
	}

	h3{
		@include sectionTitle;	
		color:#fff;
		text-align:center;
		padding-top:100px;
		position: absolute;
		transform:translateX(-50%);
		left:50%;
		z-index: 2;

		@media #{$mobile}{
			padding-top:50px;
		}		
	}
	
	#gmap{
		height:100%;
		z-index: 1;
		position:relative;
	}
}



