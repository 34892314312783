/* ========================*/
/* ==[ Style guide ]== */
/*========================*/
.page-style-guide{
	background-color:$c-gray-bg;

	.container-main{
		margin:auto;
	}

	.hero-style{
		padding-top:200px;
		position: relative;
		z-index: 1;
		width:100%;
		@media #{$mobile}{
			padding-top:120px;
		}

		h4{
			@include subTitle;
			font-size:13px;
			margin-bottom:40px;
		
			img{
				max-width:600px;
				max-height:150px;

				@media #{$mobile}{
					max-width:250px;
				}
			}

			&.-line:before{
				opacity:.3;
			}
		}

		.btn{
			@media #{$mobile}{
				.label{
					font-size:10px;
				}
			}
		}
	}

	.bg-svg{
		position: absolute;
		top:0;
		width:100%;
		height:800px;
		z-index: 1;
		pointer-events: none;
		transform: translateX(-50%);
		left: 50%;
	    min-width: 1700px;

		svg{
			width:100%;
			height:800px;

			*{
				fill:#fff;
			}
		}
	}	
	
	h1{
		@include MainExtraBold;
		font-size:80px;
		color:$c-gray-text;
		text-transform: uppercase;
		line-height:1.1em;
		margin-bottom:30px;
		max-width: 800px;

		@media #{$tablet}{
			font-size: 60px;
		}

		@media #{$mobile}{
			font-size: 30px;	
		}
	}

	

	.wrapper{
		width: 100%;
		max-width:1600px;
		position: relative;
		z-index: 20;
		padding-bottom:100px;
		@media #{$mobile}{
			padding-bottom:50px;
		}
	}

	.img{
		position:relative;
		width:100%;

		.description{
			bottom:20px;
			left:20px;
			@include subTitle;
			position:absolute;
		}

		img{
			width:100%;		
		}
	}
}


// Navigation
.page-style-guide{
	.navigation{
		position: relative;
		z-index: 2;
		margin:auto;
		max-width:1600px;

		.wrapper-nav{
			lost-utility:clearfix;
			margin-bottom: 80px;
			@media #{$mobile}{
				margin-bottom: 0px;
			}
		}

		.col{
			lost-column:1/3 3 20px;
			@media #{$mobile}{
				lost-column:1/1 1 0px;
				margin-bottom: 30px;
			}
		}

		h4{
			font-size:20px;
			@include MainExtraBold;
			color:$c-gray-text;
			text-transform: uppercase;
			margin-bottom:25px;
		}

		li a{
			@include subTitle;
			display: block;
			margin-bottom:15px;
			transition:color .3s ease;
			
			.desktop &:hover{
				color:#000;
			}
		}
	}
}

// Single-block
.section-block{
	.container-main{
		padding-top:90px;
		@media #{$mobile}{
			padding-top:50px;
		}
		&:before{
			content:'';
			height:2px;
			width:calc(100% - 26vw);
			background-color:#c9c9c9;
			position: absolute;
			top:0;

			@media #{$mediumDesktop}{
				width:calc(100% - 24vw);
			}

			@media #{$smallDesktop}{
				width:calc(100% - 14vw);
			}

			@media #{$tablet}{
				width:calc(100% - 40px);
			}
		}
	}

	h2{
		@include MainExtraBold;
		font-size:80px;
		color:$c-gray-text;
		text-transform: uppercase;
		line-height:1.1em;
		margin-bottom:60px;
		width:calc(100% + 160px);
		position:relative;
		left:-80px;
		@media #{$tablet}{
			font-size: 60px;
		}

		@media #{$mobile}{
			font-size: 30px;	
			margin-bottom: 40px;
			left:0;
			width:100%;

		}
	}

	.wysiwyg{
		width:calc(100% - 160px);
		margin:auto;



		p:last-of-type{
			margin-bottom: 0;
		}

		@media #{$mobile}{
			width:100%;
		}

		strong{
			color:#000;
			@include MainBold;
		}

		img{
			width:100%;
			height: auto;
		}

		h6{
			@include subTitle;
			color:$c-gray-text;
			letter-spacing: 1px;
			line-height: 1.7em;
			margin:25px 0 20px;
			font-size:13px;
		}

		.wp-caption{
			width:calc(100% + 160px) !important;
			height:auto !important;
			left:-80px;
			position:relative;
			margin-top:40px;

			@media #{$mobile}{
				width:100% !important;
				left:0;

			}

			img{
				width:100%;
				height:auto !important;
			}

			.wp-caption-text{
				bottom:15px;
				left:20px;
				@include subTitle;
				position:absolute;
				@media #{$mobile}{
					font-size:10px;
					left:10px;
					bottom:10px;
				}
			}
		}
		
		h3{
			position:relative;
			margin-bottom: 30px;
			margin-top: 80px;
			@include MainBold;
			font-size:28px;
			color:$c-gray-text;
			@media #{$mobile}{
				margin-top: 40px;
			}
		}

		p{
			@include mainText;
			margin-bottom: 30px;
		}		

		blockquote{
			position:relative;
			margin-bottom: 30px;
			
			p{
				font-size:20px;
				@include MainBook;	
				line-height: 1.7em;
				color:$c-gray-text;
			}

			&:before{
				content:'';
				position:absolute;
				background-color:$c-main;
				left:-80px;
				width:10px;
				height:100%;
			}						
		}	
	}
}

.page-style-guide .two-col-block{
	margin-top:100px;
	margin-bottom:100px;
	
	@media #{$mobile}{
		margin-top:50px;
		margin-bottom:50px;	
	}
	
	img{
		width:100%;
	}
	
	.wysiwyg{
		width:100%;
	}

	.wrapper-two-col{
		lost-utility:clearfix;
	}

	.col{
		lost-column:1/2 2 50px;
		@media #{$mobile}{
			lost-column:1/1 1 0px;
			&.right{
				margin-top:40px;
			}
		}
	}

	h3{
		margin-top:0;
	}
}


.page-style-guide .three-col-block{
	lost-utility:clearfix;
	margin-bottom:100px;
	margin-top:50px;

	@media #{$mobile}{
		margin-bottom:50px;		
	}

	& + .three-col-block{
		margin-top: -80px;
	}

	.col{
		lost-column:1/3 3 20px;
		@media #{$mobile}{
			lost-column:1/1 1 0px;
			margin-bottom:20px;
		}
		.img{
			width:100%;
			img{
				width:100%;
			}
		}
	}
}
.page-style-guide .five-col-block{
	lost-utility:clearfix;
	margin-top:50px;

	@media #{$mobile}{
		margin-bottom:20px;		
	}

	& + .five-col-block{
		margin-top: -80px;
	}

	.col{
		lost-column:1/5 5 20px;
		@media #{$mobile}{
			lost-column:1/2 2 20px;
			margin-bottom:20px;
		}
		.img{
			width:100%;
			img{
				width:100%;
			}
		}
	}
}


.page-style-guide .color-block{
	margin-top:40px;
	// margin-bottom:100px;
	lost-utility:clearfix;

	@media #{$mobile}{
		margin-bottom:50px;
	}
	.col{
		lost-column:1/3 3 20px;
		@media #{$mobile}{
			lost-column:1/1 1 0px;
			margin-bottom:40px;
		}
	}

	.color-bg{
		height:140px;
		width:100%;		
		position:relative;
		img{
			width:100%;
			height:100%;
		}
	}

	h6{
		@include subTitle;
		color:$c-gray-text;
		letter-spacing: 2px;
		margin:25px 0 20px;
		font-size:13px;
	}

	p{
		@include subTitle;
		text-transform:uppercase;
		color:$c-gray-light-text;
		margin-bottom:15px;
	}
}

