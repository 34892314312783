/* ========================*/
/* ==[ Logo Block ]== */
/*========================*/

.logo-block{
	background-color:#fff;
	
	&.container-main{
		padding-top:100px;
		padding-bottom:60px;

		@media #{$mobile}{
			padding-top:50px;
			padding-bottom:40px;	
		}
	}
	
	h2{
		@include sectionTitle;	
		margin-bottom:20px;
	}

	p{
		@include mainText;
	}
	
	.logo-block__header{
		text-align: center;
	}

	.logo-block__list{
		lost-utility:clearfix;
		margin-top:120px;
		display: flex;
		align-items:center;
		justify-content: center;
		flex-wrap:wrap;

		@media #{$mobile}{
			margin-top:40px;
		}

		.single-logo{
			display:inline-block;
			width:25%;
			flex-grow:1;
			display: flex;
			align-items:center;
			justify-content: center;
			margin-bottom:100px;

			@media #{$mobile}{
				width:50%;
				margin-bottom:30px;
			}
		}

		img{
			max-width:	130px;
			max-height: 130px;
			width:100%;

			@media #{$mobile}{
				width:70%;
			}
		}
	}
}