/* ========================*/
/* ==[ Career ]== */
/*========================*/
/* [GEN] ==== General
/* [INF] ==== Info block
/* [MAP] ==== Map
/* [TEXT] ==== Text Image Block
/* [HIR] ==== Hiring Block
/* [ALL] ==== All Jobs Block
/* ========================*/

/* -----------------------*/
/* [GEN] ==== General
/*------------------------*/
.page-career{
	background-color:$c-gray-bg;

	.preview-block {
		perspective: none !important;
	}


	.location-block .title-other-offices {
		width: 70vw;
		margin-left: auto;
		margin-right: auto;
		font-size: 33px;
		font-family: "ReneBold", sans-serif;
		text-transform: uppercase;
		text-align:center;
		color: #333333;
		text-align: center;
		padding-top: 100px;
		z-index: 2;
		pointer-events: none;
	}

	.btn-sound-video{
		width: 60px;
		height: 60px;
		position: absolute;
		background: $c-main;
		bottom: 70px;
		right: 0;
		cursor: pointer;
		z-index: 2;


		.sound-on, .sound-off{

			svg{
				width: 30px;
				height: 30px;
				margin-left: 15px;
				margin-top: 15px;
				path{
					fill: #000;;
				}
			}
		}

		.sound-off{
			display: none;
		}
	}


	.video{
		//display:none;
	}

	.btn{
		position: relative;
		left:auto;
		display:block;

		@media #{$tablet}{
			position: absolute;
			//left:-999em;
		}
	}

	.btn-scroll{
		position: absolute;
		//left:-999em;

		@media #{$tablet}{
			position: relative;
			left:auto;
			display:block;
		}
	}	

	.btn-scroll .arrow-down{
		position: absolute;
	    left: 15px;
	    transform: translateY(-50%) rotate(90deg);
	    top: calc(50% + 2px);	
	}

	.filter-bg{
		width:100%;
		background-color:$c-gray-text;
		height:100%;
		position: absolute;
		top:0;
		left:0;
		z-index: 1;
		opacity:.4;
		transform:translateZ(0);
	}

	@media #{$mobile}{
		min-height:540px;
	}

	.video{
		height:100%;
		width:100%;
		object-fit:cover;
		

		@media #{$tablet}{
			//display:none;
		}
	}

	.home-hero__bg{
		position : absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-size:cover;
		background-position:center center;
		// transform:translateZ(0);
	}

	.header-img-mobile{
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

/* -----------------------*/
/* [INF] ==== Info block
/*------------------------*/
.page-career .info-block{
	padding-top:80px;
	padding-bottom:150px;
	lost-utility:clearfix;
	background-color:#fff;

	h3{
		width: 70vw;
		margin-left: auto;
		margin-right: auto;
		font-size: 33px;
		font-family: "ReneBold", sans-serif;
		text-transform: uppercase;
		text-align:center;
		color: #333333;
		text-align: center;
		padding-bottom: 90px;
		z-index: 2;
		pointer-events: none;
	}

	@media #{$mobile}{
		padding-top:40px;
		padding-bottom:40px;
	}

	.intro{
		lost-column:6/16 2 20px;

		@media #{$mobile}{
			lost-column:1/1 1 0px;
			text-align:center;
			margin-bottom:40px;
		}

		p{
			@include mainTextBig;
			line-height: 1.6em;
			letter-spacing: .5px;

			@media #{$mobile}{
				font-size:19px;
			}
		}
	}

	.info-list{
		lost-column:1/1 2 20px;	
		lost-utility:clearfix;

	

		@media #{$mobile}{
			lost-column:1/1 1 0px;
		}


		

		.single-info{
			lost-column:1/2 2 10%;	
			margin-bottom:50px;

			display: flex;
			flex-direction: row;
			align-items: flex-start;

			

			h4{
				margin-top: 0;

				@media #{$tablet}{
					margin-top: 20px;
				}
			}
			.infos-description{
				margin-left: 25px;

				@media #{$tablet}{
					margin-left: 0px;
				}

				@media #{$mobile}{
					margin-left: auto;
					margin-right: auto;
				}
				
			}

			@media #{$tablet}{
			flex-direction: column;
			}

			@media #{$mobile}{
				lost-column:1/1 1 0px;
				text-align:center;

				img {
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

/* -----------------------*/
/* [MAP] ==== Map
/*------------------------*/
.page-career .map-icons{
	height:640px;
	background-color:$c-gray-dark;
	position:relative;
	
	@media #{$mobile}{
		height:450px;
	}

	h3{
		@include sectionTitle;	
		color:#fff;
		text-align:center;
		padding-top:100px;
		position: absolute;
		transform:translateX(-50%);
		left:50%;
		z-index: 2;
		pointer-events:none;
		
		@media #{$mobile}{
			padding-top:50px;
		}
	}

	#gmap{
		height:100%;
		z-index: 1;
		position:relative;
	}

	.pins{
		display:none;
	}
}

/* -----------------------*/
/* [TEXT] ==== Text Image Block
/*------------------------*/
.page-career .textimage-block{
	padding: 150px 40px;
	background-color:#fff;
	position: relative;

	@media #{$mobile}{
		padding:20px 0px;
	}
	
	.container-main{
		display: flex;
		justify-content:space-between;	
		align-items:center;

		@media #{$mobile}{
			flex-direction:column;
		}
	}

	.left{
		lost-column:6/16 2 0px;
		left:4vw;
		position:relative;

		@media #{$mobile}{
			lost-column:1/1 1 0px;
			order:2;
			left:0;
		}
		
		h3{
			@include MainExtraBold;	
			letter-spacing: 1px;
			font-size: 35px;
			text-transform: uppercase;
			display: inline-block;
			color:$c-gray-text;
			top:3px;
			position:relative;
			margin-bottom:20px;

			@media #{$mobile}{
				font-size:21px;
			}
		}

		p{
			@include mainText;
			margin-bottom:30px;
		}

		ul li{
			line-height:3em;
			@include MainBold;
			font-size:11px;
			color:$c-gray-light-text;
			text-transform: uppercase;	
			letter-spacing: 1px;
			position: relative;
			margin-left:15px;
		    margin-bottom: 10px;
			line-height: 1.5em;

			&:before{
				content:'';
				width:4px;
				height:4px;
				border-radius:50%;
				position: absolute;
				left:-15px;
				top:5px;
				background-color:$c-main;
			}
		}
	}

	.right{
		lost-column:1/2 2 20px;
		height:330px;
		overflow: hidden;
		order:1;

		@media #{$mobile}{
			lost-column:1/1 1 0px;
			height: 200px;
			margin-bottom:30px;
		}
		
		img{
			width:130%;
			max-width: none;
			top:-30px;
			position: relative;
		}
	}
}

/* -----------------------*/
/* [HIR] ==== Hiring Block
/*------------------------*/
.page-career .career-hiring-block{
	padding-top:150px;
	position:relative;
	z-index: 2;

	@media #{$mobile}{
		padding-top:50px;
		padding-left:0;
		padding-right:0;
	}

	.container-inner{
		text-align:center;
	}

	h1{
		@include mainTitle;
		font-size:80px;
		padding-bottom:80px;

		@media #{$mobile}{
			padding-bottom:0px;
			padding-left:20px;
			padding-right:20px;
		}
	}

	.career-hiring-block__list{
		background-color:$c-gray-bg;
		lost-utility:clearfix;
		padding-bottom:200px;
		padding-top:100px;

		@media #{$mobile}{
			padding-top:50px;
			padding-bottom:50px;
		}

		.right{
			lost-column:9/16 1 0;
			lost-offset:7/16;

			@media #{$tablet}{
				lost-column:8/16 1 0;
			}

			@media #{$mobile}{
				lost-column:1/1 1 0px;
				lost-offset:1/1;
			}
		}
		
		header{
			lost-utility:clearfix;

			@media #{$mobile}{
				display:flex;
			}
		}

		.tab{
			lost-column:1/2 2 0;
			background-color:#f2f2f2;
			text-align:center;
			padding:20px 0;
			transition:background-color .3s ease;
			cursor:pointer;

			@media #{$tablet}{
				padding:10px;
			}

			@media #{$mobile}{
				padding:10px;
				text-align: left;
			}
			
			.icon-plus {
				display:none;
			}

			.desktop &:hover{
				background-color:$c-gray-text;

				span{
					color:#fff;
				}
			}

			span{
				@include subTitle;
				color:#999999;
				font-size:13px;
				transition:color .3s ease;

				@media #{$mobile}{
					font-size:11px;
					letter-spacing:1px;
				}
			}

			&.active{
				background-color:$c-main;

				span{
					color:$c-gray-text;
				}
			}
		}

		.tab-content{
			background-color:#fff;
			display:none;

			@media #{$mobile}{
				.icon-plus {
					display:block;
					position: absolute;
					left:10px;

					.wrapper{
						height:20px;
						width:20px;
						position:relative;

						.line{
							transform-origin:center center;
							position:absolute;
							transform:translate(-50%,-50%);
							left:50%;
							top:50%;
							background-color:$c-gray-text;
							width:100%;
							height:2px;
							transition:opacity .3s ease;

							&:nth-of-type(2){
								transform:translate(-50%,-50%) rotate(90deg);
							}
						}
					}
				}
			}

			&.active{
				display:block;
			}
			
			ul{
				padding:40px 80px 40px 0;

				@media #{$tablet}{
					padding:20px 40px 20px 0;
				}

				@media #{$mobile}{
					padding:0;
				}
			}

			li{
				text-align:left;
				width:100%;
				padding:30px 20%;
				position:relative;
				cursor: pointer;

				@media #{$mobile}{
					padding:20px 40px;
				}

				&:before{
					content:'';
					position:absolute;
					top:0;
					left:0;
					width:calc(100% - 4px + 80px);
					height:calc(100% - 4px);
					border:2px solid $c-main;
					opacity:0;
					transition:opacity .3s ease;
					transform:translateX(-80px);
					z-index: 4;

					@media #{$mobile}{
						display:none;
					}
				}

				&:after{
					content:'';
					position:absolute;
					bottom:0;
					height:1px;
					background-color:$c-gray-bg;
					width:calc(100% - 20% );
					left:20%;
				}

				h5,span{
					transition:color .3s ease
				}

				&.active{
					background-color:transparent;	

					&:before{
						opacity:1;
					}
					
					h5,span{
						color:$c-main;
					}

					@media #{$mobile}{
						background-color:$c-gray-dark;	

						h5,span{
							color:#fff;
						}
						
						.icon-plus .line{
							background-color:#fff;
						}

						.icon-plus{
							transform:rotate(90deg);
						}

						.icon-plus .line:nth-of-type(1){
							opacity: 0;
						}	
					}
				}

				h5{
					@include MainBold;
					color:$c-gray-text;
					font-size:18px;
					margin-bottom:10px;
					text-transform: uppercase;

					@media #{$mobile}{
						font-size:15px;
						margin-bottom:0px;

					}
				}

				span{
					@include subTitle;
					color:$c-gray-light-text;
				}

				&.active{
					.details-box{
						pointer-events:auto;
						opacity:1;
						transform:translate(0px,-50%);
					
						@media #{$mobile}{
							transform:none;
							display:block;
						}
					}
				}
			}
		}

		.details-box{
			position:absolute;
			left:calc(-40% - 60px);
			lost-offset:-4/16;
			width:70%;
			top:50%;
			transition:transform .3s ease,opacity .3s ease;
			opacity:0;
			transform:translate(0,-40%);
			font-size:0;
			pointer-events:none;

			@media #{$tablet}{
				width:100%;
				lost-offset:-6/16;
				left:calc(-50% - 60px);
			}

			@media #{$mobile}{
				position: relative;
				left:-20px;
				transform:none;
				top:0;
				lost-offset:none;
				width:calc(100%  + 40px);
				margin-top:20px;
				margin-bottom:40px;
				display:none;
			}

			.wrapper{
				background-color:$c-gray-dark;
				padding:40px 55px;

				@media #{$tablet}{
					padding:20px 25px;					
				}
				
				@media #{$mobile}{
					padding:20px 0px;
				}
			}

			img{
				width:100%;
				display: block;
				pointer-events: none;
			}

			p{
				@include mainText;
				color:#fff;
				pointer-events: none;
			}

			a{
				margin-top:20px;
				display: inline-block;
				@include subTitle;
				color:#fff;	
				transition:color .3s ease;

				.desktop &:hover{
					color:$c-main;

					svg *{
						fill:$c-main;
					}
				}

				svg *{
					fill:#fff;
					transition:fill .3s ease;
				}
			}
		}
	}
}


/* -----------------------*/
/* [ALL] ==== All Jobs Block
/*------------------------*/
.page-career .all-jobs-block{
	background-color:#fff;
	padding:100px 0 0;
	text-align:center;
	position:relative;
	z-index: 1;

	@media #{$mobile}{
		padding:50px 0;
	}

	h4{
		@include subTitle;
		margin-bottom:30px;
	}

	p{
		@include mainTextBig;
		line-height: 1.6em;
		letter-spacing: .5px;
		max-width:600px;
		margin:auto;

		@media #{$mobile}{
			font-size:20px;
		}
	}

	.all-jobs-block__list{
		width:100%;

		ul {
			width:100%;
			margin-top:100px;

			@media #{$mobile}{
				margin-top:50px;
			}

			li{
				border-top:1px solid $c-gray-light-text;
				width:100%;
				lost-utility:clearfix;
				transition:background-color .3s ease;
				cursor:pointer;
				overflow: hidden;

				&:last-of-type{
					border-bottom:1px solid $c-gray-light-text;
				}

				&.active{
					.content{
						height:auto;
						transform:scaleY(1);
					}

					.icon-plus .line:nth-of-type(1){
						opacity: 0;
					}
				}

				.desktop &:hover,&.active{
					background-color:$c-main;
					border-color:transparent;

					.icon-plus{
						transform:rotate(90deg);
					}

					.circle{
						background-color:$c-gray-text;

						span{
							color:$c-main;
						}
					}
				}
			}	
		}

		header{
			position: relative;
			display:flex;
			width:100%;
			justify-content: space-between;
			align-items:center;

			&.container-main{
				padding-top:50px;	
				padding-bottom:50px;	

				@media #{$mobile}{
					padding-top:30px;	
					padding-bottom:30px;	
				}
			}
			
			h5{
				@include sectionTitle;
				font-size:20px;
				width:70%;

				@media #{$mobile}{
					font-size:16px;
				}
			}

			.icon-plus{
				transition:transform .3s ease;
			}

			.circle{
				border-radius:50%;
				width:35px;
				height:35px;
				float:right;
				display:flex;
				align-items:center;
				justify-content: center;
				background-color:#f2f2f2;
				transition: background-color .3s ease;
				
				span{
					@include MainExtraBold;	
					font-size:13px;
					line-height:1em;
					color:$c-gray-light-text;
					transition: color .3s ease;
					position: relative;
					top:1px;
				}
			}
		}

		.content{
			width:100%;
			overflow: hidden;
			transition:transform .3s ease;	

			.wrapper{
				padding-bottom:40px;	
				lost-utility:clearfix;
			}

			span{
				text-align:center;
				lost-column:1/3 3 20px;
				@include MainExtraBold;
				font-size: 13px;
				text-transform: uppercase;
				color:$c-gray-text;
				margin:10px 0;
				
				@media #{$mobile}{
					lost-column:1/1 1 0px;
				}
			}
		}
	}
	
	.icon-plus .wrapper{
		height:20px;
		width:20px;
		position:relative;

		.line{
			transform-origin:center center;
			position:absolute;
			transform:translate(-50%,-50%);
			left:50%;
			top:50%;
			background-color:$c-gray-text;
			width:100%;
			height:2px;
			transition:opacity .3s ease;

			&:nth-of-type(2){
				transform:translate(-50%,-50%) rotate(90deg);
			}
		}
	}
}
