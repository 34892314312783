/* ========================*/
/* ==[ Overlay video ]== */
/*========================*/

.overlay-video{
	width:calc(100vw - 60px);
	height:calc(100vh - 60px);
	position:fixed;
	top:30px;
	left:30px;
    z-index: 55;
	background-color:#fff;
	pointer-events:none;
	transform:translateY(150px);
	opacity:0;
	transition:transform .6s ease,opacity .6s ease;

	@media #{$mobile}{
		width:calc(100vw - 60px);
		height:calc(90vh - 60px);
	}
	
	iframe{
		width:calc(100vw - 60px);
		height:calc(100vh - 60px);

		@media #{$mobile}{
			height:calc(90vh - 60px);
		}
	}
	
	&.active{
		pointer-events:auto;
		transform:translateY(0px);
		opacity:1;
	}	
}


.overlay-movie-copy{
	display:none;

	@media #{$mobile}{
		overflow:scroll;
	}
}
.overlay-movie-container{
	z-index: 10;
	position:fixed;
	top:0;
}
.overlay-movie{
	z-index: 5;
	width:100vw;
	height:100vh;
	position:fixed;
	display: flex;
	background-color:rgba(0,0,0,0.5);
	padding:20px 0;
	overflow-y: scroll;
	@media #{$tablet}{
		z-index: 500;
	}

	.select-navigation__closed{
		z-index: 5;
		position: relative;
		
		.yellow-text-block{
			margin:auto;
		}

		&.container-main{
			padding:0 5vw
		}
	}
	
	@media #{$mobile}{
		padding:0;
		overflow:hidden;
	}

	.overlay-movie-copy{
		display:block;
		width:880px;
		margin:auto;
		position: relative;
		background-color:#fff;
		margin-bottom:40px;

		.bg-preload{
			width:100%;
			height:100%;
			position: relative;
			background-repeat:no-repeat;
			background-size: cover;
		}

		@media #{$tabletPortrait}{
			width:calc(100% - 80px);
			
		}

		@media #{$mobile}{
			width:100%;
			height:100vh;
		}
	}
	
	.swiper-slide{
		text-align: center;
		display:flex;
		justify-content: center;
		align-items:center;
		height:520px;
		@media #{$mobile}{
			height:auto;
		}
		img{
			width:100%;
		}
	}
	
	.swiper-wrapper{
		@media #{$mobile}{
			height:200px;
		}
	}

	.swiper-container-imagegrid-movie{
		margin:auto;
		width:880px;
		height:520px;
		position:relative;

		@media #{$mobile}{
			height:250px;
			width:100%;
		}
	}

	.overlay-content{
		padding:60px;
		background-color : $c-gray-bg; 

		@media #{$mobile}{
			padding:30px;
		}

		p{
			@include mainText;
		}

		p + p {
			padding-top:10px;
		}

		h2{
			@include sectionTitle;
			margin-bottom:30px;
		}
	}
}

.overlay-newsletter{
	@media #{$tablet}{
		z-index: 500 !important;	
	}
	@media #{$mobile}{
		position:absolute !important;
		bottom:auto;
		height:100vh !important;
		z-index: 50000 !important;
	}
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:fixed !important;

	.gform_confirmation_wrapper {
		@include mainText;
		h3{
			@include sectionTitle;
			text-transform: none;
			margin-bottom:20px;
		}
	}
	
	

	img.gform_ajax_spinner {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid #ccc;
		border-top-color: #333;
		background:none !important;
		animation: spinner .6s linear infinite;
	}
	@keyframes spinner {
		to {transform: rotate(360deg);}
	}
}

.overlay-download-pdf,.overlay-newsletter{
	position: absolute; 
	left: -999em;
	width:100vw;
	height:100vh;
	opacity:0;
	background-color:rgba(0,0,0,0.4);
	padding:30px 0;
	z-index: 40;
	overflow:scroll;
	
	h3{
		@include sectionTitle;
		text-transform:none;
		margin-bottom:40px;
	}

	@media #{$mobile}{
		padding:0px;
		padding-bottom:40px;
	}

	.wrapper{
		width:880px;		
		background-color:#fff;
		padding:40px 60px;
		position: relative;
		margin:auto;

		@media #{$tablet}{
			width:90%;
			padding:40px;
		}

		@media #{$mobile}{
			width:100%;
		}
	}
}

.overlay-newsletter{
	overflow-x:hidden;
}

.overlay-download-pdf{
	padding-top:60px;
	// overflow:hidden;
	z-index: 200;
	.wrapper{
		@media #{$tablet}{
			padding:40px 60px 40px 20px;
		}
	}
	
	@keyframes spinner {
		to {transform: rotate(360deg);}
	}
	 
	.spinner:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid #ccc;
		border-top-color: #333;
		animation: spinner .6s linear infinite;
	}

	h3{
		@include sectionTitle;
		text-transform: none;
	}
	
	.wrapper-input{
		position:relative;
		margin:30px 0;
	}

	input{
		width:300px;
	}

	.placeholder{
		color:$c-gray-light-text;
		@include MainBold;
		letter-spacing: 1px;
		font-size: 11px;
		position:absolute;
		left:20px;
		transform:translateY(-50%);
		top:50%;
		text-transform: uppercase;
		pointer-events: none;



		@media #{$mobile}{
			left:20px;
		}
	}
	
	.loading-msg,.server-error-msg,.email-error-msg,.success-msg{
		transition:opacity .3s ease,transform .3s ease;
		margin-top:20px;
		color:#c63630;
		display: none;
		@include MainBook;
	}

	button{
		background-color:$c-main;
		border:none;
		padding:20px 30px;
		@include MainBold;
		text-transform: uppercase;
		font-size:13px;
		letter-spacing: 1px;
		transition: background-color .3s ease, color .3s ease;

		.desktop &:hover{
			background-color:$c-gray-text;
			color:$c-main;
		}
	}

	&.success{
		.success-msg{
			opacity:1;
			display: block;
			margin-top:0;
		}

		.form-contain{
			display:none;
		}
	}

	&.email-error{
		.email-error-msg{
			opacity:1;
			display: block;
		}		
	}

	&.server-error{
		.server-error-msg{
			opacity:1;
			display: block;
		}		
	}

	&.loading{
		.loading-msg{
			opacity:1;
			display: block;
		}
	}

	&.active .wrapper{
		pointer-events:auto;
	}

	.wrapper{
		position:absolute;
		transform:translate(-50%,-50%);
		top:50%;
		left:50%;
		pointer-events:none;
		@media #{$mobile}{
			transform:none;
			top:auto;
			left:auto;
		}
	}
}


.overlay-download-pdf,.overlay-newsletter,.overlay-movie,.overlay-video{
	.btn-close{
		position:absolute;
		top:0;
		right:0;
		width:60px;
		height:100px;
		background-color:rgba(31,31,31,.4);
		z-index: 5;
		transition: background-color .3s ease;

		@media #{$mobile}{
			height:60px;
		}

		.desktop &:hover{
			background-color:rgba(31,31,31,.9);
			
			.line:nth-of-type(1){
				transform:translate(-50%,-50%) rotate(-45deg);
				background-color:#fff;
			}

			.line:nth-of-type(2){
				transform:translate(-50%,-50%) rotate(45deg);
				background-color:#fff;
			}
		}	

		.line{
			width:20px;
			height:2px;
			position:absolute;
			top:50%;
			left:50%;
			background-color:#fff;
			transition:transform .5s ease, background-color .3s ease;

			&:nth-of-type(1){
				transform-origin:center center;
				transform:translate(-50%,-50%) rotate(135deg);
			}

			&:nth-of-type(2){
				transform-origin:center center;
				transform:translate(-50%,-50%) rotate(-135deg);	
				transition-delay:.1s;
			}
		}
	}
}