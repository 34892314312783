/* ========================*/
/* ==[ Buttons ]== */
/*========================*/
/* [MBT] ==== Main Btn
/* ========================*/

/* -----------------------*/
/* [MBT] ==== Main Btn
/*------------------------*/
.btn,.btn-flat,.btn-special{
	position:relative;
	height:60px;
	display: block;

	svg,.label,.arrow{
		pointer-events:none;
	}

	.loadings{
		position:absolute;
		right:-40px;
		transform:translate(-30px,-50%);
		opacity: 0;
		top:50%;
		transition:opacity .3s ease,transform .3s ease;
	}

	&.loading{
		.loadings{
			opacity: 1;
			transform:translate(0,-50%);
		}
	}

	.label{
		display: block;
		position:absolute;
		// width:100%;
		// right:20px;
		// transform:translateY(-50%);
		text-align: center;

		//top:calc(50% - .5em);
		//Modifs Jeremie

		top: 50%;
		transform: translateY(-50%);
		padding-left: 70px;
		padding-right: 10px;
		
		color:#ffb900;
		text-transform:uppercase;
		font-size:$fs-btn;
		letter-spacing: 1px;
		@include MainBold;
		will-change:transform;
	}

	.arrow{
		position:absolute;
		left:15px;
		transform:translateY(-50%);
		top:50%;
	}
	
	&.white-arrow .arrow{
		svg * {
			fill:#fff;
		}
	}
}

.btn-flat{
	.label{
		// right:26px;
		padding-left: 00px;
	}
}
