.page-404{
	.wrapper{
		height:800px;
		background-color: $c-gray-bg;
		position: relative;

		@media #{$mobile}{
			height:540px;
		}
	}

	.container-main{
		position: relative;
	}

	h1{
		color:$c-main;
		font-size: 425px;
		@include MainExtraBold;
		position:absolute;
		transform: translate(-50%,-50%);
		left:50%;
		top:50%;
		z-index: 1;
		letter-spacing: 10px;

		@media #{$mobile}{
			font-size:40vw;
			top:80px;
			transform:translate(0,0);
			position: relative;
			top:auto;
			left:auto;
			padding-top:80px;
		}
	}

	img{
		position: absolute;
		transform: translate(-50%,-50%);
		left:50%;
		top:60%;
		z-index: 5;
		@media #{$mobile}{
			width:40%;
			position: absolute;
		    transform: translate(0, -50%);
		    right: -5vw;
		    left:auto;
		    top: 70%;
		}
	}

	.footer{
		position: absolute;
		bottom:100px;
		width:calc(100% - 26vw);
		display:flex;
		justify-content: space-between;
		align-items:center;	
		border-bottom:2px solid #ccc;
		padding-bottom:30px;
		z-index: 4;

		@media #{$mediumDesktop}{
			width:calc(100% - 18vw);
		}

		@media #{$smallDesktop}{
			width:calc(100% - 14vw);	
		}
		
		@media #{$tablet}{
			width:calc(100% - 80px);	
		}

		@media #{$mobile}{
			width:70%;	
			display: block;
			position: relative;
			bottom:auto;

		}

		p{
			text-transform: uppercase;
			@include MainExtraBold;
			font-size:20px;
			max-width: 250px;
			color:$c-gray-text;
			z-index: 1;
			@media #{$mobile}{
				max-width: none;
				width:100%;
				text-align:center;
				margin-bottom:30px;
			}
		}
		@media #{$mobile}{
			.btn{
				margin:auto;
				margin-top:30px;
			}	
		}
		

		.arrow svg{
			fill:#fff;
		}
	}
}