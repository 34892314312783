/* ========================*/
/* ==[ Footer ]== */
/*========================*/
/* [GEN] ==== General
/* ========================*/

.main-footer{
	padding-top:100px;
	background-color: #000;
	text-align:center;
	position: relative;

	@media #{$mobile}{
		padding-top:40px;
	}
	
	.bg-svg{
		position: absolute;
		width:100%;
		height:500px;
		bottom:0;
		pointer-events: none;
		&.animated{
			// left:-50%;	
		}

		svg{
			width:100%;
			position: absolute;
			bottom:50px;
			height:500px;
			right:0;

			@media #{$mobile}{
				height: 200px;
				bottom:130px;
			}

			*{
				fill:#1f1f1f;
			}
		}
	}

	.container-main{
		padding-bottom:100px;
		position: relative;
		z-index: 2;

		@media #{$mobile}{
			padding-bottom:0px;
		}
	}

	.text-wrapper{
		margin-bottom:90px;

		@media #{$mobile}{
			margin-bottom:40px;
		}

		h4{
			@include subTitle;
			margin-bottom: 20px;
		}

		p{
			@include mainTextBig;
			color:#fff;
			max-width:610px;
			margin: auto;

			@media #{$mobile}{
				font-size:17px;
			}
		}
	}

	.col-wrapper{
		lost-utility:clearfix;
		position:relative;

		&:before{
			content:'';
			height:2px;
			background-color: $c-gray-bg;
			opacity: .5;
			position: absolute;
			bottom:25px;
			width:14vw;
			transform:translateX(-50%);
			left:50%;
			z-index: 0;

			@media #{$tablet}{
				width:8vw;
			}

			@media #{$mobile}{
				display:none;
			}
		}

		.col{
			lost-column:1/2 2 0;
			text-align:center;
			position: relative;

			@media #{$mobile}{
				lost-column:1/1 1 0;
				&.-left{
					margin-bottom:50px;
					&:before{
						content:'';
						position: absolute;
						bottom:-15px;
						width:2px;
						background-color: $c-gray-text;
						transform:translateX(-50%);
						left:50%;
						height:30px;
						display: block;
					}
				}
			}
		}

		a, p{
			color:#fff;
			@include MainExtraBold;
			font-size: $fs-text-bigger;
			text-transform: uppercase;
			display: inline-block;
			width:auto;
			padding:0 40px;

			@media #{$mobile}{
				font-size:21px;
				margin-bottom:40px;
			}
		}

		a > span{
			opacity:1;
			transition:color .4s ease;

			.desktop &:hover{
				color:$c-main;
			}
		}

		h4{
			@include subTitle;
			margin-bottom: 20px;
		}
	}

	.lower-footer{
		width:100%;
		padding:0 9vw;
		background-color:$c-gray-dark;
		z-index: 5;
		position: relative;

		@media #{$tablet}{
			padding-bottom:30px;	
		}

		.wrapper-lower{
			height:100px;	
			display: flex;
			justify-content: space-between;
			align-items:center;
			@media #{$mobile}{
				display: block;
				height:150px;
				align-items:initial;
			}
		}

		@media #{$mobile}{
			padding:0 50px;
			padding-bottom:20px;
		}

		.label{
			color:$c-gray-light-text;
			@include MainBold;
			font-size:11px;
			letter-spacing: 1px;
			text-transform: uppercase;
			@include underlineHover(10px,1px,1px);

			@media #{$mobile}{
				top:70px;
				float:left;
			}
		}
		
		span{
			color:$c-gray-light-text;
			font-size:13px;
			@include MainBook;
			
			@media #{$mobile}{
				top:68px;
				position: relative;
				float:right;
			}
		}
	}

	.icon-wrapper{
		width:30%;
		display:flex;
		justify-content: space-between;
		align-items:center;

		@media #{$mobile}{
			position: absolute;
			transform:translateX(-50%);
			left:50%;
			width:calc(100% - 100px);
			top:25px;
		}
		
		a svg *{
			opacity:1;
			transition:fill .3s ease;
		}

		.desktop & a:hover{
			svg * {
				fill:$c-main;
			}			
		}
	}
}
