/* ========================*/
/* ==[ Hero Full ]== */
/*========================*/

.hero-full{
	height:800px;
	background-size:cover;
	display: flex;
	align-items:center;
	padding: 0 13vw;
	position: relative;

	.bg{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height: 100%;
		overflow: hidden;
		background-color:#000;

		.bg-image{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height: 100%;
			background-size:cover;
			background-position:center center;
			will-change:transform;
		}
	}

	.filter-bg{
		width:100%;
		background-color:$c-gray-text;
		height:100%;
		position: absolute;
		top:0;
		left:0;
		z-index: 2;
		opacity:.4;
		transform:translateZ(0);

		@media #{$tablet}{
			background-color:#000000;
		}
	}

	.video{
		height:100%;
		width:100%;
		object-fit:cover;
		@media #{$tablet}{
			display:none;
		}
	}

	@media #{$mobile}{
		height:480px;
		padding: 0 20px;

		.page-project-single &,.page-product-single &{
			height:600px;
			display: block;
			padding-top:100px;
		}
	}
	
	.wrapper{
		width: 100%;
		max-width:1600px;
		position: relative;
		z-index: 3;
	}
	
	h1{
		@include mainTitle;
		color:#fff;
		margin-bottom:30px;
		max-width: 700px;
		transform:translateZ(0);

		@media #{$mobile}{
			font-size:30px;
			.page-project-single &,.page-product-single &{
				text-align:center;
				margin-bottom: 20px !important;
			}
		}	
	}

	.btn{
		.arrow{
			position: absolute;
		    left: 25px;
		    transform: translateY(-50%);
		    top: calc(50% + 2px);
		}

		@media #{$mobile}{
			.page-project-single &,.page-product-single &{
				margin:auto;
			}
		}
	}


	h4{
		@include subTitle;
		color:#fff;
		font-size:13px;
		margin-bottom:40px;
		transform:translateZ(0);

	
		img{
			max-width:200px;
			max-height:150px;

			@media #{$mobile}{
				max-width:200px;
			}
		}

		&.-line:before{
			background-color:#fff;
			opacity:.3;
		}

		@media #{$mobile}{
			.page-project-single &,.page-product-single &{
				text-align:center;
				margin-bottom:20px;

				img{
					max-width:130px;
				}
			}	
		}
	}

	.hero-full__caracter{
		position:absolute;
		width:60vw;
		// width:1100px;
		// top:60%;
		// transform:translateY(-50%);
		bottom:-20%;
		right: 0vw;
		z-index: 2;
		min-width:900px;
		max-width: 950px;
		transform:translateZ(0);
		display: block;
		will-change:transform;
		@media #{$smallDesktop}{
			right: -15vw;

		}
		
		@media #{$bigDesktop}{
			right:15vw;
		}

		@media #{$tablet}{
			min-width:700px;
			bottom:-12%;
		}

		
		@media #{$mobile}{
			min-width:0px;
			z-index: 23;
			top:auto;
			bottom:-60px;
			transform:translateX(-50%) !important; 
			left:50%;
			width: 360px;
			z-index: 1;
		}
	}

	
}

.hero-career{
		.video{
			display: block;
		}
}

